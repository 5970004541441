import React, { useState, useRef } from 'react';
import {
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { Settings } from "@carbon/icons-react";
// import Button from '@material-ui/core/Button';
import MenuContainer from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import MoreIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import { Theme, useMediaQuery } from '@material-ui/core';

// import AboutDialog from '../../../AboutDialog/AboutDialog';
import ConnectionOptionsDialog from '../../../ConnectionOptionsDialog/ConnectionOptionsDialog';
import DeviceSelectionDialog from '../../../DeviceSelectionDialog/DeviceSelectionDialog';
// import SettingsIcon from '../../../../icons/SettingsIcon';
import { useAppState } from '../../../../state';


export default function SettingsMenu({ mobileButtonClass }: { mobileButtonClass?: string }) {
  // const classes = useStyles();
  const { roomType } = useAppState();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [menuOpen, setMenuOpen] = useState(false);
  // const [aboutOpen, setAboutOpen] = useState(false);
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
  const [connectionSettingsOpen, setConnectionSettingsOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Tooltip placement='top' fontWeight="600" label="Setări">
        <IconButton
          w="54px"
          isRound
          bg="#fff"
          color="brandGray.500"
          borderColor="brandGray.500"
          _hover={{
            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.24)",
          }}
          _active={{
            bg: '#fff'
          }}
          style={{ height: "54px" }}
          aria-label="Setări"
          icon={<Settings size={24}/>}
          variant="outline"
          ref={anchorRef}
          onClick={() => setMenuOpen(true)}
        />
      </Tooltip>
      {/* {isMobile ? (
        <Button
          ref={anchorRef}
          onClick={() => setMenuOpen(true)}
          startIcon={<MoreIcon />}
          className={mobileButtonClass}
        >
          More
        </Button>
      ) : (
        <Button
          ref={anchorRef}
          onClick={() => setMenuOpen(true)}
          startIcon={<SettingsIcon />}
          className={classes.settingsButton}
        >
          Setări
        </Button>
      )} */}
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : 'bottom',
          horizontal: 'center',
        }}
      >
        {/* <MenuItem onClick={() => setAboutOpen(true)}>
          <Typography variant="body1">About</Typography>
        </MenuItem> */}
        <MenuItem onClick={() => setDeviceSettingsOpen(true)}>
          <Typography>Setări audio și video</Typography>
        </MenuItem>
        {roomType !== 'peer-to-peer' && roomType !== 'go' && (
          <MenuItem onClick={() => setConnectionSettingsOpen(true)}>
            <Typography>Setări conexiune</Typography>
          </MenuItem>
        )}
      </MenuContainer>
      {/* <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      /> */}
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
      <ConnectionOptionsDialog
        open={connectionSettingsOpen}
        onClose={() => {
          setConnectionSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
