import {
  IAuthCredentials,
  ILoginFormErrors,
  IRegisterFormErrors,
  IResetPasswordPayload,
  IResetPasswordFormErrors,
  IAuthEmail,
  IChangeEmailFormErrors,
  IAuthEnterCredentials,
  IEnterFormErrors,
} from "./types";
import { IRegisterFormPayload } from "../user/types";
import { IRegisterFormPayload as IAPRegisterFormPayload } from "../activityProvider/types";

const initialRegisterFormPayload: (IRegisterFormPayload | IAPRegisterFormPayload) & {
  agreementChecked: boolean;
  ageChecked: boolean;
} = {
  u_name: "",
  f_name: "",
  l_name: "",
  email: "",
  pw: "",
  ageChecked: false,
  agreementChecked: false,
  mobile_phone_number: "",
};

const initialRegisterFormErrors: IRegisterFormErrors = {
  u_name: null,
  f_name: null,
  l_name: null,
  email: null,
  pw: null,
  ageChecked: null,
  agreementChecked: null,
  mobile_phone_number: null,
};

const defaultUserInfo = {
  address: "",
  country: "RO",
  city: "",
  labels: [],
  tags: [],
  locale: "ro_RO",
  timezone: "Europe/Bucharest",
};

const initialLoginFormPayload: IAuthCredentials = {
  user_name: "",
  password: "",
};

const initialEnterFormPayload: IAuthEnterCredentials = {
  user_name: "",
};

const initialChangeEmailFormPayload: IAuthEmail = {
  user_name: "",
};

const initialLoginFormErrors: ILoginFormErrors = {
  user_name: null,
  password: null,
};

const initialEnterFormErrors: IEnterFormErrors = {
  user_name: null,
};

const initialChahgeEmailFormErrors: IChangeEmailFormErrors = {
  user_name: null,
};

const initialResetPasswordFormPayload: IResetPasswordPayload = {
  password: "",
  repeatPassword: "",
};

const initialResetPasswordFormErrors: IResetPasswordFormErrors = {
  password: null,
  repeatPassword: null,
};

export {
  initialRegisterFormPayload,
  initialRegisterFormErrors,
  initialLoginFormPayload,
  initialLoginFormErrors,
  initialResetPasswordFormPayload,
  initialResetPasswordFormErrors,
  defaultUserInfo,
  initialChangeEmailFormPayload,
  initialChahgeEmailFormErrors,
  initialEnterFormPayload,
  initialEnterFormErrors,
};
