import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  Input,
  Button,
  Box,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { Email } from "@carbon/icons-react";
import { useEnterInitMutation } from "../service";
import { setAuthorizationTokens } from "../slice";
import { useAppDispatch } from "../../hooks";
import { Form, FormControl } from "../../shared/form";
import { validateForm } from "../../utils/forms";
import { enterValidationRules } from "../../utils/forms/validators";
import { IAuthEnterCredentials, IEnterFormErrors } from "../types";
import { initialEnterFormPayload, initialEnterFormErrors } from "../defaults";
import { userApi } from "../../user/service";
import { IRoles } from "../../user/types";
import { AP_HOME_PAGE, USER_HOME_PAGE, USER_REDIRECT_AFTER_ENTER_WAIT } from "../../routes/defaults";
import useNotification from "../../shared/hooks/useNotification";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalyticsHelper";
import { cleanupUTMCookies, getUTMParamsFromCookie } from "../../utils/requests";

type Props = {
  userType: IRoles;
  initial_email: string | null | undefined;
  initial_submit: boolean | undefined;
};

const EnterForm = ({ userType, initial_email, initial_submit }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const notify = useNotification();

  const [enter, { isLoading }] = useEnterInitMutation();
  const enterEnterForm = { ...initialEnterFormPayload };
  if (initial_email) {
    enterEnterForm.user_name = initial_email;
  }

  const [formValues, setFormValues] = useState<IAuthEnterCredentials>(
    enterEnterForm
  );
  const [formErrors, setFormErrors] = useState<IEnterFormErrors>(
    initialEnterFormErrors
  );

  const { user_name } = formValues;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormErrors(initialEnterFormErrors);
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    const authCredentials = { user_name };

    const validationErrors = validateForm(
      authCredentials,
      enterValidationRules
    );

    if (!validationErrors) {
      try {
        setFormErrors(initialEnterFormErrors);

        const utm = getUTMParamsFromCookie();
        const authTokens = await enter({ ...authCredentials, utm }).unwrap();
        if (authTokens.new_user) {
          // New user will get the auth token and proceed with the confirmation flow
          dispatch(setAuthorizationTokens(authTokens));
          const user = await dispatch(
            userApi.endpoints.getLoggedInUser.initiate()
          ).unwrap();

          const isRegisteredUser = user.roles.includes("registered_user");
          const isActivityProvider = user.roles.includes("activity_provider");

          if (isRegisteredUser) {
            TrackGoogleAnalyticsEvent("user_login", "user_login_completed", "")
            navigate(USER_HOME_PAGE);
          } else if (isActivityProvider) {
            TrackGoogleAnalyticsEvent("activity_provider_login", "activity_provider_login_completed", "")
            navigate(AP_HOME_PAGE);

          }
          cleanupUTMCookies();
        }
        else {
          navigate(USER_REDIRECT_AFTER_ENTER_WAIT);
          // Tell the user to wait for the email
          console.error('REDIRECT TO WAIT FOR EMAIL')
        }

      } catch (error: any) {
        console.log("Error", error);
        const errorMessage =
          "status" in error && error.status === 401
            ? "auth_login_wrong_credentials_error"
            : "auth_login_error";
        notify("error", errorMessage);
      }
    } else {
      setFormErrors({ ...initialEnterFormErrors, ...validationErrors });
    }
  };

  useEffect(() => {
    if (initial_submit) {
      handleLogin();
    }
  }, [initial_submit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box w="100%">
      <Form onSubmit={handleLogin} mt="16px">
        <FormControl errorMessage={formErrors.user_name}>
          <InputGroup mb="12px">
            <InputLeftElement
              pointerEvents="none"
              opacity=".54"
              pt="3px"
              px="8px"
              h="100%"
              children={<Email size={20} color="#696871" />}
            />
            <Input
              placeholder={intl.formatMessage({ id: "auth_email" })}
              type="email"
              name="user_name"
              value={user_name}
              size="lg"
              required
              onChange={(e) => onInputChange(e)}
            />
          </InputGroup>
        </FormControl>
        <Button w="full" size="lg" type="submit" isLoading={isLoading} mt="8px">
          {intl.formatMessage({ id: "auth_enter_btn" })}
        </Button>
      </Form>
    </Box>
  );
};

export default EnterForm;
