import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Text, Flex, Button, AspectRatio } from "@chakra-ui/react";
import Steps from "../../shared/Steps";
import { AP_REDIRECT_AFTER_ONBOARDING } from "../../routes/defaults";

const Step6 = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <Steps activeStep={6} stepCount={6}>
      <h1>{intl.formatMessage({ id: "ap_onboarding_step6_title" })}</h1>
      <Text textStyle="largeP" mt="8px" mb="24px">
        {intl.formatMessage({ id: "ap_onboarding_step6_subtitle" })}
      </Text>

      <Flex mb="32px" alignItems="center" justifyContent="center" w="full">
        <AspectRatio
          ratio={16 / 9}
          layerStyle="videoThumbnail"
          maxW="500px"
          flex="1"
        >
          <video autoPlay loop muted playsInline />
        </AspectRatio>
      </Flex>

      <Button
        w="full"
        maxW={{ base: "100%", md: "310px" }}
        m="auto"
        size="lg"
        onClick={() => navigate(`${AP_REDIRECT_AFTER_ONBOARDING}`)}
      >
        {intl.formatMessage({ id: "ap_onboarding_btn_label" })}
      </Button>
    </Steps>
  );
};

export default Step6;
