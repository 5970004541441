import { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Flex, Select } from "@chakra-ui/react";
import { getStartScheduleOptions, getEndScheduleOptions } from "./options";
import { IScheduleItem } from "./types";

type Props = {
  value: IScheduleItem;
  onScheduleItemChange: (item: IScheduleItem) => void;
};

const ScheduleItem = ({ value, onScheduleItemChange }: Props) => {
  const intl = useIntl();

  const { start_time, end_time, day } = value;

  const [start, setStart] = useState(start_time);
  const [end, setEnd] = useState(end_time);

  const onStartChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStart(e.target.value);
    onScheduleItemChange({
      day: day,
      start_time: e.target.value,
      end_time: end,
    });
  };

  const onEndChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEnd(e.target.value);
    onScheduleItemChange({
      day: day,
      start_time: start,
      end_time: e.target.value,
    });
  };

  const startOptions = getStartScheduleOptions();
  const endOptions = getEndScheduleOptions();

  const isStartOptionDisabled = (start: string, end: string) => {
    let isDisabled = false;
    if (
      end === "" ||
      end === "non_working_day" ||
      start === "non_working_day"
    ) {
      isDisabled = false;
    } else if (end <= start) {
      isDisabled = true;
    }
    return isDisabled;
  };

  const isEndOptionDisabled = (end: string, start: string) => {
    let isDisabled = false;
    if (
      start === "" ||
      start === "non_working_day" ||
      end === "non_working_day"
    ) {
      isDisabled = false;
    } else if (start >= end) {
      isDisabled = true;
    }
    return isDisabled;
  };

  return (
    <Flex w="full">
      <Flex
        w="97px"
        alignItems="center"
        pl="16px"
        color="brandGray.500"
        fontWeight="600"
        textTransform="capitalize"
        bgColor="rgba(255,255,255,0.54)"
        borderY="1px"
        borderLeft="1px"
        borderColor="border.500"
        borderLeftRadius="md"
        flexShrink={0}
      >
        {day}
      </Flex>
      {[start, end].includes("non_working_day") && (
        <Box
          flex="1 0 auto"
          border="1px"
          borderColor="border.500"
          borderRightRadius="md"
        >
          <Select
            border="0"
            borderLeftRadius="0 !important"
            value={"non_working_day"}
            onChange={(e) => {
              setStart(e.target.value);
              setEnd("");
              onScheduleItemChange({
                day: day,
                start_time: e.target.value,
                end_time: "",
              });
            }}
          >
            {startOptions.map((option, idx) => (
              <option key={idx} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </Box>
      )}
      {![start, end].includes("non_working_day") && (
        <>
          <Box border="1px" borderColor="border.500">
            <Select
              border="0"
              borderRadius="0 !important"
              placeholder={intl.formatMessage({
                id: "ap_onboarding_step5_from",
              })}
              value={start}
              onChange={onStartChange}
            >
              {startOptions.map((option, idx) => {
                return (
                  <option
                    key={idx}
                    disabled={isStartOptionDisabled(option.value, end)}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                );
              })}
            </Select>
          </Box>
          <Box
            borderWidth="1px 1px 1px 0"
            borderColor="border.500"
            borderRightRadius="md"
          >
            <Select
              border="0"
              borderLeftRadius="0 !important"
              placeholder={intl.formatMessage({ id: "ap_onboarding_step5_to" })}
              value={end}
              onChange={onEndChange}
            >
              {endOptions.map((option, idx) => (
                <option
                  key={idx}
                  disabled={isEndOptionDisabled(option.value, start)}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </Select>
          </Box>
        </>
      )}
    </Flex>
  );
};

export default ScheduleItem;
