const psychologicalTraining: string[] = [
  "cognitive_behavioral_psychotherapy",
  "systemic_family_and_couple_psychotherapy",
  "integrative_psychotherapy",
  "adlerian_psychotherapy",
  "clinical_hypnosis_and_ericksonian_therapy",
  "experiential_psychotherapy",
  "psychoanalytic_psychotherapy",
  "clinical_psychotherapy",
  "psychological_counseling",
];

export default psychologicalTraining;
