import { AUTH_COOKIE_DOMAIN } from "../config";
import { ICookieAttributes, getCookie, removeCookie, setCookie } from "./cookies";



export const defaultRedirectCookie: ICookieAttributes = {
  path: "/",
  domain: AUTH_COOKIE_DOMAIN,
  sameSite: "strict",
  expires: 1  //expire in 365 days from creation
};


export const removeRedirectCookie: ICookieAttributes = {
  path: "/",
  domain: AUTH_COOKIE_DOMAIN,
  sameSite: "strict",
  expires: 0  //expire in 365 days from creation
};


const allowedRegexes = [
  new RegExp('^/bookings$'),
  new RegExp('^/room/[^/]+/[^/]+$'),
  new RegExp('^/activities$'),
  new RegExp('^/activities/[^/]+$'),
  new RegExp('^/preferences$'),
]

export const storeCurrentUrlForRedirect = (url: string) => {
  const allowed = allowedRegexes.some(regex => {
    return url.match(regex);
  });
  if (allowed) {
    setCookie(`_redir`, url, defaultRedirectCookie);
  }
}

export const getAndDeleteRedirect = (): string | undefined => {
  const url = getCookie(`_redir`);
  if (url) {
    const allowed = allowedRegexes.some(regex => {
      return url.match(regex);
    });
    if (allowed) {
      removeCookie(`_redir`, removeRedirectCookie);
      return url;
    }
  }
}