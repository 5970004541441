import { Flex } from "@chakra-ui/react";
import RatingStars from "./RatingStars";

type Props = {
  score: number;
  count?: number;
};

const Rating = ({ score, count }: Props) => {
  return (
    <Flex alignItems="center" visibility={score ? "visible" : "hidden"}>
      <Flex alignItems="center" margin="0 8px 0 0">
        <Flex alignItems="center">
          <RatingStars rating={score} size='small' readonly={true} ratingW="96px" ratingH="16px" />
        </Flex>
      </Flex>
      {count && `(${count})`}
    </Flex>
  )
}

export default Rating;
