import { Box, Button } from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import { useIntl } from "react-intl";
import Layout from "../layout";
import ResetPasswordForm from "../forms/ResetPasswordForm";
import { IRoles } from "../../user/types";

type Props = {
  userType: IRoles;
};

const ResetPassword = ({ userType }: Props) => {
  const intl = useIntl();
  return (
    <Layout userType={userType} >
      <Box
        borderRadius="8px"
        w="100%"
        bg="#fff"
        border="1px"
        borderColor={{ base: "border.500", md: "transparent" }}
        p={{ base: "24px", md: "0" }}
      >
        <h1>{intl.formatMessage({ id: "auth_reset_password" })}</h1>
        <ResetPasswordForm />
        <Button
          as={RouteLink}
          to={userType === "registered_user" ? "/auth/login" : "/ap/auth/login"}
          w="full"
          size="md"
          mt="16px"
          variant="link"
        >
          {intl.formatMessage({ id: "auth_back_to_login" })}
        </Button>
      </Box>
    </Layout>
  );
};

export default ResetPassword;
