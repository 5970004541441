import { useIntl } from "react-intl";
import Vimeo from "@u-wave/react-vimeo";
import { Text, Divider, List, ListItem, ListIcon, Box } from "@chakra-ui/react";
import { CheckmarkFilled } from "@carbon/icons-react";
import HtmlContent from "../../shared/HtmlContent";
import { getVimeoId } from "../../utils/vimeoUrl";
import { IActivityProvider } from "../../activityProvider/types";
import { IActivity } from "../types";

type Props = {
  activity: IActivity;
  activityProvider: IActivityProvider;
};

const Description = ({ activity, activityProvider }: Props) => {
  const intl = useIntl();

  const { description, vimeo_url } = activity;
  const {
    bachelor_degree,
    master_degree,
    dr_degree,
    psychological_training,
    psychological_methods,
  } = activityProvider;

  const training =
    psychological_training && psychological_training.length
      ? psychological_training.split(",")
      : [];
  const methods =
    psychological_methods && psychological_methods.length
      ? psychological_methods.split(",")
      : [];

  const vimeoId = vimeo_url ? getVimeoId(vimeo_url) : "";

  return (
    <>
      <h2>{intl.formatMessage({ id: "activity_details_studies" })}</h2>
      <List spacing={4} mt="12px" color="brandGray.500" fontSize="16px" p="0">
        <ListItem display="flex" alignItems="center">
          <ListIcon
            w="24px"
            h="24px"
            as={CheckmarkFilled}
            size={32}
            color="brand.500"
            mt="2px"
          />

          {bachelor_degree}
        </ListItem>
        {master_degree && (
          <ListItem display="flex" alignItems="center">
            <ListIcon
              w="24px"
              h="24px"
              as={CheckmarkFilled}
              size={32}
              color="brand.500"
              mt="2px"
            />
            {master_degree}
          </ListItem>
        )}
        {dr_degree?.length && (
          <ListItem display="flex" alignItems="center">
            <ListIcon
              w="24px"
              h="24px"
              as={CheckmarkFilled}
              size={32}
              color="brand.500"
              mt="2px"
            />
            {dr_degree}
          </ListItem>
        )}
      </List>

      {vimeoId && (
        <>
          <Divider borderColor="border.500" my="24px" />
          <h2>{intl.formatMessage({ id: "activity_video_description" })}</h2>
          <Box mt="12px">
            <Vimeo video={vimeoId} autoplay={false} />
          </Box>
        </>
      )}
      {activity.vimeo_embed_code && (
        <>
          <Divider borderColor="border.500" my="24px" />
          <h2>{intl.formatMessage({ id: "activity_video_description" })}</h2>
          <Box mt="12px">
            <HtmlContent content={activity.vimeo_embed_code} />
            <br />
          </Box>
        </>
      )}

      <Divider borderColor="border.500" my="24px" />
      <h2>{intl.formatMessage({ id: "activity_details_training" })}</h2>
      <List spacing={4} mt="12px" color="brandGray.500" fontSize="16px" p="0">
        {training.map((training, idx) => (
          <ListItem key={idx} display="flex" alignItems="center">
            <ListIcon
              w="24px"
              h="24px"
              as={CheckmarkFilled}
            size={32}
              color="brand.500"
              mt="2px"
            />
            {intl.formatMessage({ id: training })}
          </ListItem>
        ))}
      </List>

      <Divider borderColor="border.500" my="24px" />
      <h2>{intl.formatMessage({ id: "activity_details_methods" })}</h2>
      <List spacing={4} mt="12px" color="brandGray.500" fontSize="16px" p="0">
        {methods.map((method, idx) => (
          <ListItem key={idx} display="flex" alignItems="center">
            <ListIcon
              w="24px"
              h="24px"
              as={CheckmarkFilled}
            size={32}
              color="brand.500"
              mt="2px"
            />
            {intl.formatMessage({ id: method })}
          </ListItem>
        ))}
      </List>

      <Divider borderColor="border.500" my="24px" />
      <h2>
        {intl.formatMessage({ id: "activity_details_general_description" })}
      </h2>


      <Text mt="12px" className="html-content-text-wrapper">
        <HtmlContent content={description} />
      </Text>
    </>
  );
};

export default Description;
