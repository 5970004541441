import { useIntl } from "react-intl";
import { NavLink } from "./NavLink";
import { useLocation } from "react-router-dom";
import {
  Dashboard,
  Stethoscope,
  StarReview,
  Blog,
} from "@carbon/icons-react";

const ProviderNav = () => {
  const intl = useIntl();
  const location = useLocation();
  const current_page = location.pathname;

  return (
    <>
      <NavLink
        to="/ap/dashboard"
        label={intl.formatMessage({ id: "navbar_dashboard_label" })}
        icon={Dashboard} iconSize={20}
        isActive={current_page === "/ap/dashboard"}
      />
      <NavLink
        to="/ap/sessions"
        label={intl.formatMessage({ id: "navbar_sessions_label" })}
        icon={Stethoscope} iconSize={20}
        isActive={current_page === "/ap/sessions"}
      />
      <NavLink
        to="/ap/testimonials"
        label={intl.formatMessage({ id: "navbar_testimonials_label" })}
        icon={StarReview} iconSize={20}
        isActive={current_page === "/ap/testimonials"}
      />
      <NavLink
        to="/ap/articles"
        label={intl.formatMessage({ id: "navbar_articles_label" })}
        icon={Blog} iconSize={20}
        isActive={current_page === "/ap/articles"}
      />
    </>
  );
};
export default ProviderNav;
