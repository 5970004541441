export const saveToLocalStorage = (key: string, value: string) => {
    try {
        localStorage.setItem(key, value)
    } catch (e) {
        console.log(e)
    }
}


export const getFromLocalStorage = (key: string) => {
    try {
        const value = localStorage.getItem(key)
        if (value) {
            return value
        }
    } catch (e) {
        console.log(e)
    }
}
