import { useEffect, useState } from "react";
import { Link as RouteLink } from "react-router-dom";
import Layout from "../layout";
import Loader from "../../shared/Loader";
import useNotification from "../../shared/hooks/useNotification";
import { useUnsubscribeUserMutation } from "../service";
import { Box, Button, Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";


const Unsub = () => {
  const intl = useIntl();
  const notify = useNotification();
  const [loading, setLoading] = useState(true);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [handleUnsub] = useUnsubscribeUserMutation();
  const userType = "registered_user";

  // on mount check for code and error and call the server
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const guid = urlParams.get("guid");
    if (!guid) {
      notify("error", "unsub_generic_error");
      return;
    }
    const handlRequest = async () => {
      try {
        await handleUnsub({ guid }).unwrap();
        setLoading(false);
        setUnsubscribed(true);
      } catch (error: any) {
        setLoading(false);
        setUnsubscribed(true);
      }
    };
    handlRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout userType={userType}>
      <Box
        w="100%"
        borderRadius="8px"
        bg="#fff"
        border="1px"
        borderColor={{ base: "border.500", md: "transparent" }}
        p={{ base: "24px", md: "0" }}
      >
        {loading && <Loader />}
        {unsubscribed && (
          <>
            <h1>Dezabonare cu succes</h1>
            <Text textStyle="largeP" mt="12px">
              Ai fost dezabonat cu succes din serviciul nostru.
              Îți mulțumim pentru timpul petrecut cu noi.
            </Text>
            <Button
              as={RouteLink}
              to="/auth/enter"
              w="full"
              size="md"
              mt="16px"
              variant="link"
            >
              {intl.formatMessage({ id: "auth_back_to_login" })}
            </Button>
          </>
        )}
      </Box>
    </Layout>
  );
};

export default Unsub;
