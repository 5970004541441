import { useEffect, useState } from "react";
import { Box, Flex, Button } from "@chakra-ui/react";
import { StarReview } from "@carbon/icons-react";
import { useGetReviewsQuery } from "../service";
import serialize from "../../utils/serialize";
import Testimonial from "./Testimonial";
import Loader from "../../shared/Loader";
import { IReview } from "../types";
import { useIntl } from "react-intl";

const REVIEW_LIMIT = 10;

type Props = {
  activityGuid: string;
};

const TestimonialsList = ({ activityGuid }: Props) => {
  const intl = useIntl();

  const [reviews, setReviews] = useState<IReview[]>([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | null>(null);

  const { data, isLoading, isFetching } = useGetReviewsQuery({
    a_guid: activityGuid,
    limit: REVIEW_LIMIT,
    last_evaluated_key: lastEvaluatedKey,
  });

  useEffect(() => {
    const newReviews = data?.data || [];
    if (newReviews.length > 0) {
      setReviews((reviews) => [...reviews, ...newReviews]);
    }
  }, [data]);

  const meta = data?.meta || null;
  const hasMore = meta?.has_more || false;
  const serializedLastEvaluatedKey = meta?.last_evaluated_key
    ? serialize(meta.last_evaluated_key)
    : "";

  const handleLoadMore = () => {
    setLastEvaluatedKey(serializedLastEvaluatedKey);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {!isLoading && reviews.length > 0 && (
        <Box>
          {reviews.map((review, idx) => (
            <Testimonial key={idx} review={review} />
          ))}
          {hasMore && (
            <Button
              mt="16px"
              size="lg"
              isLoading={isFetching}
              onClick={handleLoadMore}
            >
              {intl.formatMessage({ id: "ap_testimonials_load_more" })}
            </Button>
          )}
        </Box>
      )}
      {!isLoading && reviews.length === 0 && (
        <>
          <Box layerStyle="card" mt="16px">
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              textAlign="center"
            >
              <Flex
                borderRadius="100%"
                alignItems="center"
                justifyContent="center"
                color="#fff"
                w="80px"
                h="80px"
                bg="gray.500"
                mb="16px"
              >
                <StarReview size={32}/>
              </Flex>
              <>
                <h2>{intl.formatMessage({ id: "ap_testimonials_no_data" })}</h2>
              </>
            </Flex>
          </Box>
        </>
      )}
    </>
  );
};

export default TestimonialsList;
