import { useIntl } from "react-intl";
import { Box } from "@chakra-ui/react";
import { useGetMyActivitiesQuery } from "../../activity/service";
import TestimonialsList from "./TestimonialsList";
import Loader from "../../shared/Loader";

const Testimonials = () => {
  const intl = useIntl();

  const { data: activities, isLoading } = useGetMyActivitiesQuery();
  const myActivityGuid = activities?.data ? activities.data[0]["guid"] : "";

  return (
    <Box>
      <>
        <h1>{intl.formatMessage({ id: "ap_testimonials_title" })}</h1>
        {isLoading && <Loader />}
        {!isLoading && myActivityGuid && (
          <TestimonialsList activityGuid={myActivityGuid} />
        )}
      </>
    </Box>
  );
};

export default Testimonials;
