import { useIntl } from "react-intl";
import TestimonialsList from "../../reviews/testimonials/TestimonialsList";

type Props = {
  activityGuid: string;
};

const Testimonials = ({ activityGuid }: Props) => {
  const intl = useIntl();

  return (
    <>
      <h2>{intl.formatMessage({ id: "activity_details_testimonials" })}</h2>
      <TestimonialsList activityGuid={activityGuid} />
    </>
  );
};

export default Testimonials;
