import React, { useState } from 'react';
import Snackbar from '../../Snackbar/Snackbar';
import useDevices from '../../../hooks/useDevices/useDevices';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export function getSnackbarContent(hasAudio: boolean, hasVideo: boolean, error?: Error) {
  let headline = '';
  let message = '';

  switch (true) {
    // These custom errors are thrown by the useLocalTracks hook. They are thrown when the user explicitly denies
    // permission to only their camera, or only their microphone.
    case error?.message === 'CameraPermissionsDenied':
      headline = 'Nu se poate accesa media:';
      message =
        'Utilizatorul a refuzat permisiunea de a folosi video. Vă rugăm să acordați permisiunea browserului pentru a accesa camera.';
      break;
    case error?.message === 'MicrophonePermissionsDenied':
      headline = 'Nu se poate accesa media:';
      message =
        'Utilizatorul a refuzat permisiunea de a folosi audio. Vă rugăm să acordați browserului permisiunea de a accesa microfonul.';
      break;

    // This error is emitted when the user or the user's system has denied permission to use the media devices
    case error?.name === 'NotAllowedError':
      headline = 'Nu se poate accesa media:';

      if (error!.message === 'Permisiune blocată de către sistem') {
        // Chrome only
        message =
          'Sistemul de operare a blocat browserul în a accesa microfonul sau camera. Vă rugăm să verificați setările sistemului de operare.';
      } else {
        message =
          'Utilizatorul a blocat permisiunea de a utiliza audio și video. Vă rugăm să acordați permisiunea browserului pentru a accesa microfonul și camera.';
      }

      break;

    // This error is emitted when input devices are not connected or disabled in the OS settings
    case error?.name === 'NotFoundError':
      headline = 'Nu se poate localiza microfonul sau camera:';
      message =
        'Browserul nu poate accesa microfonul  sau camera. Vă rugăm să vă asigurați că toate dispozitivele sunt conectate și activate.';
      break;

    // Other getUserMedia errors are less likely to happen in this app. Here we will display
    // the system's error message directly to the user.
    case Boolean(error):
      headline = 'Eroare de accesare media:';
      message = `${error!.name} ${error!.message}`;
      break;

    case !hasAudio && !hasVideo:
      headline = 'Nu se poate localiza microfonul sau camera:';
      message = 'Ceilalți participanți nu vă vor putea auzi sau vedea.';
      break;

    case !hasVideo:
      headline = 'Nu se poate localiza camera:';
      message = 'Ceilalți participanți nu vă vor putea vedea.';
      break;

    case !hasAudio:
      headline = 'Nu se poate localiza microfonul:';
      message = 'Ceilalți participanți nu vă vor putea auzi.';
  }

  return {
    headline,
    message,
  };
}

export default function MediaErrorSnackbar({ error }: { error?: Error }) {
  const { hasAudioInputDevices, hasVideoInputDevices } = useDevices();

  const { isAcquiringLocalTracks } = useVideoContext();

  const [isSnackbarDismissed, setIsSnackbarDismissed] = useState(false);

  const isSnackbarOpen =
    !isSnackbarDismissed &&
    !isAcquiringLocalTracks &&
    (Boolean(error) || !hasAudioInputDevices || !hasVideoInputDevices);

  const { headline, message } = getSnackbarContent(hasAudioInputDevices, hasVideoInputDevices, error);

  return (
    <Snackbar
      open={isSnackbarOpen}
      handleClose={() => setIsSnackbarDismissed(true)}
      headline={headline}
      message={message}
      variant="warning"
    />
  );
}
