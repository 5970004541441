const initialFormValues = {
  image: null,
  firstName: "",
  lastName: "",
  gender: "",
  birthYear: "",
};

const initialFormErrors = {
  firstName: null,
  lastName: null,
  gender: null,
  birthYear: null,
};

export { initialFormValues, initialFormErrors };
