import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { rtkQueryErrorInterceptor } from "../errors/rtkQueryErrorInterceptor";
import iamReducer from "../auth/slice";
import { authApi } from "../auth/service";
import { userApi } from "../user/service";
import { activityProviderApi } from "../activityProvider/service";
import { activityApi } from "../activity/service";
import activitiesFiltersReducer from "../activity/activitiesFilters/slice";
import { articlesApi } from "../articles/service";
import { bookingApi } from "../booking/service";
import { activityScheduleApi } from "../schedule/service";
import { notesApi } from "../notes/service";
import { reviewApi } from "../reviews/service";
import { paymentApi } from "../payments/service";
import { roomApi } from "../room/service";
import { vouchersApi } from "../voucher/service";

export const store = configureStore({
  reducer: {
    iam: iamReducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [activityProviderApi.reducerPath]: activityProviderApi.reducer,
    [activityApi.reducerPath]: activityApi.reducer,
    activities_filters: activitiesFiltersReducer,
    [articlesApi.reducerPath]: articlesApi.reducer,
    [bookingApi.reducerPath]: bookingApi.reducer,
    [activityScheduleApi.reducerPath]: activityScheduleApi.reducer,
    [notesApi.reducerPath]: notesApi.reducer,
    [reviewApi.reducerPath]: reviewApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [roomApi.reducerPath]: roomApi.reducer,
    [vouchersApi.reducerPath]: vouchersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      rtkQueryErrorInterceptor,
      authApi.middleware,
      userApi.middleware,
      activityProviderApi.middleware,
      activityApi.middleware,
      articlesApi.middleware,
      bookingApi.middleware,
      activityScheduleApi.middleware,
      notesApi.middleware,
      reviewApi.middleware,
      paymentApi.middleware,
      roomApi.middleware,
      vouchersApi.middleware,
    ),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
