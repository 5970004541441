import { Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useGetLoggedInUserQuery } from "../../user/service";
import Steps from "../../shared/Steps";
import Loader from "../../shared/Loader";
import PinForm from "./PinForm";
import { useEffect } from "react";
import { USER_HOME_PAGE } from "../../routes/defaults";

const Step1 = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code") || null;

  const { data: user, isFetching } = useGetLoggedInUserQuery();

  useEffect(() => {
    if (user?.status === "active") {
      // navigate("/onboarding/step/2");
      setTimeout(() => {
        window.location.href = `${USER_HOME_PAGE}`;
      }, 1000);
    }
  }, [user?.status]);

  if (isFetching) {
    return <Loader />;
  }
  return (
    <Steps activeStep={1} stepCount={4}>
      <h1>
        <FormattedMessage
          id="user_onboarding_step1_title"
          values={{ name: user?.f_name }}
        />
      </h1>
      <Text textStyle="largeP" mt="12px">
        <FormattedMessage
          id="user_onboarding_step1_subtitle"
          values={{ email: user?.email }}
        />
      </Text>
      <PinForm initialCode={code} />
    </Steps>
  );
};

export default Step1;
