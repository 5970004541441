import React, { useCallback, useRef } from 'react';
import { 
    IconButton,
    Tooltip,
} from "@chakra-ui/react";
import { Video, VideoOff } from "@carbon/icons-react";

// import Button from '@material-ui/core/Button';
// import VideoOffIcon from '../../../icons/VideoOffIcon';
// import VideoOnIcon from '../../../icons/VideoOnIcon';

import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <>
    <Tooltip placement='top' fontWeight="600" label={!hasVideoInputDevices ? 'Lipsă Video' : isVideoEnabled ? 'Inchide camera' : 'Deschide camera'}>
      <IconButton 
        w="54px"
        isRound
        bg={isVideoEnabled ? "#fff" : "#E65050"}
        color={isVideoEnabled ? "brandGray.500" : "#fff"}
        borderColor={isVideoEnabled ? "brandGray.500" : "#E65050"}
        _hover={{
          boxShadow: "0 8px 16px 0 rgba(0,0,0,0.24)",
          bg: '{isVideoEnabled ? "#F2F2F2" : "#E65050"}'
        }}
        _active={{
          bg: '{isVideoEnabled ? "#EDEDED" : "#E65050"}'
        }}
        style={{height: "54px"}}
        aria-label={!hasVideoInputDevices ? 'Lipsă Video' : isVideoEnabled ? 'Inchide camera' : 'Deschide camera'} 
        onClick={toggleVideo}
        disabled={!hasVideoInputDevices || props.disabled}
        icon={isVideoEnabled ? <Video size={24}/> : <VideoOff size={24}/>}
        variant="outline"
      />
    </Tooltip>
      {/* <Button
        className={props.className}
        onClick={toggleVideo}
        disabled={!hasVideoInputDevices || props.disabled}
        startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
      >
        {!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'Stop Video' : 'Start Video'}
      </Button> */}
    </>
  );
}
