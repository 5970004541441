import { Text } from "@chakra-ui/react";
import Layout from "../../shared/Layout";
import Navbar from "../../shared/Navbar";
import ActivityDetails from "../../activity/activityDetails/ActivityDetails";
import { useGetMyActivitiesQuery } from "../../activity/service";
import Loader from "../../shared/Loader";

const PublicProfilePage = () => {
  const { data, isLoading } = useGetMyActivitiesQuery();
  const activities = data?.data ? data.data : [];
  const activityGuid = activities[0]["guid"] || "";

  return (
    <Layout>
      <>
        <Navbar userType="activity_provider" />
        {isLoading && <Loader />}
        {!isLoading && activityGuid && (
          <ActivityDetails guid={activityGuid} isBookingBtbDisabled={true} />
        )}
        {!isLoading && !activityGuid && (
          <Text>Eroare, incercati mai tarziu</Text>
        )}
      </>
    </Layout>
  );
};

export default PublicProfilePage;
