import { useIntl } from "react-intl";
import { Text } from "@chakra-ui/react";
import Steps from "../../shared/Steps";
import UserInfoForm from "./UserInfoForm";

const Step3 = () => {
  const intl = useIntl();

  return (
    <Steps activeStep={3} stepCount={4}>
      <h1>{intl.formatMessage({ id: "user_onboarding_step3_title" })}</h1>
      <Text textStyle="largeP" mt="16px">
        {intl.formatMessage({ id: "user_onboarding_step3_subtitle" })}
      </Text>
      <UserInfoForm />
    </Steps>
  );
};

export default Step3;
