import { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Box } from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/react";
import {
  EventClickArg,
  EventInput,
  EventSourceFunc,
} from "@fullcalendar/react";
import { useFetchBookingsQuery } from "../service";
import { useGetActivityScheduleQuery } from "../../schedule/service";
import { useGetLoggedInUserQuery } from "../../user/service";
import { getLoadingEvent, generateEvents } from "./bookingEvents";
import BookingModal from "./BookingModal";
import Calendar from "../calendar/Calendar";
import ChangeBookingModal from "./ChangeBookingModal";
import ResumeBookingPaymentModal from "./ResumeBookingPaymentModal";
import { DATETIME_FORMAT } from "../../utils/dates";
import { IActivity } from "../../activity/types";
import { fromServerToLocalMoment, fromServerToMoment } from "../utils";
import { MIN_BOOKING_TIME_IN_ADVANCE } from "../../config";

type Props = {
  activity: IActivity;
  firstAvailableDay: string;
  isChangingBooking?: boolean;
  b_guid?: string;
};

const BookingCalendar = ({
  activity,
  firstAvailableDay,
  isChangingBooking,
  b_guid,
}: Props) => {
  const navigate = useNavigate();

  const startOfWeek = fromServerToMoment(firstAvailableDay)
    .startOf("week")
    .format(DATETIME_FORMAT);
  const endOfWeek = fromServerToMoment(firstAvailableDay)
    .endOf("week")
    .format(DATETIME_FORMAT);
  const startOfDay = fromServerToMoment(firstAvailableDay)
    .startOf("day")
    .format(DATETIME_FORMAT);
  const endOfDay = fromServerToMoment(firstAvailableDay)
    .endOf("day")
    .format(DATETIME_FORMAT);
  const [isBrowser] = useMediaQuery("(min-width: 480px)");

  const [isBookingModalVisible, setIsBookingModalVisible] =
    useState<boolean>(false);
  const [isChangeBookingModalVisible, setIsChangeBookingModalVisible] =
    useState<boolean>(false);
  const [isResumePaymentModalVisible, setIsResumePaymentModalVisible] =
    useState<boolean>(false);

  const [startDate, setStartDate] = useState<string>(
    isBrowser ? startOfWeek : startOfDay
  );
  const [endDate, setEndDate] = useState<string>(
    isBrowser ? endOfWeek : endOfDay
  );

  const [selectedDate, setSelectedDate] = useState<{
    start: string;
    end: string;
  } | null>(null);

  const [selectedBookingGuid, setSelectedBookingGuid] = useState<string>("");

  const { data: activitySchedule, isFetching: isFetchingSchedule } =
    useGetActivityScheduleQuery({
      a_guid: activity.guid,
      start_datetime: startDate,
      end_datetime: endDate,
    });

  const { data: allBookings, isFetching: isFetchingBookings } =
    useFetchBookingsQuery({
      a_guid: activity.guid,
      start_datetime: startDate,
      end_datetime: endDate,
    });

  const isFetching = isFetchingBookings || isFetchingSchedule;

  const { data: user } = useGetLoggedInUserQuery();

  let schedule = activitySchedule || [];
  let userGuid = user ? user.guid : null;
  let userName = user ? user.f_name : "";

  let bookings =
    allBookings && userGuid
      ? allBookings.data.filter((booking) => {
        if (booking.u_guid !== userGuid) return true;
        if (booking.status !== "failed") return true;
        return false;
      })
      : [];

  let myBookings =
    allBookings && userGuid
      ? allBookings.data
        .filter(
          (booking) =>
            booking.u_guid === userGuid && booking.status !== "failed"
        )
        .map((booking) => ({
          ...booking,
          myName: userName,
        }))
      : [];

  let minBookingTimeInAdvance = MIN_BOOKING_TIME_IN_ADVANCE * 60;
  if (activity?.booking_buffer_minutes) {
    minBookingTimeInAdvance = activity.booking_buffer_minutes;
  }

  const events: EventInput[] = !isFetching
    ? generateEvents(startDate, endDate, schedule, bookings, myBookings, minBookingTimeInAdvance)
    : getLoadingEvent(startDate, endDate);

  const handleEventSelect = (eventClickInfo: EventClickArg) => {
    const eventId = eventClickInfo.event.groupId;
    if (eventId === "available") {
      setSelectedDate({
        start: eventClickInfo.event.startStr.split("+")[0],
        end: eventClickInfo.event.endStr.split("+")[0],
      });
      if (isChangingBooking) {
        setIsChangeBookingModalVisible(true);
      } else {
        setIsBookingModalVisible(true);
      }
    } else if (eventId === "myBookings") {
      const booking = eventClickInfo.event.extendedProps.booking;
      const startTimeLocal = fromServerToLocalMoment(booking.start_time);
      if (booking.status === "active") {
        if ((startTimeLocal.toDate().getTime() - new Date().getTime()) > 6 * 60 * 60 * 1000) {
          navigate(`/bookings`);
          return
        }
        navigate(`/room/${activity.guid}/${booking.b_guid}`);
      } else if (booking.status === "pending_payment") {
        setSelectedDate({
          start: eventClickInfo.event.startStr.split("+")[0],
          end: eventClickInfo.event.endStr.split("+")[0],
        });
        setSelectedBookingGuid(booking.b_guid);
        setIsResumePaymentModalVisible(true);
      }
    }
  };

  const handleEvents: EventSourceFunc = (fetchInfo) => {
    // UTC => Z
    // NON UTC => +02:00
    let start = fetchInfo.startStr.split("Z")[0]
    start = start.split("+")[0]

    setStartDate(start);
    const updatedEndDate =
      moment(fetchInfo.endStr).subtract(1, "day").format("YYYY-MM-DD") + "T23:59:59";
    setEndDate(updatedEndDate);

    return Promise.resolve(events);
  };

  return (
    <>
      <Box
        bg="#fff"
        fontSize="14px"
        color="brandGray.500"
        fontWeight="600"
        height="100%"
      >
        <Calendar
          initialView={isBrowser ? "timeGridWeek" : "timeGridDay"}
          initialDate={startDate}
          displayEventTime={false}
          selectable={true}
          events={handleEvents}
          eventClick={handleEventSelect}
        />

        {selectedDate && isBookingModalVisible && (
          <BookingModal
            isOpen={isBookingModalVisible}
            onCloseModal={() => {
              setIsBookingModalVisible(false);
              setSelectedDate(null);
            }}
            onSuccessBooking={() => {
              setIsBookingModalVisible(false);
              setSelectedDate(null);
            }}
            start={selectedDate.start}
            end={selectedDate.end}
            activity={activity}
          />
        )}
        {selectedDate && isResumePaymentModalVisible && selectedBookingGuid && (
          <ResumeBookingPaymentModal
            isOpen={isResumePaymentModalVisible}
            onCloseModal={() => {
              setIsResumePaymentModalVisible(false);
              setSelectedBookingGuid("");
              setSelectedDate(null);
            }}
            start={selectedDate.start}
            end={selectedDate.end}
            a_guid={activity.guid}
            b_guid={selectedBookingGuid}
          />
        )}
        {selectedDate && isChangeBookingModalVisible && b_guid && (
          <ChangeBookingModal
            isOpen={isChangeBookingModalVisible}
            onCloseModal={() => {
              setIsChangeBookingModalVisible(false);
              setSelectedDate(null);
            }}
            start={selectedDate.start}
            end={selectedDate.end}
            activity={activity}
            b_guid={b_guid}
          />
        )}
      </Box>
    </>
  );
};

export default BookingCalendar;
