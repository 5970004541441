import { Select } from "@chakra-ui/react";
import { useIntl } from "react-intl";

type Props = {
  onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  value: string;
  [x: string]: any;
};

const GenderSelector = ({ onChange, value, ...other }: Props) => {
  const intl = useIntl();

  return (
    <Select
      placeholder={intl.formatMessage({
        id: "onboarding_gender_selector_placeholder",
      })}
      {...other}
      w="full"
      size="lg"
      value={value ? value : ""}
      onChange={onChange}
      required
    >
      <option value="female">
        {intl.formatMessage({
          id: "onboarding_gender_female",
        })}
      </option>
      <option value="male">
        {intl.formatMessage({
          id: "onboarding_gender_male",
        })}
      </option>
      <option value="unspecified">
        {intl.formatMessage({
          id: "onboarding_gender_unspecified",
        })}
      </option>
    </Select>
  );
};

export default GenderSelector;
