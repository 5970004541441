import { useState } from "react";
import { Select, SelectProps } from "@chakra-ui/react";

const CURRENT_YEAR = new Date().getFullYear();

const generateYears = (
  startYear: number = 1960,
  endYear: number = CURRENT_YEAR
) => {
  let years = [];
  while (startYear <= endYear) {
    years.push(startYear++);
  }
  return years;
};

type Props = {
  initialValue?: string;
  placeholder: string;
  startYear: number;
  endYear?: number;
  onYearSelect: (year: string) => void;
} & SelectProps;

const YearSelector = (props: Props) => {
  const {
    initialValue,
    placeholder,
    onYearSelect,
    startYear,
    endYear = CURRENT_YEAR,
    ...rest
  } = props;

  const [selectedYear, setSelectedYear] = useState<string | undefined>(
    initialValue || undefined
  );

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(e.target.value);
    onYearSelect(e.target.value);
  };

  return (
    <Select
      placeholder={placeholder}
      w="full"
      size="lg"
      name={"year"}
      value={selectedYear}
      onChange={handleYearChange}
      required
      {...rest}
    >
      {generateYears(startYear, endYear).map((year, idx) => (
        <option key={idx} value={`${year}`}>
          {year}
        </option>
      ))}
    </Select>
  );
};

export default YearSelector;
