import React, { useState } from "react";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  Input,
  Button,
  Box,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { Email } from "@carbon/icons-react";
import { useAuthenticateMutation } from "../service";
import { setAuthorizationTokens } from "../slice";
import { useAppDispatch } from "../../hooks";
import { Form, FormControl, PasswordInput } from "../../shared/form";
import { validateForm } from "../../utils/forms";
import { loginValidationRules } from "../../utils/forms/validators";
import { IAuthCredentials, ILoginFormErrors } from "../types";
import { initialLoginFormPayload, initialLoginFormErrors } from "../defaults";
import { userApi } from "../../user/service";
import { IRoles } from "../../user/types";
import { AP_HOME_PAGE, USER_HOME_PAGE } from "../../routes/defaults";
import useNotification from "../../shared/hooks/useNotification";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalyticsHelper";
import { cleanupUTMCookies, getUTMParamsFromCookie } from "../../utils/requests";

type Props = {
  userType: IRoles;
};

const LoginForm = ({ userType }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const notify = useNotification();

  const [authenticate, { isLoading }] = useAuthenticateMutation();

  const [formValues, setFormValues] = useState<IAuthCredentials>(
    initialLoginFormPayload
  );
  const [formErrors, setFormErrors] = useState<ILoginFormErrors>(
    initialLoginFormErrors
  );

  const { user_name, password } = formValues;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormErrors(initialLoginFormErrors);
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    const authCredentials = { user_name, password };

    const validationErrors = validateForm(
      authCredentials,
      loginValidationRules
    );

    if (!validationErrors) {
      try {
        setFormErrors(initialLoginFormErrors);

        const utm = getUTMParamsFromCookie();

        const authTokens = await authenticate({ ...authCredentials, utm }).unwrap();
        dispatch(setAuthorizationTokens(authTokens));

        const user = await dispatch(
          userApi.endpoints.getLoggedInUser.initiate()
        ).unwrap();

        const isRegisteredUser = user.roles.includes("registered_user");
        const isActivityProvider = user.roles.includes("activity_provider");

        if (isRegisteredUser) {
          TrackGoogleAnalyticsEvent("user_login", "user_login_completed", "")
          navigate(USER_HOME_PAGE);
        } else if (isActivityProvider) {
          TrackGoogleAnalyticsEvent("activity_provider_login", "activity_provider_login_completed", "")
          navigate(AP_HOME_PAGE);
        }
        cleanupUTMCookies();
      } catch (error: any) {
        console.log("Error", error);
        const errorMessage =
          "status" in error && error.status === 401
            ? "auth_login_wrong_credentials_error"
            : "auth_login_error";
        notify("error", errorMessage);
      }
    } else {
      setFormErrors({ ...initialLoginFormErrors, ...validationErrors });
    }
  };

  return (
    <Box w="100%">
      <Form onSubmit={handleLogin} mt="16px">
        <FormControl errorMessage={formErrors.user_name}>
          <InputGroup mb="12px">
            <InputLeftElement
              pointerEvents="none"
              opacity=".54"
              pt="3px"
              px="8px"
              h="100%"
              children={<Email size={20} color="#696871" />}
            />
            <Input
              placeholder={intl.formatMessage({ id: "auth_email" })}
              type="email"
              name="user_name"
              value={user_name}
              size="lg"
              required
              onChange={(e) => onInputChange(e)}
            />
          </InputGroup>
        </FormControl>
        <FormControl errorMessage={formErrors.password}>
          <PasswordInput
            placeholder={intl.formatMessage({ id: "auth_password" })}
            name="password"
            value={password}
            onInputChange={(e) => onInputChange(e)}
          />
        </FormControl>
        <Button
          as={RouteLink}
          display="inline-flex"
          py="8px"
          to={
            userType === "registered_user"
              ? "/auth/forgot-password"
              : "/ap/auth/forgot-password"
          }
          size="md"
          mt="8px"
          variant="link"
        >
          {intl.formatMessage({ id: "auth_forget_password_title" })}
        </Button>
        <Button w="full" size="lg" type="submit" isLoading={isLoading} mt="8px">
          {intl.formatMessage({ id: "auth_login_btn" })}
        </Button>
      </Form>
    </Box>
  );
};

export default LoginForm;
