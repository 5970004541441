import { Grid, GridItem } from "@chakra-ui/react";
import ActivityPreview from "./activityPreview/ActivityPreview";
import { IActivity } from "./types";

type Props = {
  activities: IActivity[];
  mainAction: 'book' | 'view';
};

const ActivitiesList = ({ activities, mainAction }: Props) => {
  return (
    <Grid
      templateColumns={{
        base: "repeat(1, 1fr)",
        md: "repeat(2, 1fr)",
        lg: "repeat(4, 1fr)",
      }}
      gap={6}
      my="24px"
    >
      {activities.map((activity, idx) => (
        <GridItem key={idx}>
          <ActivityPreview activity={activity} mainAction={mainAction} />
        </GridItem>
      ))}
    </Grid>
  );
};

export default ActivitiesList;
