import { Box, Text, List, ListItem, ListIcon, Image, Flex, Divider } from "@chakra-ui/react";
import { CheckmarkFilled } from "@carbon/icons-react";
import { useIntl } from "react-intl";

type Props = {
  specializations: string[];
};

const Specializations = ({ specializations }: Props) => {
  const intl = useIntl();

  return (
    <Box
      w={{ base: "100%", md: "300px" }}
      paddingBottom={{ base: "24px", md: "0" }}
    >
      <h2>{intl.formatMessage({ id: "activity_details_accreditation" })}</h2>
      <Flex mt="12px" p="20px" borderRadius="8px" bg="#fff" alignItems="center" justifyContent="center" border="1px solid" borderColor="border.500">
        <Image src="/sigla-colegiul-psihologilor-romania.png" alt="Colegiul Psihologilor Romania" w="164px" />
      </Flex>
      <Divider borderColor="border.500" my="24px" />
      <h2>{intl.formatMessage({ id: "activity_details_specializations" })}</h2>
      <List spacing={4} mt="12px" color="brandGray.500" fontSize="16px" p="0">
        {specializations.map((specialization, idx) => (
          <ListItem key={idx} display="flex" alignItems="center">
            <ListIcon
              w="24px"
              h="24px"
              as={CheckmarkFilled}
              size={32}
              color="brand.500"
              mt="2px"
            />
            <Text textTransform="capitalize">
              {intl.formatMessage({ id: specialization })}
            </Text>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Specializations;
