import React from "react";
import { 
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  IconButton,
  TableContainer,
} from "@chakra-ui/react";
import { Download } from "@carbon/icons-react";

const PaymentsHistory = () => {
  return (
    <Box>
      <>
        <h1>Istoric plăți</h1>
        <Box layerStyle="card" mt="16px" p="8px"  overflow="hidden">
          <TableContainer>
            <Table size='md'>
              <Thead bg="gray.50">
                <Tr>
                  <Th>Data</Th>
                  <Th>Suma</Th>
                  <Th isNumeric>Număr invoice</Th>
                  <Th isNumeric>Download</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>12-10-2022</Td>
                  <Td>199.99 RON</Td>
                  <Td isNumeric>12389912312</Td>
                  <Td isNumeric><IconButton w="44px" h="44px" variant="outline" aria-label='Download invoice' icon={<Download size={20}/>} /></Td>
                </Tr>
                <Tr>
                  <Td>12-10-2022</Td>
                  <Td>199.99 RON</Td>
                  <Td isNumeric>12389912312</Td>
                  <Td isNumeric><IconButton w="44px" h="44px" variant="outline" aria-label='Download invoice' icon={<Download size={20}/>} /></Td>
                </Tr>
                <Tr>
                  <Td>12-10-2022</Td>
                  <Td>199.99 RON</Td>
                  <Td isNumeric>12389912312</Td>
                  <Td isNumeric><IconButton w="44px" h="44px" variant="outline" aria-label='Download invoice' icon={<Download size={20}/>} /></Td>
                </Tr>
                <Tr>
                  <Td>12-10-2022</Td>
                  <Td>199.99 RON</Td>
                  <Td isNumeric>12389912312</Td>
                  <Td isNumeric><IconButton w="44px" h="44px" variant="outline" aria-label='Download invoice' icon={<Download size={20}/>} /></Td>
                </Tr>
                <Tr>
                  <Td>12-10-2022</Td>
                  <Td>199.99 RON</Td>
                  <Td isNumeric>12389912312</Td>
                  <Td isNumeric><IconButton w="44px" h="44px" variant="outline" aria-label='Download invoice' icon={<Download size={20}/>} /></Td>
                </Tr>
              </Tbody>
              <Tfoot bg="gray.50">
                <Tr>
                  <Th>Data</Th>
                  <Th>Suma</Th>
                  <Th isNumeric>Număr invoice</Th>
                  <Th isNumeric>Download</Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </Box>
      </>
    </Box>
  );
};

export default PaymentsHistory;
