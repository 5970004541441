import React from 'react'
import { useIntl } from 'react-intl';
import { Alert, AlertIcon } from "@chakra-ui/react";

export default function AccountInactive() {
    const intl = useIntl();

    return (
        <Alert
            mt="16px"
            status="info"
            fontSize="14px"
            lineHeight="normal"
            padding="8px 12px"
            borderRadius="8px"
            color="infoBlue.500"
            bg="infoBlue.50"
        >
            <AlertIcon color="infoBlue.500" mt="1px" />
            {intl.formatMessage({ id: "account_status_inactive" })}
        </Alert>
    )
}


