import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useGetUserQuery } from '../../../../../user/service';

const useStyles = makeStyles(() =>
  createStyles({
    messageInfoContainer: {
      padding: '0',
      marginTop: "20px",
      fontSize: '12px',
      opacity: ".64",
      lineHeight: "normal",
    },
  })
);

interface MessageInfoProps {
  author: string;
  dateCreated: string;
  isLocalParticipant: boolean;
}

export default function MessageInfo({ author, dateCreated, isLocalParticipant }: MessageInfoProps) {
  const classes = useStyles();

  const userId = author.split(':')[0]
  const { data } = useGetUserQuery(userId)
  const interlocutorName = data ? data.f_name : ''

  return (
    <div className={classes.messageInfoContainer}>
      <div>{isLocalParticipant ? '( Tu )' : <span>{interlocutorName}</span>}</div>
      <div style={{ fontWeight: "bold" }}>{dateCreated}</div>
    </div>
  );
}
