import { Box, Flex, Text, Button, Stack, HStack } from "@chakra-ui/react";
import { getCookie, setCookie } from "../../utils/cookies";
import { useMemo, useState } from "react";


const GDPR = () => {
  const cookieConsent = useMemo(() => getCookie("cookie_consent"), []);
  const [displayed, setDisplayed] = useState(!cookieConsent);

  const handleAcceptAll = () => {
    setCookie("cookie_consent", "accept", {
      path: "/",
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10),
    });
    setDisplayed(false);
  };

  const handleRejectAll = () => {
    setCookie("cookie_consent", "reject", {
      path: "/",
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10),
    });
    setDisplayed(false);
  };

  if (!displayed) {
    return null;
  }

  return (
    <Box
      width="100%"
      px="24px"
      py="16px"
      bottom={0}
      position={{ base: "fixed" }}
      background="white"
      zIndex={1000}
      boxShadow="0px -4px 6px rgba(0, 0, 0, 0.1)"
    >
      <Box width="100%" background="white">
        <Text fontSize="lg" fontWeight="bold" color="brandGray.500">
          Confidențialitatea datelor dvs. este importantă pentru noi
        </Text>
        <Stack
          spacing="4"
          direction={{ base: "column", sm: "row" }}
          justify="space-between"
          width="100%"
        >
          <Flex
            width="100%"
            minWidth="100%"
            alignItems={{ base: "center", md: "center" }}
            flexDirection={{ base: "column", md: "row" }}
            gap={2}
            pb={2}
          >
            <Text color="muted" fontSize="sm" width="100%">
              Folosim cookie-uri pentru a vă îmbunătăți experiența de navigare,
              pentru a vă prezenta conținut și reclame personalizate și pentru a
              analiza traficul nostru. Făcând click pe „Acceptare toate”, acceptați
              utilizarea cookie-urilor.
            </Text>
            <HStack spacing="2" flex={{ base: "1", md: "auto" }} width={{ base: "100%", md: "40%" }}>
              <Button
                flex={{ base: "1" }}
                w="full"
                maxW="100%"
                m="auto"
                size="md"
                type="submit"
                variant="outline"
                onClick={handleRejectAll}
              >
                Refuzare toate
              </Button>
              <Button
                flex={{ base: "1" }}
                w="full"
                maxW="100%"
                m="auto"
                size="md"
                type="submit"
                onClick={handleAcceptAll}
              >
                Acceptare toate
              </Button>
            </HStack>
          </Flex>
        </Stack>
      </Box>
    </Box>
  );
};

export default GDPR;
