import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../shared/Layout";
import MainContainer from "../../shared/MainContainer";
import BottomNavigation from "../../shared/BottomNavigation";
import Navbar from "../../shared/Navbar";
import Activities from "../Activities";
import NextBookingNotification from "../../shared/nextBookingNotification";
import GDPR from "../../auth/layout/GDPR";

interface IProps {
  redirectUrl?: string | undefined;
  redirectTimeout?: number;
};

const ActivitiesPage = ({ redirectUrl, redirectTimeout = 3000 }: IProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      if (redirectUrl) {
        navigate(redirectUrl);
      }
    }, redirectTimeout);
    return () => clearTimeout(timer);
  });

  return (

    <Layout>
      <>
        <Navbar userType="registered_user" />
        <MainContainer>
          <Activities />
        </MainContainer>
        <NextBookingNotification userType="registered_user" />
        <BottomNavigation userType="registered_user" />
        <GDPR />
      </>
    </Layout>
  );
};

export default ActivitiesPage;
