import { useState } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import {
  Box,
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Alert,
  AlertIcon,
  Textarea,
} from "@chakra-ui/react";
import { activityApi } from "../activity/service";
import { usePostReviewMutation } from "./service";
import { useAppDispatch } from "../hooks";
import useNotification from "../shared/hooks/useNotification";
import { getRoLocaleDate } from "../utils/dates";
import ActivityMeta from "../shared/ActivityMeta";
import RatingStars from "./RatingStars";
import { IBooking } from "../booking/types";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  booking: IBooking;
};

const ReviewModal = ({ isOpen, closeModal, booking }: Props) => {
  const intl = useIntl();
  const notify = useNotification();
  const dispatch = useAppDispatch();

  const [message, setMessage] = useState("");
  const [rating, setRating] = useState(0);
  const [saveReview, { isLoading }] = usePostReviewMutation();
  const { a_guid, start_time, end_time } = booking;

  const onCloseModal = () => {
    closeModal();
    setMessage("");
    setRating(0);
  };

  const saveUserReview = (e: React.FormEvent) => {
    e.preventDefault();

    saveReview({
      content: message,
      rating,
      a_guid,
    })
      .then(
        () => {
          notify("success", "review_modal_add_success_message");
          dispatch(
            activityApi.util.invalidateTags([{ type: "Activity", id: a_guid }])
          );
        },
        () => notify("error", "ap_onboarding_step1_error")
      )
      .catch(() => notify("error", "ap_onboarding_step1_error"))
      .finally(onCloseModal);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered={true}
      scrollBehavior="inside"
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottom="1px solid var(--chakra-colors-border-400)">
          <h2>{intl.formatMessage({ id: "review_modal_title" })}</h2>
        </ModalHeader>
        <ModalCloseButton w="44px" h="44px" />
        <ModalBody py="16px">
          <Flex alignItems="center" justifyContent="space-between">
            <ActivityMeta guid={a_guid} />
            <Divider orientation="vertical" h="44px" opacity="1" />
            <Box textAlign="right">
              <Text
                fontSize="18px"
                lineHeight="normal"
                color="brandGray.500"
                fontWeight="600"
              >
                {getRoLocaleDate(start_time, "dddd, DD MMMM YYYY")}
              </Text>
              <Text>{`Ora ${moment(start_time).format("HH:mm")} - ${moment(
                end_time
              ).format("HH:mm")}`}</Text>
            </Box>
          </Flex>
          <Alert
            mt="16px"
            status="info"
            fontSize="14px"
            lineHeight="normal"
            padding="8px 12px"
            borderRadius="8px"
            color="infoBlue.500"
            bg="infoBlue.50"
          >
            <AlertIcon color="infoBlue.500" mt="1px" />
            {intl.formatMessage({ id: "review_modal_info" })}
          </Alert>
          <Box
            w="264px"
            h="44px"
            position="relative"
            overflow="hidden"
            margin="32px auto"
          >
            <Box
              transform="scale(2.75)"
              position="absolute"
              top="16px"
              left="84px"
            >
              <RatingStars
                rating={rating}
                onChange={setRating}
                readonly={false}
                ratingW="96px"
                ratingH="16px"
                margin="0"
              />
            </Box>
          </Box>

          <Divider my="24px" opacity="1" />
          <Box w="full" textAlign="left" mb="12px" opacity=".8">
            <Text textStyle="smallLabel">
              {intl.formatMessage({ id: "review_modal_add_comment" })}
            </Text>
          </Box>
          <Textarea
            maxW={{ md: "3xl" }}
            rows={5}
            resize="none"
            onChange={(e) => setMessage(e.currentTarget.value)}
            value={message}
          />
        </ModalBody>

        <ModalFooter borderTop="1px solid var(--chakra-colors-border-400)">
          <Button
            w="full"
            size="lg"
            isLoading={isLoading}
            onClick={saveUserReview}
          >
            {intl.formatMessage({ id: "review_modal_btn_label" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReviewModal;
