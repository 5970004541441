import validate from "validate.js";
import { createIntl, createIntlCache } from "react-intl";
import { translations as roTranslations } from "../../locale/ro/local_translation";
import { translations as enTranslations } from "../../locale/en/local_translation";

const cache = createIntlCache();
const intl = createIntl(
  { locale: "ro-RO", messages: { ...roTranslations, ...enTranslations } },
  cache
);

const email = {
  email: {
    message: `^${intl.formatMessage({ id: "auth_email_validation_message" })}`,
  },
};

const firstName = {
  presence: true,
  length: {
    minimum: 1,
    message: `^${intl.formatMessage({ id: "mandatory_field_message" })}`,
  },
};

const password = {
  presence: true,
  length: {
    minimum: 6,
    message: `^${intl.formatMessage({
      id: "auth_password_validation_message",
    })}`,
  },
};

const repeatPassword = {
  equality: {
    attribute: "password",
    message: `^${intl.formatMessage({
      id: "auth_repeat_password_validation_message",
    })}`,
  },
};

const agreementChecked = {
  inclusion: {
    within: [true],
    message: `^${intl.formatMessage({
      id: "auth_tnc_validation_message",
    })}`,
  },
};

const year = {
  presence: true,
  length: {
    minimum: 4,
    message: `^${intl.formatMessage({ id: "birth_year_mandatory_message" })}`,
  },
  numericality: {
    message: `^${intl.formatMessage({ id: "birth_year_validation_message" })}`,
  },
};

const content = {
  presence: true,
  length: {
    minimum: 10,
    message: `^${intl.formatMessage({ id: "mandatory_field_message" })}`,
  },
};

export const updateUserAccountValidationRules = {
  email,
  f_name: firstName,
  birthdate: year,
  mobile_phone_number: { phoneValidator: " " },
};

export const registerValidationRules = {
  email,
  f_name: firstName,
  pw: password,
  mobile_phone_number: { phoneValidator: " " },
  agreementChecked,
};

export const registerActivityProviderValidationRules = {
  email,
  f_name: firstName,
  l_name: firstName,
  pw: password,
  mobile_phone_number: { phoneValidator: " " },
  agreementChecked,
};

export const loginValidationRules = {
  user_name: email,
  password,
};

export const enterValidationRules = {
  user_name: email,
};

export const changeEmailValidationRules = {
  user_name: email,
};

export const forgotPasswordValidationRules = {
  email,
};

export const resetPasswordValidationRules = {
  password,
  repeatPassword,
};

export const changePasswordValidationRules = {
  old_pw: password,
  password,
  repeatPassword,
};

export const articleValidationRules = {
  content,
};

export const onboardingValidationRules = {
  f_name: firstName,
  l_name: firstName,
  birthdate: year,
  mobile_phone_number: { phoneValidator: " " },
};

validate.validators.phoneValidator = function (value: any) {
  if (value.length) {
    if (isNaN(value)) {
      return `^${intl.formatMessage({ id: "auth_phone_validation_message" })}`;
    } else if (value.length < 9 || value.length > 9) {
      return `^${intl.formatMessage({ id: "auth_phone_validation_message" })}`;
    }
  }
  return null;
};
