import { Link as RouteLink } from "react-router-dom";
import Layout from "../layout";
import { Box, Button, Text } from "@chakra-ui/react";

const Error404 = () => {
  return (
    <Layout userType="registered_user">
      <Box
        w="100%"
        borderRadius="8px"
        bg="#fff"
        border="1px"
        borderColor={{ base: "border.500", md: "transparent" }}
        p={{ base: "24px", md: "0" }}
      >
        <Text fontSize="2xl" fontWeight="bold">
          404 - Pagina nu a fost găsită
        </Text>
        <Text fontSize="xl" mt="4">
          Oops! Ne pare rău, dar pagina pe care o căutai nu a fost găsită. Te rugăm să revii la pagina principală
        </Text>
        <Button as={RouteLink} to="/" colorScheme="blue" mt="8">
          Mergi la pagina principală
        </Button>
      </Box>
    </Layout>
  );
};

export default Error404;
