import { useIntl } from "react-intl";
import { Flex, Divider, Box } from "@chakra-ui/react";
import ActivitiesList from "./ActivitiesList";
import { Rating, Name } from "./activitiesFilters/filters";
import { changeFetchFilters, getActivitiesFilters } from "./activitiesFilters/slice";
import { useGetLoggedInUserQuery } from "../user/service";
import { useGetActivitiesForTodayQuery, useGetActivitiesUnavailableForOneWeekQuery, useGetActivitiesOneWeekFromTomorrowQuery } from "./service";
import { useAppDispatch, useAppSelector } from "../hooks";
import Loader from "../shared/Loader";
import { useEffect } from 'react';
import { useState } from 'react';

// at least 3h in the future for any new reservation

const Activities = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [skip, setSkip] = useState(true)
  const { data: user, isLoading } = useGetLoggedInUserQuery();
  const filters = useAppSelector(getActivitiesFilters);

  useEffect(() => {
    if (!isLoading && user) {
      user.profile && dispatch(changeFetchFilters({ ...filters, issues_to_fix: user.profile.issues_to_fix }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, user])

  useEffect(() => {  // logic used to trigger fetch events after filters are set
    if (!isLoading && user) {
      setSkip(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])


  const { data, isFetching: isFetchingTodaysActivities } = useGetActivitiesForTodayQuery(
    {
      ...filters,
    },
    { skip }
  );
  const todaysActivities = data?.data || [];

  const { data: activities, isFetching: isFetchingActivitiesStartingFromTomorrow } = useGetActivitiesOneWeekFromTomorrowQuery(
    {
      ...filters,
    },
    { skip }
  );
  const activitiesStartingFromTomorrow = activities?.data || [];



  const { data: unavailableData, isFetching: isFetchingUnavailable } = useGetActivitiesUnavailableForOneWeekQuery(
    {
      ...filters,
    },
    { skip }
  );
  const activitiesUnavailable = unavailableData?.data || [];


  const isFetching = isFetchingActivitiesStartingFromTomorrow || isFetchingTodaysActivities;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexWrap={{ base: "wrap", lg: "nowrap" }}
        >
          <h1>{intl.formatMessage({ id: "activities_therapists_title" })}</h1>
          <Flex
            flexWrap={{ base: "wrap", md: "nowrap" }}
            w={{ base: "100%", lg: "auto" }}
            mt={{ base: "16px", lg: "0" }}
          >
            <Rating />
            <Name />
          </Flex>
        </Flex>
        {/* <IssuesToFix /> */}

        {isFetching && <Loader />}

        {!isFetching && todaysActivities.length > 0 && (
          <>
            <h2>
              {intl.formatMessage(
                { id: "activities_therapists_available_today" },
                { count: todaysActivities.length }
              )}
            </h2>
            <ActivitiesList activities={todaysActivities} mainAction="book" />
            <Divider borderColor="border.500" my="32px" />
          </>
        )}

        {!isFetching && activitiesStartingFromTomorrow.length > 0 && (
          <>
            <h2>
              {intl.formatMessage(
                { id: "activities_therapists_available_starting_tomorrow" },
                { count: activitiesStartingFromTomorrow.length }
              )}
            </h2>
            <ActivitiesList activities={activitiesStartingFromTomorrow} mainAction="book" />
          </>
        )}
        {!isFetching &&
          [todaysActivities, activitiesStartingFromTomorrow].every(
            (array) => array.length === 0
          ) && (
            <Box layerStyle="card" mt="24px">
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                textAlign="center"
              >
                <h2>
                  {intl.formatMessage({
                    id: "activities_no_activity_available",
                  })}
                </h2>
              </Flex>
            </Box>
          )}

        {!isFetchingUnavailable && activitiesUnavailable.length > 0 && (
          <>
            <h2>
              {intl.formatMessage(
                { id: "activities_therapists_unavailable" },
                { count: activitiesUnavailable.length }
              )}
            </h2>
            <ActivitiesList activities={activitiesUnavailable} mainAction="view" />
          </>
        )}
      </>
    </>
  );
};

export default Activities;
