import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Text, Box } from "@chakra-ui/react";
import { useGetActivityProviderQuery } from "../../activityProvider/service";
import { useGetUserGuidFromAccessToken } from "../../user/hooks";
import Loader from "../../shared/Loader";

const StripeOnboardingFinalized = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const guid = useGetUserGuidFromAccessToken();

  const { data: activityProvider } = useGetActivityProviderQuery(guid, {
    pollingInterval: 5000,
  });

  const isOnboardingFinished =
    activityProvider && activityProvider.stripe_account_id;

  useEffect(() => {
    if (isOnboardingFinished) {
      navigate("/ap/onboarding/step/1");
    }
  }, [isOnboardingFinished, navigate]);

  return (
    <Box>
      <Loader />
      <Text textAlign="center">
        {intl.formatMessage({ id: "ap_onboarding_stripe_finalized" })}
      </Text>
    </Box>
  );
};

export default StripeOnboardingFinalized;
