import { Flex, Divider, Text, Button } from "@chakra-ui/react";
import { IActivity } from "../types";
import { EventSchedule } from "@carbon/icons-react";
import { useMemo } from "react";
import { fromServerToLocalMoment } from "../../booking/utils";
import { Link as RouteLink } from "react-router-dom";


type Props = {
  activity: IActivity;
};

const AvailabilitySlots = ({ activity }: Props) => {
  const first_date = useMemo(() => {
    if (activity.availabilities_first_date) {
      return fromServerToLocalMoment(activity.availabilities_first_date).locale("ro").format('DD MMMM')
    }
    return null
  }, [activity.availabilities_first_date])

  if (!first_date || !activity.availabilities_first_slots?.length) return null;
  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        mb="8px"
        mt="24px"
        flexDirection="row"
        w="full"
      >
        <Divider
          display="inline-flex"
          w="auto"
          flex="1"
          mt="3px"
          mx="3px"
          py="3px"
          borderColor="border.400"
          opacity="1"
        />
        <Text
          mx="16px"
          fontSize="12px"
          lineHeight="1"
          textTransform="uppercase"
          fontWeight="700"
          letterSpacing="1px"
        >
          Disponibilitate
        </Text>
        <Divider
          display="inline-flex"
          w="auto"
          flex="1"
          mt="3px"
          borderColor="border.400"
          opacity="1"
        />
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        width="100%"
        flexWrap="wrap"
        marginTop={"6px"}
      >
        <Flex
          alignItems="start"
          justifyContent="left"
          width="100%"
          flexWrap="wrap"
        >
          <EventSchedule size={"16"} />
          <Text
            mx="8px"
            fontSize="12px"
            lineHeight="1"
            textTransform="uppercase"
            fontWeight="800"
            letterSpacing="1px">
            {first_date}
          </Text>
        </Flex>
        <Flex
          alignItems="left"
          justifyContent="space-evenly"
          width="100%"
          flexWrap="wrap"
          mt="8px"
        >
          {activity.availabilities_first_slots.slice(0, 3).map((slot) => {
            const hour = fromServerToLocalMoment(slot.start).format('h A')
            return <Button
              as={RouteLink}
              size="xs"
              maxWidth="33%"
              bgColor="#796161"
              _hover={{ bgColor: "#584848" }}
              _active={{ bgColor: "#584848" }}
              display="inline-flex"
              to={`/activities/${activity.guid}/book-activity`}
              key={slot.start}
              padding="4px"
              mx="4px"
              flexGrow={1}
              fontSize="12px"
            >{hour}</Button>
          })}
        </Flex>
      </Flex>
    </>
  );
};

export default AvailabilitySlots;
