import { useIntl } from "react-intl";
import { Text, Flex, Button, AspectRatio } from "@chakra-ui/react";
import Steps from "../shared/Steps";
import { USER_HOME_PAGE } from "../routes/defaults";
import { TrackGoogleAnalyticsEvent } from "../utils/GoogleAnalyticsHelper";

const Step4 = () => {
  const intl = useIntl();
  const onboardingFinished = () => {
    // navigate(`${USER_HOME_PAGE}`)
    TrackGoogleAnalyticsEvent("user_onboarding", "user_onboarding_completed", "")
    setTimeout(() => {
      window.location.href = `${USER_HOME_PAGE}`;
    }, 1000);

    // OR REDIRECT TO USER_REDIRECT_AFTER_ONBOARDING
  }

  return (
    <Steps activeStep={4} stepCount={4}>
      <h1>{intl.formatMessage({ id: "user_onboarding_step4_title" })}</h1>
      <Text textStyle="largeP" mt="16px" mb="24px">
        {intl.formatMessage({ id: "user_onboarding_step4_subtitle" })}
      </Text>

      <Flex mb="32px" alignItems="center" justifyContent="center" w="full">
        <AspectRatio
          ratio={16 / 9}
          layerStyle="videoThumbnail"
          maxW="500px"
          flex="1"
        >
          <video autoPlay loop muted playsInline />
        </AspectRatio>
      </Flex>

      <Button
        w="full"
        maxW={{ base: "100%", md: "310px" }}
        m="auto"
        size="lg"
        onClick={() => onboardingFinished()}
      >
        {intl.formatMessage({ id: "user_onboarding_step4_btn_label" })}
      </Button>
    </Steps>
  );
};

export default Step4;
