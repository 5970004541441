import { useIntl } from "react-intl";
import { Box, Flex, Divider, Text } from "@chakra-ui/react";

const DISPLAYED_SPECIALIZATIONS = 3;

type Props = {
  specializations: string[];
};

const Specializations = ({ specializations }: Props) => {
  const intl = useIntl();

  const notDisplayedSpecializations = specializations.slice(
    DISPLAYED_SPECIALIZATIONS
  ).length;

  if (specializations.length === 0) return null;

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        mb="8px"
        mt="24px"
        flexDirection="row"
        w="full"
      >
        <Divider
          display="inline-flex"
          w="auto"
          flex="1"
          mt="3px"
          borderColor="border.400"
          opacity="1"
        />
        <Text
          mx="16px"
          fontSize="12px"
          lineHeight="1"
          textTransform="uppercase"
          fontWeight="700"
          letterSpacing="1px"
        >
          {intl.formatMessage({ id: "activity_specialized_in" })}
        </Text>
        <Divider
          display="inline-flex"
          w="auto"
          flex="1"
          mt="3px"
          borderColor="border.400"
          opacity="1"
        />
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        width="100%"
        flexWrap="wrap"
      >
        {specializations
          .slice(0, DISPLAYED_SPECIALIZATIONS)
          .map((specialization, idx) => (
            <Box layerStyle="dataTypePill" key={idx}>
              {intl.formatMessage({ id: specialization })}
            </Box>
          ))}
        {notDisplayedSpecializations ? (
          <Box layerStyle="dataTypePill">+{notDisplayedSpecializations}</Box>
        ) : (
          ""
        )}
      </Flex>
    </>
  );
};

export default Specializations;
