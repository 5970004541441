import React, { useState } from 'react'
import PasswordInput from './PasswordInput'
import PasswordStrengthBar, { PasswordFeedback } from 'react-password-strength-bar';
import { useIntl } from 'react-intl';
type Props = {
    placeholder: string;
    name: string;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function PasswordStrengthIndicator({ placeholder, name, onInputChange }: Props) {
    const intl = useIntl();
    const [password, setPassword] = useState('');
    const scoreWords = [
        intl.formatMessage({ id: "password_weak" }),
        intl.formatMessage({ id: "password_okay" }),
        intl.formatMessage({ id: "password_good" }),
        intl.formatMessage({ id: "password_strong" })
    ]
    const updatePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.currentTarget.value);
    }
    const handleScoreChange = (score: number, feedback: PasswordFeedback) => {
        if (score >= 1) {
            onInputChange({ target: { name: name, value: password } } as React.ChangeEvent<HTMLInputElement>);
        } else {
            onInputChange({ target: { name: name, value: "" } } as React.ChangeEvent<HTMLInputElement>);
        }
    }
    return (
        <>
            <PasswordInput
                placeholder={placeholder}
                name={name}
                value={password}
                onInputChange={updatePassword}
            />
            <PasswordStrengthBar
                shortScoreWord={false}
                scoreWords={scoreWords}
                password={password}
                onChangeScore={handleScoreChange}
            />
        </>
    )
}
