const validateVimeoURL = (url: string): boolean => {
  return /^(http:\/\/|https:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/.test(url);
};

const getVimeoId = (url: string) => {
  const isValidVimeoUrl = validateVimeoURL(url);
  let vimeoId = "";

  if (isValidVimeoUrl) {
    vimeoId = url.split("/").pop() || "";
  }

  return vimeoId;
};

export { validateVimeoURL, getVimeoId };
