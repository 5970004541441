import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useMarkAsFreeMutation } from "../service";
import useNotification from "../../shared/hooks/useNotification";

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  a_guid: string;
  b_guid: string;
};

const ManualMarkedBookingsModal = ({ isOpen, onCloseModal, a_guid,
  b_guid, }: Props) => {
  const notify = useNotification();
  const [markAsFree, { isLoading: isMarkingBooking }] = useMarkAsFreeMutation();

  const handleMarkAsFreeBooking = () => {
    markAsFree({ a_guid, b_guid })
      .unwrap()
      .then((response) => {
        console.log(response)
        // onCloseModal();
        notify("success", "mark_booking_free");
        onCloseModal();
        setTimeout(() => {
          window.location.href = "/ap/dashboard" // Need to force refreshing the bookings
        }, 2000)
      })
      .catch(() => {
        notify("error", "mark_booking_free_error");
        onCloseModal();
        setTimeout(() => {
          window.location.href = "/ap/dashboard" // Need to force refreshing the bookings
        }, 2000)
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      isCentered={true}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          borderBottom="1px solid var(--chakra-colors-border-400)"
          textAlign="center"
        >
          <h2>
            Marchează ca sedinta gratuită
          </h2>
        </ModalHeader>
        <ModalCloseButton w="44px" h="44px" />
        <ModalBody py="16px">
          <Text
            fontSize="18px"
            lineHeight="normal"
            color="brandGray.500"
            fontWeight="600"
          >
            Poti alege pentru un timp foarte scurt sa marchezi aceasta rezervare ca sedinta gratuita.
            In acest caz, nu se emite factura si utilizatorul va fi notificat cu o ora inainte de programare.
          </Text>
        </ModalBody>

        <ModalFooter borderTop="1px solid var(--chakra-colors-border-400)">
          <Button w="full" size="lg" onClick={handleMarkAsFreeBooking} isLoading={isMarkingBooking}>
            Marcheaza ca sedinta gratuita
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ManualMarkedBookingsModal;
