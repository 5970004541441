import React from "react";
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useGetLoggedInUserQuery, useModifyUserMutation } from "./service";
import Loader from "../shared/Loader";
import TherapyPreferences from "./TherapyPreferences";
import { useIntl } from "react-intl";
import useNotification from "../shared/hooks/useNotification";
import PersonalInformationForm from "./PersonalInformationForm";
import { IRegisteredUser } from "./types";

const Preferences = () => {
  const intl = useIntl();
  const notify = useNotification();

  const { data: user } = useGetLoggedInUserQuery();
  const [modifyUser, { isLoading: updatingUser }] = useModifyUserMutation();

  const saveData = (payload: {
    user: Partial<IRegisteredUser>;
    guid: string;
  }) => {
    modifyUser(payload)
      .then(
        () => notify("success", "user_details_success_update"),
        () => notify("error", "ap_onboarding_step1_error")
      )
      .catch(() => notify("error", "ap_onboarding_step1_error"));
  };

  if (!user) return <Loader />;

  return (
    <Box>
      <>
        <h1>{intl.formatMessage({ id: "navbar_preferences_label" })}</h1>
        <Tabs colorScheme="brand" mt="16px">
          <TabList border="0">
            <Tab fontSize="13px" px="0" mr="24px">
              {intl.formatMessage({ id: "therapy_category" })}
            </Tab>
            <Tab fontSize="13px" px="0" mr="24px">
              {intl.formatMessage({ id: "personal_setup" })}
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel px="0" py="0">
              <TherapyPreferences
                user={user}
                updateUser={saveData}
                updating={updatingUser}
              />
            </TabPanel>
            <TabPanel px="0" py="0">
              <PersonalInformationForm
                user={user}
                updateUser={saveData}
                updating={updatingUser}
                preferences
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
    </Box>
  );
};

export default Preferences;
