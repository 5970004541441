import moment from "moment";
import momentTz from "moment-timezone";
import { IActivitySchedule } from "../schedule/types";
import { MIN_BOOKING_TIME_IN_ADVANCE } from "../config";

type IHourlySchedule = { start: string; end: string }[];

const timeStringToMinutes = (time: string, separator: string): number => {
  const [hour, min] = time.split(separator);
  return Number(hour) * 60 + Number(min);
};

export const toLocalHM = (time: any): string => {
  return moment(time).format("HH:mm")
}
export const toLocalddddDMY = (time: any): string => {
  return moment(time).format("dddd, DD MMM YYYY")
}

export const fromServerToMoment = (time: any): moment.Moment => {
  return momentTz.tz(time, "Europe/Bucharest")
}

export const fromServertoLocalYMDTHms = (time: any): string => {
  return momentTz.tz(time, "Europe/Bucharest").local().format("YYYY-MM-DDTHH:mm:ss")
}
export const fromServertoLocalDate = (time: any): string => {
  return momentTz.tz(time, "Europe/Bucharest").local().format("YYYY-MM-DD")
}

export const fromServerToLocalMoment = (time: any): moment.Moment => {
  return momentTz.tz(time, "Europe/Bucharest").local()
}

export const fromServerToLocalHM = (time: any): string => {
  return momentTz.tz(time, "Europe/Bucharest").local().format("HH:mm")
}
export const fromServerToLocalHMS = (time: any): string => {
  return momentTz.tz(time, "Europe/Bucharest").local().format("HH:mm:ss")
}
export const fromLocalToServerYMDTHms = (time: any): string => {
  // convert from local timezone to Bucharest
  return moment(time).tz("Europe/Bucharest").format("YYYY-MM-DDTHH:mm:ss")
}

const minutesToTimeString = (minutes: number, separator: string): string => {
  const minutesPart = `${minutes % 60}`.padStart(2, "0");
  const hoursPart = `${Math.floor(minutes / 60)}`.padStart(2, "0");
  return hoursPart + separator + minutesPart;
};

const generateTimeSlots = (start: string, end: string, periodInMinutes: number, separator: string = ":"): string[] => {
  const timeSlots = [];
  const startMinutes = timeStringToMinutes(start, separator);
  const endMinutes = timeStringToMinutes(end, separator);

  for (let i = startMinutes; i <= endMinutes; i = i + periodInMinutes) {
    timeSlots.push(minutesToTimeString(i, separator));
  }

  return timeSlots;
};

const generateHourlySchedule = (
  schedule: IActivitySchedule,
  minBookingTimeInAdvance: number = MIN_BOOKING_TIME_IN_ADVANCE * 60
): IHourlySchedule => {
  const hourlySchedule: IHourlySchedule = [];

  schedule.forEach((scheduleItem, idx) => {
    scheduleItem.availability.forEach((availability) => {
      const [start, end] = availability;
      const timeSlots = generateTimeSlots(start, end, 60, ":");
      for (let i = 0; i < timeSlots.length - 1; i++) {
        const scheduleStart = `${scheduleItem.date}T${timeSlots[i]}:00`;
        if (moment(fromServertoLocalYMDTHms(scheduleStart)).isBefore(moment().add(minBookingTimeInAdvance, "minutes"))) {  // prevent booking with less than MIN_BOOKING_TIME_IN_ADVANCE hours in advance
          continue;
        }
        const scheduleEnd = `${scheduleItem.date}T${timeSlots[i + 1]}:00`;
        hourlySchedule.push({
          start: scheduleStart,
          end: scheduleEnd,
        });
      }
    });
  });

  return hourlySchedule;
};

const generateSchedule = (start: string, end: string, minutesInterval: number = 60) => {
  const schedule = [];

  let currentDate = moment(start);
  let stopDate = moment(end);
  while (currentDate < stopDate) {
    const updatedCurrentDate = moment(currentDate).add(minutesInterval, "minutes");
    schedule.push({
      start: moment(currentDate).format("YYYY-MM-DDTHH:mm:ss"),
      end: updatedCurrentDate.format("YYYY-MM-DDTHH:mm:ss"),
    });
    currentDate = updatedCurrentDate;
  }
  return schedule;
};

export { generateHourlySchedule, generateSchedule };
