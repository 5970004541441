import React from "react";
import {
  FormControl as StyledFormControl,
  FormErrorMessage,
} from "@chakra-ui/react";

type Props = {
  errorMessage: string | null;
  children: React.ReactNode;
};

export default function FormControl({ errorMessage, children }: Props) {
  return (
    <StyledFormControl isInvalid={errorMessage !== null}>
      {children}
      <FormErrorMessage fontSize="12px" mb="12px">
        {errorMessage}
      </FormErrorMessage>
    </StyledFormControl>
  );
}
