//price is in cents
const commissionAndNetFee = (price: number) => {
  const stripeCommission = price ? (0.029 * price) / 100 + 1 : 0;
  const platformCommission = price ? price / 100 / 2 : 0;
  const commission = stripeCommission + platformCommission;
  const netFee = price ? price / 100 - commission : 0;
  return { commission, netFee };
};

export default commissionAndNetFee;
