import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Flex, Box, Button } from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/react";
import {
  EventClickArg,
  EventContentArg,
  EventSourceFunc,
} from "@fullcalendar/react";
import BookingsStats from "./BookingsStats";
import Calendar from "../calendar/Calendar";
import { useGetMyActivitiesQuery } from "../../activity/service";
import { useGetActivityScheduleQuery } from "../../schedule/service";
import ScheduleModal from "./ScheduleModal";
import { getLoadingEvent, generateEvents } from "./bookingEvents";
import AddExceptionMenu from "./AddExceptionMenu";
import { useGetLoggedInUserQuery } from "../../user/service";
import AccountInactive from "../../shared/AccountInactive";
import useGetMyActiveBookings from "../hooks/useGetMyActiveBookings";
import { useIsActivityProvider } from "../../user/hooks";
import UnpaidBookingsModal from "./UnpaidBookingsModal";
import ManualMarkedBookingsModal from "./ManualMarkedBookingsModal";
import { MIN_BOOKING_TIME_IN_ADVANCE } from "../../config";

const today = moment();
const startOfWeek = today.startOf("week").format("YYYY-MM-DDTHH:mm:ss");
const endOfWeek = today.endOf("week").format("YYYY-MM-DDTHH:mm:ss");
const startOfToday = moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss");
const endOfToday = moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss");

const Bookings = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const isActivityProvider = useIsActivityProvider();
  const { data: myAccount, isLoading: gettingMyAccount } =
    useGetLoggedInUserQuery();
  const contInactive = isActivityProvider
    ? myAccount
      ? myAccount.status !== "active"
      : false
    : false;

  const [isBrowser] = useMediaQuery("(min-width: 768px)");

  const [isScheduleModalVisible, setIsScheduleModalVisible] =
    useState<boolean>(false);
  const [currentBooking, setCurrentBooking] = useState<any>(null); // TODO: type this
  const [isUnpaidBookingModalVisible, setIsUnpaidBookingModalVisible] =
    useState<boolean>(false);
  const [isManualMarketAsPaidModalVisible, setIsManualMarketAsPaidModalVisible] =
    useState<boolean>(false);

  const [startDate, setStartDate] = useState<string>(
    isBrowser ? startOfWeek : startOfToday
  );
  const [endDate, setEndDate] = useState<string>(
    isBrowser ? endOfWeek : endOfToday
  );

  const myActivities = useGetMyActivitiesQuery();
  const activity = myActivities.data?.data[0] || null;
  const activityGuid = myActivities.data?.data[0]["guid"] || "";

  const { data: activitySchedule, isFetching: isFetchingSchedule } =
    useGetActivityScheduleQuery(
      {
        a_guid: activityGuid,
        start_datetime: startDate,
        end_datetime: endDate,
      },
      { skip: !myActivities.isSuccess }
    );

  const { data: bookings, isFetching: isFetchingBookings } =
    useGetMyActiveBookings(
      { start_datetime: startDate, end_datetime: endDate },
      true
    );

  const isFetching =
    isFetchingSchedule || isFetchingBookings || gettingMyAccount;

  let schedule = activitySchedule || [];

  let minBookingTimeInAdvance = MIN_BOOKING_TIME_IN_ADVANCE * 60;
  if (activity?.booking_buffer_minutes) {
    minBookingTimeInAdvance = activity.booking_buffer_minutes;
  }

  const events = !isFetching
    ? generateEvents(startDate, endDate, schedule, bookings, minBookingTimeInAdvance)
    : getLoadingEvent(startDate, endDate);

  const handleEventClick = (eventClickInfo: EventClickArg) => {
    const eventId = eventClickInfo.event.groupId;
    if (eventId === "booking") {
      const booking = eventClickInfo.event.extendedProps.booking;
      if (booking.status === "active") {
        setCurrentBooking(null)
        navigate(`/ap/patient/${booking.u_guid}/${booking.a_guid}`);
      } else if (booking.status === "pending_payment") {
        setIsUnpaidBookingModalVisible(true);
        setCurrentBooking(booking)
      }
    }
  };

  const renderEventContent = (eventInfo: EventContentArg) => {
    const eventId = eventInfo.event.groupId;
    if (eventId === "available") {
      const { label, start, end } = eventInfo.event.extendedProps;
      return (
        <AddExceptionMenu
          exceptionType="disable"
          a_guid={activityGuid}
          label={label}
          start={start}
          end={end}
        />
      );
    } else if (eventId === "booking") {
      const { display_name, start_time, end_time, status } =
        eventInfo.event.extendedProps.booking;
      return (
        <Box className="ap-booking" lineHeight="normal">
          <Box fontWeight="700">{display_name}</Box>
          <Box fontSize="12px">{`${moment(start_time).format(
            "HH:mm"
          )} - ${moment(end_time).format("HH:mm")}`}</Box>
          {status === "pending_payment" && <Box>Nefinalizat</Box>}
        </Box>
      );
    } else if (eventId === "unavailable") {
      const { start, end } = eventInfo.event.extendedProps;

      let minBookingTimeInAdvance = MIN_BOOKING_TIME_IN_ADVANCE * 60;
      if (activity?.booking_buffer_minutes) {
        minBookingTimeInAdvance = activity.booking_buffer_minutes;
      }

      if (moment(start).isBefore(moment().add(minBookingTimeInAdvance, "minutes"))) {  // prevent adding booking intervals in less then MIN_BOOKING_TIME_IN_ADVANCE
        return null;
      }
      return (
        <AddExceptionMenu
          exceptionType="enable"
          a_guid={activityGuid}
          label={""}
          start={start}
          end={end}
        />
      );
    }
  };

  const handleEvents: EventSourceFunc = (fetchInfo) => {
    setStartDate(fetchInfo.startStr.split("+")[0]);
    const updatedEndDate =
      moment(fetchInfo.endStr).subtract(1, "day").format("YYYY-MM-DD") +
      "T23:59:59";
    setEndDate(updatedEndDate);
    return Promise.resolve(events);
  };

  return (
    <>
      {contInactive && <AccountInactive />}
      {!contInactive && <BookingsStats />}
      <Box
        layerStyle="card"
        p="0"
        mt="24px"
        overflow="hidden"
        _hover={{ boxShadow: "0 8px 16px 0 rgba(39,47,55,0.12)" }}
        className="ap-booking-calendar"
        position="relative"
      >
        <Flex
          bg="#13181D"
          px="24px"
          py="16px"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <h2 style={{ color: "#fff" }}>
            {intl.formatMessage({ id: "calendar" })}
          </h2>
          <Button
            variant="link"
            color="#fff"
            onClick={() => setIsScheduleModalVisible(true)}
          >
            {intl.formatMessage({ id: "booking_ap_edit_schedule" })}
          </Button>
        </Flex>
        <Box position="relative" zIndex="1">
          <Calendar
            initialView={isBrowser ? "timeGridWeek" : "timeGridDay"}
            displayEventTime={false}
            events={handleEvents}
            eventClick={handleEventClick}
            eventContent={renderEventContent}
          />
        </Box>
        {isScheduleModalVisible && activity && (
          <ScheduleModal
            activity={activity}
            isVisible={isScheduleModalVisible}
            onModalClose={() => setIsScheduleModalVisible(false)}
          />
        )}
        {isUnpaidBookingModalVisible && (
          <UnpaidBookingsModal
            isOpen={isUnpaidBookingModalVisible}
            onCloseModal={() => {
              setIsUnpaidBookingModalVisible(false)
              setCurrentBooking(null)
            }}
            onManualMarkBooking={() => {
              setIsUnpaidBookingModalVisible(false)
              setIsManualMarketAsPaidModalVisible(true)
            }}
          />
        )}
        {isManualMarketAsPaidModalVisible && currentBooking && (
          <ManualMarkedBookingsModal
            isOpen={isManualMarketAsPaidModalVisible}
            onCloseModal={() => setIsManualMarketAsPaidModalVisible(false)}
            a_guid={currentBooking.a_guid}
            b_guid={currentBooking.b_guid}
          />
        )}
      </Box>
    </>
  );
};

export default Bookings;
