import { translations as en_local_translation } from "./en/local_translation";
import { translations as en_server_translation } from "./en/server_translation";
import { translations as ro_local_translation } from "./ro/local_translation";
import { translations as ro_server_translation } from "./ro/server_translation";

const ro_translations = { ...ro_local_translation, ...ro_server_translation };
const en_translations = { ...en_local_translation, ...en_server_translation };

const getTranslations = (locale: string) => {
  switch (locale) {
    case "en":
      return en_translations;
    default:
      return ro_translations;
  }
};

export default getTranslations;
