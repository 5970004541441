import { useState } from "react";
import { Box, Text, Image } from "@chakra-ui/react";
import { Image as ImageIcon } from "@carbon/icons-react";
import ImageUploader from "./ImageUploader";
import { IMediaAsset } from "./types";

type Props = {
  onImageUpload: (image: IMediaAsset[]) => void;
  label: string;
};

const AvatarUploader = ({ onImageUpload, label }: Props) => {
  const [avatar, setAvatar] = useState<string | null>(null);

  const handleAvatarUpload = (mediaAssets: IMediaAsset[]) => {
    setAvatar(mediaAssets[0].url);
    onImageUpload(mediaAssets);
  };

  return (
    <Box
      d="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      w="100%"
      h="100%"
      pos="relative"
      bgColor="white"
      border="1px"
      borderColor="border.500"
      borderRadius="8px"
      px="32px"
      py="49px"
      cursor="pointer"
      overflow="hidden"
    >
      <ImageUploader onImageUpload={handleAvatarUpload} />
      <Box w="100%" h="100%" pos="absolute" zIndex="9" borderRadius="8px">
        {avatar && <Image src={avatar} w="100%" h="100%" objectFit="cover" />}
      </Box>
      <ImageIcon size={32}color="border.500" />
      <Text>{label}</Text>
    </Box>
  );
};

export default AvatarUploader;
