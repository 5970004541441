import { Show, Flex, Box, Link } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { Link as RouteLink } from "react-router-dom";
import Logo from "../../shared/Logo";
type Props = {
  imageUrl: string;
  logoImage: string;
  logoRouteUrl: string;
};

//TODO: add tnc and privacy policy links

const AuthSideLayout = ({ imageUrl, logoImage, logoRouteUrl }: Props) => {
  const intl = useIntl();

  return (
    <Show above="md">
      <Flex
        h="full"
        bg={`url(${imageUrl}) no-repeat center`}
        bgSize="cover"
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box
          px="32px"
          pt="32px"
          h="40%"
          bgImage="linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0.00) 100%)"
        >
          <Link as={RouteLink} to={logoRouteUrl} mr="auto">
            <Logo src={logoImage} />
          </Link>
        </Box>
        <Box
          position="absolute"
          top="0"
          right="0"
          bottom="0"
          width="60%"
          zIndex="1"
          bgImage="linear-gradient(270deg, #FFFFFF 0%, rgba(255,255,255,0.00) 100%)"
        />
        <Box
          px="32px"
          pb="32px"
          mt="auto"
          h="150px"
          display="flex"
          bgImage="linear-gradient(0deg, #FFFFFF 0%, rgba(255,255,255,0.00) 100%)"
        >
          <Box mt="auto">
            <Link href="https://www.psiholog.ro/termeni-si-conditii" isExternal color="brandGray.500">
              {intl.formatMessage({ id: "terms_and_conditions" })}
            </Link>
            <Link href="https://www.psiholog.ro/politica-de-confidentialitate" isExternal color="brandGray.500" ml="32px">
              {intl.formatMessage({ id: "privacy_policy" })}
            </Link>
          </Box>
        </Box>
      </Flex>
    </Show>
  );
};

export default AuthSideLayout;
