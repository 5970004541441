import { useIntl } from "react-intl";
import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  onManualMarkBooking: () => void;
};

const UnpaidBookingsModal = ({ isOpen, onCloseModal, onManualMarkBooking }: Props) => {
  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      isCentered={true}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          borderBottom="1px solid var(--chakra-colors-border-400)"
          textAlign="center"
        >
          <h2>
            {intl.formatMessage({ id: "payment_pending_booking_modal_title" })}
          </h2>
        </ModalHeader>
        <ModalCloseButton w="44px" h="44px" />
        <ModalBody py="16px">
          <Text
            fontSize="18px"
            lineHeight="normal"
            color="brandGray.500"
            fontWeight="600"
          >
            {intl.formatMessage({
              id: "payment_pending_booking_modal_description",
            })}
          </Text>
          <Button
            variant="link"
            onClick={() => onManualMarkBooking()}
          >
            Marchează ca sedinta gratuită
          </Button>
        </ModalBody>

        <ModalFooter borderTop="1px solid var(--chakra-colors-border-400)">
          <Button w="full" size="lg" onClick={onCloseModal}>
            {intl.formatMessage({ id: "payment_pending_booking_modal_btn" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UnpaidBookingsModal;
