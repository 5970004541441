import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import { IAuthTokens, IAuthCredentials, OauthInitResponse, OauthInitRequest, OauthHandleResponse, OauthHandleRequest, IAuthRefreshTokensWithUtm, AuthEnterInitResponse, AuthEnterInitRequest, UnsubHandleRequest, UnsubHandleResponse } from "./types";


export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    authenticate: builder.mutation<IAuthTokens, IAuthCredentials>({
      query: (payload: IAuthCredentials) => {
        return {
          url: "/auth",
          method: "POST",
          body: payload,
        };
      },
    }),
    enterInit: builder.mutation<AuthEnterInitResponse, AuthEnterInitRequest>({
      query: (payload: AuthEnterInitRequest) => {
        return {
          url: "/auth/enter/init",
          method: "POST",
          body: payload,
        };
      },
    }),
    oauthInit: builder.mutation<OauthInitResponse, OauthInitRequest>({
      query: (payload: OauthInitRequest) => {
        return {
          url: "/auth/oauth/init",
          method: "POST",
          body: payload,
        };
      },
    }),
    oauthHandle: builder.mutation<OauthHandleResponse, OauthHandleRequest>({
      query: (payload: OauthHandleRequest) => {
        return {
          url: "/auth/oauth/handle",
          method: "POST",
          body: payload,
        };
      },
    }),
    getNewAccessToken: builder.mutation<
      Pick<IAuthTokens, "access_token">,
      IAuthRefreshTokensWithUtm
    >({
      query: (payload: IAuthRefreshTokensWithUtm) => {
        const { refresh_token, utm } = payload;
        return {
          url: "/auth/refresh",
          method: "POST",
          headers: { Authorization: `Bearer ${refresh_token}` },
          body: { utm },
        };
      },
    }),
    unsubscribeUser: builder.mutation<UnsubHandleResponse, UnsubHandleRequest>({
      query: (payload: UnsubHandleRequest) => {
        return {
          url: "/auth/unsub",
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
});

export const {
  useAuthenticateMutation,
  useGetNewAccessTokenMutation,
  useOauthInitMutation,
  useOauthHandleMutation,
  useEnterInitMutation,
  useUnsubscribeUserMutation,
} =
  authApi;
