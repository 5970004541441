import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Input,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { Form, FormControl } from "../shared/form";
import { useIntl } from "react-intl";
import { validateForm } from "../utils/forms";
import { changePasswordValidationRules } from "../utils/forms/validators";

type Props = {
  changePassword: ({
    pw,
    old_pw,
  }: {
    pw: string;
    old_pw: string;
  }) => Promise<void>;
  updating: boolean;
};

const initialFormValues = { old_pw: "", password: "", repeatPassword: "" };
const defaultErrors = { old_pw: null, password: null, repeatPassword: null };

const ChangePasswordForm = ({ changePassword, updating }: Props) => {
  const intl = useIntl();

  const [formErrors, setFormErrors] = useState<{
    old_pw: null | string;
    password: null;
    repeatPassword: null;
  }>(defaultErrors);
  const [formValues, setFormValues] = useState(initialFormValues);

  const { old_pw, password, repeatPassword } = formValues;

  const onInputChange = (e: React.ChangeEvent<any>, targetName: string) => {
    setFormErrors(defaultErrors);
    const targetValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormValues({ ...formValues, [targetName]: targetValue });
  };

  const submit = (e: any) => {
    e.preventDefault();
    const validationErrors = validateForm(
      formValues,
      changePasswordValidationRules
    );
    if (!validationErrors) {
      changePassword({ pw: password, old_pw }).then(
        () => {
          setFormValues(initialFormValues);
        },
        (error) => {
          setFormErrors({
            ...defaultErrors,
            old_pw: intl.formatMessage({ id: "invalid_old_password" }),
          });
        }
      );
    } else {
      setFormErrors({ ...defaultErrors, ...validationErrors });
    }
  };

  return (
    <Form onSubmit={submit}>
      <Box layerStyle="card" mt="16px">
        <Stack spacing="5">
          <Stack
            spacing="4"
            direction={{ base: "column", sm: "row" }}
            justify="space-between"
          >
            <Box>
              <Text fontSize="lg" fontWeight="bold" color="brandGray.500">
                {intl.formatMessage({ id: "change_password" })}
              </Text>
              <Text color="muted" fontSize="sm">
                {intl.formatMessage({ id: "change_password_info" })}
              </Text>
            </Box>
            <Button type="submit" alignSelf="start" isLoading={updating}>
              {intl.formatMessage({ id: "save_btn" })}
            </Button>
          </Stack>
          <Divider />
          <Stack spacing="5" divider={<StackDivider />}>
            <FormControl errorMessage={formErrors.old_pw}>
              <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={{ base: "1.5", lg: "8" }}
                justify="space-between"
              >
                <FormLabel
                  variant="inline"
                  fontWeight="600"
                  color="brandGray.500"
                >
                  {" "}
                  {intl.formatMessage({ id: "old_password" })}
                </FormLabel>
                <Input
                  value={old_pw}
                  type="password"
                  maxW={{ lg: "3xl" }}
                  onChange={(e) => onInputChange(e, "old_pw")}
                  size="lg"
                />
              </Stack>
            </FormControl>
            <FormControl errorMessage={formErrors.password}>
              <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={{ base: "1.5", lg: "8" }}
                justify="space-between"
              >
                <FormLabel
                  variant="inline"
                  fontWeight="600"
                  color="brandGray.500"
                >
                  {intl.formatMessage({ id: "new_password" })}
                </FormLabel>
                <Input
                  value={password}
                  type="password"
                  maxW={{ lg: "3xl" }}
                  onChange={(e) => onInputChange(e, "password")}
                  size="lg"
                />
              </Stack>
            </FormControl>
            <FormControl errorMessage={formErrors.repeatPassword}>
              <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={{ base: "1.5", lg: "8" }}
                justify="space-between"
              >
                <FormLabel
                  variant="inline"
                  fontWeight="600"
                  color="brandGray.500"
                >
                  {intl.formatMessage({ id: "repeat_new_password" })}
                </FormLabel>
                <Input
                  value={repeatPassword}
                  type="password"
                  maxW={{ lg: "3xl" }}
                  onChange={(e) => onInputChange(e, "repeatPassword")}
                  size="lg"
                />
              </Stack>
            </FormControl>

            <Flex direction="row-reverse">
              <Button type="submit" isLoading={updating}>
                {intl.formatMessage({ id: "save_btn" })}
              </Button>
            </Flex>
          </Stack>
        </Stack>
      </Box>
    </Form>
  );
};

export default ChangePasswordForm;
