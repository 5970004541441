import { Box } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import Loader from "../shared/Loader";

import useNotes from "./hooks/useNotes";
import Note from "./Note";
import { INote } from "./types";

type Props = {
  userId: string,
  radius?: string,
  shadow?: string,
  padding?: string,
}

const ImportantNotes = ({ userId, radius, shadow, padding }: Props) => {
  const intl = useIntl();
  const { notes, isLoading } = useNotes(userId, true)
  if (isLoading) return <Loader />
  return (
    <Box layerStyle="card" color="#fff" bg="#272F37" borderRadius={radius} boxShadow={shadow} _hover={{boxShadow:{shadow}}} p={padding}>
      <h2 style={{ color: "#fff" }}>{intl.formatMessage({ id: "important_info" })}</h2>
      {notes.map(((note: INote) => <Note note={note} key={note.guid} featured />))}
    </Box>
  );
};

export default ImportantNotes;
