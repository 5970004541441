import { Box } from "@chakra-ui/react";
import React from "react";

type Props = {
  value: number;
  label: string;
  isDanger: boolean;
};

const DateTimeDisplay = ({ value, label, isDanger }: Props) => {
  return (
    <Box flex="1" color={isDanger ? "border.500" : "black"}>
      <Box fontSize="18px" fontWeight="600">{value}</Box>
      <Box fontSize="11px" letterSpacing="1px" fontWeight="600" opacity=".54" textTransform="uppercase">{label}</Box>
    </Box>
  );
};

export default DateTimeDisplay;
