import { useSelector } from "react-redux";
import { RootState } from "../../store";
import getUserGuidFromAccessToken from "../userGuidFromAccessToken";

const useGetUserGuidFromAccessToken = (): string => {
  const accessToken = useSelector((state: RootState) => state.iam.access_token);
  return getUserGuidFromAccessToken(accessToken);
};

export default useGetUserGuidFromAccessToken;
