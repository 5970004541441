import { Select, Box, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { IPrice } from "../types";
import commissionAndNetFee from "./price";

type Props = {
  initialPrice?: IPrice;
  isDisabled?: boolean;
  onSelectPrice: (price: IPrice | undefined) => void;
};

const ActivityPrice = ({ initialPrice, isDisabled, onSelectPrice }: Props) => {
  const intl = useIntl();

  const [price, setPrice] = useState<IPrice | undefined>(
    initialPrice || undefined
  );

  const handlePriceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let updatedPrice;

    if (e.target.value !== "") {
      updatedPrice = {
        ...price,
        value: e.target.value,
        label: `${Number(e.target.value) / 100} RON`,
        currency: "RON",
      };
    }

    setPrice(updatedPrice);
    onSelectPrice(updatedPrice);
  };

  const { commission, netFee } = commissionAndNetFee(
    price ? Number(price.value) : 0
  );

  return (
    <VStack>
      <Select
        mb="8px"
        placeholder={intl.formatMessage({
          id: "ap_onboarding_step4_placeholder",
        })}
        w="full"
        size="lg"
        value={price && price.value ? price.value : ""}
        onChange={handlePriceChange}
        disabled={isDisabled}
      >
        {[10000, 15000, 20000, 25000, 30000, 35000].map((priceValue, idx) => (
          <option key={idx} value={`${priceValue}`}>
            {`${priceValue / 100} RON`}
          </option>
        ))}
      </Select>

      <Box mb="16px">
        {price &&
          intl.formatMessage(
            { id: "ap_onboarding_step4_price" },
            {
              price: <b>{`${Number(price.value) / 100} RON`}</b>,
              apPart: <b>{`${netFee} RON`}</b>,
              commission: <b>{`${commission} RON`}</b>,
            }
          )}
      </Box>
    </VStack>
  );
};

export default ActivityPrice;
