import { IActivityFilters } from "./types";

const defaultActivityFilters: IActivityFilters = {
  rating: "0-5",
  issues_to_fix: [],
  name: "",
  available_from: "",
  available_until: "",
};

export { defaultActivityFilters };
