import { Box, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useGetOnboardingLinkQuery } from "../../activityProvider/service";
import Loader from "../../shared/Loader";
import { Center } from "@chakra-ui/react";
const StripeOnboarding = () => {
  const intl = useIntl();

  const { data } = useGetOnboardingLinkQuery();

  useEffect(() => {
    const stripeLink = data?.onboarding_link;

    if (stripeLink) {
      window.location.href = stripeLink;
    }
  }, [data]);

  return (
    <Box>
      <Loader />
      <Center>
        <Text>{intl.formatMessage({ id: "ap_onboarding_stripe" })}</Text>
      </Center>
    </Box >
  );
};

export default StripeOnboarding;
