
import ReactGA4 from "react-ga4";
import { getFromLocalStorage, saveToLocalStorage } from "./localStorage";
const InitializeGoogleAnalytics = () => {
    // Initialize GA4 - Add your measurement ID
    if(!process.env.REACT_APP_GA_MEASUREMENT_ID) return;
    ReactGA4.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID as string);
    // console.log("GA INITIALIZED");
};

const TrackGoogleAnalyticsEvent = (
    category: string,
    action: string,
    label?: string,
    value?: number,
    nonInteraction?: boolean,
    transport?: ('beacon' | 'xhr' | 'image')
) => {
    if(!process.env.REACT_APP_GA_MEASUREMENT_ID) {
        console.log("Missing GA_MEASUREMENT_ID");
        return;
    }
    console.log("GA event: category=", category, " action=", action, " label=", label, " value=", value, "nonInteraction=", nonInteraction, "transport=", transport);
    
    // Send GA4 Event
    
    ReactGA4.event({
        category: category,
        action: action,
        label: label,
        value: value,
        nonInteraction: nonInteraction,
        transport: transport
    });

};

const TrackGoogleAnalyticsEventOnce = (
    uniqueId: string,
    category: string,
    action: string,
    label?: string,
    value?: number,
    nonInteraction?: boolean,
    transport?: ('beacon' | 'xhr' | 'image')
    ) => {
    if(!process.env.REACT_APP_GA_MEASUREMENT_ID) {
        console.log("Missing GA_MEASUREMENT_ID");
        return;
    }
    const cacheKey = `ga-event-unique-${uniqueId}`
    if(getFromLocalStorage(cacheKey)) {
        console.log("GA event ignore duplicate: category=", category, " action=", action, " label=", label, " value=", value, "nonInteraction=", nonInteraction, "transport=", transport);
        return
    }
    TrackGoogleAnalyticsEvent(category, action, label, value, nonInteraction, transport)
    saveToLocalStorage(cacheKey, "true")

};

export {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, TrackGoogleAnalyticsEventOnce}