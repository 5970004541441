import React from "react";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useGetLoggedInUserQuery, useModifyUserMutation } from "./service";
import PersonalInformationForm from "./PersonalInformationForm";
import { useAuthenticateMutation } from "../auth/service";
import ChangePasswordForm from "./ChangePasswordForm";
import { IRegisteredUser } from "./types";
import useNotification from "../shared/hooks/useNotification";

type ChangePw = { pw: string, old_pw: string }

const MyAccount = () => {
  const intl = useIntl();
  const notify = useNotification()
  const { data: user } = useGetLoggedInUserQuery();
  const [modifyUser, { isLoading: updatingUser }] = useModifyUserMutation();
  const [authenticate, { isLoading: checkingPassword }] = useAuthenticateMutation();

  const changePersonalInformation = (personal_info: { user: Partial<IRegisteredUser>, guid: string }) => {
    modifyUser(personal_info).then(() => {
      notify('success', 'user_details_success_update')
    }, () => {
      notify('error', 'ap_onboarding_step1_error')
    })
  }

  const changePassword = ({ pw, old_pw }: ChangePw): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const authCredentials = {
        password: old_pw,
        user_name: user ? user.u_name : ''
      }
      authenticate(authCredentials).unwrap().then((response: any) => {
        if (response && response.access_token) {
          modifyUser({ user: { pw, }, guid: user ? user.guid : '' }).finally(() => {
            notify('success', 'user_details_password_success_update')
            resolve()
          })
        } else {
          reject()
        }
      }, (error) => {
        reject()
      })
    })
  }
  if (!user) return null
  return (
    <Box>
      <>
        <h1>{intl.formatMessage({ id: "my_account_title" })}</h1>
        <Tabs colorScheme="brand" mt="16px">
          <TabList border="0">
            <Tab fontSize="13px" px="0" mr="24px">
              {intl.formatMessage({ id: "my_account_personal_info" })}
            </Tab>
            <Tab fontSize="13px" px="0" mr="24px">
              {intl.formatMessage({ id: "my_account_personal_password" })}
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel px="0" py="0">
              <PersonalInformationForm user={user} updateUser={changePersonalInformation} updating={updatingUser} />
            </TabPanel>
            <TabPanel px="0" py="0">
              <ChangePasswordForm changePassword={changePassword} updating={updatingUser || checkingPassword} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
    </Box>
  );
};

export default MyAccount;
