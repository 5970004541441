import React from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { RootState, store } from "./store";
import { throttle } from "lodash";
import App from "./App";
import {
  setCookie,
  removeCookie,
  defaultIamCookieAttributes,
  removeIamCookieAttributes
} from "./utils/cookies";
import { AUTH_COOKIE_NAME, SENTRY_DSN, SENTRY_ENV } from "./config";

const saveIamToCookie = (state: RootState) => {
  const iam = {
    access_token: state.iam.access_token,
    refresh_token: state.iam.refresh_token,
  };

  const serializedIAM = JSON.stringify(iam);

  if (iam.access_token && iam.refresh_token) {
    setCookie(AUTH_COOKIE_NAME, serializedIAM, defaultIamCookieAttributes);
  } else {
    removeCookie(AUTH_COOKIE_NAME, removeIamCookieAttributes);
  }
};

const initSentry = () => {
  if (!SENTRY_DSN || !SENTRY_ENV) return;
  if (!window?.location?.hostname) return;

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    ignoreErrors: [
      "icons",
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    debug: false,
  });

}

initSentry()

store.subscribe(throttle(() => saveIamToCookie(store.getState()), 1000));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
