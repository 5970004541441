import moment from "moment";
import { useIntl } from "react-intl";
import { Box, Flex } from "@chakra-ui/react";
import { EventSchedule } from "@carbon/icons-react";
import SessionPreview from "./SessionPreview";
import Loader from "../../shared/Loader";
import useGetMyActiveBookings from "../hooks/useGetMyActiveBookings";

const START_DATETIME = moment().startOf("hour").format("YYYY-MM-DDTHH:mm:ss");
const END_DATETIME = moment().add(1, "year").add(20, "day").format("YYYY-MM-DDTHH:mm:ss");

const ActiveSessions = () => {
  const intl = useIntl();

  const { data, isLoading } = useGetMyActiveBookings({
    start_datetime: START_DATETIME,
    end_datetime: END_DATETIME,
  });

  const bookings =
    data && data.length
      ? data.filter((booking) => booking.status === "active")
      : [];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {bookings.length > 0 &&
        bookings.map((booking, idx) => (
          <SessionPreview
            key={idx}
            booking={booking}
            start_datetime={START_DATETIME}
            end_datetime={END_DATETIME}
          />
        ))}
      {bookings.length === 0 && (
        <>
          <Box layerStyle="card" mt="24px">
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              textAlign="center"
            >
              <Flex
                borderRadius="100%"
                alignItems="center"
                justifyContent="center"
                color="#fff"
                w="80px"
                h="80px"
                bg="gray.500"
              >
                <EventSchedule size={32}/>
              </Flex>
              <h2>
                {intl.formatMessage({ id: "booking_no_active_bookings_title" })}
              </h2>
            </Flex>
          </Box>
        </>
      )}
    </>
  );
};

export default ActiveSessions;
