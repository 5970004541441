import { Flex, Avatar, Box, Text } from "@chakra-ui/react";
import { useGetActivityQuery } from "../activity/service";
import Rating from "../reviews/Rating";
import Loader from "./Loader";

type Props = {
  guid: string;
  hideRating?: boolean;
  displayAvatar?: string;
};

const ActivityMeta = ({ guid, hideRating, displayAvatar }: Props) => {
  const { data: activity, isLoading } = useGetActivityQuery(guid);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Flex alignItems="center">
      {isLoading && !activity && <Loader />}
      {activity && (
        <>
          <Avatar
            name={activity.name}
            src={activity.images[0] ? activity.images[0]["url"] : ""}
            w="44px"
            h="44px"
            mr="8px"
            display={displayAvatar}
          />
          <Box>
            <Text
              fontSize="18px"
              lineHeight="normal"
              color="brandGray.500"
              fontWeight="600"
            >
              {activity.name}
            </Text>
            {!hideRating && <Rating score={activity.rating} count={activity.reviews_no} />}
          </Box>
        </>
      )}
    </Flex>
  );
};

export default ActivityMeta;
