import { useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@chakra-ui/react";

type Props = {
  issue: string;
  onCheckIssueToFix: (issues: string, isChecked: boolean) => void;
};

const IssueToFixCheckbox = ({ issue, onCheckIssueToFix }: Props) => {
  const intl = useIntl();

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleOnClick = () => {
    setIsChecked(!isChecked);
    onCheckIssueToFix(issue, !isChecked);
  };

  return (
    <Box
      layerStyle={isChecked ? "checkboxPillSelected" : "checkboxPill"}
      onClick={handleOnClick}
    >
      {intl.formatMessage({ id: issue })}
    </Box>
  );
};

export default IssueToFixCheckbox;
