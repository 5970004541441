import React from 'react'
import { Box, Checkbox } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import conditions from '../conditions';

type ActivityList = { onChange: (list: string[]) => void, selected: string[] }

const ActivityCategoriesList = ({ onChange, selected }: ActivityList) => {
    const intl = useIntl();
    const onChangeUpdate = (e: any) => {
        if (e.currentTarget.checked) {
            const merged_activity: any = [...selected, e.currentTarget.value]
            onChange(merged_activity)
        } else {
            const removed_activity: any = selected.filter(activity => activity !== e.currentTarget.value)
            onChange(removed_activity)
        }
    }
    return (
        <Box w="full" maxW={{ md: '3xl' }}>
            {conditions.map(activity => {
                const isChecked = selected?.length ? selected.includes(activity) : false
                return <Checkbox display="flex" py="8px" colorScheme='brand' onChange={onChangeUpdate} isChecked={isChecked} value={activity} key={activity} >{intl.formatMessage({ id: activity })}</Checkbox>
            })}
        </Box>
    )
}
export default ActivityCategoriesList