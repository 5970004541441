import moment from "moment";
import { EventInput } from "@fullcalendar/react";
import { IActivitySchedule } from "../../schedule/types";
import { IBooking } from "../types";
import { fromServertoLocalYMDTHms, generateHourlySchedule, generateSchedule, toLocalHM } from "../utils";
import { MIN_BOOKING_TIME_IN_ADVANCE } from "../../config";

const generateEvents = (
  startDate: string,
  endDate: string,
  schedule: IActivitySchedule,
  bookings: IBooking[],
  myBookings: (IBooking & { myName: string })[],
  minBookingTimeInAdvance: number = MIN_BOOKING_TIME_IN_ADVANCE * 60
) => {
  let events: EventInput[] = [];

  const weekSchedule = generateSchedule(startDate, endDate);

  weekSchedule.forEach((scheduleItem) => {
    events.push({
      groupId: "unavailable",
      className: ["unavailable"],
      start: scheduleItem.start,
      end: scheduleItem.end,
      backgroundColor: "#eeeeee",
      borderColor: "#eeeeee",
    });
  });

  const hourlySchedule = generateHourlySchedule(schedule, minBookingTimeInAdvance);
  const availabilities = hourlySchedule.filter((scheduleItem) => {
    return moment(scheduleItem.start).isAfter(moment().add(minBookingTimeInAdvance, "minutes"))
  });


  events.forEach((event, idx) => {
    availabilities.forEach(({ start, end }) => {
      // TODO - Pre-convert to local time 
      const localStart = fromServertoLocalYMDTHms(start)
      const localEnd = fromServertoLocalYMDTHms(end)
      if (localStart === event.start && localEnd === event.end) {
        events[idx] = {
          groupId: "available",
          className: ["available"],
          title: `${toLocalHM(localStart)}-${toLocalHM(localEnd)}`,
          start: localStart,
          end: localEnd,
          backgroundColor: "white",
          borderColor: "#eeeeee",
          textColor: "grey",
        };
      }
    });
  });

  events.forEach((event, idx) => {
    bookings.forEach((booking) => {
      // TODO - Pre-convert to local time
      const localStart = fromServertoLocalYMDTHms(booking.start_time)
      const localEnd = fromServertoLocalYMDTHms(booking.end_time)
      if (localStart === event.start && localEnd === event.end) {
        events[idx] = {
          groupId: "unavailable",
          className: ["unavailable"],
          start: event.start,
          end: event.end,
          backgroundColor: "#eeeeee",
          borderColor: "#eeeeee",
        };
      }
    });
  });
  
  events.forEach((event, idx) => {
    myBookings.forEach((myBooking) => {
      // TODO - Pre-convert to local time
      const localStart = fromServertoLocalYMDTHms(myBooking.start_time)
      const localEnd = fromServertoLocalYMDTHms(myBooking.end_time)
      if (
        moment().isBefore(moment(localStart)) &&
        localStart === event.start &&
        localEnd === event.end
      ) {
        const { status } = myBooking;
        events[idx] = {
          groupId: "myBookings",
          classNames: status === "pending_payment" ? ["my-bookings", "unpaid"] : ["my-bookings"],
          title: status === "active" ? `${toLocalHM(localStart)}-${toLocalHM(localEnd)}` : `Nefinalizat`,
          start: event.start,
          end: event.end,
          backgroundColor: "#blue",
          extendedProps: { booking: myBooking },
        };
      }
    });
  });


  return events;
};

const getLoadingEvent = (startDate: string, endDate: string) => [
  {
    groupId: "loading-state",
    className: ["loading-state"],
    start: startDate,
    end: endDate,
    backgroundColor: "white",
    borderColor: "white",
  },
];

export { getLoadingEvent, generateEvents };
