import {
  Avatar,
  Box,
  HStack,
  Text,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuGroup,
  Spinner,
  Show,
} from "@chakra-ui/react";
import {
  ChevronSort,
  Logout,
  SettingsAdjust,
  UserProfile as UserProfileIcon,
  View,
  // Wallet,
} from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import { useGetLoggedInUserQuery } from "../user/service";
import { logoutUser } from "../auth/slice";
import { useIntl } from "react-intl";
import { useEffect } from "react";
import { setUser } from "@sentry/react"


const UserProfile = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const { data: user, isFetching } = useGetLoggedInUserQuery();

  const userRoles = user?.roles ? user.roles : [];

  const isActivityProvider = userRoles.includes("activity_provider");
  const isRegisteredUser = userRoles.includes("registered_user");

  useEffect(() => {
    try {
      if (user) {
        setUser({
          id: user.guid,
          username: user.email,
          email: user.email,
        });
      }
      else {
        setUser(null);
      }
    }
    catch (error) {
      console.log('error', error)
    }
  }, [user?.u_name]) /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <>
      {isFetching && <Spinner />}
      {user && (
        <Menu placement="bottom-end" autoSelect={false}>
          <MenuButton color="brandGray.500">
            <HStack
              cursor="pointer"
              transition="all 120ms ease-in-out"
              _hover={{ opacity: 0.9 }}
              role="button"
            >
              <Avatar
                name={user.f_name}
                src={user.profile_img?.url}
                size="sm"
                bg="gray.500"
                color="#fff"
                fontWeight="700"
              />
              <Flex alignItems="center">
                <Show above="md">
                  <Box mr="8px">
                    <Text>{user.f_name}</Text>
                  </Box>
                </Show>
                <ChevronSort size={16}/>
              </Flex>
            </HStack>
          </MenuButton>
          <MenuList minW="160px" p="0" color="brandGray.500" overflow="hidden">
            <MenuGroup title={user.email}>
              {isActivityProvider && (
                <>
                <MenuDivider m="0" />
                  <MenuItem
                    onClick={() => navigate(`/ap/my-account`)}
                    icon={<UserProfileIcon size={20}color="#696871" />}
                  >
                    {intl.formatMessage({ id: "user_menu_my_account" })}
                  </MenuItem>
                  {/* <MenuDivider m="0" />
                  <MenuItem
                    onClick={() => navigate(`/ap/payments`)}
                    icon={<Wallet size={20}color="#696871" />}
                  >
                    {intl.formatMessage({ id: "user_menu_payments_history" })}
                  </MenuItem> */}
                  <MenuDivider m="0" />
                  <MenuItem
                    onClick={() => navigate(`/ap/public-profile`)}
                    icon={<View size={20}color="#696871" />}
                  >
                    {intl.formatMessage({ id: "user_menu_public_profile" })}
                  </MenuItem>
                </>
              )}
              {isRegisteredUser && (
                <>
                  <MenuDivider m="0" />
                  <MenuItem
                    onClick={() => navigate(`/my-account`)}
                    icon={<UserProfileIcon color="#696871" size={20} />}
                  >
                    {intl.formatMessage({ id: "user_menu_my_account" })}
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate(`/preferences`)}
                    icon={<SettingsAdjust color="#696871" size={20} />}
                  >
                    {intl.formatMessage({ id: "navbar_preferences_label" })}
                  </MenuItem>
                </>
              )}
              <MenuDivider m="0" />
              <MenuItem
                icon={<Logout size={20} color="#696871" />}
                onClick={() => logoutUser()}
              >
                {intl.formatMessage({ id: "user_menu_logout" })}
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      )}
    </>
  );
};

export default UserProfile;
