import { useIntl } from "react-intl";
import { Select } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { changeFetchFilters, getActivitiesFilters } from "../slice";

const Rating = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const filters = useAppSelector(getActivitiesFilters);
  const { rating } = filters;

  const handleRatingChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(changeFetchFilters({ ...filters, rating: e.target.value }));
  };

  return (
    <Select
      mr={{ base: "0", md: "8px" }}
      my={{ base: "12px", md: "0" }}
      maxW={{ base: "100%", md: "140px" }}
      value={rating}
      onChange={handleRatingChange}
    >
      <option value="0-5">
        {intl.formatMessage({ id: "activity_rating_placeholder" })}
      </option>
      <option value="1-5">
        {intl.formatMessage({ id: "activity_rating_option" })} 1 ★
      </option>
      <option value="2-5">
        {intl.formatMessage({ id: "activity_rating_option" })} 2 ★
      </option>
      <option value="3-5">
        {intl.formatMessage({ id: "activity_rating_option" })} 3 ★
      </option>
      <option value="4-5">
        {intl.formatMessage({ id: "activity_rating_option" })} 4 ★
      </option>
      <option value="5-5">
        {intl.formatMessage({ id: "activity_rating_option" })} 5 ★
      </option>
    </Select>
  );
};

export default Rating;
