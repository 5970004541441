import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import { Box, Text, Button } from "@chakra-ui/react";
import Layout from "../layout";
import ForgotPasswordForm from "../forms/ForgotPasswordForm";
import { IRoles } from "../../user/types";
import { AP_HOME_PAGE, USER_HOME_PAGE } from "../../routes/defaults";
import { RootState } from "../../store";

type Props = {
  userType: IRoles;
};

const ForgotPassword = ({ userType }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const accessToken = useSelector((state: RootState) => state.iam.access_token);

  useEffect(() => {
    if (accessToken) {
      if (userType === "activity_provider") {
        navigate(AP_HOME_PAGE);
      } else if (userType === "registered_user") {
        navigate(USER_HOME_PAGE);
      }
    }
  }, [accessToken, navigate, userType]);

  return (
    <Layout userType={userType}>
      <Box
        borderRadius="8px"
        w="100%"
        bg="#fff"
        border="1px"
        borderColor={{ base: "border.500", md: "transparent" }}
        p={{ base: "24px", md: "0" }}
      >
        <h1>{intl.formatMessage({ id: "auth_forget_password_title" })}</h1>
        <Text textStyle="largeP" mt="12px">
          {intl.formatMessage({ id: "auth_forget_password_subtitle" })}
        </Text>
        <ForgotPasswordForm />

        <Button
          as={RouteLink}
          to={userType === "registered_user" ? "/auth/login" : "/ap/auth/login"}
          w="full"
          size="md"
          mt="16px"
          variant="link"
        >
          {intl.formatMessage({ id: "auth_back_to_login" })}
        </Button>
      </Box>
    </Layout>
  );
};

export default ForgotPassword;
