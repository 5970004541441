import { useEffect, useState } from "react";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import Layout from "../layout";
import Loader from "../../shared/Loader";
import useNotification from "../../shared/hooks/useNotification";
import { useGetNewAccessTokenMutation } from "../service";
import { logoutUser, setAuthorizationTokens } from "../slice";
import { Box, Button, AlertIcon, Alert, Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { Flex } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { AP_HOME_PAGE, USER_HOME_PAGE } from "../../routes/defaults";
import { cleanupUTMCookies, getUTMParamsFromCookie } from "../../utils/requests";
import { getAndDeleteRedirect } from "../../utils/login";

const Qlogin = () => {
  const intl = useIntl();
  const notify = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [handleQlogin] = useGetNewAccessTokenMutation();
  const userType = "registered_user";

  // on mount check for code and error and call the server
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refresh_token = urlParams.get("token");
    const return_url = urlParams.get("return_url");
    const user_type = urlParams.get("user_type");
    if (!refresh_token || !user_type) {
      notify("error", "oauth_generic_error");
      return;
    }
    logoutUser();

    const handlRequest = async () => {
      try {
        const utm = getUTMParamsFromCookie();
        const response = await handleQlogin({ refresh_token, utm }).unwrap();
        dispatch(
          setAuthorizationTokens({
            access_token: response.access_token,
            refresh_token: refresh_token,
          })
        );

        // Onboarding takes priority over return_url
        const lastRedirUrl = getAndDeleteRedirect();
        let final_return_url: string = '';
        if (lastRedirUrl && lastRedirUrl.indexOf('onboarding') > -1) {
          final_return_url = lastRedirUrl;
        }
        else if (return_url) {
          final_return_url = return_url;
        }
        else if (lastRedirUrl) {
          final_return_url = lastRedirUrl;
        }


        if (final_return_url) {
          // navigate(final_return_url);
          setTimeout(() => {
            navigate(final_return_url);
          }, 1000);
        }
        else {
          if (user_type === 'ap') {
            setTimeout(() => {
              navigate(AP_HOME_PAGE);
            }, 1000);
          }
          else {
            setTimeout(() => {
              navigate(USER_HOME_PAGE);
            }, 1000);
          }
        }

        cleanupUTMCookies();

      } catch (error: any) {
        setError(error?.data?.message);
        setLoading(false);
        notify("error", error?.data?.message || "oauth_generic_error");
        //navigate(USER_HOME_PAGE)
      }
    };
    handlRequest();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout userType={userType}>
      <Box
        w="100%"
        borderRadius="8px"
        bg="#fff"
        border="1px"
        borderColor={{ base: "border.500", md: "transparent" }}
        p={{ base: "24px", md: "0" }}
      >
        {loading && <Loader />}
        {error && (
          <Alert
            mt="16px"
            w="full"
            status="info"
            fontSize="14px"
            lineHeight="normal"
            padding="24px"
            borderRadius="8px"
            color="infoBlue.500"
            bg="infoBlue.50"
          >
            <Flex flexDirection="column">
              <Flex alignItems="center">
                <AlertIcon color="infoBlue.500" mt="1px" />
                <Text textStyle="largeP" m="6px">
                  {intl.formatMessage({ id: "oauth_login_long_error" })}
                </Text>
              </Flex>
              <Button
                as={RouteLink}
                py="8px"
                to="/auth/login"
                size="md"
                mt="8px"
                variant="link"
              >
                {intl.formatMessage({ id: "auth_back_to_login" })}
              </Button>
            </Flex>
          </Alert>
        )}
      </Box>
    </Layout>
  );
};

export default Qlogin;
