import { Box, Divider, Flex, Image, Text, Link } from "@chakra-ui/react";
import { useOauthInitMutation } from "./service";

const SocialSignOn = () => {
  const [initOauth] = useOauthInitMutation();
  // get current Hostname + /auth/oauth/handle
  const handleLogin = async (e: React.FormEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    const port = window.location.port;
    const redirect_uri = `${protocol}//${hostname}${
      port ? `:${port}` : ""
    }/auth/oauth/handle`;
    try {
      const response = await initOauth({ redirect_uri }).unwrap();
      window.location.href = response.redirect_uri;
    } catch (error: any) {
      console.error("error", error);
    }
  };

  return (
    <Box
      w="100%"
      my="24px"
      borderBottom="0px"
      borderColor="border.400"
      pb="24px"
    >
      <Flex alignItems="center" justifyContent="center" mb="24px">
        <Divider
          display="inline-flex"
          w="auto"
          flex="1"
          mt="3px"
          borderColor="border.400"
          opacity="1"
        />
        <Text mx="16px">
          Alte Opțiuni de Autentificare
        </Text>
        <Divider
          display="inline-flex"
          w="auto"
          flex="1"
          mt="3px"
          borderColor="border.400"
          opacity="1"
        />
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <Link
          size="lg"
          isExternal
          href="#"
          _hover={{ opacity: 0.8 }}
          mx="32px"
          onClick={handleLogin}
        >
          <Image src="/logo-google.svg" alt="Google logo" w="32px" />
        </Link>
      </Flex>
    </Box>
  );
};

export default SocialSignOn;
