import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauthorization from "../store/baseQuery";
import { IActivityProvider, IRegisterFormPayload } from "./types";

export const activityProviderApi = createApi({
  reducerPath: "activityProviderApi",
  baseQuery: baseQueryWithReauthorization,
  tagTypes: ["ActivityProvider"],
  endpoints: (builder) => ({
    registerAP: builder.mutation<IActivityProvider, IRegisterFormPayload>({
      query: (payload: IRegisterFormPayload) => {
        return {
          url: "/activity-provider",
          method: "POST",
          body: payload,
        };
      },
    }),
    getOnboardingLink: builder.query<{ onboarding_link: string }, void>({
      query: () => {
        return {
          url: "activity-provider/onboarding-link",
        };
      },
    }),
    getActivityProvider: builder.query<IActivityProvider, string>({
      query: (guid: string) => {
        return {
          url: `/activity-provider/${guid}`,
        };
      },
      providesTags: ["ActivityProvider"],
    }),
    modifyActivityProvider: builder.mutation<
      IActivityProvider,
      {
        activityProvider: Partial<IActivityProvider>;
        guid: string;
      }
    >({
      query: ({ activityProvider, guid }) => {
        return {
          url: `/activity-provider/${guid}`,
          method: "PATCH",
          body: activityProvider,
        };
      },
      invalidatesTags: ["ActivityProvider"],
    }),
  }),
});

export const {
  useRegisterAPMutation,
  useGetOnboardingLinkQuery,
  useGetActivityProviderQuery,
  useModifyActivityProviderMutation,
} = activityProviderApi;
