import Layout from "../../shared/Layout";
import Navbar from "../../shared/Navbar";
import MainContainer from "../../shared/MainContainer";
import BottomNavigation from "../../shared/BottomNavigation";
import PaymentsHistory from "../PaymentsHistory";

const PaymentsHistoryPage = () => {
  return (
    <Layout>
      <>
        <Navbar userType="activity_provider" />
        <MainContainer>
          <PaymentsHistory />
        </MainContainer>
        <BottomNavigation userType="activity_provider" />
      </>
    </Layout>
  );
};

export default PaymentsHistoryPage;
