import React from 'react';
import { 
    IconButton,
    Tooltip,
} from "@chakra-ui/react";
import { Microphone, MicrophoneOff } from "@carbon/icons-react";

// import Button from '@material-ui/core/Button';
// import MicIcon from '../../../icons/MicIcon';
// import MicOffIcon from '../../../icons/MicOffIcon';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  return (
    <>
    <Tooltip placement='top' fontWeight="600" label={!hasAudioTrack ? 'Lipsă sunet' : isAudioEnabled ? 'Inchide microfon' : 'Deschide microfon'}>
      <IconButton 
        w="54px"
        isRound
        bg={isAudioEnabled ? "#fff" : "#E65050"}
        color={isAudioEnabled ? "brandGray.500" : "#fff"}
        borderColor={isAudioEnabled ? "brandGray.500" : "#E65050"}
        _hover={{
          boxShadow: "0 8px 16px 0 rgba(0,0,0,0.24)",
          bg: '{isAudioEnabled ? "#F2F2F2" : "#E65050"}'
        }}
        _active={{
          bg: '{isAudioEnabled ? "#EDEDED" : "#E65050"}'
        }}
        style={{height: "54px"}}
        aria-label={!hasAudioTrack ? 'Lipsă sunet' : isAudioEnabled ? 'Inchide microfon' : 'Deschide microfon'} 
        onClick={toggleAudioEnabled}
        disabled={!hasAudioTrack || props.disabled}
        icon={isAudioEnabled ? <Microphone size={24}/> : <MicrophoneOff size={24}/>}
        data-cy-audio-toggle
        variant="outline"
      />
    </Tooltip>
    </>
  );
}
