import { Box, Flex, Text, Avatar } from "@chakra-ui/react";
import { useGetUserQuery } from "../../user/service";
import Rating from "../Rating";
import { IReview } from "../types";

type Props = {
  review: IReview;
  display?: string;
};

const useGetUserQueryIfUserGuid = (userGuid: string) => {
  return useGetUserQuery(userGuid);
}

const Testimonial = ({ review, display }: Props) => {
  const { c_by: userGuid, c_by_name, content, rating } = review;

  const userName = c_by_name;

  return (
    <Box
      layerStyle="card"
      fontSize="16px"
      lineHeight="24px"
      p="16px"
      mt="16px"
      display={display}
    >
      <Flex>
        <Avatar
          name={userName}
          size="md"
          mr="16px"
          fontWeight="600"
          bg="gray.400"
          color="#fff"
        />
        <Box>
          {userName || !userGuid ? (
            <Text color="#272f37" fontWeight="600" mb="8px">
              {userName}
            </Text>
          ) : (
            <TestimonialWithUser userGuid={userGuid} />
          )}

          <Rating score={rating} />
          <Text>{content}</Text>
        </Box>
      </Flex>
    </Box>
  );
};


type PropsWithUser = {
  userGuid: string;
};

const TestimonialWithUser = ({ userGuid }: PropsWithUser) => {
  const { data } = useGetUserQueryIfUserGuid(userGuid);
  const userName = data?.f_name || "";

  if (userName) {
    return (
      <Text color="#272f37" fontWeight="600" mb="8px">
        {userName}
      </Text>
    )
  }
  return null;
};

export default Testimonial;
