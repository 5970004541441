import { Box, Flex } from "@chakra-ui/react";
import { useGetActivityQuery } from "../service";
import MainContainer from "../../shared/MainContainer";
import Specializations from "./Specializations";
import Loader from "../../shared/Loader";
import ActivityDetailsTabs from "./tabs";
import Header from "./Header";
import { useGetActivityProviderQuery } from "../../activityProvider/service";
import moment from "moment";
import { useGetLoggedInUserQuery, useGetUserQuery } from "../../user/service";

type Props = {
  guid: string;
  isBookingBtbDisabled?: boolean;
};

const ActivityDetails = ({ guid, isBookingBtbDisabled }: Props) => {
  const { data: activity, isLoading: isLoadingActivity } =
    useGetActivityQuery(guid);

  const { data: activityProvider, isLoading: isLoadingAP } =
    useGetActivityProviderQuery(activity?.executed_by_guid || "", {
      skip: !activity?.executed_by_guid,
    });
  const { data: user, isLoading: isLoadingUser } = useGetLoggedInUserQuery();
  const { data: apUser } =
      useGetUserQuery(activity?.executed_by_guid || "", {
        skip: !activity?.executed_by_guid,
      });


  const experienceYears =
    activityProvider && activityProvider.graduation_year
      ? Number(moment().format("YYYY")) -
        Number(activityProvider.graduation_year)
      : null;

  const apAge =
      apUser && apUser.birthdate
      ? Number(moment().format("YYYY")) - Number(apUser.birthdate)
      : null;

  const isLoading = isLoadingActivity || isLoadingAP || isLoadingUser;
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && activity && activityProvider && user && (
        <>
          <Header
            activity={activity}
            isBtbDisabled={isBookingBtbDisabled}
            experienceYears={experienceYears}
            apAge={apAge}
          />
          <MainContainer>
            <Flex flexWrap="wrap-reverse">
              <Box
                flex="1"
                pr={{ base: "0", md: "24px" }}
                mr={{ base: "0", md: "24px" }}
                borderRight={{
                  base: "none",
                  md: "1px solid var(--chakra-colors-border-500)",
                }}
              >
                <ActivityDetailsTabs
                  activity={activity}
                  activityProvider={activityProvider}
                />
              </Box>
              <Specializations specializations={activity.categories} />
            </Flex>
          </MainContainer>
        </>
      )}
    </>
  );
};

export default ActivityDetails;
