import { useIntl } from "react-intl";
import { Flex, Grid, Box, Button } from "@chakra-ui/react";
import { Document } from "@carbon/icons-react";
import { useGetArticlesQuery } from "../service";
import Loader from "../../shared/Loader";
import ArticlePreview from "./ArticlePreview";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import serialize from "../../utils/serialize";
import { IArticle } from "../types";

const ARTICLES_LIMIT = 10;

const Articles = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [articles, setArticles] = useState<IArticle[]>([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | null>(null);

  const { data, isLoading, isFetching } = useGetArticlesQuery({
    status: "published",
    limit: ARTICLES_LIMIT,
    last_evaluated_key: lastEvaluatedKey,
  });

  useEffect(() => {
    const newArticles = data?.data || [];
    if (newArticles.length > 0) {
      setArticles((articles) => [...articles, ...newArticles]);
    }
  }, [data]);

  const meta = data?.meta || null;
  const hasMore = meta?.has_more || false;
  const serializedLastEvaluatedKey = meta?.last_evaluated_key
    ? serialize(meta.last_evaluated_key)
    : "";

  const handleLoadMore = () => {
    setLastEvaluatedKey(serializedLastEvaluatedKey);
  };

  return (
    <>
      <>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <h1>{intl.formatMessage({ id: "article_my_articles_title" })}</h1>
          <Button
            onClick={() => navigate(`/ap/articles/add`)}
            w={{ base: "100%", md: "auto" }}
            mt={{ base: "16px", md: "0" }}
          >
            {" "}
            {intl.formatMessage({ id: "article_add_button_label" })}
          </Button>
        </Flex>

        {isLoading && <Loader />}

        {articles.length > 0 && !isLoading && (
          <Grid templateColumns="repeat(1, 1fr)" gap={4} mt="16px">
            {articles.map((article, idx) => (
              <Box
                key={idx}
                onClick={() => navigate(`/ap/articles/${article.guid}`)}
              >
                <ArticlePreview article={article} />
              </Box>
            ))}
          </Grid>
        )}

        {articles.length === 0 && !isLoading && (
          <>
            <Box layerStyle="card" mt="16px">
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                textAlign="center"
              >
                <Flex
                  borderRadius="100%"
                  alignItems="center"
                  justifyContent="center"
                  color="#fff"
                  w="80px"
                  h="80px"
                  bg="gray.500"
                  mb="16px"
                >
                  <Document size={32}/>
                </Flex>
                <h2>{intl.formatMessage({ id: "articles_no_data" })}</h2>
              </Flex>
            </Box>
          </>
        )}

        {hasMore && (
          <Button
            mt="16px"
            size="lg"
            isLoading={isFetching}
            onClick={handleLoadMore}
          >
            {intl.formatMessage({ id: "articles_load_more" })}
          </Button>
        )}
      </>
    </>
  );
};

export default Articles;
