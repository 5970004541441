import { Input, InputProps } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { useIntl } from "react-intl";
import { useAppDispatch } from "../hooks";
import { mediaAssetsApi } from "./service";
import { IMediaAsset } from "./types";

const IMAGE_MAX_SIZE_BYTES = 3 * 1024 * 1024;

type Props = {
  onImageUpload: (image: IMediaAsset[]) => void;
} & InputProps;

const ImageUploader = (props: Props) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const toast = useToast();

  const { onImageUpload, ...rest } = props;

  const handleImagePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    const filesToUpload = [];

    if (!selectedFiles) {
      return;
    }

    for (let idx = 0; idx < selectedFiles.length; idx++) {
      if (selectedFiles[idx].size < IMAGE_MAX_SIZE_BYTES) {
        filesToUpload.push(selectedFiles[idx]);
      } else {
        toast({
          title: intl.formatMessage({ id: "image_uploader_size_error" }),
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      }
    }

    if (filesToUpload.length === 0) {
      return;
    } else {
      dispatch(mediaAssetsApi.endpoints.uploadImage.initiate(filesToUpload))
        .then((response) => {
          const mediaAssets = "data" in response ? response.data : [];
          onImageUpload(mediaAssets);
        })
        .catch((error) => console.log("Error", error));
    }
  };

  return (
    <Input
      variant="unstyled"
      pos="absolute"
      w="full"
      top="0"
      bottom="0"
      left="0"
      zIndex="10"
      opacity="0"
      cursor="pointer"
      accept="image/*"
      type="file"
      name="avatar"
      onChange={(e) => handleImagePreview(e)}
      {...rest}
    />
  );
};

export default ImageUploader;
