import { ISchedule } from "./types";

const MIN_START = 7;
const MAX_START = 20;
const MIN_END = 8;
const MAX_END = 21;

const DAYS = [
  "luni",
  "marți",
  "miercuri",
  "joi",
  "vineri",
  "sâmbătă",
  "duminică",
];

const getInitialSchedule = (days: string[]) => {
  let schedule: ISchedule = [];
  days.forEach((day) => {
    schedule.push({ day, start_time: "", end_time: "" });
  });
  return schedule;
};

const getScheduleHours = (start: number, end: number) => {
  const hours = [];
  for (let i = start; i <= end; i++) {
    hours.push({
      value: `${i}`.padStart(2, "0") + ":00",
      label: `${i}`.padStart(2, "0") + ":00",
      isDisabled: false,
    });
  }
  return hours;
};

const getStartScheduleOptions = () => {
  const hours = getScheduleHours(MIN_START, MAX_START);
  return [
    ...hours,
    {
      value: "non_working_day",
      label: "Nu lucrez",
      isDisabled: false,
    },
  ];
};

const getEndScheduleOptions = () => {
  const hours = getScheduleHours(MIN_END, MAX_END);
  return [
    ...hours,
    {
      value: "non_working_day",
      label: "Nu lucrez",
      isDisabled: false,
    },
  ];
};

export {
  getStartScheduleOptions,
  getEndScheduleOptions,
  getInitialSchedule,
  DAYS,
};
