const conditions = [
  "anxiety",
  "burnout",
  "depression",
  "insomnia",
  "stress",
  "low_confidence",
  "sadness",
  "loneliness",
  "sexuality",
  "anger",
  "motivation",
  "trauma",
  "ADHD",
  "addiction",
  "abuse",
];

export default conditions;