import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Text, Flex, Button } from "@chakra-ui/react";
import Steps from "../../shared/Steps";
import SpecializationCheckbox from "./SpecializationCheckbox";
import conditions from "../../conditions";

const Step3 = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [selectedSpecializations, setSelectedSpecializations] = useState<
    string[]
  >([]);

  const onCheckSpecialization = (
    specialization: string,
    isChecked: boolean
  ) => {
    let updatedSpecializations = [...selectedSpecializations];
    if (isChecked) {
      updatedSpecializations = [...updatedSpecializations, specialization];
    } else {
      updatedSpecializations = updatedSpecializations.filter(
        (selectedSpecialization) => selectedSpecialization !== specialization
      );
    }
    setSelectedSpecializations(updatedSpecializations);
  };

  const handleMoveToNextStep = () => {
    navigate("/ap/onboarding/step/4", {
      state: { specializations: selectedSpecializations },
    });
  };

  return (
    <Steps activeStep={3} stepCount={6}>
      <h1>{intl.formatMessage({ id: "ap_onboarding_step3_title" })}</h1>
      <Text textStyle="largeP" mt="8px" mb="32px">
        {intl.formatMessage({ id: "ap_onboarding_step3_subtitle" })}
      </Text>
      <Flex
        mx="auto"
        mb="32px"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        maxW="800px"
      >
        {conditions.map((condition, idx) => (
          <SpecializationCheckbox
            key={idx}
            specialization={condition}
            onCheckSpecialization={onCheckSpecialization}
          />
        ))}
      </Flex>
      <Button
        w="full"
        maxW={{ base: "100%", md: "310px" }}
        m="auto"
        size="lg"
        onClick={handleMoveToNextStep}
        disabled={!selectedSpecializations.length}
      >
        {intl.formatMessage({ id: "user_onboarding_continue_btn_label" })}
      </Button>
    </Steps>
  );
};

export default Step3;
