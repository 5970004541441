import Layout from "../../shared/Layout";
import Navbar from "../../shared/Navbar";
import MainContainer from "../../shared/MainContainer";

const ContactPage = () => {
  return (
    <Layout>
      <>
        <Navbar userType="registered_user" />
        <MainContainer>
          <>Contact page</>
        </MainContainer>
      </>
    </Layout>
  );
};

export default ContactPage;
