import moment from "moment";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Box, Text, IconButton, Flex } from "@chakra-ui/react";
import { ArrowRight } from "@carbon/icons-react";
import { IBooking } from "../../booking/types";
import { IRoles } from "../../user/types";
import CountdownTimer from "./CountdownTimer";
import { fromServertoLocalYMDTHms, fromServertoLocalDate, fromServerToLocalHM, fromServerToLocalMoment } from "../../booking/utils";

type Props = {
  booking: IBooking;
  userType: IRoles;
};

const NextBooking = ({ booking, userType }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const {
    display_name: name,
    b_guid,
    start_time,
    end_time,
    a_guid
  } = booking;

  const bookingTimeInterval = booking
    ? `${fromServerToLocalHM(start_time)} - ${fromServerToLocalHM(end_time)}` : "";

  const bookingDate = fromServertoLocalDate(start_time);
  const startTimeLocal = fromServerToLocalMoment(start_time);

  const handleGoToSessionRoom = () => {
    if ((startTimeLocal.toDate().getTime() - new Date().getTime()) > 6 * 60 * 60 * 1000) {
      navigate(`/bookings`);
      return
    }
    const url =
      userType === "activity_provider"
        ? `/ap/room/${a_guid}/${b_guid}`
        : `/room/${a_guid}/${b_guid}`;

    navigate(url);
  };

  if (booking.is_from_package && booking.is_free_slot_booking) {
    return null;
  }

  const isMeetingStarted = moment().isBetween(booking.start_time, booking.end_time, undefined, '[]')

  return (
    <Box layerStyle="bottomNotification" cursor="pointer" onClick={handleGoToSessionRoom} w={{ base: "100%", sm: "auto" }}>
      <Box pr="8px">
        <Flex flexWrap="wrap">
          <Text fontSize="16px" lineHeight="24px" fontWeight="600">
            {intl.formatMessage({ id: `${isMeetingStarted ? "meeting_started" : "next_booking_title"}` })}&nbsp;
          </Text>
          {!isMeetingStarted && <CountdownTimer targetDate={fromServertoLocalYMDTHms(start_time)} />}
        </Flex>
        <Text opacity=".8" fontSize="13px" lineHeight="normal">
          {`${name}, ${bookingDate}, ${bookingTimeInterval}`}
        </Text>
      </Box>
      <IconButton
        display="flex"
        borderRadius="100%"
        w="32px"
        h="32px"
        minW="32px"
        minH="32px"
        style={{ height: "32px" }}
        ml="auto"
        mr="-8px"
        aria-label="Mergi la ședință"
        icon={<ArrowRight size={20} />}
      />
    </Box>
  );
};

export default NextBooking;
