import { Box, Text, Image } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { IRoles } from "../../user/types";

type Props = {
  userType: IRoles;
};

const NoFutureBooking = ({ userType }: Props) => {
  const intl = useIntl();

  return (
    <Box layerStyle="bottomNotification" w={{base: "100%", sm: "auto"}} p="12px">
      <Box
        w="32px"
        h="32px"
        minW="32px"
        bg="#fff"
        border="2px solid #fff"
        borderRadius="100%"
        mr="8px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Image src="/therapy-icon.svg" alt="Icon" height="16px" />
      </Box>

      <Box>
        <Text fontSize="16px" lineHeight="24px" fontWeight="600">
          {intl.formatMessage({ id: "next_booking_no_booking_title" })}
        </Text>
        <Text opacity=".8"  fontSize="13px" lineHeight="normal">
          {userType === "registered_user" &&
            intl.formatMessage({ id: "next_booking_no_booking_subtitle" })}
        </Text>
      </Box>
    </Box>
  );
};

export default NoFutureBooking;
