import { Navigate } from "react-router-dom";
import Loader from "../../shared/Loader";
import { useGetLoggedInUserQuery } from "../../user/service";
import GDPR from "../../auth/layout/GDPR";

type Props = {
  children: JSX.Element;
};

const UserOnboarded = ({ children }: Props) => {
  const { data: user, error, isLoading } = useGetLoggedInUserQuery();

  const isUserOnboarded = user && user.labels.includes("onboarded");
  const isPendingActivation = user && user.status === 'pending_email_verification'
  // const isPendingActivation = user && user.status === 'pending_email_activation'

  if (isLoading && !user && !error) {
    return <Loader />;
  } else if (!isUserOnboarded || isPendingActivation) {
    return <Navigate to="/onboarding/step/1" />;
  } else {
    return <>{children}<GDPR /></>;
  }
};

export default UserOnboarded;
