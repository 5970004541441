const APP_VERSION = 'b68046f'
const BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : "";
const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE
  ? process.env.REACT_APP_DEFAULT_LOCALE
  : "ro";
const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV
  ? process.env.REACT_APP_SENTRY_ENV
  : null;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
  ? process.env.REACT_APP_SENTRY_DSN
  : null;
const AUTH_COOKIE_DOMAIN = process.env.REACT_APP_AUTH_COOKIE_DOMAIN || "";
const AUTH_COOKIE_NAME = "psiholog.iam";
const SMS_REMINDER_DELTA_TIME = 60 //minutes
const EMAIL_ADDRESS = 'contact@psiholog.ro'
const MIN_BOOKING_TIME_IN_ADVANCE = 3 //hours

export {
  BASE_URL,
  API_BASE_URL,
  DEFAULT_LOCALE,
  AUTH_COOKIE_DOMAIN,
  AUTH_COOKIE_NAME,
  APP_VERSION,
  SMS_REMINDER_DELTA_TIME,
  EMAIL_ADDRESS,
  MIN_BOOKING_TIME_IN_ADVANCE,
  SENTRY_ENV,
  SENTRY_DSN,
};
