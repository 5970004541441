import { Select } from "@chakra-ui/react";
import { useIntl } from "react-intl";

type Props = {
  onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  value: string;
  [x: string]: any;
};

export default function GenderTherapistSelector({
  onChange,
  value,
  ...other
}: Props) {
  const intl = useIntl();

  return (
    <Select
      placeholder={intl.formatMessage({
        id: "user_onboarding_step3_therapist_preferences",
      })}
      {...other}
      w="full"
      size="lg"
      required
      name={"preferredAP"}
      value={value ? value : ""}
      onChange={onChange}
    >
      <option value="female">
        {intl.formatMessage({
          id: "user_onboarding_step3_woman",
        })}
      </option>
      <option value="male">
        {intl.formatMessage({
          id: "user_onboarding_step3_man",
        })}
      </option>
      <option value="no_preference">
        {intl.formatMessage({
          id: "user_onboarding_step3_no_preference",
        })}
      </option>
    </Select>
  );
}
