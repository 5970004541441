import FullCalendar, {
  CalendarOptions,
  DateSpanApi,
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import "./Calendar.css";
import { useMemo } from "react";
import { fromServerToLocalHMS } from "../utils";

type Props = CalendarOptions;

const Calendar = (props: Props) => {
  const handleSelectAllow = (selectionInfo: DateSpanApi) => {
    const currentTimestamp = new Date().getTime();
    const startTimestamp = selectionInfo.start.getTime();
    const endTimestamp = selectionInfo.end.getTime();
    // allow the user to select only one hour slots
    // disable the selection of past dates
    return (
      endTimestamp - startTimestamp <= 60 * 60 * 1000 &&
      startTimestamp > currentTimestamp
    );
  };

  const slotMinTime = useMemo(() => {
    return fromServerToLocalHMS("2023-08-01T07:00:00")
  }, []);

  const slotMaxTime = useMemo(() => {
    return fromServerToLocalHMS("2023-08-01T21:00:00");
  }, []);

  return (
    <FullCalendar
      {...props}
      selectAllow={handleSelectAllow}
      plugins={[listPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin]}
      eventTimeFormat={{
        hour: "numeric",
        minute: "2-digit",
        meridiem: false,
      }}
      locale="ro"
      height="auto"
      firstDay={1}
      allDaySlot={false}
      expandRows={false}
      slotDuration={"01:00:00"}
      slotMinTime={slotMinTime}
      slotMaxTime={slotMaxTime}
      stickyHeaderDates={false}
      nowIndicator={true}
      headerToolbar={{
        left: "",
        center: "prev,title,next",
        right: "",
      }}
      slotLabelFormat={{
        hour: "2-digit",
        minute: "2-digit",
        omitZeroMinute: false,
        meridiem: "short",
      }}
      titleFormat={{
        year: "numeric",
        month: "long",
        day: "numeric",
      }}
      buttonText={{
        today: "Astăzi",
        month: "Lună",
        week: "Săptămână",
        day: "Zi",
        list: "Listă",
      }}
    />
  );
};

export default Calendar;
