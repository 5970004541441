import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Button, Box } from "@chakra-ui/react";
import { Form, FormControl, PasswordInput } from "../../shared/form";
import { useResetPasswordMutation } from "../../user/service";
import { validateForm } from "../../utils/forms";
import { resetPasswordValidationRules } from "../../utils/forms/validators";
import { IResetPasswordFormErrors, IResetPasswordPayload } from "../types";
import {
  initialResetPasswordFormPayload,
  initialResetPasswordFormErrors,
} from "../defaults";
import useNotification from "../../shared/hooks/useNotification";
import PasswordStrengthIndicator from "../../shared/form/PasswordStrengthIndicator";

const ResetPasswordForm = () => {
  const notify = useNotification()
  const intl = useIntl();
  let { token = "" } = useParams();

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const [formValues, setFormValues] = useState<IResetPasswordPayload>(
    initialResetPasswordFormPayload
  );
  const [formErrors, setFormErrors] = useState<IResetPasswordFormErrors>(
    initialResetPasswordFormErrors
  );

  const { password, repeatPassword } = formValues;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormErrors(initialResetPasswordFormErrors);
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    const resetPasswordPayload = { password, repeatPassword };

    const validationErrors = validateForm(
      resetPasswordPayload,
      resetPasswordValidationRules
    );

    if (!validationErrors) {
      setFormErrors(initialResetPasswordFormErrors);
      try {
        resetPassword({ pw: password, token }).unwrap().then(
          () => notify('success', 'user_details_password_success_update'),
          () => notify('error', 'ap_onboarding_step1_error')
        )
      } catch (error) {
        notify('error', 'ap_onboarding_step1_error')
      }
    } else {
      setFormErrors({ ...initialResetPasswordFormErrors, ...validationErrors });
    }
  };

  return (
    <Box w="100%">
      <Form onSubmit={handleResetPassword} mt="16px">
        <FormControl errorMessage={formErrors.password}>
          <PasswordStrengthIndicator
            placeholder={intl.formatMessage({ id: "auth_password" })}
            name="password"
            onInputChange={(e) => onInputChange(e)}
          />
        </FormControl>
        <FormControl errorMessage={formErrors.repeatPassword}>
          <PasswordInput
            placeholder={intl.formatMessage({ id: "auth_password" })}
            name="repeatPassword"
            value={repeatPassword}
            onInputChange={(e) => onInputChange(e)}
          />
        </FormControl>
        <Button type="submit" w="full" size="lg" mt="16px" isLoading={isLoading}>
          {intl.formatMessage({ id: "auth_reset_password" })}
        </Button>
      </Form>
    </Box>
  );
};

export default ResetPasswordForm;
