import { Menu, MenuButton, MenuItem, MenuList, Portal } from "@chakra-ui/react";
import { JoinInner } from "@carbon/icons-react";
import { useAddExceptionMutation } from "../../schedule/service";
import { useIntl } from "react-intl";

type Props = {
  exceptionType: "enable" | "disable";
  label: string;
  start: string;
  end: string;
  a_guid: string;
};

const AddExceptionMenu = ({
  exceptionType,
  a_guid,
  label,
  start,
  end,
}: Props) => {
  const intl = useIntl();

  const [addScheduleException, { isLoading: isAddingException }] =
    useAddExceptionMutation();

  return (
    <Menu>
      <MenuButton className="unblock-date-btn">{label}</MenuButton>
      <Portal>
        <MenuList zIndex="3">
          <MenuItem
            isDisabled={isAddingException}
            icon={<JoinInner size={24}/>}
            onClick={() => {
              addScheduleException({
                a_guid: a_guid,
                start_datetime: start,
                end_datetime: end,
                modifier: exceptionType,
              });
            }}
          >
            {exceptionType === "enable" &&
              intl.formatMessage({ id: "booking_unblock_once" })}
            {exceptionType === "disable" &&
              intl.formatMessage({ id: "booking_block_once" })}
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};

export default AddExceptionMenu;
