import React from "react";
import { Box, Flex } from "@chakra-ui/react";

type Props = {
  activeStep: number;
  stepCount: number;
  children: React.ReactNode;
};

const Steps = ({ activeStep, stepCount, children }: Props) => {
  const isActiveStep = (currentStep: number) => currentStep === activeStep;

  const getLayerStyle = (currentStep: number) =>
    isActiveStep(currentStep) ? "stepCounterActive" : "stepCounter";

  return (
    <Flex
      textAlign="center"
      pt={{ base: "16px", lg: "48px" }}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Flex
        w="100%"
        maxW={{ base: "100%", md: "310px" }}
        margin="auto"
        alignItems="center"
        justifyContent="center"
      >
        {Array.from(new Array(stepCount)).map((_, idx) => (
          <Box key={idx} layerStyle={getLayerStyle(idx + 1)} />
        ))}
      </Flex>
      {children}
    </Flex>
  );
};

export default Steps;
