import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IAuthTokens } from "./types";
import { AUTH_COOKIE_NAME } from "../config";
import {
  removeIamCookieAttributes,
  getCookie,
  removeCookie,
} from "../utils/cookies";
import { RootState, store } from "../store";
import { userApi } from "../user/service";
import { activityProviderApi } from "../activityProvider/service";
import { activityApi } from "../activity/service";
import { bookingApi } from "../booking/service";
import { articlesApi } from "../articles/service";
import { authApi } from "./service";
import { mediaAssetsApi } from "../mediaAssets/service";
import { vouchersApi } from "../voucher/service";

const getIamFromCookie = () => {
  try {
    const iam = getCookie(AUTH_COOKIE_NAME);
    if (iam) {
      console.log("Restoring session");
      return JSON.parse(iam);
    } else {
      console.log("No session to restore");
      return {};
    }
  } catch (e) {
    console.error(e);
    return {};
  }
};

const initialState: IAuthTokens = {
  access_token: null,
  refresh_token: null,
  ...getIamFromCookie(),
};

const iamSlice = createSlice({
  name: "iam",
  initialState: initialState,
  reducers: {
    setAuthorizationTokens: (state, { payload: { access_token, refresh_token } }: PayloadAction<IAuthTokens>) => {
      state.access_token = access_token;
      state.refresh_token = refresh_token;
    },
    logout: (state) => {
      state.access_token = null;
      state.refresh_token = null;
    },
  },
});

const getAccessToken = (state: RootState): string | null => {
  try {
    if (state.iam) {
      return state.iam.access_token;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

const getRefreshToken = (state: RootState): string | null => {
  try {
    if (state.iam) {
      return state.iam.refresh_token;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

export const { setAuthorizationTokens, logout } = iamSlice.actions;

const logoutUser = () => {
  const dispatch = store.dispatch;
  removeCookie(AUTH_COOKIE_NAME, removeIamCookieAttributes);
  dispatch(logout());
  dispatch(userApi.util.resetApiState());
  dispatch(activityProviderApi.util.resetApiState());
  dispatch(activityApi.util.resetApiState());
  dispatch(bookingApi.util.resetApiState());
  dispatch(articlesApi.util.resetApiState());
  dispatch(authApi.util.resetApiState());
  dispatch(mediaAssetsApi.util.resetApiState());
  dispatch(vouchersApi.util.resetApiState());
};

export { getAccessToken, getRefreshToken, logoutUser };

export default iamSlice.reducer;
