import { useIntl } from "react-intl";
import {
  Box,
} from "@chakra-ui/react";
import Loader from "../shared/Loader";
import CreateNote from "./CreateNote";
import Note from "./Note";
import { INote } from "./types";
import useNotes from "./hooks/useNotes";

type Props = {
  userId: string,
  radius?: string,
  shadow?: string,
  padding?: string,
}

const Notes = ({ userId, radius, shadow, padding }: Props) => {
  const intl = useIntl();
  const { notes, isLoading } = useNotes(userId)
  if (isLoading) return <Loader />
  return (
    <Box layerStyle="card" borderRadius={radius} boxShadow={shadow} _hover={{boxShadow:{shadow}}} p={padding}>
      <h2>{intl.formatMessage({ id: "notes" })}</h2>
      <CreateNote userId={userId} />
      {notes.map(((note: INote) => <Note note={note} key={note.guid} />))}
    </Box>
  );
};

export default Notes;
