import { useState } from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Flex,
} from "@chakra-ui/react";
import { Password, View, ViewOff } from "@carbon/icons-react";

type Props = {
  placeholder: string;
  name: string;
  value: string;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const PasswordInput = ({ placeholder, name, value, onInputChange }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputGroup mb="12px">
      <InputLeftElement
        pointerEvents="none"
        opacity=".54"
        pt="3px"
        px="8px"
        h="100%"
        children={<Password size={20}color="#696871" />}
      />
      <Input
        placeholder={placeholder}
        type={showPassword ? "text" : "password"}
        name={name}
        value={value}
        size="lg"
        onChange={onInputChange}
        required
      />
      <InputRightElement width="40px" h="100%">
        <Flex
          h="100%"
          w="40px"
          onClick={() => setShowPassword(!showPassword)}
          justifyContent="center"
          alignItems="center"
          cursor="pointer"
        >
          {showPassword ? <ViewOff size={20}/> : <View size={20}/>}
        </Flex>
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInput;
