import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../../store";
import { IRoles } from "../../user/types";
import { useIsActivityProvider, useIsRegisteredUser } from "../../user/hooks";
import UserOnboarded from "./UserOnboarded";
import ActivityProviderOnboarded from "./ActivityProviderOnboarded";
import { AP_HOME_PAGE, USER_HOME_PAGE } from "../defaults";
import { storeCurrentUrlForRedirect } from "../../utils/login";
import { useEffect } from "react";

type Props = {
  children: JSX.Element;
  userType: IRoles;
  omitOnboarding?: boolean;
};

const ProtectedComponent = ({ children, userType, omitOnboarding }: Props) => {
  const accessToken = useSelector((state: RootState) => state.iam.access_token);

  const authLink =
    userType === "registered_user" ? "/auth/enter" : "/ap/auth/login";

  const isRegisteredUser = useIsRegisteredUser();
  const isActivityProvider = useIsActivityProvider();

  const { pathname } = window.location;
  useEffect(() => {
    storeCurrentUrlForRedirect(pathname);
  }, [pathname]);

  if (!accessToken) {
    return <Navigate to={authLink} />;
  } else if (isRegisteredUser && userType === "activity_provider") {
    return <Navigate to={USER_HOME_PAGE} />;
  } else if (isActivityProvider && userType === "registered_user") {
    return <Navigate to={AP_HOME_PAGE} />;
  } else if (isRegisteredUser && !omitOnboarding) {
    return <UserOnboarded>{children}</UserOnboarded>;
  } else if (isActivityProvider && !omitOnboarding) {
    return <ActivityProviderOnboarded>{children}</ActivityProviderOnboarded>;
  } else {
    return children;
  }
};

export default ProtectedComponent;
