import React from 'react';
import Video from 'twilio-video';
import { Container, Link, Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Text } from '@chakra-ui/react';

const useStyles = makeStyles({
  container: {
    marginTop: '2.5em',
  },
  paper: {
    padding: '1em',
  },
  heading: {
    marginBottom: '0.4em',
  },
});

export default function UnsupportedBrowserWarning({ children }: { children: React.ReactElement }) {
  const classes = useStyles();


  if (!Video.isSupported) {
    return (
      <Container>
        <Grid container justifyContent="center" className={classes.container}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography variant="h4" className={classes.heading}>
                Browserul tău nu este suportat
              </Typography>
              <Typography>
                Din păcate, browserul pe care îl folosești nu este compatibil cu aplicația noastră de streaming video.
                Pentru a te bucura de o experiență optimă, te rugăm să utilizezi unul dintre următoarele browsere:
              </Typography>
              <List spacing={4} mt="12px" color="brandGray.500" fontSize="16px" p="0">
                <ListItem alignItems="center">
                  <Text >
                    Chrome <Link href="https://www.google.com/chrome/" target="_blank" rel="noopener">https://www.google.com/chrome/</Link>
                  </Text>

                  <Text >
                    Firefox <Link href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener">https://www.mozilla.org/en-US/firefox/new/</Link>
                  </Text>

                  <Text >
                    Safari <Link href="https://www.apple.com/safari/" target="_blank" rel="noopener">https://www.apple.com/safari/</Link>
                  </Text>

                </ListItem>
              </List>
              <Typography>
                Pe lângă folosirea unui browser compatibil, vă recomandăm să utilizați un computer desktop sau laptop pentru a vă bucura de cea mai bună experiență de streaming video.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return children;
}
