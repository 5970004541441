import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Flex, IconButton, Text, Show } from "@chakra-ui/react";
import { ArrowLeft } from "@carbon/icons-react";
import UserProfile from "../../shared/UserProfile";
import ActivityMeta from "../../shared/ActivityMeta";
import { IActivity } from "../../activity/types";

type Props = {
  activity: IActivity;
  isChangingBooking?: boolean;
};

const BookingCalendar = ({ activity, isChangingBooking }: Props) => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <>
      <Flex
        w="full"
        bg="white"
        py={{ base: "16px", lg: "24px" }}
        px={{ base: "16px", lg: "32px" }}
        borderBottomWidth="1px"
        borderColor="border.400"
        justifyContent="space-between"
        alignItems="center"
        position="fixed"
        top="0"
        h={{ base: "64px", lg: "80px" }}
        zIndex="3"
      >
        <Flex alignItems="center" zIndex="1">
          <IconButton
            bg="#fff"
            color="brandGray.500"
            border="1px solid var(--chakra-colors-border-500)"
            borderRadius="100%"
            mr="8px"
            w="44px"
            h="44px"
            minW="44px"
            minH="44px"
            position="relative"
            _hover={{
              color: "#fff",
              background: "brand.500",
              borderColor: "brand.500",
            }}
            icon={<ArrowLeft size={24} />}
            aria-label="înapoi"
            onClick={() => navigate(-1)}
          />
          <ActivityMeta guid={activity.guid} displayAvatar="none" />
        </Flex>
        <Show above="md">
          <Text
            color="brandGray.500"
            fontWeight="600"
            position="absolute"
            left="0"
            right="0"
            textAlign="center"
            zIndex="0"
            fontSize="20px"
          >
            {isChangingBooking
              ? intl.formatMessage({ id: "booking_change_booking" })
              : intl.formatMessage({ id: "booking_new_booking" })}
          </Text>
        </Show>
        <Flex alignItems="center" zIndex="1">
          <UserProfile />
        </Flex>
      </Flex>
    </>
  );
};

export default BookingCalendar;
