import Layout from "../../shared/Layout";
import Navbar from "../../shared/Navbar";
import MainContainer from "../../shared/MainContainer";
import BottomNavigation from "../../shared/BottomNavigation";
import Step6 from "../Step6";

const OnboardingStep6 = () => {
  return (
    <Layout>
      <>
        <Navbar userType="activity_provider" hideNavigation allowLogoutOnly />
        <MainContainer>
          <Step6 />
        </MainContainer>
        <BottomNavigation userType="activity_provider" display="none" />
      </>
    </Layout>
  );
};

export default OnboardingStep6;
