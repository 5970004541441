import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function Reload() {  //used because after leaving room page media streams are still active
    const { pathname } = useLocation();
    const [lastLocation, setLastLocation] = useState('')
    useEffect(() => {
        if (lastLocation.includes('/room/')) {
            window.location.reload()
        }
        if (pathname.includes('/room/')) {
            setLastLocation(pathname)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    return null
} 
