import { useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { Text, Flex, Button } from "@chakra-ui/react";
import { useAddActivityMutation } from "../../activity/service";
import { useGetLoggedInUserQuery } from "../../user/service";
import Steps from "../../shared/Steps";
import { Form } from "../../shared/form";
import {
  IAddActivityPayload,
  IPrice,
  IRecurringSchedule,
} from "../../activity/types";
import { ISchedule } from "../../shared/schedule/types";
import Schedule from "../../shared/schedule/Schedule";
import useNotification from "../../shared/hooks/useNotification";

const getRecurringSchedule = (schedule: ISchedule): IRecurringSchedule => {
  let recurringSchedule: IRecurringSchedule = [];
  schedule.forEach((scheduleItem) => {
    const { start_time, end_time } = scheduleItem;
    const recurringScheduleItem =
      start_time && end_time ? [[start_time, end_time]] : [];
    recurringSchedule.push(recurringScheduleItem);
  });

  return recurringSchedule;
};

const initialRecurringSchedule: IRecurringSchedule = [
  [],
  [],
  [],
  [],
  [],
  [],
  [],
];

const Step5 = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const notify = useNotification();

  const { state } = useLocation();
  const { data: loggedInUser } = useGetLoggedInUserQuery();
  const [addActivity, { isLoading }] = useAddActivityMutation();

  const { specializations: categories = [], price } = state as {
    specializations: string[];
    price: IPrice;
  };

  const [schedule, setSchedule] = useState<IRecurringSchedule>(
    initialRecurringSchedule
  );

  const handleScheduleChange = (schedule: ISchedule) => {
    const recurringSchedule = getRecurringSchedule(schedule);
    setSchedule(recurringSchedule);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (schedule.every((daySchedule) => daySchedule.length === 0)) {
      notify("error", "ap_onboarding_step5_schedule_required");
      return;
    }

    if (loggedInUser) {
      const activity: IAddActivityPayload = {
        name: `${loggedInUser.f_name} ${loggedInUser.l_name}`,
        description: loggedInUser.description || "",
        price: price,
        categories: categories,
        executed_by_guid: loggedInUser.guid,
        address: "",
        images: loggedInUser?.profile_img ? [loggedInUser.profile_img] : [],
        country: "",
        city: "",
        labels: [],
        tags: [],
        timezone: loggedInUser.timezone,
        recurring_schedule: schedule,
      };

      addActivity(activity)
        .then(() => navigate("/ap/onboarding/step/6"))
        .catch((error) => console.log("Error", error));
    }
  };

  return (
    <Steps activeStep={5} stepCount={6}>
      <h1>{intl.formatMessage({ id: "ap_onboarding_step5_title" })}</h1>
      <Text textStyle="largeP" mt="8px" mb="32px">
        {intl.formatMessage({ id: "ap_onboarding_step5_subtitle" })}
      </Text>
      <Flex
        my="auto"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        w={{ base: "100%", md: "320px" }}
      >
        <Form onSubmit={handleSubmit}>
          <Schedule onScheduleChange={handleScheduleChange} />
          <Button
            w={{ base: "100%", md: "310px" }}
            my="auto"
            mt="32px"
            size="lg"
            type="submit"
            isLoading={isLoading}
          >
            {intl.formatMessage({ id: "user_onboarding_continue_btn_label" })}
          </Button>
        </Form>
      </Flex>
    </Steps>
  );
};

export default Step5;
