import moment from "moment";
import { useIntl } from "react-intl";
import { Box, Flex, VStack, Alert } from "@chakra-ui/react";

import { EventSchedule, Information } from "@carbon/icons-react";
import Loader from "../../shared/Loader";
import BookingPreview from "./BookingPreview";
import useGetMyActiveBookings from "../hooks/useGetMyActiveBookings";
import { fromLocalToServerYMDTHms } from "../utils";

const startsInMoreThan24Hours = (date: string) =>
  new Date(date).getTime() - new Date().getTime() > 24 * 60 * 60 * 1000;

const startTime = fromLocalToServerYMDTHms(moment().startOf("hour"))
const endTime = fromLocalToServerYMDTHms(moment().add(1, "year"))

const ActiveBookings = () => {
  const intl = useIntl();

  const { data, isLoading } = useGetMyActiveBookings(
    {
      start_datetime: startTime,
      end_datetime: endTime,
    },
    true
  );

  if (isLoading) {
    return <Loader />;
  }
  const bookings =
    data && data.length
      ? data.filter((booking) => booking.status === "active")
      : [];

  const hasBookingsThatStartInMoreThan24h = bookings.some((booking) =>
    startsInMoreThan24Hours(booking.start_time)
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {hasBookingsThatStartInMoreThan24h && (
        <Alert
          status="info"
          padding="12px"
          fontSize="14px"
          lineHeight="normal"
          borderRadius="8px"
          justifyContent="center"
          mt="16px"
          bg="#E2F3FD"
          color="#2D3748"
        >
          <Information size={16}style={{ marginRight: "4px" }} />
          {intl.formatMessage({ id: "booking_change_session_alert" })}
        </Alert>
      )}
      {bookings.length === 0 && (
        <>
          <Box layerStyle="card" mt="24px">
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              textAlign="center"
            >
              <Flex
                borderRadius="100%"
                alignItems="center"
                justifyContent="center"
                color="#fff"
                w="80px"
                h="80px"
                bg="gray.500"
              >
                <EventSchedule size={32}/>
              </Flex>
              <h2>
                {intl.formatMessage({ id: "booking_no_active_bookings_title" })}
              </h2>
              <p>
                {intl.formatMessage({
                  id: "booking_no_active_bookings_subtitle",
                })}
              </p>
            </Flex>
          </Box>
        </>
      )}
      {bookings.length > 0 && (
        <VStack spacing="16px" mt="16px" alignItems="stretch">
          {bookings.map((booking, idx) => (
            <BookingPreview key={idx} booking={booking} isInTheFuture={true} />
          ))}
        </VStack>
      )}
    </>
  );
};

export default ActiveBookings;
