import { Flex, Link, Show } from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import Logo from "./Logo";
import ClientNav from "./ClientNav";
import ProviderNav from "./ProviderNav";
import UserProfile from "./UserProfile";
import UserLogout from "./UserLogout";
import { IRoles } from "../user/types";

type Props = {
  hideNavigation?: boolean;
  allowLogoutOnly?: boolean;
  userType: IRoles;
};

const Navbar = ({ userType, hideNavigation, allowLogoutOnly }: Props) => {
  return (
    <>
      <Flex
        w="full"
        bg="white"
        py={{ base: "16px", lg: "24px" }}
        px={{ base: "16px", lg: "32px" }}
        borderBottomWidth="1px"
        borderColor="border.400"
        justifyContent="space-between"
        alignItems="center"
        position="fixed"
        top="0"
        h={{ base: "64px", lg: "80px" }}
        zIndex="3"
      >
        <Link as={RouteLink} to="/">
          <Logo />
        </Link>
        <Flex alignItems="center">
          <Show above="lg">
            {!hideNavigation &&
              <Flex
                alignItems="center"
                as="nav"
                aria-label="Meniu"
                mr="32px"
                borderRight="1px"
                borderColor="border.400"
                minH="40px"
                color="brandGray.500"
              >
                {userType === "registered_user" && <ClientNav />}
                {userType === "activity_provider" && <ProviderNav />}
              </Flex>}

          </Show>
          {allowLogoutOnly ? <UserLogout /> : <UserProfile />}
        </Flex>
      </Flex>
    </>
  );
};

export default Navbar;
