import Cookies from "js-cookie";
import { AUTH_COOKIE_DOMAIN } from "../config";

export interface ICookieAttributes {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: "strict" | "Strict" | "lax" | "Lax" | "none" | "None";
}

export const defaultIamCookieAttributes: ICookieAttributes = {
  path: "/",
  domain: AUTH_COOKIE_DOMAIN,
  sameSite: "strict",
  expires: 365  //expire in 365 days from creation
};

export const removeIamCookieAttributes: ICookieAttributes = {
  path: "/",
  domain: AUTH_COOKIE_DOMAIN,
  sameSite: "strict",
  expires: 0  //expire in 365 days from creation
};

function setCookie(
  name: string,
  value: string,
  options?: ICookieAttributes
): string | undefined {
  return Cookies.set(name, value, options);
}

function getCookie(name: string): string | undefined {
  return Cookies.get(name);
}

function removeCookie(name: string, options?: ICookieAttributes): void {
  Cookies.remove(name, options);
}

export { setCookie, getCookie, removeCookie };
