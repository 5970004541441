import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauthorization from "../store/baseQuery";
import { IMediaAsset } from "./types";

export const mediaAssetsApi = createApi({
  reducerPath: "mediaAssetsApi",
  baseQuery: baseQueryWithReauthorization,
  endpoints: (builder) => ({
    uploadImage: builder.mutation<IMediaAsset[], File[]>({
      query: (images) => {
        const formData = new FormData();
        for (let idx = 0; idx < images.length; idx++) {
          formData.append("images", images[idx]);
        }

        return {
          url: "/media-assets/image",
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const { useUploadImageMutation } = mediaAssetsApi;
