import { Button } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Flex, Box, Text, Image } from "@chakra-ui/react";
import Layout from '../shared/Layout';
import MainContainer from '../shared/MainContainer';
import Navbar from '../shared/Navbar';
import BottomNavigation from "../shared/BottomNavigation";
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useEffect } from 'react';

export default function OnboardingInfo() {
    const accessToken = useSelector((state: RootState) => state.iam.access_token);
    const navigate = useNavigate();
    useEffect(() => {
        if (!accessToken) {
            navigate('/ap/auth/login')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken])
    const goToStripe = () => {
        navigate('/ap/onboarding/stripe')
    }
    return (
        <Layout>
            <>
                <Navbar userType="activity_provider" hideNavigation allowLogoutOnly />
                <MainContainer>
                    <>
                        <Flex
                            textAlign="center"
                            pt={{ base: "16px", lg: "48px" }}
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                        >
                            <Box
                                mx="auto"
                                w={{ base: "100%", md: "576px" }}
                            >
                                <Image
                                    alt="Succes!"
                                    src="/icon-success.svg"
                                    w="124px"
                                    h="124px"
                                    mb="16px"
                                    mx="auto"
                                />
                                <h1>Felicitări, contul tău a fost creat cu succes</h1>
                                <Text textStyle="largeP" mb="24px" mt="12px">
                                    Acum urmează să completezi detaliile referitoare la contul tău de procesare plăți pe stripe.com. Atenție! Îți vor fi solicitate datele referitoare la înregistrarea cabinetului de psihoterapie CIF, contul IBAN în care ți se va efectua plata și datele tale personale.
                                </Text>
                                <Text textStyle="largeP" mb="24px">
                                    Îți vei crea un cont pe stripe.com. Te rugăm să urmezi pașii si să salvezi datele de login și codul de securitate din procesul de înregistrare (stripe backup code). Mulțumim!
                                </Text>
                                <Button
                                    w="full"
                                    maxW={{ base: "100%", md: "310px" }}
                                    size="lg"
                                    type="submit"
                                    onClick={goToStripe}
                                >
                                    Continuă spre Stripe
                                </Button>
                            </Box>
                        </Flex>
                    </>
                </MainContainer>
                <BottomNavigation userType="activity_provider" display="none" />
            </>
        </Layout>
    )
}
