import { useIntl } from "react-intl";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useFetchNotesNumberQuery } from "../../notes/service";
import {
  useGetBookingsCountQuery,
  useGetNextBookingsOfUserAndActivityQuery,
} from "../../booking/service";
import moment from "moment";
type Props = {
  userId: string;
  activityId: string;
};

const START_DATETIME = "2022-09-01T00:00:00";
const END_DATETIME = moment().format("YYYY-MM-DDTHH:mm:ss");

const PatientMeta = ({ userId, activityId }: Props) => {
  const intl = useIntl();
  const { data: notes } = useFetchNotesNumberQuery({ u_guid: userId });
  const { data: nextBooking } = useGetNextBookingsOfUserAndActivityQuery({
    u_guid: userId,
    a_guid: activityId,
  });
  const { data: bookingsNumber } = useGetBookingsCountQuery({
    u_guid: userId,
    a_guid: activityId,
    start_datetime: START_DATETIME,
    end_datetime: END_DATETIME,
  });
  const nextBookingDate =
    nextBooking && nextBooking.length
      ? moment(nextBooking[0].start_time).format("dddd, DD MMMM YYYY, HH:mm")
      : null;
  return (
    <Flex
      alignItems="center"
      justifyContent={{ base: "flex-start", lg: "flex-start" }}
      textAlign={{ base: "left", lg: "left" }}
      p={{ base: "16px", lg: "0" }}
      bg={{ base: "#fff", lg: "transparent" }}
      mt={{ base: "16px", lg: "0" }}
      borderRadius="8px"
      w={{ base: "100%", lg: "auto" }}
      flexWrap="wrap"
      boxShadow={{ base: "0 8px 16px 0 rgba(39,47,55,0.12)", lg: "none" }}
    >
      <Box
        m={{ base: "0 0 8px 0", md: "0 24px 0 0" }}
        p={{ base: "0 0 8px 0", md: "0 24px 0 0" }}
        borderRight={{ base: "0", md: "1px" }}
        borderBottom={{ base: "1px", md: "0" }}
        borderColor={{ base: "border.400", md: "border.500" }}
        w={{ base: "100%", md: "auto" }}
      >
        <h3>{bookingsNumber ? bookingsNumber.booking_count : 0}</h3>
        <Text>{intl.formatMessage({ id: "ap_sessions" })}</Text>
      </Box>
      <Box
        m={{ base: "0 0 8px 0", md: "0 24px 0 0" }}
        p={{ base: "0 0 8px 0", md: "0 24px 0 0" }}
        borderRight={{ base: "0", md: "1px" }}
        borderBottom={{ base: "1px", md: "0" }}
        borderColor={{ base: "border.400", md: "border.500" }}
        w={{ base: "100%", md: "auto" }}
      >
        <h3>{notes ? notes.notes_no : 0}</h3>
        <Text>{intl.formatMessage({ id: "notes" })}</Text>
      </Box>
      <Box w={{ base: "100%", md: "auto" }} p={{ base: "0", md: "0" }}>
        <h3>{nextBookingDate}</h3>
        <Text>
          {intl.formatMessage({
            id: nextBookingDate ? "booking_next_session" : "booking_no_data",
          })}
        </Text>
      </Box>
    </Flex>
  );
};

export default PatientMeta;
