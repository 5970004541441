import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Box, Text } from "@chakra-ui/react";
import Steps from "../../shared/Steps";
import { useState } from "react";
import ActivityPrice from "../../activity/activityPrice";
import { IPrice } from "../../activity/types";

const Step4 = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { state } = useLocation();
  const specializations =
    (state as { specializations: string[] })?.specializations || [];

  const [price, setPrice] = useState<IPrice | undefined>(undefined);

  const handlePriceChange = (selectedPrice: IPrice | undefined) => {
    setPrice(selectedPrice);
  };

  return (
    <Steps activeStep={4} stepCount={6}>
      <h1>{intl.formatMessage({ id: "ap_onboarding_step4_title" })}</h1>
      <Text textStyle="largeP" mt="8px" mb="32px">
        {intl.formatMessage({ id: "ap_onboarding_step4_subtitle" })}
      </Text>
      <Box mb="16px" w="full" maxW={{ base: "100%", md: "310px" }}>
        <ActivityPrice onSelectPrice={handlePriceChange} />
      </Box>

      <Button
        w="full"
        maxW={{ base: "100%", md: "310px" }}
        m="auto"
        size="lg"
        onClick={() =>
          navigate("/ap/onboarding/step/5", {
            state: { price, specializations },
          })
        }
        isDisabled={!price}
      >
        {intl.formatMessage({ id: "user_onboarding_continue_btn_label" })}
      </Button>
    </Steps>
  );
};

export default Step4;
