import { useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import ActiveBookings from "./ActiveBookings";
import PastBookings from "./PastBookings";
import SummaryBookingModal from "../bookActivity/SummaryModal";

const BookingsTabs = () => {
  const intl = useIntl();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const b_guid = queryParams.get("b_guid") || "";
  const a_guid = queryParams.get("a_guid") || "";
  const success = queryParams.get("success");

  const [isModalVisible, setIsModalVisible] = useState<boolean>(
    !!b_guid && !!a_guid && success === "true"
  );

  return (
    <Box>
      <h1>{intl.formatMessage({ id: "booking_user_title" })}</h1>

      <Tabs colorScheme="brand" mt="16px">
        <TabList border="0">
          <Tab fontSize="13px" px="0" mr="24px">
            {intl.formatMessage({ id: "booking_user_active" })}
          </Tab>
          <Tab fontSize="13px" px="0" mr="24px">
            {intl.formatMessage({ id: "booking_user_past" })}
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel px="0" py="8px">
            <ActiveBookings />
          </TabPanel>
          <TabPanel px="0" py="8px">
            <PastBookings />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {isModalVisible && (
        <SummaryBookingModal
          b_guid={b_guid}
          a_guid={a_guid}
          isOpen={isModalVisible}
          onCloseModal={() => {
            setIsModalVisible(false);
          }}
        />
      )}
    </Box>
  );
};

export default BookingsTabs;
