import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Box, IconButton, Flex, Grid, GridItem } from "@chakra-ui/react";
import { ArrowLeft } from "@carbon/icons-react";
import { useGetUserQuery } from "../../user/service";
import Notes from "../../notes/Notes";
import ImportantNotes from "../../notes/ImportantNotes";
import PatientMeta from "./PatientMeta";
import Loader from "../../shared/Loader";

const Patient = () => {
  const navigate = useNavigate();

  let { userId = "", activityId = "" } = useParams();
  const { data: user, isLoading } = useGetUserQuery(userId);

  const nameAndAge = `${user?.f_name}, ${Number(moment().format("YYYY")) - Number(user?.birthdate)}`;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box>
      <>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Flex alignItems="center">
            <IconButton
              bg="#fff"
              color="brandGray.500"
              border="1px solid var(--chakra-colors-border-500)"
              borderRadius="100%"
              mr="8px"
              w="44px"
              h="44px"
              minW="44px"
              minH="44px"
              position="relative"
              _hover={{
                color: "#fff",
                background: "brand.500",
                borderColor: "brand.500",
              }}
              icon={<ArrowLeft size={24} />}
              aria-label="înapoi"
              onClick={() => navigate(-1)}
            />
            <h1>{nameAndAge}</h1>
          </Flex>

          <PatientMeta userId={userId} activityId={activityId} />
        </Flex>
        <Grid
          templateRows={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
          gap={{ base: "16px", md: "24px" }}
          mt="16px"
        >
          <GridItem rowSpan={1} colSpan={{ base: 1, md: 2 }}>
            <Notes userId={userId} />
          </GridItem>

          <GridItem colSpan={1}>
            <ImportantNotes userId={userId} />
          </GridItem>
        </Grid>
      </>
    </Box>
  );
};

export default Patient;
