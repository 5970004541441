import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Box, Input, Select, Button } from "@chakra-ui/react";
import { useEditArticleMutation } from "../service";
import TextEditor from "../../shared/textEditor/TextEditor";
import decodeHtmlEntity from "../../shared/textEditor/decodeHtmlEntity";
import ArticleImageUploader from "../../mediaAssets/ArticleImageUploader";
import { IMediaAsset } from "../../mediaAssets/types";
import { IArticle } from "../types";
import { categories } from "../defaults";
import { validateForm } from "../../utils/forms";
import { articleValidationRules } from "../../utils/forms/validators";
import { Form, FormControl } from "../../shared/form";

type Props = {
  article: IArticle;
};
const defaultFormErrors = {
  content: null,
};

const EditArticleForm = ({ article }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [articleToUpdate, setArticleToUpdate] = useState<IArticle>(article);
  const [content, setContent] = useState<string>(articleToUpdate.content);

  const [editArticle, { isLoading }] = useEditArticleMutation();

  const { guid, title, category, main_image, status, tags } = articleToUpdate;

  const handleChangeArticle = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setArticleToUpdate({
      ...articleToUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const handleContentChange = (htmlContent: string) => {
    setContent(htmlContent);
  };

  const handleImageUpload = (mediaAssets: IMediaAsset[]) => {
    setArticleToUpdate({ ...article, main_image: mediaAssets[0] });
  };

  const handleEditArticle = (e: React.FormEvent) => {
    e.preventDefault();
    const payload = {
      guid,
      title,
      content,
      tags,
      category,
      main_image,
      status,
    };
    const formValues = { title, content, main_image: "need no validation" };
    const validationErrors = validateForm(
      { content: formValues.content },
      articleValidationRules
    );
    if (!validationErrors) {
      editArticle(payload).then(() => navigate(`/ap/articles/${guid}`));
    } else {
      setFormErrors({ ...defaultFormErrors, ...validationErrors });
    }
  };

  return (
    <Box>
      <Form onSubmit={handleEditArticle}>
        <Select
          mt="16px"
          name="category"
          required
          placeholder={intl.formatMessage({
            id: "article_categories_placeholder",
          })}
          value={category}
          onChange={handleChangeArticle}
        >
          {categories.map((item, idx) => (
            <option value={item} key={idx}>
              {item}
            </option>
          ))}
        </Select>
        <Select
          mt="16px"
          name="status"
          required
          placeholder={intl.formatMessage({
            id: "article_status_placeholder",
          })}
          value={status}
          onChange={handleChangeArticle}
        >
          <option value={"draft"}>Draft</option>
          <option value={"published"}>Published</option>
          <option value={"archived"}>Archived</option>
          <option value={"deleted"}>Deleted</option>
        </Select>
        <ArticleImageUploader
          onImageUpload={handleImageUpload}
          articleImage={main_image}
        />
        <Input
          mt="16px"
          name="title"
          required
          placeholder={intl.formatMessage({
            id: "article_title_placeholder",
          })}
          value={title}
          onChange={handleChangeArticle}
        />
        <FormControl errorMessage={formErrors.content}>
          <TextEditor
            content={decodeHtmlEntity(content)}
            onContentChange={handleContentChange}
          />
        </FormControl>
        <Button
          w="full"
          maxW={{ base: "100%", md: "310px" }}
          m="auto"
          size="lg"
          mt="16px"
          type="submit"
          isLoading={isLoading}
        >
          {intl.formatMessage({ id: "article_edit_button_label" })}
        </Button>
      </Form>
    </Box>
  );
};

export default EditArticleForm;
