import { IAuthUTMParams } from "../auth/types";
import { AUTH_COOKIE_DOMAIN } from "../config";
import { ICookieAttributes, getCookie } from "./cookies";

const trackingCategories = [
  'fbclid', 'gclid', 'pageview', 'pageview_count', 'referrer', 't', 'utm_campaign', 'utm_medium', 'utm_source', 'utm_term'
]
const trackingCookies: string[] = [];
for (const category of trackingCategories) {
  trackingCookies.push(`_initial_${category}`);
  trackingCookies.push(`_last_${category}`);
} 

export const defaultUtmCookieAttributes: ICookieAttributes = {
  path: "/",
  domain: AUTH_COOKIE_DOMAIN,
  sameSite: "strict",
  expires: 365  //expire in 365 days from creation
};

export const removeUtmCookieAttributes: ICookieAttributes = {
  path: "/",
  domain: AUTH_COOKIE_DOMAIN,
  sameSite: "strict",
  expires: 0  //expire immediately
};

export const redirectHTTPToHTTPS = () => {
  try {
    if (window.location?.protocol?.toLowerCase() === "http:") {
      const url = `https:${window.location.href.substring(window.location.protocol.length)}`;
      window.location?.replace(url);
    }
  }
  catch (e) {
    console.log(e);
  }
}

export const getUTMParamsFromCookie = (): IAuthUTMParams | undefined => {
  const utm: any = {};
  try {
    for (const cookieName of trackingCookies) {
      const cookieValue = getCookie(cookieName);
      const paramName = cookieName.substring(1);
      if (cookieValue) {
        utm[paramName] = cookieValue;
      }
    }
    if (Object.keys(utm).length > 0) {
      return utm as IAuthUTMParams;
    }
  }
  catch (e) {
    console.log(e);
  }
}


export const cleanupUTMCookies = () => {
  // we don't need to cleanup cookies anymore

  // for (const param of utmParams) {
  //   for (const prefix of ["initial", "last"]) {
  //     const cookieName = `_${prefix}_${param}`;
  //     if (getCookie(cookieName)) {
  //       console.log(`Removing ${cookieName} cookie`);
  //       setCookie(cookieName, '', removeUtmCookieAttributes);
  //     }
  //   }
  // }
}
