import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  Input,
  Button,
  Box,
  Text,
  InputGroup,
  Flex,
  Checkbox,
  Link,
  InputLeftElement,
  InputLeftAddon,
} from "@chakra-ui/react";
import { Email, Identification } from "@carbon/icons-react";
import { useAppDispatch } from "../../hooks";
import { setAuthorizationTokens } from "../slice";
import { useRegisterAPMutation } from "../../activityProvider/service";
import { useAuthenticateMutation } from "../service";
import { validateForm } from "../../utils/forms";
import { Form, FormControl } from "../../shared/form";
import { registerActivityProviderValidationRules } from "../../utils/forms/validators";
import { IRegisterFormPayload } from "../../activityProvider/types";
import { IRegisterFormErrors } from "../types";
import {
  initialRegisterFormErrors,
  initialRegisterFormPayload,
  defaultUserInfo,
} from "../defaults";
import useNotification from "../../shared/hooks/useNotification";
import PasswordStrengthIndicator from "../../shared/form/PasswordStrengthIndicator";

type IFormPayload = IRegisterFormPayload & { agreementChecked: boolean };

const ActivityProviderRegisterForm = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const notify = useNotification();
  const navigate = useNavigate();

  const [registerAP] = useRegisterAPMutation();
  const [authenticate] = useAuthenticateMutation();

  const [formValues, setFormValues] = useState<IFormPayload>(
    initialRegisterFormPayload
  );
  const [formErrors, setFormErrors] = useState<IRegisterFormErrors>(
    initialRegisterFormErrors
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { f_name, l_name, email, pw, agreementChecked, mobile_phone_number } =
    formValues;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormErrors(initialRegisterFormErrors);
    const targetName = e.target.name;
    const targetValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormValues({ ...formValues, [targetName]: targetValue });
  };

  const mobilePhoneChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let targetValue = e.target.value;
    if (targetValue.length > 1 && targetValue[0] === "0") {
      targetValue = targetValue.replace("0", "");
    }
    setFormValues({ ...formValues, mobile_phone_number: targetValue });
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    const registerPayload = {
      ...formValues,
      u_name: email,
    };
    const validationErrors = validateForm(
      registerPayload,
      registerActivityProviderValidationRules
    );
    if (!validationErrors) {
      setFormErrors(initialRegisterFormErrors);
      setIsLoading(true);
      try {
        const payload = { ...registerPayload, ...defaultUserInfo };
        if (mobile_phone_number) {
          payload.mobile_phone_number = "+40".concat(mobile_phone_number);
        }
        const { agreementChecked, ...updatedPayload } = payload;
        await registerAP(updatedPayload).unwrap();
        const authTokens = await authenticate({
          user_name: email,
          password: pw,
        }).unwrap();
        dispatch(setAuthorizationTokens(authTokens));
        navigate("/ap/onboarding-info");
      } catch (error: any) {
        const errorMessage =
          "status" in error && error.status === 409
            ? "auth_register_409_status_error"
            : "auth_register_error";
        notify("error", errorMessage);
        setIsLoading(false);
      }
    } else {
      setFormErrors({ ...initialRegisterFormErrors, ...validationErrors });
    }
  };

  return (
    <Box w="100%">
      <Form onSubmit={handleRegister} mt="16px">
        <FormControl errorMessage={formErrors.f_name}>
          <InputGroup mb="12px">
            <InputLeftElement
              pointerEvents="none"
              opacity=".54"
              pt="3px"
              px="8px"
              h="100%"
              children={<Identification size={20}color="#696871" />}
            />
            <Input
              placeholder={intl.formatMessage({ id: "auth_first_name" })}
              type="text"
              name="f_name"
              value={f_name}
              onChange={(e) => onInputChange(e)}
              size="lg"
              required
            />
          </InputGroup>
        </FormControl>
        <FormControl errorMessage={formErrors.l_name}>
          <InputGroup mb="12px">
            <InputLeftElement
              pointerEvents="none"
              opacity=".54"
              pt="3px"
              px="8px"
              h="100%"
              children={<Identification size={20}color="#696871" />}
            />
            <Input
              placeholder={intl.formatMessage({
                id: "ap_onboarding_step1_name",
              })}
              type="text"
              name="l_name"
              value={l_name}
              onChange={(e) => onInputChange(e)}
              size="lg"
              required
            />
          </InputGroup>
        </FormControl>
        <FormControl errorMessage={formErrors.email}>
          <InputGroup mb="12px">
            <InputLeftElement
              pointerEvents="none"
              opacity=".54"
              pt="3px"
              px="8px"
              h="100%"
              children={<Email size={20}color="#696871" />}
            />
            <Input
              placeholder={intl.formatMessage({ id: "auth_email" })}
              type="email"
              name="email"
              value={email}
              onChange={(e) => onInputChange(e)}
              size="lg"
              required
            />
          </InputGroup>
        </FormControl>
        <FormControl errorMessage={formErrors.pw}>
          <PasswordStrengthIndicator
            placeholder={intl.formatMessage({ id: "auth_password" })}
            name="pw"
            onInputChange={(e) => onInputChange(e)}
          />
        </FormControl>
        <FormControl errorMessage={formErrors.mobile_phone_number}>
          <InputGroup maxW={{ md: "3xl" }}>
            <InputLeftAddon children="+40" h="44px" />
            <Input
              type="tel"
              borderRadius="0 8px 8px 0"
              value={mobile_phone_number}
              onChange={mobilePhoneChanged}
              required
              placeholder={intl.formatMessage({ id: "mobile_phone_number" })}
            />
          </InputGroup>
        </FormControl>
        <FormControl errorMessage={formErrors.agreementChecked}>
          <Checkbox
            mb="12px"
            colorScheme="brand"
            py="16px"
            display="flex"
            alignItems="center"
            lineHeight="normal"
            color="#65646D"
            name="agreementChecked"
            isChecked={agreementChecked}
            isInvalid={!!formErrors.agreementChecked}
            onChange={(e) => onInputChange(e)}
            required
          >
            <Flex mt="-2px">
              <Text>
                {intl.formatMessage({ id: "auth_tnc_agreement" })}&nbsp;
                <Link
                  href="https://www.psiholog.ro/termeni-si-conditii"
                  isExternal
                  color="brandGray.500"
                  textDecoration="underline"
                >
                  {intl.formatMessage({ id: "auth_tnc" })}
                </Link>
              </Text>
            </Flex>
          </Checkbox>
        </FormControl>
        <Button w="full" size="lg" type="submit" isLoading={isLoading}>
          {intl.formatMessage({ id: "auth_register_btn" })}
        </Button>
      </Form>
    </Box>
  );
};

export default ActivityProviderRegisterForm;
