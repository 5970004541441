import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Image as ImageIcon } from "@carbon/icons-react";
import ImageUploader from "./ImageUploader";
import { IMediaAsset } from "./types";
import { useState } from "react";
import { useIntl } from "react-intl";

type Props = {
  onImageUpload: (image: IMediaAsset[]) => void;
  articleImage?: IMediaAsset;
  isRequired?: boolean;
};

const ArticleImageUploader = ({
  articleImage,
  onImageUpload,
  isRequired,
}: Props) => {
  const intl = useIntl();

  const [image, setImage] = useState<IMediaAsset | null>(articleImage || null);

  const handleImageUpload = (mediaAssets: IMediaAsset[]) => {
    setImage(mediaAssets[0]);
    onImageUpload(mediaAssets);
  };

  return (
    <>
      {image && (
        <Flex
          mt="16px"
          maxW="200px"
          maxH="200px"
          alignItems="center"
          justify="center"
          flex="1"
        >
          <Image src={image.url} maxW="100%" maxH="100%" />
        </Flex>
      )}
      <Box
        mt="16px"
        d="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        w="100%"
        h="100%"
        pos="relative"
        bgColor="white"
        border="1px"
        borderColor="border.500"
        borderRadius="8px"
        px="32px"
        py="49px"
        cursor="pointer"
      >
        {/* could add multiple attribute */}
        <ImageUploader
          onImageUpload={handleImageUpload}
          isRequired={isRequired}
        />
        <ImageIcon size={32}color="border.500" style={{ width: "64px", height: "64px" }} />
        <Text>{intl.formatMessage({ id: "articles_add_image" })}</Text>
      </Box>
    </>
  );
};

export default ArticleImageUploader;
