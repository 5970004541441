import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AP_HOME_PAGE, USER_HOME_PAGE } from "../../routes/defaults";
import useGetUserRoles from "../../user/hooks/useGetUserRoles";

const HomePage = () => {
  const navigate = useNavigate();

  const roles = useGetUserRoles();
  const isRegisteredUser = roles.includes("registered_user");
  const isActivityProvider = roles.includes("activity_provider");

  useEffect(() => {
    if (isRegisteredUser) {
      navigate(USER_HOME_PAGE);
    } else if (isActivityProvider) {
      navigate(AP_HOME_PAGE);
    } else {
      navigate("/auth/login");
    }
  }, [isRegisteredUser, isActivityProvider, navigate]);

  return null;
};

export default HomePage;
