import { createApi } from "@reduxjs/toolkit/query/react";
import { IRoomToken, ICreateRoomTokenPayload, IPingPayload } from "./types";
import baseQueryWithReauthorization from "../store/baseQuery";

export const roomApi = createApi({
  reducerPath: "roomApi",
  baseQuery: baseQueryWithReauthorization,
  endpoints: (builder) => ({
    createToken: builder.mutation<IRoomToken, ICreateRoomTokenPayload>({
      query: (payload: ICreateRoomTokenPayload) => {
        return {
          url: "/room/token",
          method: "POST",
          headers: {},
          body: payload,
        };
      }
    }),
    ping: builder.mutation<IRoomToken, IPingPayload>({
      query: (payload: IPingPayload) => {
        return {
          url: "/room/stats",
          method: "POST",
          headers: {},
          body: payload,
        };
      },
    }),
  }),
});

export const { useCreateTokenMutation, usePingMutation } = roomApi;
