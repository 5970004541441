import { useToast } from "@chakra-ui/react";
import { useIntl } from "react-intl";

export default function useNotification() {
  const intl = useIntl();
  const toast = useToast();

  const notify = (status: "success" | "error" | "info", message: string) => {
    toast({
      title: intl.formatMessage({
        id: message,
      }),
      status,
      duration: 5000,
      isClosable: true,
    });
  };

  return notify;
}
