import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {
  setAuthorizationTokens,
  getAccessToken,
  getRefreshToken,
  logoutUser,
} from "../auth/slice";
import { RootState } from ".";
import { API_BASE_URL } from "../config";
import getUserRoles from "../user/userRoles";

const PUBLIC_ENDPOINTS = [
  "registerUser",
  // "getActivityProvider",
  "registerAP",
  "refresh",
  "resetPasswordRequest",
  "resetPassword",
  "getActivities",
  "getActivity",
];

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const accessToken = (getState() as RootState).iam.access_token;
    if (accessToken && !PUBLIC_ENDPOINTS.includes(endpoint)) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }

    return headers;
  },
});

const baseQueryWithReauthorization: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    //[401, 403].indexOf(result.error.status) !== -1
    const refreshToken = getRefreshToken(api.getState() as RootState);
    const refreshTokenResult = await baseQuery(
      {
        url: "/auth/refresh",
        method: "POST",
        headers: { authorization: `Bearer ${refreshToken}` },
      },
      { ...api, endpoint: "refresh" },
      extraOptions
    );
    if ("data" in refreshTokenResult) {
      const newAccessToken = (
        refreshTokenResult.data as { access_token: string }
      ).access_token;
      // store the new token
      api.dispatch(
        setAuthorizationTokens({
          access_token: newAccessToken,
          refresh_token: refreshToken,
        })
      );
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      logoutUser();
      const accessToken = getAccessToken(api.getState() as RootState);
      const userRoles = getUserRoles(accessToken);
      if (userRoles.includes("registered_user")) {
        window.location.href = "/auth/login";
      } else if (userRoles.includes("activity_provider")) {
        window.location.href = "/ap/auth/login";
      }
    }
  }
  return result;
};

export default baseQueryWithReauthorization;
