import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauthorization from "../store/baseQuery";
import {
  IReview,
  IFetchReviewsPayload,
  IFetchReviewsResponse,
  IDeleteReviewResponse,
  IReviewPayload,
} from "./types";

export const reviewApi = createApi({
  reducerPath: "reviewApi",
  baseQuery: baseQueryWithReauthorization,
  tagTypes: ["Review"],
  endpoints: (builder) => ({
    getReviews: builder.query<IFetchReviewsResponse, IFetchReviewsPayload>({
      query: (payload: IFetchReviewsPayload) => {
        const { a_guid, limit, last_evaluated_key } = payload;
        const params: Partial<IFetchReviewsPayload> = {
          limit,
        };
        if (last_evaluated_key) {
          params["last_evaluated_key"] = last_evaluated_key;
        }

        return {
          url: `/review/${a_guid}`,
          method: "GET",
          params: params,
        };
      },
      providesTags: ["Review"],
    }),
    postReview: builder.mutation<IReview, IReviewPayload>({
      query: (payload: IReview) => {
        return {
          url: "/review",
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Review"],
    }),
    deleteReview: builder.mutation<IDeleteReviewResponse, string>({
      query: (guid: string) => {
        return {
          url: `/review/${guid}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Review"],
    }),
  }),
});

export const {
  useGetReviewsQuery,
  usePostReviewMutation,
  useDeleteReviewMutation,
} = reviewApi;
