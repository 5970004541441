import { Box } from "@chakra-ui/react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import "./styles.css";

type Props = {
  content: string;
  onContentChange: (htmlContent: string) => void;
  formatStyle: "paragraph" | "bulletList";
};

const PresetTextEditor = ({ content, onContentChange, formatStyle }: Props) => {
  const editor = useEditor({
    extensions: [StarterKit],

    content: content,
    onUpdate: ({ editor }) => {
      const htmlContent = editor.getHTML();
      onContentChange(htmlContent);
    },
    onCreate: ({ editor }) => {
      if (formatStyle === "paragraph") {
        editor.chain().focus().setParagraph().run();
      } else if (formatStyle === "bulletList") {
        editor.chain().focus().toggleBulletList().run();
      }
    },
  });

  return (
    <Box
      d="flex"
      flexDirection="column"
      maxH="500px"
      border="1px solid"
      borderColor="border.500"
      borderRadius="4px"
      mt="16px"
      overflow="hidden"
    >
      <EditorContent className="preset-text-editor-content" editor={editor} />
    </Box>
  );
};

export default PresetTextEditor;
