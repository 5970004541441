import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Text,
  PinInput,
  PinInputField,
  HStack,
  Button,
} from "@chakra-ui/react";
import {
  useActivateUserMutation,
  useResendActivationCodeMutation,
} from "../../user/service";
import useNotification from "../../shared/hooks/useNotification";
import Loader from "../../shared/Loader";
import ChangeEmailModal from "./ChangeEmailModal";
import { USER_HOME_PAGE } from "../../routes/defaults";

type Props = {
  initialCode?: string | null;
}

const PinForm: React.FC<Props> = ({ initialCode }) => {
  const intl = useIntl();
  const notify = useNotification();

  const [sendActivationCode, { isLoading: isSendingActivationCode }] =
    useActivateUserMutation();

  const [requestNewActivationCode, { isLoading: isRequestingNewCode }] =
    useResendActivationCodeMutation();


  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const pinInputLength = 4;

  const [pin, setPin] = useState("");

  const handlePinChange = (value: string) => {
    setPin(value);
    if (value.length === 4) {
      handlePinSubmit(value);
    }
  };


  const showChangeEmailModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (initialCode) {
      setPin(initialCode);
      handlePinSubmit(initialCode);
    }
  }, [initialCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const resendActivationCode = () => {
    requestNewActivationCode({ channel: "email" })
      .then(
        (response) => {
          if ("error" in response) {
            notify("error", "ap_onboarding_step1_error");
          } else {
            notify("success", "new_activation_code");
          }
        },
        () => notify("error", "ap_onboarding_step1_error")
      )
      .catch(() => notify("error", "ap_onboarding_step1_error"));
  };

  const handlePinSubmit = (pin: string) => {
    sendActivationCode(pin)
      .then(
        (response) => {
          if ("error" in response) {
            notify("error", "ap_onboarding_step1_error");
            setPin("");
          } else {
            setTimeout(() => {
              // navigate("/onboarding/step/2");
              window.location.href = `${USER_HOME_PAGE}`;
            }, 1000);
            
          }
        },
        () => notify("error", "ap_onboarding_step1_error")
      )
      .catch(() => notify("error", "ap_onboarding_step1_error"));
  };

  if (isSendingActivationCode || isRequestingNewCode) {
    return <Loader />;
  }

  return (
    <>
      <HStack m="24px auto 0 auto">
        <PinInput
          autoFocus
          size="lg"
          type="number"
          value={pin}
          onChange={handlePinChange}
        >
          {Array.from(new Array(pinInputLength)).map((_, idx) => (
            <PinInputField
              key={idx}
              borderRadius="8px"
              bg="white"
              borderColor="border.500"
            />
          ))}
        </PinInput>
      </HStack>
      <Text m="16px 0 24px 0" color="#696871" fontWeight="600">
        <Text as="span">
          {intl.formatMessage({ id: "user_onboarding_step1_link" })}&nbsp;
        </Text>
        <Button
          variant="link"
          onClick={resendActivationCode}
          disabled={isRequestingNewCode}
        >
          {intl.formatMessage({ id: "user_onboarding_step1_link_label" })}
        </Button>
        <Text as="span">
          &nbsp;{intl.formatMessage({ id: "or" })}&nbsp;
        </Text>
        <Button
          variant="link"
          onClick={showChangeEmailModal}
        >
          {intl.formatMessage({ id: "user_onboarding_step1_change_email_label" })}
        </Button>
        {isModalVisible && (
          <ChangeEmailModal
            isOpen={isModalVisible}
            onCloseModal={() => {
              setIsModalVisible(false);
            }}
          />
        )}
      </Text>
    </>
  );
};

export default PinForm;
