import { Flex, Tooltip, IconButton, Box, AspectRatio, Image } from "@chakra-ui/react";
import { ArrowLeft } from "@carbon/icons-react";
import HtmlContent from "../../shared/HtmlContent";
import { IArticle } from "../types";
import { useIntl } from "react-intl";

type Props = {
  handleGoBack: () => void;
  article: IArticle;
};

const Article = ({ handleGoBack, article }: Props) => {
  const intl = useIntl();

  return (
    <>
      <Flex alignItems="center">
        <Tooltip label={intl.formatMessage({ id: "article_back_to_articles" })}>
          <IconButton
            bg="#fff"
            color="brandGray.500"
            border="1px solid var(--chakra-colors-border-500)"
            borderRadius="100%"
            mr="8px"
            w="44px"
            h="44px"
            minW="44px"
            minH="44px"
            position="relative"
            aria-label="Inapoi"
            _hover={{
              color: "#fff",
              background: "brand.500",
              borderColor: "brand.500",
            }}
            icon={<ArrowLeft size={24} />}
            onClick={handleGoBack}
          />
        </Tooltip>
        <h2>{article.title}</h2>
      </Flex>
      <AspectRatio ratio={16 / 9}
        mt="16px"
      >
        <Image src={article.main_image.url} w="100%" borderRadius="8px 8px 0 0" objectFit='cover'  />
      </AspectRatio>
      <Box p={{base: "16px", lg:"24px 24px"}} borderRadius="0 0 8px 8px" bg="#fff" maxW={{base: "100%", lg: "800px"}} ml="auto" mr="auto" position="relative" zIndex="2" className="article-wrapper">
        <HtmlContent content={article.content} />
      </Box>
      
    </>
  );
};

export default Article;
