import {
  Box,
  Flex,
  Text,
  Button,
  Avatar,
  IconButton,
  Show,
} from "@chakra-ui/react";
import { ArrowLeft } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { IActivity } from "../types";
import Rating from "../../reviews/Rating";
import HtmlContent from "../../shared/HtmlContent";

type Props = {
  activity: IActivity;
  isBtbDisabled?: boolean;
  experienceYears: number | null;
  apAge: number | null;
};

const Header = ({ activity, isBtbDisabled, experienceYears, apAge }: Props) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const { price, description, rating, reviews_no, booking_package } = activity;

  const has_package = booking_package && booking_package?.active;

  return (
    <Box
      color="#fff"
      bg="brandGray.500"
      pt={{ base: "64px", lg: "80px" }}
      zIndex="2"
      position="relative"
      marginBottom={{ base: "-64px", lg: "-80px" }}
    >
      <Box
        py={{ base: "24px", lg: "48px" }}
        px={{ base: "16px", lg: "32px" }}
        maxW="1200px"
        marginTop="auto"
        marginLeft="auto"
        marginRight="auto"
      >
        <Flex
          w="full"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
        >
          <Flex
            flex="1"
            alignItems="center"
            pr={{ base: "0", md: "24px" }}
            mr={{ base: "0", md: "24px" }}
            borderRight={{ base: "none", md: "1px solid #6B7987" }}
            flexWrap={{ base: "wrap", md: "unset" }}
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              position="relative"
              w={{ base: "100%", md: "auto" }}
              mb={{ base: "24px", md: "0" }}
            >
              <IconButton
                bg="#fff"
                color="brandGray.500"
                border="3px solid var(--chakra-colors-brandGray-500)"
                borderRadius="100%"
                w="48px"
                h="48px"
                minW="48px"
                minH="48px"
                mr="-24px"
                aria-label="înapoi"
                position="relative"
                zIndex="1"
                z-index="1"
                icon={<ArrowLeft size={24} />}
                _hover={{
                  color: "#fff",
                  background: "brand.500",
                }}
                onClick={() => navigate(-1)}
              />
              <Avatar
                name={activity.name}
                src={activity.images[0] ? activity.images[0].url : ""}
                // w={{ base: "72px", md: "148px" }}
                // h={{ base: "72px", md: "148px" }}
                // minW={{ base: "72px", md: "148px" }}
                w="148px"
                h="148px"
                minW="148px"
                mr="8px"
                border="3px solid #fff"
                position="relative"
                zIndex="0"
                fontWeight="700"
              />
              {experienceYears && (
                <Box
                  position="absolute"
                  borderRadius="24px"
                  bg="brandGray.500"
                  border="2px solid #fff"
                  p="6px 12px"
                  fontSize="11px"
                  lineHeight="12px"
                  fontWeight="700"
                  textTransform="uppercase"
                  letterSpacing="1px"
                  bottom="0"
                  w="148px"
                  mr={{ base: "-82px", md: "0" }}
                  right={{ base: "50%", md: "8px" }}
                  textAlign="center"
                >
                  {`${experienceYears} ani experiență`}
                </Box>
              )}
            </Flex>
            <Box
              ml={{ base: "0", md: "24px" }}
              textAlign={{ base: "center", md: "left" }}
              w={{ base: "full", md: "auto" }}
            >
              <Flex
                alignItems={{ base: "center", md: "flex-start" }}
                justifyContent={{ base: "center", md: "flex-start" }}
              >
                <Rating score={rating} count={reviews_no} />
              </Flex>

              <h1 style={{ color: "#fff" }}>
                {activity.name}
                {apAge && <Show above="md">{`, ${apAge} ani`}</Show>}
              </h1>
              <Show above="md">
                <Text textStyle="largeP" mt="8px" noOfLines={2}>
                  <HtmlContent content={description} />
                </Text>
              </Show>
            </Box>
          </Flex>

          <Box
            w={{ base: "calc(100% + 32px)", md: "300px" }}
            borderTop={{ base: "1px solid #6B7987", md: "none" }}
            padding={{ base: "16px", md: "0" }}
            bg={{ base: "rgba(0,0,0,.3)", md: "transparent" }}
            mt={{ base: "16px", md: "0" }}
            mb={{ base: "-24px", md: "0" }}
            mx={{ base: "-16px", md: "0" }}
            textAlign={{ base: "center", md: "left" }}
          >

            <Flex
              alignItems="center"
              lineHeight="1"
              fontSize={has_package ? "14px" : "18px"}
              letterSpacing="-.5px"
              fontWeight="700"
              justifyContent={{ base: "center", md: "flex-start" }}
            >
              <Text fontSize={has_package ? "34px" : "44px"} mx="6px">
                {price.label}
              </Text>
              <Text alignSelf="end" lineHeight="40px">
                / {intl.formatMessage({ id: "activity_session" })}
              </Text>
            </Flex>
            {has_package && (
              <Flex
                alignItems="center"
                lineHeight="1"
                fontSize="18px"
                letterSpacing="-.5px"
                fontWeight="700"
                justifyContent={{ base: "center", md: "flex-start" }}
              >
                <Text fontSize="34px" mx="6px">
                  {booking_package.label}
                </Text>
                <Text fontSize="24px" alignSelf="end" lineHeight="40px">
                  / {booking_package.booking_num} {intl.formatMessage({ id: "activity_sessions" })}
                </Text>
              </Flex>
            )}
            <Button
              size="lg"
              w="full"
              mt="16px"
              isDisabled={isBtbDisabled}
              onClick={() =>
                navigate(`/activities/${activity.guid}/book-activity`)
              }
            >
              {intl.formatMessage({ id: "activity_book_btn_label" })}
            </Button>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;
