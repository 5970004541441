import decodeHtmlEntity from "./textEditor/decodeHtmlEntity";

type Props = {
  content: string;
};

const HtmlContent = ({ content }: Props) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: decodeHtmlEntity(content) }} />
  );
};

export default HtmlContent;
