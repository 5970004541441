import { useFetchNotesQuery } from '../service'
import { useEffect } from 'react';
import { useState } from 'react';
import { INote } from '../types';

export default function useNotes(userId: string, featured = false) {
    const [notes, setNotes] = useState<INote[]>([])
    const { data, isLoading } = useFetchNotesQuery({ u_guid: userId })

    useEffect(() => {
        const filteredNotes = data ? data.filter((note) => note.featured === featured) : []
        setNotes(filteredNotes)
    }, [data, featured])
    return { notes, isLoading }
}
