import { Editor } from "@tiptap/react";
import { Box } from "@chakra-ui/react";
import {
  TextBold,
  TextItalic,
  TextStrikethrough,
  Code,
  Quotes,
  PaintBrushAlt,
  Paragraph,
  ListBulleted,
  ListNumbered,
  // ListChecked,
  TextWrap,
  TextClearFormat,
  Undo,
  Redo,
  // TextAlignLeft,
  // TextAlignRight,
  // TextAlignCenter,
  // TextAlignJustify,
} from "@carbon/icons-react";
import MenuItem from "./MenuItem";

type Props = {
  editor: Editor | null;
};

const MenuBar = ({ editor }: Props) => {
  if (!editor) {
    return null;
  }

  return (
    <Box p="6px" flex={"0 0 auto"}>
      {/* <MenuItem
        aria-label="H1"
        tooltipLabel="H1"
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        isActive={editor.isActive("heading", { level: 1 })}
        icon={<>H1</>}
      /> */}
      <MenuItem
        aria-label="H2"
        tooltipLabel="H2"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        isActive={editor.isActive("heading", { level: 2 })}
        icon={<>H2</>}
      />
      <MenuItem
        aria-label="H3"
        tooltipLabel="H3"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        isActive={editor.isActive("heading", { level: 3 })}
        icon={<>H3</>}
      />
      <MenuItem
        aria-label="H4"
        tooltipLabel="H4"
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        isActive={editor.isActive("heading", { level: 4 })}
        icon={<>H4</>}
      />
      <MenuItem
        aria-label="H5"
        tooltipLabel="H5"
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        isActive={editor.isActive("heading", { level: 5 })}
        icon={<>H5</>}
      />
      <MenuItem
        aria-label="H6"
        tooltipLabel="H6"
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        isActive={editor.isActive("heading", { level: 6 })}
        icon={<>H6</>}
      />
      <MenuItem
        aria-label="Bold"
        tooltipLabel="Bold"
        onClick={() => editor.chain().focus().toggleBold().run()}
        isActive={editor.isActive("bold")}
        icon={<TextBold size={24}/>}
      />
      <MenuItem
        aria-label="Italic"
        tooltipLabel="Italic"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        isActive={editor.isActive("italic")}
        icon={<TextItalic size={24}/>}
      />
      <MenuItem
        aria-label="Strike"
        tooltipLabel="Strike"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        isActive={editor.isActive("strike")}
        icon={<TextStrikethrough size={24}/>}
      />
      {/* <MenuItem
        aria-label="Aliniază la stânga"
        tooltipLabel="Aliniază la stânga"
        onClick={() => editor.chain().focus().setTextAlign("left").run()}
        isActive={editor.isActive({ textAlign: "left" })}
        icon={<TextAlignLeft size={24}/>}
      />
      <MenuItem
        aria-label="Aliniază centru"
        tooltipLabel="Aliniază centru"
        onClick={() => editor.chain().focus().setTextAlign("center").run()}
        isActive={editor.isActive({ textAlign: "center" })}
        icon={<TextAlignCenter size={24}/>}
      />
      <MenuItem
        aria-label="Aliniază la dreapta"
        tooltipLabel="Aliniază la dreapta"
        onClick={() => editor.chain().focus().setTextAlign("right").run()}
        isActive={editor.isActive({ textAlign: "right" })}
        icon={<TextAlignRight size={24}/>}
      />
      <MenuItem
        aria-label="Aliniază egal la stânga și dreapta"
        tooltipLabel="Aliniază egal la stânga și dreapta"
        onClick={() => editor.chain().focus().setTextAlign("justify").run()}
        isActive={editor.isActive({ textAlign: "justify" })}
        icon={<TextAlignJustify size={24}/>}
      /> */}
      <MenuItem
        aria-label="Evidențiază"
        tooltipLabel="Evidențiază"
        onClick={() => editor.chain().focus().toggleHighlight().run()}
        isActive={editor.isActive("highlight")}
        icon={<PaintBrushAlt size={24}/>}
      />

      <MenuItem
        aria-label="Paragraf"
        tooltipLabel="Paragraf"
        onClick={() => editor.chain().focus().setParagraph().run()}
        isActive={editor.isActive("paragraph")}
        icon={<Paragraph size={24}/>}
      />
      <MenuItem
        aria-label="Listă"
        tooltipLabel="Listă"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        isActive={editor.isActive("bulletList")}
        icon={<ListBulleted size={24}/>}
      />
      <MenuItem
        aria-label="Listă ordonată"
        tooltipLabel="Listă ordonată"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        isActive={editor.isActive("orderedList")}
        icon={<ListNumbered size={24}/>}
      />
      {/* <MenuItem
        aria-label="Listă de sarcini"
        tooltipLabel="Listă de sarcini"
        onClick={() => editor.chain().focus().toggleTaskList().run()}
        isActive={editor.isActive("taskList")}
        icon={<ListChecked size={24}/>}
      /> */}
      <MenuItem
        aria-label="Bloc de cod"
        tooltipLabel="Bloc de cod"
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        isActive={editor.isActive("codeBlock")}
        icon={<Code size={24}/>}
      />
      <MenuItem
        aria-label="Citat"
        tooltipLabel="Citat"
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        isActive={editor.isActive("blockquote")}
        icon={<Quotes size={24}/>}
      />
      <MenuItem
        aria-label="Linie spațiere orizontală"
        tooltipLabel="Linie spațiere orizontală"
        isActive={false}
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
        icon={<>-</>}
      />
      <MenuItem
        aria-label="Hard break"
        tooltipLabel="Hard break"
        isActive={false}
        onClick={() => editor.chain().focus().setHardBreak().run()}
        icon={<TextWrap size={24}/>}
      />
      <MenuItem
        aria-label="Șterge formatarea"
        tooltipLabel="Șterge formatarea"
        isActive={false}
        onClick={() =>
          editor.chain().focus().clearNodes().unsetAllMarks().run()
        }
        icon={<TextClearFormat size={24}/>}
      />

      <MenuItem
        aria-label="undo"
        isActive={false}
        onClick={() => editor.chain().focus().undo().run()}
        icon={<Undo size={24}/>}
      />
      <MenuItem
        aria-label="redo"
        isActive={false}
        onClick={() => editor.chain().focus().redo().run()}
        icon={<Redo size={24}/>}
      />
    </Box>
  );
};

export default MenuBar;
