import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import AddToCalendar from "react-add-to-calendar";
import {
  Box,
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Divider,
  Image,
  SimpleGrid,
  Show,
} from "@chakra-ui/react";
import { ArrowRight } from "@carbon/icons-react";
import { useAddBookingReminderMutation, useGetBookingQuery } from "../service";
import { useGetLoggedInUserQuery } from "../../user/service";
import useNotification from "../../shared/hooks/useNotification";
import ActivityMeta from "../../shared/ActivityMeta";
import Loader from "../../shared/Loader";
import { BASE_URL, SMS_REMINDER_DELTA_TIME } from "../../config";
import { fromServerToLocalMoment, toLocalddddDMY, toLocalHM } from "../utils";
import { useEffect } from "react";
import { TrackGoogleAnalyticsEventOnce } from "../../utils/GoogleAnalyticsHelper";
import { useGetActivityQuery } from "../../activity/service";

type Props = {
  b_guid: string;
  a_guid: string;
  isOpen: boolean;
  onCloseModal: () => void;
};

const SummaryModal = ({ b_guid, a_guid, isOpen, onCloseModal }: Props) => {
  const intl = useIntl();
  const notify = useNotification();
  const navigate = useNavigate();
  const { data: user } = useGetLoggedInUserQuery();
  const [sendReminder, { isLoading: sendingReminder }] =
    useAddBookingReminderMutation();
  const showSmsButton = user && user.mobile_phone_number.length ? true : false;
  const { data: booking, isLoading } = useGetBookingQuery(
    { a_guid, b_guid },
    { skip: !b_guid || !a_guid }
  );
  const { data: activity } = useGetActivityQuery(a_guid);

  useEffect(() => {
    if (booking && activity?.price?.value) {
      TrackGoogleAnalyticsEventOnce(b_guid, "user_payment", "payment_success", undefined, Math.floor(parseInt(activity.price.value) / 100 / 2))
    }

  }, [booking, activity?.price?.value, b_guid])


  if (!booking) return null;


  const localStart = fromServerToLocalMoment(booking.start_time)
  const localEnd = fromServerToLocalMoment(booking.end_time)

  const event = {
    title: intl.formatMessage(
      { id: "calendar_event_title" },
      { name: booking.display_name }
    ),
    description: intl.formatMessage(
      { id: "calendar_event_description" },
      { name: booking.display_name }
    ),
    location: `${BASE_URL}/room/${a_guid}/${b_guid}`,
    startTime: localStart.format(),
    endTime: localEnd.format(),
  };

  const AddSmsReminder = () => {
    sendReminder({
      a_guid,
      b_guid,
      time_delta_in_minutes: SMS_REMINDER_DELTA_TIME,
      channel: "sms",
    })
      .then(
        () => notify("success", "reminder_set"),
        () => notify("error", "ap_onboarding_step1_error")
      )
      .catch(() => notify("error", "ap_onboarding_step1_error"));
  };

  const localEvent = {
    ...event,
    startTime: localStart.toISOString(),
    endTime: localEnd.toISOString(),
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      isCentered={true}
      scrollBehavior="inside"
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody py="16px">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Flex
              justifyContent={{ base: "center", md: "flex-start" }}
              w={{ base: "100%", md: "auto" }}
            >
              <ActivityMeta guid={a_guid} />
            </Flex>
            <Show above="md">
              <Divider orientation="vertical" h="44px" opacity="1" />
            </Show>
            <Show below="md">
              <Divider opacity="1" my="12px" />
            </Show>
            {booking && (
              <Box
                textAlign={{ base: "center", md: "right" }}
                w={{ base: "100%", md: "auto" }}
              >
                <Text
                  fontSize="18px"
                  lineHeight="normal"
                  color="brandGray.500"
                  fontWeight="600"
                >
                  {toLocalddddDMY(localStart)}
                </Text>
                <Text>{`Ora ${toLocalHM(localStart)} - ${toLocalHM(localEnd)}`}</Text>
              </Box>
            )}
            {isLoading && !booking && <Loader />}
          </Flex>
          <Divider my="16px" opacity="1" />
          <Flex
            py="32px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Image
              alt="Succes!"
              src="/icon-success.svg"
              w="124px"
              h="124px"
              mb="16px"
            />
            <h2>{intl.formatMessage({ id: "booking_success_message" })}</h2>
            <Text maxWidth="390px" textStyle="largeP" mt="8px">
              {intl.formatMessage({ id: "booking_reschedule" })}
            </Text>
            <SimpleGrid
              columns={{ base: 1, sm: showSmsButton ? 2 : 1 }}
              spacing="16px"
              mt="16px"
              w={{ base: "100%", md: "auto" }}
            >
              <AddToCalendar
                buttonWrapperClass="add-to-calendar-button"
                dropdownClass="add-to-calendar-dropdown"
                event={localEvent}
                buttonLabel={intl.formatMessage({
                  id: "booking_add_to_calendar",
                })}
              />
              {showSmsButton && (
                <Button
                  variant="outline"
                  onClick={AddSmsReminder}
                  isLoading={sendingReminder}
                >
                  {intl.formatMessage({ id: "booking_sms_reminder" })}
                </Button>
              )}
            </SimpleGrid>
          </Flex>
        </ModalBody>

        <ModalFooter borderTop="1px solid var(--chakra-colors-border-400)">
          <Button
            variant="link"
            w="full"
            onClick={() => {
              onCloseModal();
              navigate("/bookings");
            }}
            color="brandGray.500"
            display="flex"
            alignItems="center"
            textDecoration="none"
          >
            <Text mr="8px" _hover={{ textDecoration: "underline" }}>
              {intl.formatMessage({ id: "booking_appointments_link" })}
            </Text>
            <ArrowRight size={20} style={{ marginTop: "3px" }} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SummaryModal;
