import {
  Email,
  Stethoscope,
} from "@carbon/icons-react";
import { HrefLink, NavLink } from "./NavLink";
import { logoutUser } from "../auth/slice";
import { useIntl } from "react-intl";
import { EMAIL_ADDRESS } from "../config";

const UserLogout = () => {
  const intl = useIntl();

  return (
    <>
      <HrefLink
        to={`mailto:${EMAIL_ADDRESS}`}
        target="_blank"
        label={intl.formatMessage({ id: "navbar_contact_label" })}
        icon={Email}
        iconSize={20}
      />
      <NavLink
        to="/"
        onClick={() => logoutUser()}
        label={intl.formatMessage({ id: "user_menu_logout" })}
        icon={Stethoscope} iconSize={20}
      />
    </>
  )
};

export default UserLogout;
