import { Box } from "@chakra-ui/react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextAlign from "@tiptap/extension-text-align";
import MenuBar from "./MenuBar";
import "./styles.css";

type Props = {
  content: string;
  onContentChange: (htmlContent: string) => void;
};

const TextEditor = ({ content, onContentChange }: Props) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Highlight,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
    ],
    content: content,
    onUpdate: ({ editor }) => {
      const htmlContent = editor.getHTML();
      onContentChange(htmlContent);
    },
  });

  return (
    <Box
      d="flex"
      flexDirection="column"
      maxH="500px"
      border="1px solid"
      borderColor="border.500"
      borderRadius="4px"
      mt="16px"
      overflow="hidden"
    >
      <MenuBar editor={editor} />

      <EditorContent className="text-editor-content" editor={editor} />
    </Box>
  );
};

export default TextEditor;
