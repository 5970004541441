import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Box, Text, Image, GridItem, AspectRatio } from "@chakra-ui/react";
import { IArticle } from "../types";

type Props = {
  article: IArticle;
};

const ArticlePreview = ({ article }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { guid, title, c_at } = article;

  return (
    <GridItem
      w="100%"
      cursor={"pointer"}
      onClick={() => navigate(`/ap/articles/${guid}`)}
    >
      <Box
        padding="16px"
        overflow="hidden"
        layerStyle="card"
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        _hover={{
          textDecoration: "none",
          boxShadow: "0 4px 8px 0 rgba(39,47,55,0.12)",
        }}
      >
        <AspectRatio
          ratio={{base: 16/9, md: 4/3}}
          width={{base: "100%", md: "200px"}}
          borderRadius="8px"
          overflow="hidden"
        >
          <Image
            src={article.main_image.url}
            alt="Blog image"
            objectFit="cover"
          />
        </AspectRatio>
        <Box mt={{base: "16px", md: "0"}} ml={{base: "0", md: "16px"}} flex="1">
          <Text
            fontWeight="700"
            fontSize="12px"
            letterSpacing="1px"
            mb="1"
            lineHeight="1"
          >
            {c_at.split("T")[0]}
          </Text>
          <h2>{title}</h2>

          <Text
            color="brand.500"
            mt="8px"
            fontWeight="600"
            display="inline-flex"
            textDecoration="underline"
          >
            {intl.formatMessage({ id: "article_read" })}
          </Text>
        </Box>
      </Box>
    </GridItem>
  );
};

export default ArticlePreview;
