import Layout from "../../shared/Layout";
import Navbar from "../../shared/Navbar";
import MainContainer from "../../shared/MainContainer";
import BottomNavigation from "../../shared/BottomNavigation";
import Step4 from "../Step4";

const OnboardingStep4 = () => {
  return (
    <Layout>
      <>
        <Navbar userType="registered_user" hideNavigation allowLogoutOnly />
        <MainContainer>
          <Step4 />
        </MainContainer>
        <BottomNavigation userType="registered_user" display="none" />
      </>
    </Layout>
  );
};

export default OnboardingStep4;
