import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Flex, IconButton, Tooltip } from "@chakra-ui/react";
import { ArrowLeft } from "@carbon/icons-react";
import AddArticleForm from "./AddArticleForm";

const AddArticle = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <>
      <Flex alignItems="center">
        <Tooltip
          label={intl.formatMessage({ id: "article_back_to_my_articles" })}
        >
          <IconButton
            bg="#fff"
            color="brandGray.500"
            border="1px solid var(--chakra-colors-border-500)"
            borderRadius="100%"
            mr="8px"
            w="44px"
            h="44px"
            minW="44px"
            minH="44px"
            position="relative"
            _hover={{
              color: "#fff",
              background: "brand.500",
              borderColor: "brand.500",
            }}
            icon={<ArrowLeft size={24} />}
            aria-label="înapoi"
            onClick={() => navigate(`/ap/articles`)}
          />
        </Tooltip>
        <h1>{intl.formatMessage({ id: "articles_add_title" })}</h1>
      </Flex>
      <AddArticleForm />
    </>
  );
};

export default AddArticle;
