import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Text } from "@chakra-ui/react";
import { useGetArticleQuery } from "../service";
import Layout from "../../shared/Layout";
import Navbar from "../../shared/Navbar";
import MainContainer from "../../shared/MainContainer";
import BottomNavigation from "../../shared/BottomNavigation";
import Article from "../ActivityProviderArticles/Article";
import Loader from "../../shared/Loader";

const ActivityProviderArticlePage = () => {
  const intl = useIntl();
  let { guid = "" } = useParams();

  const { data: article, isLoading } = useGetArticleQuery(guid);
  return (
    <Layout>
      <>
        <Navbar userType="activity_provider" />
        <MainContainer>
          <>
            {isLoading && <Loader />}
            {!isLoading && article && <Article article={article} />}
            {!isLoading && !article && (
              <Text> {intl.formatMessage({ id: "articles_no_data" })}</Text>
            )}
          </>
        </MainContainer>
        <BottomNavigation userType="activity_provider" />
      </>
    </Layout>
  );
};

export default ActivityProviderArticlePage;
