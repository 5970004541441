import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";
import Layout from "../layout";
import EnterForm from "../forms/EnterForm";
import SocialSignOn from "../SocialSignOn";
import { USER_HOME_PAGE } from "../../routes/defaults";
import { RootState } from "../../store";

const Enter = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const initial_submit = urlParams.get("submit") ? true : false;

  const accessToken = useSelector((state: RootState) => state.iam.access_token);

  useEffect(() => {
    if (accessToken) {
      navigate(USER_HOME_PAGE);
    }
  }, [accessToken, navigate]);

  return (
    <Layout userType="registered_user">
      <Box
        borderRadius="8px"
        w="100%"
        bg="#fff"
        border="1px"
        borderColor={{ base: "border.500", md: "transparent" }}
        p={{ base: "24px", md: "0" }}
      >
        <h1>{intl.formatMessage({ id: "auth_enter_user_title" })}</h1>
        <Text textStyle="largeP" mt="12px">
          {intl.formatMessage({ id: "auth_enter_user_subtitle" })}
        </Text>

        <EnterForm userType="registered_user" initial_email={email} initial_submit={initial_submit} />
        <SocialSignOn />
      </Box>
    </Layout>
  );
};

export default Enter;
