import { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Checkbox, Input, Stack, Text } from "@chakra-ui/react";

import YearSelector from "../../shared/YearSelector";
import psychologicalTraining from "../../activityProvider/psychologicalTraining";
import psychologicalMethods from "../../activityProvider/psychologicalMethods";
import { IActivityProvider } from "../../activityProvider/types";

type Props = {
  initialValues?: IActivityProvider;
  onStudiesChange: (fieldToChange: string, value: string) => void;
  onTrainingChange: (training: string[]) => void;
  onMethodsChange: (methods: string[]) => void;
};

const EducationForm = ({
  initialValues,
  onStudiesChange,
  onTrainingChange,
  onMethodsChange,
}: Props) => {
  const intl = useIntl();

  const [studies, setStudies] = useState<{
    graduationYear: string;
    bachelorDegree: string;
    masterDegree: string;
    drDegree: string;
  }>({
    graduationYear: initialValues?.graduation_year || "",
    bachelorDegree: initialValues?.bachelor_degree || "",
    masterDegree: initialValues?.master_degree || "",
    drDegree: initialValues?.dr_degree || "",
  });

  const [selectedPsychologicalTraining, setPsychologicalTraining] = useState<
    string[]
  >(
    initialValues?.psychological_training
      ? initialValues.psychological_training.split(",")
      : []
  );

  const [selectedPsychologicalMethods, setPsychologicalMethods] = useState<
    string[]
  >(
    initialValues?.psychological_methods
      ? initialValues.psychological_methods.split(",")
      : []
  );

  const handleSelectTraining = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedTraining = [];
    if (e.target.checked) {
      updatedTraining = [...selectedPsychologicalTraining, e.target.name];
    } else {
      updatedTraining = selectedPsychologicalTraining.filter(
        (training) => training !== e.target.name
      );
    }
    setPsychologicalTraining(updatedTraining);
    onTrainingChange(updatedTraining);
  };

  const handleSelectMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedMethods = [];
    if (e.target.checked) {
      updatedMethods = [...selectedPsychologicalMethods, e.target.name];
    } else {
      updatedMethods = selectedPsychologicalMethods.filter(
        (method) => method !== e.target.name
      );
    }
    setPsychologicalMethods(updatedMethods);
    onMethodsChange(updatedMethods);
  };

  return (
    <Box w="100%">
      <Box h="1px" mb="24px" bgColor="border.500"></Box>

      <Text
        mb="12px"
        fontWeight="600"
        color="brandGray.500"
        textStyle="largeP"
        textAlign="left"
      >
        {intl.formatMessage({
          id: "ap_onboarding_graduation_year",
        })}
      </Text>

      <YearSelector
        mb="24px"
        initialValue={studies.graduationYear}
        placeholder={intl.formatMessage({
          id: "ap_onboarding_graduation_year_placeholder",
        })}
        startYear={1933}
        onYearSelect={(year: string) => {
          setStudies({ ...studies, graduationYear: year });
          onStudiesChange("graduationYear", year);
        }}
      />

      <Box h="1px" mb="24px" bgColor="border.500"></Box>
      <Text
        mb="12px"
        fontWeight="600"
        color="brandGray.500"
        textStyle="largeP"
        textAlign="left"
      >
        {intl.formatMessage({ id: "ap_onboarding_bachelor_degree" })}
      </Text>

      <Input
        required
        mb="24px"
        size="lg"
        placeholder={intl.formatMessage({
          id: "ap_onboarding_bachelor_degree_placeholder",
        })}
        type="text"
        name="bachelorDegree"
        value={studies.bachelorDegree}
        onChange={(e) => {
          setStudies({ ...studies, bachelorDegree: e.target.value });
          onStudiesChange("bachelorDegree", e.target.value);
        }}
      />

      <Box h="1px" mb="24px" bgColor="border.500"></Box>
      <Text
        mb="12px"
        fontWeight="600"
        color="brandGray.500"
        textStyle="largeP"
        textAlign="left"
      >
        {intl.formatMessage({ id: "ap_onboarding_master_degree" })}
      </Text>

      <Input
        required
        mb="24px"
        size="lg"
        placeholder={intl.formatMessage({
          id: "ap_onboarding_master_degree_placeholder",
        })}
        type="text"
        name="masterDegree"
        value={studies.masterDegree}
        onChange={(e) => {
          setStudies({ ...studies, masterDegree: e.target.value });
          onStudiesChange("masterDegree", e.target.value);
        }}
      />

      <Box h="1px" mb="24px" bgColor="border.500"></Box>
      <Text
        mb="12px"
        fontWeight="600"
        color="brandGray.500"
        textStyle="largeP"
        textAlign="left"
      >
        {intl.formatMessage({ id: "ap_onboarding_dr_degree" })}
      </Text>

      <Input
        required
        mb="24px"
        size="lg"
        placeholder={intl.formatMessage({
          id: "ap_onboarding_dr_degree_placeholder",
        })}
        type="text"
        name="drDegree"
        value={studies.drDegree}
        onChange={(e) => {
          setStudies({ ...studies, drDegree: e.target.value });
          onStudiesChange("drDegree", e.target.value);
        }}
      />

      <Box h="1px" mb="24px" bgColor="border.500"></Box>
      <Text
        mb="12px"
        fontWeight="600"
        color="brandGray.500"
        textStyle="largeP"
        textAlign="left"
      >
        {intl.formatMessage({ id: "ap_onboarding_psychological_training" })}
      </Text>
      <Stack spacing="10px" mb="24px">
        {psychologicalTraining.map((training, idx) => (
          <Checkbox
            key={idx}
            py="8px"
            colorScheme="brand"
            name={training}
            onChange={handleSelectTraining}
            isChecked={selectedPsychologicalTraining.includes(training)}
          >
            {intl.formatMessage({ id: training })}
          </Checkbox>
        ))}
      </Stack>

      <Box h="1px" mb="24px" bgColor="border.500"></Box>
      <Text
        mb="12px"
        fontWeight="600"
        color="brandGray.500"
        textStyle="largeP"
        textAlign="left"
      >
        {intl.formatMessage({ id: "ap_onboarding_psychological_methods" })}
      </Text>
      <Stack spacing="10px" mb="24px">
        {psychologicalMethods.map((method, idx) => (
          <Checkbox
            key={idx}
            py="8px"
            colorScheme="brand"
            name={method}
            onChange={handleSelectMethod}
            isChecked={selectedPsychologicalMethods.includes(method)}
          >
            {intl.formatMessage({ id: method })}
          </Checkbox>
        ))}
      </Stack>
    </Box>
  );
};

export default EducationForm;
