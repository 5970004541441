import { useFetchMyBookingsQuery } from '../service';
import { IBooking, IInterval } from '../types';
import { useState } from 'react';
import { useEffect } from 'react';


export default function useGetMyActiveBookings({ start_datetime, end_datetime }: IInterval, all?: boolean) {
    const [filteredBookings, setBookings] = useState<IBooking[] | []>([])
    const { data, ...fetchParams } = useFetchMyBookingsQuery({ start_datetime, end_datetime });
    useEffect(() => {
        const bookings = data ? data.data : [];
        let tempBookings = bookings.filter(booking => booking.status !== 'failed')
        if (!all) {
            tempBookings = tempBookings.filter(booking => booking.status === 'active')
        }
        setBookings(tempBookings)
    }, [data, all])
    return { data: filteredBookings, ...fetchParams }
}
