import React from 'react';
import clsx from 'clsx';
import {
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { PhoneBlockFilled } from "@carbon/icons-react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';



import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: "#E65050",
      color: 'white',
      '&:hover': {
        background: '#E22D2E',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();

  return (
    <Tooltip placement='top' fontWeight="600" label="Incheie ședința">
      <IconButton
        w="54px"
        style={{ height: "54px" }}
        icon={<PhoneBlockFilled size={24}/>}
        aria-label="Incheie ședința"
        isRound
        onClick={() => room!.disconnect()}
        className={clsx(classes.button, props.className)}
        data-cy-disconnect
      />
    </Tooltip>
  );
}
