import moment from "moment";
import { useIntl } from "react-intl";
import { Box, Flex } from "@chakra-ui/react";
import { EventSchedule } from "@carbon/icons-react";
import SessionPreview from "./SessionPreview";
import Loader from "../../shared/Loader";
import useGetMyActiveBookings from "../hooks/useGetMyActiveBookings";
import { IBooking } from "../types";

const START_DATETIME = "2022-09-01T00:00:00";
const END_DATETIME = moment()
  .subtract(1, "hour")
  .endOf("hour")
  .format("YYYY-MM-DDTHH:mm:ss");

const PastSessions = () => {
  const intl = useIntl();

  const { data, isLoading } = useGetMyActiveBookings({
    start_datetime: START_DATETIME,
    end_datetime: END_DATETIME,
  });

  const bookings =
    data && data.length
      ? data.filter((booking) => booking.status === "active")
      : [];

  const sortedBookings = bookings.sort(
    (a: IBooking, b: IBooking) =>
      moment(b.start_time).valueOf() - moment(a.start_time).valueOf()
  );

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      {sortedBookings.length > 0 &&
        sortedBookings.map((booking, idx) => (
          <SessionPreview
            key={idx}
            booking={booking}
            start_datetime={START_DATETIME}
            end_datetime={END_DATETIME}
          />
        ))}
      {sortedBookings.length === 0 && (
        <>
          <Box layerStyle="card" mt="24px">
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              textAlign="center"
            >
              <Flex
                borderRadius="100%"
                alignItems="center"
                justifyContent="center"
                color="#fff"
                w="80px"
                h="80px"
                bg="gray.500"
              >
                <EventSchedule size={32}/>
              </Flex>
              <h2>
                {intl.formatMessage({ id: "booking_no_past_bookings_title" })}
              </h2>
            </Flex>
          </Box>
        </>
      )}
    </>
  );
};

export default PastSessions;
