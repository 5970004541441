import React from "react";
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useAuthenticateMutation } from "../auth/service";
import {
  useGetLoggedInUserQuery,
  useModifyUserMutation,
} from "../user/service";
import ChangePasswordForm from "../user/ChangePasswordForm";
import { useIntl } from "react-intl";
import PersonalInformationForm from "../user/PersonalInformationForm";
import InvoiceInformationForm from "./InvoiceInformationForm";
import { IRegisteredUser } from "../user/types";
import ActivityProviderServices from "./ActivityProviderServices";
import {
  useGetMyActivitiesQuery,
  useModifyActivityMutation,
} from "../activity/service";
import { IActivity } from "../activity/types";
import useNotification from "../shared/hooks/useNotification";
import ActivityProviderTraining from "./ActivityProviderTraining";
import {
  useGetActivityProviderQuery,
  useModifyActivityProviderMutation,
} from "./service";
import { IActivityProvider } from "./types";

type ChangePw = { pw: string; old_pw: string };
type ImageChanged = { image_changed?: boolean };

const MyAccount = () => {
  const intl = useIntl();
  const notify = useNotification();

  const { data: user } = useGetLoggedInUserQuery();

  const apGuid = user?.guid || "";
  const { data: activityProvider } = useGetActivityProviderQuery(apGuid, {
    skip: !apGuid,
  });
  const [modifyAP, { isLoading: updatingActivityProvider }] =
    useModifyActivityProviderMutation();
  const [modifyUser, { isLoading: updatingUser }] = useModifyUserMutation();
  const [authenticate, { isLoading: checkingPassword }] =
    useAuthenticateMutation();
  const { data: activity } = useGetMyActivitiesQuery();
  const [modifyActivity, { isLoading: updatingActivity }] =
    useModifyActivityMutation();

  const updateActivity = (payload: Partial<IActivity>) => {
    if (!activity) return;
    modifyActivity({
      activity: {
        ...payload,
        vimeo_url: payload.vimeo_url || null,
      },
      guid: activity.data[0].guid,
    }).then(
      () => {
        notify("success", "activity_successfully_updated");
      },
      () => {
        notify("error", "ap_onboarding_step1_error");
      }
    );
  };

  const changeActivityProvider = (activityProvider: IActivityProvider) => {
    if (!activityProvider) return;
    modifyAP({ guid: apGuid, activityProvider: activityProvider }).then(
      () => {
        notify("success", "therapy_service_success_message");
      },
      () => {
        notify("error", "ap_onboarding_step1_error");
      }
    );
  };

  const changePersonalInformation = (personal_info: {
    user: Partial<IRegisteredUser> & ImageChanged;
    guid: string;
  }) => {
    const { image_changed } = personal_info.user;
    delete personal_info.user["image_changed"];
    const pendingUpdates: any = [];
    let updatedActivity: Partial<IActivity> = {
      name: `${personal_info.user.f_name} ${personal_info.user.l_name}`,
    };
    if (image_changed) {
      const images: any = personal_info.user.profile_img;
      updatedActivity = { ...updatedActivity, images: [images] };
    }
    if (activity) {
      const pendingActivityUpdate = modifyActivity({
        activity: updatedActivity,
        guid: activity.data[0].guid,
      });
      pendingUpdates.push(pendingActivityUpdate);
    }

    const pendingUserUpdate = modifyUser(personal_info);
    pendingUpdates.push(pendingUserUpdate);
    Promise.all(pendingUpdates).then(
      () => {
        notify("success", "user_details_success_update");
      },
      () => {
        notify("error", "ap_onboarding_step1_error");
      }
    );
  };





  const changePassword = ({ pw, old_pw }: ChangePw): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const authCredentials = {
        password: old_pw,
        user_name: user ? user.u_name : "",
      };
      authenticate(authCredentials)
        .unwrap()
        .then(
          (response: any) => {
            if (response && response.access_token) {
              modifyUser({ user: { pw }, guid: user ? user.guid : "" }).finally(
                () => {
                  notify("success", "user_details_password_success_update");
                  resolve();
                }
              );
            } else {
              reject();
            }
          },
          (error) => {
            reject();
          }
        );
    });
  };

  if (!user || !activity || !activityProvider) return null;

  return (
    <Box>
      <>
        <h1>{intl.formatMessage({ id: "my_account_title" })}</h1>
        <Tabs colorScheme="brand" mt="16px">
          <TabList border="0">
            <Tab fontSize="13px" px="0" mr="24px">
              {intl.formatMessage({ id: "my_account_personal_info" })}
            </Tab>
            <Tab fontSize="13px" px="0" mr="24px">
              {intl.formatMessage({ id: "my_account_invoice_info" })}
            </Tab>
            <Tab fontSize="13px" px="0" mr="24px">
              {intl.formatMessage({ id: "my_account_services" })}
            </Tab>
            <Tab fontSize="13px" px="0" mr="24px">
              {intl.formatMessage({ id: "my_account_training" })}
            </Tab>
            <Tab fontSize="13px" px="0" mr="24px">
              {intl.formatMessage({ id: "my_account_personal_password" })}
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel px="0" py="0">
              <PersonalInformationForm
                user={user}
                updateUser={changePersonalInformation}
                updating={updatingUser}
                activityProvider
              />
            </TabPanel>
            <TabPanel px="0" py="0">
              <InvoiceInformationForm
                updateActivityProvider={changeActivityProvider}
                updating={updatingUser}
                activityProvider={activityProvider}
              />
            </TabPanel>
            <TabPanel px="0" py="0">
              <ActivityProviderServices
                activity={activity.data[0]}
                updating={updatingActivity}
                updateActivity={updateActivity}
              />
            </TabPanel>
            <TabPanel px="0" py="0">
              {activityProvider && (
                <ActivityProviderTraining
                  activityProvider={activityProvider}
                  updating={updatingActivityProvider}
                  updateActivityProvider={changeActivityProvider}
                />
              )}
            </TabPanel>
            <TabPanel px="0" py="0">
              <ChangePasswordForm
                changePassword={changePassword}
                updating={updatingUser || checkingPassword}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
    </Box>
  );
};

export default MyAccount;
