import { useIntl } from "react-intl";
import {
  InputGroup,
  Input,
  InputLeftElement,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Divider,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { Email } from "@carbon/icons-react";
import useNotification from "../../shared/hooks/useNotification";
import { Form, FormControl } from "../../shared/form";
import { initialChahgeEmailFormErrors, initialChangeEmailFormPayload } from "../../auth/defaults";
import React, { useState } from "react";
import { IAuthEmail, IChangeEmailFormErrors } from "../../auth/types";
import { validateForm } from "../../utils/forms";
import { changeEmailValidationRules } from "../../utils/forms/validators";
import { useChangeEmailMutation } from "../../user/service";

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
};

const ChangeEmailModal = ({ isOpen, onCloseModal }: Props) => {
  const intl = useIntl();
  const notify = useNotification();
  const [formValues, setFormValues] = useState<IAuthEmail>(
    initialChangeEmailFormPayload
  );
  const [formErrors, setFormErrors] = useState<IChangeEmailFormErrors>(
    initialChahgeEmailFormErrors
  );
  const [changeEmail, { isLoading }] = useChangeEmailMutation();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    const changeEmailData: IAuthEmail = { user_name };

    const validationErrors = validateForm(
      changeEmailData,
      changeEmailValidationRules
    );

    if (!validationErrors) {
      try {
        setFormErrors(initialChahgeEmailFormErrors);
        await changeEmail(changeEmailData).unwrap();
        notify("success", "Adresa de e-mail a fost schimbată cu succes. Vei primi pe aceasta un nou cod de activare.");
        setTimeout(() => {
          window.location.href = "/onboarding/step/1";
        }, 3000);
      } catch (error: any) {
        const errorMessage =
          "status" in error && error.status === 409
            ? "auth_register_409_status_error"
            : "auth_login_error";
        notify("error", errorMessage);
      }
    } else {
      setFormErrors({ ...initialChahgeEmailFormErrors, ...validationErrors });
    }

  };
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormErrors(initialChahgeEmailFormErrors);
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
  const { user_name } = formValues;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      isCentered={true}
      scrollBehavior="inside"
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottom="1px solid var(--chakra-colors-border-400)">
          <h2>Modifică e-mailul</h2>
        </ModalHeader>
        <ModalCloseButton w="44px" h="44px" />
        <ModalBody py="16px">
          <Alert
            mt="16px"
            status="info"
            fontSize="14px"
            lineHeight="normal"
            padding="8px 12px"
            borderRadius="8px"
            color="infoBlue.500"
            bg="infoBlue.50"
          >
            <AlertIcon color="infoBlue.500" mt="1px" />
            Introdu mai jos noua adresă de e-mail.
            Vei primi pe aceasta un nou cod de activare.
          </Alert>
          <Divider my="16px" opacity="1" />
          <Flex
            py="16px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Form onSubmit={handleLogin} w='80%'>
              <FormControl errorMessage={formErrors.user_name}>
                <InputGroup mb="12px">
                  <InputLeftElement
                    pointerEvents="none"
                    opacity=".54"
                    pt="3px"
                    px="8px"
                    h="100%"
                    children={<Email size={20} color="#696871" />}
                  />
                  <Input
                    placeholder={intl.formatMessage({ id: "auth_email" })}
                    type="email"
                    name="user_name"
                    value={user_name}
                    size="lg"
                    required
                    onChange={(e) => onInputChange(e)}
                  />
                </InputGroup>
              </FormControl>
              <Button w="full" size="lg" type="submit" isLoading={isLoading} mt="8px">
                Trimite
              </Button>
            </Form>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ChangeEmailModal;
