import { ArrowLeft, Edit } from "@carbon/icons-react";
import { Flex, IconButton, Badge, Image, Tooltip, Box, AspectRatio, } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import HtmlContent from "../../shared/HtmlContent";
import { IArticle } from "../types";
type Props = {
  article: IArticle;
};

const Article = ({ article }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <>
      <Flex alignItems="center" flexWrap="wrap">
        <Tooltip
          label={intl.formatMessage({ id: "article_back_to_my_articles" })}
        >
          <IconButton
            bg="#fff"
            color="brandGray.500"
            border="1px solid var(--chakra-colors-border-500)"
            borderRadius="100%"
            mr="8px"
            w="44px"
            h="44px"
            minW="44px"
            minH="44px"
            position="relative"
            aria-label="Inapoi"
            _hover={{
              color: "#fff",
              background: "brand.500",
              borderColor: "brand.500",
            }}
            icon={<ArrowLeft size={24} />}
            onClick={() => navigate(`/ap/articles`)}
          />
        </Tooltip>
        <Tooltip
          label={intl.formatMessage({ id: "article_edit_button_label" })}
        >
          <IconButton
            bg="#fff"
            color="brandGray.500"
            border="1px solid var(--chakra-colors-border-500)"
            borderRadius="100%"
            mr="8px"
            w="44px"
            h="44px"
            minW="44px"
            minH="44px"
            position="relative"
            aria-label="Editează"
            icon={<Edit size={24}/>}
            _hover={{
              color: "#fff",
              background: "brand.500",
              borderColor: "brand.500",
            }}
            onClick={() => navigate(`/ap/articles/${article.guid}/edit`)}
          />
        </Tooltip>
        <h1>{article.title}</h1>
      </Flex>

      <AspectRatio ratio={16 / 9}
        mt="16px"
      >
        <Image src={article.main_image.url} w="100%" borderRadius="8px" objectFit='cover'  />
      </AspectRatio>
      <Box mt={{base: "16px", lg: "-240px"}} p={{base: "16px", lg:"32px 64px"}} borderRadius="8px" bg="#fff" maxW={{base: "100%", lg: "800px"}} ml="auto" mr="auto" position="relative" zIndex="2" className="article-wrapper">
        <Badge colorScheme='purple' style={{marginBottom: "16px"}}>{article.category}</Badge>
        <HtmlContent content={article.content} />
      </Box>
    </>
  );
};

export default Article;
