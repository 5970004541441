import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import Layout from "../layout";
import { USER_HOME_PAGE, USER_REDIRECT_ENTER } from "../../routes/defaults";
import { RootState } from "../../store";
import Loader from "../../shared/Loader";

const SignUp = () => {
  const navigate = useNavigate();

  const accessToken = useSelector((state: RootState) => state.iam.access_token);

  useEffect(() => {
    if (accessToken) {
      navigate(USER_HOME_PAGE);
    }
    else {
      navigate(USER_REDIRECT_ENTER);
    }
  }, [accessToken, navigate]);

  return (
    <Layout userType="registered_user">
      <Box
        borderRadius="8px"
        w="100%"
        bg="#fff"
        border="1px"
        borderColor={{ base: "border.500", md: "transparent" }}
        p={{ base: "24px", md: "0" }}
      >
        <Loader />
        {/*
        <h1>{intl.formatMessage({ id: "auth_register_user_title" })}</h1>
        <Text textStyle="largeP" mt="12px">
          {intl.formatMessage({ id: "auth_register_user_subtitle" })}
        </Text>

        <RegisterForm />

        <SocialSignOn />
        <Text
          fontWeight="600"
          display="flex"
          alignItems="center"
          w="full"
          justifyContent="center"
        >
          <Text>
            {intl.formatMessage({ id: "auth_registered_user_message" })} &nbsp;
          </Text>
          <Button
            as={RouteLink}
            display="inline-flex"
            to="/auth/login"
            size="md"
            variant="link"
          >
            {intl.formatMessage({ id: "auth_login_incentive" })}
          </Button>
        </Text> */}
      </Box>
    </Layout>
  );
};

export default SignUp;
