import { createApi } from "@reduxjs/toolkit/query/react";
import {
  IRegisteredUser,
  IRegisterFormPayload,
  IResetPasswordPayload,
} from "./types";
import baseQueryWithReauthorization from "../store/baseQuery";
import { IAuthEmail, IAuthEmailChange } from "../auth/types";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithReauthorization,
  tagTypes: ["User"],
  endpoints: (builder) => ({
    registerUser: builder.mutation<IRegisteredUser, IRegisterFormPayload>({
      query: (payload: IRegisterFormPayload) => {
        return {
          url: "/user",
          method: "POST",
          headers: {},
          body: payload,
        };
      },
    }),
    changeEmail: builder.mutation<IAuthEmailChange, IAuthEmail>({
      query: (payload: IAuthEmail) => {
        return {
          url: "/user/change-email",
          method: "POST",
          body: payload,
        };
      },
    }),
    modifyUser: builder.mutation<
      IRegisteredUser,
      {
        user: Partial<IRegisteredUser>;
        guid: string;
      }
    >({
      query: ({ user, guid }) => {
        return {
          url: `/user/${guid}`,
          method: "PATCH",
          body: user,
        };
      },
      invalidatesTags: ["User"],
    }),
    getLoggedInUser: builder.query<IRegisteredUser, void>({
      query: () => {
        return {
          url: "/user/me",
        };
      },
      providesTags: ["User"],
    }),
    getUser: builder.query<IRegisteredUser, string>({
      query: (guid: string) => {
        return {
          url: `/user/${guid}`,
        };
      },
      providesTags: ["User"],
    }),
    resetPasswordRequest: builder.mutation<{ reset_request_sent: boolean }, string>({
      query: (email: string) => {
        return {
          url: "/user/reset-password",
          method: "POST",
          body: { u_name: email },
        };
      },
    }),
    activateUser: builder.mutation<IRegisteredUser, string>({
      query: (token: string) => {
        return {
          url: "/user/activate",
          method: "POST",
          body: { token }
        };
      },
    }),
    resendActivationCode: builder.mutation<{ "dispatched": true }, { channel: string }>({
      query: (payload: { channel: string }) => {
        return {
          url: "/user/resend-activation",
          method: "POST",
          body: payload
        };
      },
    }),
    resetPassword: builder.mutation<string, IResetPasswordPayload>({
      query: (payload: IResetPasswordPayload) => {
        return {
          url: "/user/reset-password",
          method: "PATCH",
          body: payload,
        };
      },
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useModifyUserMutation,
  useGetLoggedInUserQuery,
  useGetUserQuery,
  useResetPasswordRequestMutation,
  useResetPasswordMutation,
  useActivateUserMutation,
  useResendActivationCodeMutation,
  useChangeEmailMutation,
} = userApi;
