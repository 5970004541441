import { Select } from "@chakra-ui/react";
import { useIntl } from "react-intl";

type Props = {
  onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  value: string;
  [x: string]: any;
};

export default function EmailSubscriptionSelector({
  onChange,
  value,
  ...other
}: Props) {
  const intl = useIntl();

  return (
    <Select
      placeholder={intl.formatMessage({
        id: "my_account_unsubscribed_promo",
      })}
      {...other}
      w="full"
      size="lg"
      required
      name={"emailSubscription"}
      value={value}
      onChange={onChange}
    >
      <option value="false">
        {intl.formatMessage({
          id: "my_account_unsubscribed_active",
        })}
      </option>
      <option value="true">
        {intl.formatMessage({
          id: "my_account_unsubscribed_inactive",
        })}
      </option>
    </Select>
  );
}
