import { useParams } from "react-router-dom";
import Layout from "../../shared/Layout";
import Navbar from "../../shared/Navbar";
import ActivityDetails from "../activityDetails/ActivityDetails";
import BottomNavigation from "../../shared/BottomNavigation";
import NextBookingNotification from "../../shared/nextBookingNotification";

const ActivityDetailsPage = () => {
  let { guid = "" } = useParams();

  return (
    <Layout>
      <>
        <Navbar userType="registered_user" />
        <ActivityDetails guid={guid} />
        <NextBookingNotification userType="registered_user" />
        <BottomNavigation userType="registered_user" />
      </>
    </Layout>
  );
};

export default ActivityDetailsPage;
