import { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Stack, Button, Divider, Flex, Text } from "@chakra-ui/react";
import EducationForm from "../onboardingActivityProvider/Step2/EducationForm";
import { IActivityProvider } from "./types";
import useNotification from "../shared/hooks/useNotification";

type Props = {
  activityProvider: IActivityProvider;
  updating: boolean;
  updateActivityProvider: (activityProvider: IActivityProvider) => void;
};

const ActivityProviderTraining = ({
  activityProvider,
  updating,
  updateActivityProvider,
}: Props) => {
  const intl = useIntl();
  const notify = useNotification();

  const [studies, setStudies] = useState<{
    graduationYear: string;
    bachelorDegree: string;
    masterDegree: string;
    drDegree: string;
  }>({
    graduationYear: activityProvider.graduation_year || "",
    bachelorDegree: activityProvider.bachelor_degree || "",
    masterDegree: activityProvider.master_degree || "",
    drDegree: activityProvider.dr_degree || "",
  });

  const initialTrainingValue =
    activityProvider.psychological_training &&
    activityProvider.psychological_training.length
      ? activityProvider.psychological_training.split(",")
      : [];
  const initialMethodsValue =
    activityProvider.psychological_methods &&
    activityProvider.psychological_methods.length
      ? activityProvider.psychological_methods.split(",")
      : [];

  const [training, setTraining] = useState<string[]>(initialTrainingValue);
  const [methods, setMethods] = useState<string[]>(initialMethodsValue);

  const submit = () => {
    const { graduationYear, bachelorDegree, masterDegree, drDegree } = studies;
    if (
      !graduationYear ||
      !bachelorDegree ||
      !masterDegree ||
      !training.length ||
      !methods.length
    ) {
      notify("error", "therapy_service_mandatory_message");
      return;
    }

    const updatedActivityProvider: IActivityProvider = {
      ...activityProvider,
      graduation_year: graduationYear,
      bachelor_degree: bachelorDegree,
      master_degree: masterDegree,
      dr_degree: drDegree,
      psychological_training: training.join(","),
      psychological_methods: methods.join(","),
    };
    updateActivityProvider(updatedActivityProvider);
  };

  return (
    <Box layerStyle="card" mt="16px">
      <Stack spacing="5">
        <Stack
          spacing="4"
          direction={{ base: "column", sm: "row" }}
          justify="space-between"
        >
          <Box>
            <Text fontSize="lg" fontWeight="bold" color="brandGray.500">
              {intl.formatMessage({ id: "my_account_training_title" })}
            </Text>
            <Text color="muted" fontSize="sm">
              {intl.formatMessage({ id: "my_account_training_subtitle" })}
            </Text>
          </Box>
          <Button alignSelf="start" isLoading={updating} onClick={submit}>
            {intl.formatMessage({ id: "save_btn" })}
          </Button>
        </Stack>
        <Divider />
        <Box className="my-account-education-form">
          <EducationForm
            initialValues={activityProvider}
            onStudiesChange={(field, value) =>
              setStudies({ ...studies, [field]: value })
            }
            onTrainingChange={(training) => setTraining(training)}
            onMethodsChange={(methods) => setMethods(methods)}
          />
        </Box>
        <Divider />
        <Stack spacing="5">
          <Flex direction="row-reverse">
            <Button onClick={submit} isLoading={updating}>
              {intl.formatMessage({ id: "save_btn" })}
            </Button>
          </Flex>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ActivityProviderTraining;
