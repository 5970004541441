import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauthorization from "../store/baseQuery";
import { IGlobalVoucherRequest, IGlobalVoucherResponse } from "./types";

export const vouchersApi = createApi({
  reducerPath: "vouchersApi",
  baseQuery: baseQueryWithReauthorization,
  tagTypes: ["Vouchers"],
  endpoints: (builder) => ({
    validateVoucher: builder.query<IGlobalVoucherResponse, IGlobalVoucherRequest>({
      query: (guid) => {
        return {
          url: '/vouchers/validate-global',
          method: "POST",
          body: {
            voucher_code: guid.voucher_code,
            activity_guid: guid.activity_guid,
            price: guid.price,
          },
        };
      },
      providesTags: ["Vouchers"],
    }),
  }),
});

export const {
  useValidateVoucherQuery,
} = vouchersApi;
