import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Flex, Text, IconButton, Tooltip } from "@chakra-ui/react";
import { ArrowLeft } from "@carbon/icons-react";
import { useGetArticleQuery } from "../service";
import EditArticleForm from "./EditArticleForm";
import Loader from "../../shared/Loader";

const EditArticle = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  let { guid = "" } = useParams();
  const { data: article, isLoading } = useGetArticleQuery(guid);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && article && (
        <>
          <Flex alignItems="center">
            <Tooltip
              label={intl.formatMessage({ id: "article_back_to_my_article" })}
            >
              <IconButton
                bg="#fff"
                color="brandGray.500"
                border="1px solid var(--chakra-colors-border-500)"
                borderRadius="100%"
                mr="8px"
                w="44px"
                h="44px"
                minW="44px"
                minH="44px"
                position="relative"
                aria-label="Inapoi"
                _hover={{
                  color: "#fff",
                  background: "brand.500",
                  borderColor: "brand.500",
                }}
                icon={<ArrowLeft size={24} />}
                onClick={() => navigate(`/ap/articles/${article.guid}`)}
              />
            </Tooltip>
            <h1>{intl.formatMessage({ id: "articles_edit_title" })}</h1>
          </Flex>
          <EditArticleForm article={article} />
        </>
      )}
      {!isLoading && !article && (
        <Text> {intl.formatMessage({ id: "articles_no_data" })}</Text>
      )}
    </>
  );
};

export default EditArticle;
