import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { Form, FormControl } from "../shared/form";
import { useIntl } from "react-intl";
import { IProfile, IRegisteredUser } from "./types";
import { validateForm } from "../utils/forms";
import { updateUserAccountValidationRules } from "../utils/forms/validators";
import GenderSelector from "../shared/GenderSelector";
import GenderTherapistSelector from "../shared/GenderTherapistSelector";
import ImageUploader from "../mediaAssets/ImageUploader";
import { IMediaAsset } from "../mediaAssets/types";
import EmailSubscriptionSelector from "../shared/EmailSubscriptionSelector";

type Props = {
  user: IRegisteredUser;
  updateUser: (params: {
    user: Partial<IRegisteredUser>;
    guid: string;
  }) => void;
  updating: boolean;
  activityProvider?: boolean;
  preferences?: boolean;
};

const defaultFormErrors = {
  f_name: null,
  l_name: null,
  email: null,
  birthdate: null,
  mobile_phone_number: null,
};

const getPreferredActivityProviders = (profile: IProfile | null) => {
  if (!profile || !profile.preferred_activity_providers) return "";
  return profile.preferred_activity_providers[0];
};

const PersonalInformationForm = ({
  user,
  updateUser,
  updating,
  activityProvider,
  preferences,
}: Props) => {
  const intl = useIntl();
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [formValues, setFormValues] = useState<any>({
    f_name: "",
    l_name: "",
    email: "",
    birthdate: "",
    gender: "",
    preferred_activity_provider: "",
    unsub: "false",
    profile_img: null,
    mobile_phone_number: "",
  });

  useEffect(() => {
    const userDetails = {
      f_name: user.f_name,
      l_name: user.l_name,
      email: user.email,
      birthdate: user.birthdate,
      gender: user.gender,
      preferred_activity_provider: getPreferredActivityProviders(user.profile),
      unsub: user.unsub === true ? "true" : "false",
      profile_img: user.profile_img ? user.profile_img : null,
      mobile_phone_number: user.mobile_phone_number.replace("+40", ""),
      image_changed: false, //use this flag to know if activity image has to be updated
    };
    setFormValues(userDetails);
  }, [user]);

  const {
    f_name,
    l_name,
    email,
    birthdate,
    gender,
    preferred_activity_provider,
    unsub,
    profile_img,
  } = formValues;

  console.error('unsub', unsub)

  const onInputChange = (e: React.ChangeEvent<any>, targetName: string) => {
    setFormErrors(defaultFormErrors);
    let targetValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (targetName === "mobile_phone_number") {
      if (targetValue.length > 1 && targetValue[0] === "0") {
        targetValue = targetValue.replace("0", "");
      }
    }
    setFormValues({ ...formValues, [targetName]: targetValue });
  };

  const submit = (e: any) => {
    e.preventDefault();
    const validationErrors: any = validateForm(
      formValues,
      updateUserAccountValidationRules
    );
    if (!validationErrors) {
      const profile = {
        ...user.profile,
        preferred_activity_providers:
          formValues.preferred_activity_provider &&
            formValues.preferred_activity_provider !== "no_preference"
            ? [formValues.preferred_activity_provider]
            : [],
      };
      const userDetails = { ...formValues };
      userDetails["profile"] = profile;
      userDetails["unsub"] = formValues.unsub === "true" ? true : false;
      userDetails["mobile_phone_number"] = "+40".concat(
        userDetails["mobile_phone_number"]
      );
      delete userDetails["preferred_activity_provider"];
      updateUser({ user: userDetails, guid: user ? user.guid : "" });
    } else {
      setFormErrors({ ...defaultFormErrors, ...validationErrors });
    }
  };

  const onImageUpload = (data: IMediaAsset[]) => {
    setFormValues({ ...formValues, profile_img: data[0], image_changed: true });
  };

  return (
    <Form onSubmit={submit}>
      <Box layerStyle="card" mt="16px">
        <Stack spacing="5">
          <Stack
            spacing="4"
            direction={{ base: "column", sm: "row" }}
            justify="space-between"
          >
            <Box>
              <Text fontSize="lg" fontWeight="bold" color="brandGray.500">
                {intl.formatMessage({ id: "my_account_personal_info" })}
              </Text>
              <Text color="muted" fontSize="sm">
                {intl.formatMessage({
                  id: activityProvider
                    ? "my_account_ap_personal_info_plus"
                    : "my_account_personal_info_plus",
                })}
              </Text>
            </Box>
            <Button alignSelf="start" type="submit" isLoading={updating}>
              {intl.formatMessage({ id: "save_btn" })}
            </Button>
          </Stack>
          <Divider />
          <Stack spacing="5" divider={<StackDivider />}>
            {!preferences && (
              <FormControl errorMessage={formErrors.f_name}>
                <Stack
                  direction={{ base: "column", lg: "row" }}
                  spacing={{ base: "1.5", lg: "8" }}
                  justify="space-between"
                >
                  <FormLabel
                    variant="inline"
                    fontWeight="600"
                    color="brandGray.500"
                  >
                    {" "}
                    {intl.formatMessage({ id: "auth_first_name" })}
                  </FormLabel>
                  <Input
                    maxW={{ lg: "3xl" }}
                    value={f_name}
                    onChange={(e) => onInputChange(e, "f_name")}
                    size="lg"
                  />
                </Stack>
              </FormControl>
            )}
            {activityProvider && (
              <FormControl errorMessage={formErrors.l_name}>
                <Stack
                  direction={{ base: "column", lg: "row" }}
                  spacing={{ base: "1.5", lg: "8" }}
                  justify="space-between"
                >
                  <FormLabel
                    variant="inline"
                    fontWeight="600"
                    color="brandGray.500"
                  >
                    {" "}
                    {intl.formatMessage({ id: "ap_onboarding_step1_name" })}
                  </FormLabel>
                  <Input
                    maxW={{ lg: "3xl" }}
                    value={l_name}
                    onChange={(e) => onInputChange(e, "l_name")}
                    size="lg"
                  />
                </Stack>
              </FormControl>
            )}
            {!preferences && (
              <FormControl errorMessage={formErrors.email}>
                <Stack
                  direction={{ base: "column", lg: "row" }}
                  spacing={{ base: "1.5", lg: "8" }}
                  justify="space-between"
                >
                  <FormLabel
                    variant="inline"
                    fontWeight="600"
                    color="brandGray.500"
                  >
                    {intl.formatMessage({ id: "auth_email" })}
                  </FormLabel>
                  <Input
                    type="email"
                    maxW={{ lg: "3xl" }}
                    value={email}
                    onChange={(e) => onInputChange(e, "email")}
                    size="lg"
                    disabled
                  />
                </Stack>
              </FormControl>
            )}
            {/* {!preferences && (
              <FormControl errorMessage={formErrors.mobile_phone_number}>
                <Stack
                  direction={{ base: "column", lg: "row" }}
                  spacing={{ base: "1.5", lg: "8" }}
                  justify="space-between"
                >
                  <FormLabel
                    variant="inline"
                    fontWeight="600"
                    color="brandGray.500"
                  >
                    {intl.formatMessage({ id: "mobile_phone_number" })}
                  </FormLabel>
                  <InputGroup maxW={{ lg: "3xl" }}>
                    <InputLeftAddon children="+40" h="54px" />
                    <Input
                      type="tel"
                      size="lg"
                      borderRadius="0 8px 8px 0"
                      value={mobile_phone_number}
                      onChange={(e) => onInputChange(e, "mobile_phone_number")}
                      placeholder={intl.formatMessage({
                        id: "mobile_phone_number_placeholder",
                      })}
                      required
                    />
                  </InputGroup>
                </Stack>
              </FormControl>
            )} */}
            {activityProvider && (
              <FormControl errorMessage={null}>
                <Stack
                  direction={{ base: "column", lg: "row" }}
                  spacing={{ base: "1.5", lg: "8" }}
                  justify="space-between"
                >
                  <Box>
                    <FormLabel
                      variant="inline"
                      fontWeight="600"
                      color="brandGray.500"
                    >
                      {intl.formatMessage({ id: "avatar" })}
                    </FormLabel>
                    <FormHelperText mt="0" color="muted">
                      {intl.formatMessage({ id: "click_on_avatar" })}
                    </FormHelperText>
                  </Box>
                  <Stack
                    spacing={{ base: "3", lg: "5" }}
                    direction={{ base: "column", sm: "row" }}
                    width="full"
                    maxW={{ lg: "3xl" }}
                  >
                    <Avatar
                      w="100px"
                      h="100px"
                      name={f_name}
                      src={profile_img ? profile_img.imgix_url : null}
                    >
                      <ImageUploader onImageUpload={onImageUpload} />
                    </Avatar>
                  </Stack>
                </Stack>
              </FormControl>
            )}
            <FormControl errorMessage={formErrors.birthdate}>
              <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={{ base: "1.5", lg: "8" }}
                justify="space-between"
              >
                <FormLabel
                  variant="inline"
                  fontWeight="600"
                  color="brandGray.500"
                >
                  {intl.formatMessage({
                    id: "user_onboarding_step3_birth_year",
                  })}
                </FormLabel>
                <Input
                  type="text"
                  maxW={{ lg: "3xl" }}
                  value={birthdate ? birthdate : ""}
                  onChange={(e) => onInputChange(e, "birthdate")}
                  size="lg"
                />
              </Stack>
            </FormControl>
            <FormControl errorMessage={null}>
              <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={{ base: "1.5", lg: "8" }}
                justify="space-between"
              >
                <FormLabel
                  variant="inline"
                  fontWeight="600"
                  color="brandGray.500"
                >
                  {intl.formatMessage({
                    id: "onboarding_gender_selector_placeholder",
                  })}
                </FormLabel>
                <GenderSelector
                  maxW={{ lg: "3xl" }}
                  onChange={(e) => onInputChange(e, "gender")}
                  value={gender}
                />
              </Stack>
            </FormControl>
            {!activityProvider && (
              <FormControl errorMessage={null}>
                <Stack
                  direction={{ base: "column", lg: "row" }}
                  spacing={{ base: "1.5", lg: "8" }}
                  justify="space-between"
                >
                  <FormLabel
                    variant="inline"
                    fontWeight="600"
                    color="brandGray.500"
                  >
                    {intl.formatMessage({
                      id: "user_onboarding_step3_therapist_preferences",
                    })}
                  </FormLabel>
                  <GenderTherapistSelector
                    maxW={{ lg: "3xl" }}
                    onChange={(e) =>
                      onInputChange(e, "preferred_activity_provider")
                    }
                    value={preferred_activity_provider}
                  />
                </Stack>
              </FormControl>
            )}
            {!activityProvider && (
              <FormControl errorMessage={null}>
                <Stack
                  direction={{ base: "column", lg: "row" }}
                  spacing={{ base: "1.5", lg: "8" }}
                  justify="space-between"
                >
                  <FormLabel
                    variant="inline"
                    fontWeight="600"
                    color="brandGray.500"
                  >
                    {intl.formatMessage({
                      id: "my_account_unsubscribed_promo",
                    })}
                  </FormLabel>
                  <EmailSubscriptionSelector
                    maxW={{ lg: "3xl" }}
                    onChange={(e) =>
                      onInputChange(e, "unsub")
                    }
                    value={unsub}
                  />
                </Stack>
              </FormControl>
            )}

            <Flex direction="row-reverse">
              <Button type="submit" isLoading={updating}>
                {intl.formatMessage({ id: "save_btn" })}
              </Button>
            </Flex>
          </Stack>
        </Stack>
      </Box>
    </Form>
  );
};

export default PersonalInformationForm;
