import React, { useEffect, useState } from "react";
import { Box, Select, Input, Button } from "@chakra-ui/react";
import { IMediaAsset } from "../../mediaAssets/types";
import { usePostArticleMutation, useEditArticleMutation } from "../service";
import { useNavigate } from "react-router-dom";
import { categories } from "../defaults";
import { useIntl } from "react-intl";
import TextEditor from "../../shared/textEditor/TextEditor";
import ArticleImageUploader from "../../mediaAssets/ArticleImageUploader";
import { validateForm } from "../../utils/forms";
import { articleValidationRules } from "../../utils/forms/validators";
import { Form, FormControl } from "../../shared/form";

const initialArticle = {
  title: "",
  main_image: {
    url: "",
    file_name: "",
    imgix_url: "",
  },
  content: "",
  tags: [],
  category: "",
};

const defaultFormErrors = {
  content: null,
};

const AddArticleForm = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [article, setArticle] = useState<typeof initialArticle>(initialArticle);
  const [content, setContent] = useState<string>("");

  const [postArticle, { isLoading }] = usePostArticleMutation();
  const [editArticle] = useEditArticleMutation();

  const handleChangeArticle = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setArticle({
      ...article,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setFormErrors(defaultFormErrors);
  }, [article, content]);

  const handleContentChange = (htmlContent: string) => {
    setContent(htmlContent);
  };

  const handleImageUpload = (mediaAssets: IMediaAsset[]) => {
    setArticle({ ...article, main_image: mediaAssets[0] });
  };

  const handlePostArticle = (e: React.FormEvent) => {
    e.preventDefault();
    const formValues = {
      title: article.title,
      content,
      main_image: article.main_image.url,
    };
    const validationErrors = validateForm(
      { content: formValues.content },
      articleValidationRules
    );
    if (!validationErrors) {
      postArticle({ ...article, content: content })
        .then(async (response) => {
          const article = "data" in response ? response.data : null;
          if (article) {
            await editArticle({ guid: article.guid, status: "published" });
            navigate("/ap/articles");
          }
        })
        .catch((error) => console.log("Error", error));
    } else {
      setFormErrors({ ...defaultFormErrors, ...validationErrors });
    }
  };

  return (
    <Box>
      <Form onSubmit={handlePostArticle}>
        <Select
          mt="16px"
          name="category"
          placeholder={intl.formatMessage({
            id: "article_categories_placeholder",
          })}
          required
          value={article.category}
          onChange={handleChangeArticle}
        >
          {categories.map((category, idx) => (
            <option value={category} key={idx}>
              {category}
            </option>
          ))}
        </Select>

        <Input
          mt="16px"
          name="title"
          placeholder={intl.formatMessage({
            id: "article_title_placeholder",
          })}
          required
          value={article.title}
          onChange={handleChangeArticle}
        />

        <ArticleImageUploader
          onImageUpload={handleImageUpload}
          isRequired={true}
        />

        <FormControl errorMessage={formErrors.content}>
          <TextEditor content={content} onContentChange={handleContentChange} />
        </FormControl>
        <Button
          w="full"
          maxW={{ base: "100%", md: "310px" }}
          m="auto"
          size="lg"
          mt="16px"
          type="submit"
          isLoading={isLoading}
        >
          {intl.formatMessage({ id: "article_add_button_label" })}
        </Button>
      </Form>
    </Box>
  );
};

export default AddArticleForm;
