import { useState } from "react";
import { useIntl } from "react-intl";
import { useToast } from "@chakra-ui/toast";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Alert,
} from "@chakra-ui/react";
import { Information } from "@carbon/icons-react";
import { useAppDispatch } from "../../hooks";
import { useModifyActivityMutation } from "../../activity/service";
import { activityScheduleApi } from "../../schedule/service";
import { Form } from "../../shared/form";
import Schedule from "../../shared/schedule/Schedule";
import { DAYS } from "../../shared/schedule/options";
import { IActivity, IRecurringSchedule } from "../../activity/types";
import { ISchedule } from "../../shared/schedule/types";

const getRecurringSchedule = (schedule: ISchedule): IRecurringSchedule => {
  let recurringSchedule: IRecurringSchedule = [];
  schedule.forEach((scheduleItem) => {
    const { start_time, end_time } = scheduleItem;
    const recurringScheduleItem =
      start_time && end_time ? [[start_time, end_time]] : [];
    recurringSchedule.push(recurringScheduleItem);
  });

  return recurringSchedule;
};

const getScheduleValue = (
  recurringSchedule: IRecurringSchedule,
  days: string[]
): ISchedule => {
  const schedule: ISchedule = [];

  recurringSchedule.forEach((scheduleItem, idx) => {
    schedule.push({
      day: days[idx],
      start_time: scheduleItem[0] ? scheduleItem[0][0] : "",
      end_time: scheduleItem[0] ? scheduleItem[0][1] : "",
    });
  });

  return schedule;
};

type Props = {
  isVisible: boolean;
  onModalClose: () => void;
  activity: IActivity;
};

const ScheduleModal = ({ isVisible, onModalClose, activity }: Props) => {
  const intl = useIntl();
  const toast = useToast();
  const dispatch = useAppDispatch();

  const [schedule, setSchedule] = useState<IRecurringSchedule>(
    activity.recurring_schedule
  );

  const [modifyActivity, { isLoading: isModifyingActivity }] =
    useModifyActivityMutation();

  const handleScheduleChange = (schedule: ISchedule) => {
    const recurringSchedule = getRecurringSchedule(schedule);
    setSchedule(recurringSchedule);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { guid, ...payload } = activity;

    modifyActivity({
      guid: guid,
      activity: { ...payload, recurring_schedule: schedule },
    })
      .unwrap()
      .then((response) => {
        dispatch(activityScheduleApi.util.invalidateTags(["ActivitySchedule"]));
        onModalClose();
      })
      .catch(() => {
        toast({
          title: intl.formatMessage({ id: "booking_ap_edit_schedule_error" }),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal isOpen={isVisible} onClose={onModalClose} isCentered size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottom="1px solid var(--chakra-colors-border-400)">
          <h2>{intl.formatMessage({ id: "booking_ap_edit_schedule" })}</h2>
          {activity.booking_buffer_minutes &&
            <Alert
              status="info"
              padding="12px"
              fontSize="14px"
              lineHeight="normal"
              borderRadius="8px"
              justifyContent="center"
              mt="16px"
              bg="#E2F3FD"
              color="#2D3748"
            >
              <Information size={16} style={{ marginRight: "4px" }} />
              {intl.formatMessage({ id: "booking_ap_edit_schedule_min_buffer" }, { minute: activity.booking_buffer_minutes })}
            </Alert>}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            mt="16px"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Form onSubmit={handleSubmit}>
              <Schedule
                onScheduleChange={handleScheduleChange}
                value={getScheduleValue(schedule, DAYS)}
              />
              <Button
                w="100%"
                my="16px"
                size="lg"
                type="submit"
                isLoading={isModifyingActivity}
              >
                {intl.formatMessage({ id: "ap_save_schedule" })}
              </Button>
            </Form>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ScheduleModal;
