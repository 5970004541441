import moment from "moment";
import { EventInput } from "@fullcalendar/react";
import { IActivitySchedule } from "../../schedule/types";
import { IBooking } from "../types";
import { generateHourlySchedule, generateSchedule } from "../utils";
import { MIN_BOOKING_TIME_IN_ADVANCE } from "../../config";

const generateEvents = (
  startDate: string,
  endDate: string,
  schedule: IActivitySchedule,
  bookings: IBooking[],
  minBookingTimeInAdvance: number = MIN_BOOKING_TIME_IN_ADVANCE * 60
) => {
  let events: EventInput[] = [];

  const weekSchedule = generateSchedule(startDate, endDate);

  weekSchedule.forEach((scheduleItem) => {
    events.push({
      groupId: "unavailable",
      className: ["ap-unavailable"],
      start: scheduleItem.start,
      end: scheduleItem.end,
      backgroundColor: "#eeeeee",
      borderColor: "#eeeeee",
      extendedProps: {
        start: scheduleItem.start,
        end: scheduleItem.end,
      },
    });
  });

  const hourlySchedule = generateHourlySchedule(schedule, minBookingTimeInAdvance);
  const availabilities = hourlySchedule.filter((scheduleItem) =>
    moment().isBefore(moment(scheduleItem.start))
  );

  events.forEach((event, idx) => {
    availabilities.forEach(({ start, end }) => {
      if (start === event.start && end === event.end) {
        events[idx] = {
          groupId: "available",
          className: ["ap-available"],
          start: start,
          end: end,
          backgroundColor: "white",
          borderColor: "#eeeeee",
          textColor: "grey",
          extendedProps: {
            label: `${moment(start).format("HH:mm")}-${moment(end).format(
              "HH:mm"
            )}`,
            start: start,
            end: end,
          },
        };
      }
    });
  });

  events.forEach((event, idx) => {
    bookings.forEach((booking) => {
      const { start_time, end_time, status } = booking;
      if (start_time === event.start && end_time === event.end) {
        events[idx] = {
          groupId: "booking",
          className:
            status === "pending_payment"
              ? ["ap-booking", "unpaid"]
              : ["ap-booking"],
          start: start_time,
          end: end_time,
          extendedProps: { booking },
        };
      }
    });
  });

  return events;
};

const getLoadingEvent = (startDate: string, endDate: string) => [
  {
    groupId: "loading-state",
    className: ["loading-state"],
    start: startDate,
    end: endDate,
    backgroundColor: "white",
    borderColor: "white",
  },
];

export { getLoadingEvent, generateEvents };
