import { OverflowMenuVertical, Edit, Pin, TrashCan, SubtractAlt } from "@carbon/icons-react";
import { Menu, MenuButton, IconButton, MenuList, MenuItem, MenuDivider, Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";

type Props = {
    editNote: () => void,
    removeNote: () => void,
    makeImportant: (isImportant: boolean) => void,
    featured?: boolean
}
const NoteMenu = ({ editNote, removeNote, makeImportant, featured }: Props) => {
    const intl = useIntl();
    return (
        <Menu placement="bottom-end">
            <MenuButton
                as={IconButton}
                icon={<OverflowMenuVertical size={20}/>}
                variant="ghost"
                minW="auto"
                minH="auto"
                p="0"
                w="32px"
                mr="-12px"
                color="defaultGray.500"
                _hover={{
                    bg: "transparent",
                    color: "brand.500",
                }}
                _active={{
                    bg: "transparent",
                    color: "brand.500",
                }}
            />
            <MenuList color="brandGray.500">
                <MenuItem onClick={editNote}>
                    <Edit size={20}opacity=".7" /> <Text ml="8px">{intl.formatMessage({ id: "edit_note" })}</Text>
                </MenuItem>
                <MenuDivider margin="0" />
                {!featured && <MenuItem onClick={() => makeImportant(true)}>
                    <Pin size={20}opacity=".7" />
                    <Text ml="8px">{intl.formatMessage({ id: "add_to_important" })}</Text>
                </MenuItem>
                }
                {featured && <MenuItem onClick={() => makeImportant(false)} >
                    <SubtractAlt size={20}opacity=".7" />
                    <Text ml="8px">{intl.formatMessage({ id: "remove_from_important" })}</Text>
                </MenuItem>
                }
                <MenuDivider margin="0" />
                <MenuItem onClick={removeNote} >
                    <TrashCan size={20}opacity=".7" />
                    <Text ml="8px">{intl.formatMessage({ id: "remove_note" })}</Text>
                </MenuItem>
            </MenuList>
        </Menu>
    )
}

export default NoteMenu;