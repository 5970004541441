import {
  Flex,
  Box,
  SimpleGrid,
  Divider,
  Stat,
  StatLabel,
  StatNumber,
  // StatArrow,
  Show,
  Hide,
  Tooltip,
} from "@chakra-ui/react";
import { HelpFilled } from "@carbon/icons-react";
import { useIntl } from "react-intl";
import { useFetchPaymentBalanceQuery } from "../../payments/service";
import { useEffect, useState } from 'react';

const BookingsStats = () => {
  const intl = useIntl();
  const [balance, setBalance] = useState({ available: 0, pending: 0, available_currency: 'RON', pending_currency: 'RON' })
  const [isLabelOpen, setIsLabelOpen] = useState(false)
  const { data, isLoading } = useFetchPaymentBalanceQuery()
  useEffect(() => {
    if (data) {
      const balance_status = {
        available: data.available.amount_in_cents / 100,
        available_currency: data.available.currency.toUpperCase(),
        pending: data.pending.amount_in_cents / 100,
        pending_currency: data.pending.currency.toUpperCase()
      }
      setBalance(balance_status)
    }
  }, [data])

  if (isLoading) return null
  return (
    <Box
      layerStyle="card"
      p="0"
      _hover={{ boxShadow: "0 8px 16px 0 rgba(39,47,55,0.12)" }}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }}>
        {/* <Flex
          px="24px"
          py="16px"
          position="relative"
          alignItems="center"
          justifyContent="center"
        >
          <Stat>
            <StatLabel mb="8px">Săptămâna aceasta</StatLabel>
            <StatNumber color="brandGray.500">
              RON 323.45 <StatArrow type="decrease" />
            </StatNumber>
          </Stat>
          <Show above="md">
            <Divider
              orientation="vertical"
              borderColor="border.500"
              position="absolute"
              top="0"
              bottom="0"
              right="0"
              h="100%"
            />
          </Show>
          <Hide above="lg">
            <Divider
              borderColor="border.500"
              position="absolute"
              bottom="0"
              right="0"
              left="0"
            />
          </Hide>
        </Flex> */}
        <Flex
          px="24px"
          py="16px"
          position="relative"
          alignItems="center"
          justifyContent="center"
        >
          <Stat>
            <StatLabel mb="8px">În așteptare</StatLabel>
            <StatNumber color="brandGray.500">
              {balance.pending_currency} {balance.pending}
              {/* <StatArrow type="increase" /> */}
            </StatNumber>
          </Stat>
          <Show above="md">
            <Divider
              orientation="vertical"
              borderColor="border.500"
              position="absolute"
              top="0"
              bottom="0"
              right="0"
              h="100%"
            />
          </Show>
          <Hide above="md">
            <Divider
              borderColor="border.500"
              position="absolute"
              bottom="0"
              right="0"
              left="0"
            />
          </Hide>
        </Flex>
        <Flex
          px="24px"
          py="16px"
          position="relative"
          alignItems="center"
          justifyContent="center"
        >
          <Stat>
            <StatLabel mb="8px">Total disponibil</StatLabel>
            <StatNumber color="brandGray.500">
              <Tooltip isOpen={isLabelOpen} aria-label={intl.formatMessage({ id: "payout_message" })} label={intl.formatMessage({ id: "payout_message" })} fontWeight="600" placement="bottom-start" maxW="200px">
                <Flex onMouseEnter={() => setIsLabelOpen(true)} onMouseLeave={() => setIsLabelOpen(false)} onClick={() => setIsLabelOpen(true)} alignItems="center" cursor="pointer">
                  {balance.available_currency} {balance.available}
                  <HelpFilled size={20}opacity=".48" style={{ margin: "5px 0 0 6px" }} />
                  {/* <StatArrow type="increase" /> */}
                </Flex>
              </Tooltip>
            </StatNumber>
          </Stat>
          {/* <Show above="md">
            <Divider
              orientation="vertical"
              borderColor="border.500"
              position="absolute"
              top="0"
              bottom="0"
              right="0"
              h="100%"
            />
          </Show>
          <Hide above="lg">
            <Divider
              borderColor="border.500"
              position="absolute"
              bottom="0"
              right="0"
              left="0"
            />
          </Hide> */}
        </Flex>

        {/* <Flex
          px="24px"
          py="16px"
          position="relative"
          alignItems="center"
          justifyContent="center"
        > */}
        {/* <Button w="full" variant="outline" size="lg">
            Transferă RON 1323.45
          </Button> */}
        {/* {intl.formatMessage({ id: "payout_message" })}
        </Flex> */}
      </SimpleGrid>
    </Box>
  );
};

export default BookingsStats;
