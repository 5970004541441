export const translations = {
  or: "sau",
  password_weak: "Slabă",
  password_okay: "Medie",
  password_good: "Bună",
  password_strong: "Puternică",
  auth_age_check: "Confirm ca am peste 18 ani",
  auth_enter_user_title: "Bine ai venit!",
  auth_enter_user_subtitle: " Introdu adresa ta de email pentru a începe",
  auth_enter_btn: "Continuă",
  auth_login_ap_title: "Login",
  auth_login_ap_subtitle: "Dacă ești terapeut din sistemul nostru, te poți loga mai jos",
  auth_login_user_title: "Login",
  auth_login_user_subtitle: " Loghează-te în contul Psiholog.ro",
  auth_login_btn: "Intră în cont",
  auth_login_error: "A apărut o eroare",
  auth_login_wrong_credentials_error:
    "Adresa de email sau parola este incorectă. Te rugăm sa introduci o altă combinație.",
  auth_register_ap_title: "Creează un cont de terapeut",
  auth_register_ap_subtitle: "Pentru a putea programa o ședință, trebuie să ai un cont activ.",
  auth_register_user_title: "Creează un cont",
  auth_register_user_subtitle: "Pentru a putea programa o ședință, trebuie să ai un cont activ.",
  auth_register_btn: "Creează cont",
  auth_register_409_status_error: "Această adresă de e-mail este deja folosită",
  auth_register_error: "A apărut o eroare",
  auth_forget_password_title: "Ai uitat parola?",
  auth_forget_password_subtitle:
    "Completează în câmpul de mai jos email-ul tău, iar noi îți vom trimite pașii pentru recuperarea parolei.",
  auth_forgot_password_btn: "Recuperează parola",
  auth_forgot_password_success_message: "Un email a fost trimis la adresă de email introdusă de tine.",
  auth_forgot_password_error_message: "Această adresă nu este inregistrată în baza noastră de date.",
  auth_reset_password: "Resetează parola",
  auth_first_name: "Prenume",
  auth_email: "Email",
  auth_password: "Parola",
  auth_repeat_password: "Repetă parola",
  auth_tnc_agreement: "Sunt de acord cu",
  auth_tnc: "Termenii și condițiile",
  auth_back_to_login: "Înapoi la login",
  auth_registered_user_message: "Ai deja cont?",
  auth_login_incentive: "Loghează-te aici",
  auth_login_user_message: "Ești nou în sistem?",
  auth_register_incentive: "Creează un cont",
  auth_social_sign_in_label: "Sau continuă cu",
  oauth_system_error: "Eroare de sistem",
  oauth_email_verified: "Se verifică emailul...",
  oauth_email_not_provided: "Emailul nu a fost furnizat de către provider",
  oauth_generic_error: "Eroare de autentificare",
  unsub_generic_error: "Eroare la dezabonare. Te rugam sa ne contactezi la contact@psiholog.ro",
  oauth_login_long_error: "A apărut o eroare la autentificare. Te rugăm să revii la pagina de login și să încerci din nou.",
  user_onboarding_enter_title: "Verifică-ți Emailul pentru Link-ul de Acces",
  user_onboarding_enter_subtitle: `
    Am trimis un email la adresa pe care ai furnizat-o cu un link unic pentru acces. Acesta îți va permite să te loghezi direct în contul tău de pe Psiholog.ro, fără a necesita o parolă.
    Verifică inbox-ul și, dacă nu găsești emailul, nu uita să verifici și în folderul de Spam sau Promoții. Link-ul este valid pentru următoarele 30 de minute.
    `,
  user_onboarding_step1_title: "Bună, {name}. Te rugăm să-ți verifici emailul",
  user_onboarding_step1_subtitle: "Pentru a continua, introdu codul primit pe emailul {email}",
  user_onboarding_step1_link: "Întâmpini probleme?",
  user_onboarding_step1_link_label: "Retrimite codul pe email",
  user_onboarding_step1_change_email_label: "Modifică emailul",
  user_onboarding_continue_btn_label: "Continuă",
  user_onboarding_step2_title: "Ce fel de probleme ai vrea să rezolvi?",
  user_onboarding_step2_subtitle: "Poți selecta una sau mai multe opțiuni.",
  user_onboarding_step2_link_label: "sari peste acest pas",
  user_onboarding_step3_title: "Spune-ne puțin despre tine",
  user_onboarding_step3_subtitle: "Opțiunile de mai jos ne vor ajuta să-ți oferim cele mai bune variante de terapeuți",
  user_onboarding_step3_birth_year: "Anul nașterii",
  user_onboarding_step3_therapist_preferences: "Preferință terapeut",
  user_onboarding_step3_woman: "Femeie",
  user_onboarding_step3_man: "Bărbat",
  user_onboarding_step3_no_preference: "Fără preferință",
  user_onboarding_step4_title: "Toate ședințele se vor desfășura online",
  user_onboarding_step4_subtitle: "Te rugăm să ai pregătită o camera web și un microfon funcțional.",
  user_onboarding_step4_btn_label: "Finalizează procesul",
  activity_rating_placeholder: "Orice rating",
  activity_rating_option: "Minim",
  activity_search_placeholder: "Caută",
  activity_availability_placeholder: "Disponibil oricând",
  activity_availability_option1: "Disponibil mâine",
  activity_availability_option2: "Disponibil în 2 zile",
  activity_availability_option3: "Săptămâna asta",
  activities_therapists_title: "Terapeuți",
  activities_therapists_starting_today:
    "{count, plural, =0 {# terapeuți disponibili} one {# terapeut disponibil} other {# terapeuți disponibili }} începând de astăzi",
  activities_therapists_available_today:
    "{count, plural, =0 {# terapeuți disponibili} one {# terapeut disponibil} other {# terapeuți disponibili }} astăzi",
  activities_therapists_available_starting_tomorrow:
    "{count, plural, =0 {# terapeuți disponibili} one {# terapeut disponibil} other {# terapeuți disponibili}} începând de mâine",
  activities_therapists_unavailable: "Terapeuți momentan indisponibili",
  activities_no_activity_available: "Nu există terapeuți disponibili conform criteriile selectate",
  activity_details_specializations: "Cu ce te pot ajuta",
  activity_details_accreditation: "Psiholog specializat și acreditat de",
  activity_details_description: "Despre",
  activity_details_studies: "Studii",
  activity_details_training: "Formare psihologică",
  activity_video_description: "Prezentare",
  activity_details_methods: "Metode și tehnici utilizate",
  activity_details_general_description: "Descriere personală",
  activity_details_testimonials: "Testimoniale",
  activity_details_articles: "Articole",
  activity_schedule_meeting: "Programează ședință",
  activity_view_profile: "Vezi profil",
  activity_specialized_in: "Specializări în",
  activity_session: "ședință",
  activity_sessions: "ședințe",
  activity_first_meeting: "prima ședință",
  activity_book_btn_label: "Rezervă",
  articles_add_image: "Adaugă imagine",
  article_read: "Citește articolul",
  articles_no_data: "Nu sunt articole",
  articles_add_title: "Articol nou",
  articles_edit_title: "Editează articolul",
  article_categories_placeholder: "Categorii",
  article_status_placeholder: "Status",
  article_title_placeholder: "Titlu articol",
  article_content_placeholder: "Conținut articol",
  article_add_button_label: "Adaugă articol",
  article_edit_button_label: "Editează articolul",
  article_my_articles_title: "Articolele mele",
  article_back_to_my_articles: "Înapoi la articolele mele",
  article_back_to_my_article: "Înapoi la articolul meu",
  article_back_to_articles: "Înapoi la articole",
  articles_load_more: "Încarcă mai multe articole",
  onboarding_gender_selector_placeholder: "Genul cu care te identifici",
  onboarding_gender_female: "Feminin",
  onboarding_gender_male: "Masculin",
  onboarding_gender_unspecified: "Prefer să nu spun",
  ap_onboarding_stripe: "Veți fi redirecționat imediat pe Stripe",
  ap_onboarding_stripe_finalized:
    "Veți fi redirecționat imediat pe pagina de onboarding",
  ap_onboarding_verification_code: "Cod verificare",
  ap_onboarding_verification_code_message:
    "Pentru a continua, introdu codul format din 4 cifre primit pe mail",
  ap_onboarding_step1_title:
    "Ne bucurăm ca ești parte din echipa noastră, {name}",
  ap_onboarding_step1_subtitle:
    "Înainte de a incepe, te rugăm să-ți completezi profilul de terapeut",
  ap_onboarding_step1_error: "A apărut o eroare",
  ap_onboarding_step1_avatar_uploader_label: "Adaugă avatar",
  ap_onboarding_step1_first_name: "Prenume",
  ap_onboarding_step1_name: "Nume",
  ap_onboarding_step1_birth_year: "Anul nașterii",
  ap_onboarding_step1_gender: "Genul cu care te identifici",
  ap_onboarding_step1_description_placeholder:
    "Adaugă o scurtă descriere a serviciilor tale",
  ap_onboarding_step1_description_required:
    "Trebuie sa completezi descrierea serviciilor tale",
  ap_onboarding_step2_title: "Detalii studii și formare",
  ap_onboarding_step2_subtitle:
    "Te rugăm să completezi nivelul studiilor, a formării psihologice și metodele principale utilizate de către tine",
  ap_onboarding_graduation_year: "Anul absolvirii",
  ap_onboarding_graduation_year_placeholder: "Psiholog practicant din anul",
  ap_onboarding_bachelor_degree: "Studii licență",
  ap_onboarding_bachelor_degree_placeholder: "Universitate, Oraș, Facultate",
  ap_onboarding_master_degree: "Studii master",
  ap_onboarding_dr_degree: "Studii doctorat",
  ap_onboarding_master_degree_placeholder:
    "Universitate, Oraș, Facultate, Specializare",
  ap_onboarding_dr_degree_placeholder: "*optional",
  ap_onboarding_psychological_training: "Formare psihologică",
  ap_onboarding_psychological_training_required:
    "Trebuie să selectezi măcar o opțiune din formarea psihologică",
  ap_onboarding_psychological_methods: "Metode și tehnici utilizate",
  ap_onboarding_psychological_methods_required:
    "Trebuie să selectezi măcar o opțiune din metode și tehnici utilizate",
  ap_onboarding_step3_title: "Cu ce afecțiuni poți ajuta?",
  ap_onboarding_step3_subtitle: "Poți selecta una sau mai multe opțiuni.",
  ap_onboarding_step3_conditions_required:
    "Trebuie să selectezi măcar o afecțiune.",
  ap_onboarding_step4_title: "Setează prețul unei  ședințe",
  ap_onboarding_step4_subtitle: "Adaugă un preț per ședință de terapie.",
  ap_onboarding_step4_placeholder: "Selectează un preț",
  ap_onboarding_step4_price:
    "Prețul de {price} este plătit de client, din care partea ta netă este {apPart}, iar restul de {commission} reprezintă comisionul platformei și taxele de procesare",
  ap_onboarding_step5_title:
    "Care este timpul tău disponibil pentru terapie în fiecare zi?",
  ap_onboarding_step5_subtitle:
    "Vei putea modifica timpii, sau bloca anumite time-sloturi oricând, din interfața de administrare",
  ap_onboarding_step5_from: "De la",
  ap_onboarding_step5_to: "Până la",
  ap_onboarding_step5_schedule_required:
    "Trebuie să selectezi măcar un interval disponibil pentru terapie",
  ap_onboarding_step6_title: "Toate ședințele se vor desfășura online",
  ap_onboarding_step6_subtitle:
    "Te rugăm să ai pregătită o camera web și un microfon funcțional.",
  ap_onboarding_btn_label: "Finalizează procesul",
  ap_save_schedule: "Salvează",
  image_uploader_size_error: "Imaginea trebuie să fie mai mică de 3 Mb",
  booking_new_booking: "Programare nouă",
  booking_change_booking: "Modifică programarea",
  booking_change_booking_error: "Nu s-a putut realiza modificarea programării",
  booking_confirmation: "Confirmă și plătește",
  booking_reschedule:
    "Veți putea modifica programarea gratuit cu maxim 24 ore înainte de ședință.",
  booking_reschedule_with_package_info: "Veți putea modifica programarea gratuit cu maxim 24 ore înainte de ședință. Dacă achiziționați un pachet, puteți programa restul ședințelor ulterior din contul dvs.",
  booking_payment: "Informații plată",
  booking_price: "Preț de bază",
  booking_price_after_discount: "Final de plată",
  booking_price_buy_package: "Cumpară pachet",
  booking_btn_label_continue: "Continuă",
  booking_btn_label_pay_one: "Cumpară o ședință",
  booking_btn_label_use_coupon: "Aplică cupon",
  booking_discount_form_title: "Discount",
  booking_discount_percent_title: "Reducere",
  booking_btn_label: "Confirmă și plătește",
  booking_success_message: "Programarea a fost făcută cu succes",
  booking_add_to_calendar: "Adaugă în calendar",
  booking_sms_reminder: "Amintește prin SMS",
  booking_appointments_link: "Vezi ședințele active",
  booking_user_title: "Ședințele mele",
  booking_no_data: "Nu sunt ședințe",
  booking_no_active_bookings_title: "Nu ai nicio ședință planificată",
  booking_no_past_bookings_title: "Nu ai avut nicio ședință planificată",
  booking_no_active_bookings_subtitle:
    "Alege un terapeut pentru a începe o ședință nouă.",
  booking_user_past: "Trecute",
  booking_user_active: "Active",
  booking_session_page_btn_label: "Pagina ședinței",
  booking_change_session_btn_label: "Modifică programarea",
  booking_schedule_session_btn_label: "Programează",
  booking_ongoing_booking: "În curs de desfășurare",
  booking_change_session_alert:
    "Veți putea modifica programarea gratuit cu maxim 24 ore înainte de ședință",
  booking_modify_session_btn_label: "Modifică programarea",
  booking_ap_edit_schedule: "Editează timpii impliciți",
  booking_ap_edit_schedule_min_buffer: "Pacienții vor face programări cu cel puțin {minute} minute înainte de începerea ședinței",
  booking_ap_edit_schedule_error: "Nu s-au putut modifica timpii impliciți",
  booking_block_once: "Blochează o dată",
  booking_unblock_once: "Deblochează o dată",
  booking_next_session: "Următoarea ședință",
  booking_next_sessions_tab_label: "Ședințe viitoare",
  booking_last_session_tab_label: "Trecute",
  booking_session:
    "{count, plural, =0 {# ședințe} one {# ședință} other {# ședințe}}",
  book_activity_error: "Nu s-a putut realiza programarea",
  book_activity_no_available_dates:
    "Nu sunt date disponibile in următoarele 60 de zile",
  navbar_activities_label: "Terapeuți",
  navbar_bookings_label: "Ședințele mele",
  navbar_preferences_label: "Preferințe terapie",
  navbar_guide_label: "Ghid utilizare",
  navbar_contact_label: "Contact",
  navbar_dashboard_label: "Dashboard",
  navbar_sessions_label: "Ședințe",
  navbar_testimonials_label: "Testimoniale",
  navbar_articles_label: "Articolele mele",
  navbar_payments_label: "Istoric plăți",
  my_account_title: "Contul meu",
  my_account_personal_info: "Info",
  my_account_personal_password: "Parolă",
  my_account_personal_info_plus:
    "Câteva informații pentru a ajuta potențialii terapeuți",
  my_account_ap_personal_info_plus:
    "Câteva informații pentru a ajuta potențialii clienți",
  my_account_unsubscribed_promo: "Promoții pe email",
  my_account_unsubscribed_active: "Active",
  my_account_unsubscribed_inactive: "Inactive",
  save_btn: "Salvează",
  change_password: "Schimbare parolă",
  change_password_info:
    "Pentru a schimba parola, vei avea nevoie de parola inițială",
  old_password: "Parola veche",
  new_password: "Parola nouă",
  repeat_new_password: "Repetă parola nouă",
  invalid_old_password: "Parola veche nu este validă!",
  notes: "Notițe",
  ap_sessions: "Ședințe",
  user_menu_my_account: "Contul meu",
  user_menu_payments_history: "Istoric plăți",
  user_menu_public_profile: "Profil public",
  user_menu_logout: "Logout",
  user_details_success_update: "Informațiile personale au fost schimbate",
  user_details_password_success_update: "Parola a fost schimbată",
  my_account_services: "Servicii",
  my_account_training: "Formare",
  my_account_invoice_info: "Facturare",
  my_account_training_title: "Detalii studii și formare",
  my_account_invoice_title: "Detalii facturare",
  my_account_invoice_subtitle:
    "Informații ce vor apărea pe factura emisă clienților",
  my_account_training_subtitle:
    "Nivelul studiilor, a formării psihologice și metodele principale utilizate",
  business_name: "Numele firmei",
  business_name_subtitle: "Numele PFA-ului sau al firmei",
  business_tax_id: "Cod unic de inregistrare (CUI)",
  business_tax_id_subtitle: "Codul unic de inregistrare al firmei/PFA-ului",
  business_reg_no: "Cod registrul comertutului",
  business_reg_no_subtitle:
    "Codule eliberat de registrul comertului la infiintarea firmei/PFA-ului",
  business_address_1: "Adresa 1",
  business_address_1_subtitle: "Adresa firmei/PFA-ului",
  business_address_2: "Adresa 2",
  business_address_2_subtitle: "Continuarea adresei firmei/PFA-ului",
  invoice_details_greater_than_30:
    "Informațiile de facturare nu pot depăși 30 de caractere",
  avatar: "Avatar",
  click_on_avatar: "Click pe avatar pentru a schimba imaginea",
  therapy_service: "Servicii terapie",
  describe_your_services: "Te rugăm să-ți descrii serviciile terapeutice",
  therapy_specializations: "Cu ce poți ajuta",
  therapy_service_success_message: "Informațiile au fost schimbate",
  therapy_service_mandatory_message: "Toate câmpurile sunt obligatorii",
  select_from_options: "Poți selecta una sau mai multe opțiuni.",
  bio: "Bio",
  add_short_description: "Adaugă o scurtă descriere a serviciilor tale",
  activity_successfully_updated: "Informațiile personale fost schimbate",
  remove_note_success: "Nota a fost ștearsă",
  add_note: "Adaugă notiță nouă",
  note_success_update: "Notița a fost modificata cu success",
  edit_note: "Editează notiță",
  add_to_important: "Adaugă la importante",
  remove_from_important: "Scoate din importante",
  remove_note: "Șterge notiță",
  therapy_category: "Categorii terapie",
  select_your_problems: "Selectează ce probleme ai vrea sa rezolvi",
  select_multiple_options: "Poți selecta una sau mai multe opțiuni.",
  available_options: "{count} opțiuni disponibile",
  therapy_category_mandatory: "Selectează cel puțin o opțiune",
  personal_setup: "Setări personale",
  important_info: "Informații importante",
  next_booking_no_booking_title: "Nu ai nicio ședință planificată",
  next_booking_no_booking_subtitle:
    "Alege un terapeut pentru a începe o ședință nouă.",
  next_booking_title: "Următoarea ședință în",
  meeting_started: "Ședința a început",
  countdown_day_label: "{count, plural, =0 {# zile} one {# zi} other {# zile}}",
  countdown_hour_label: "{count, plural, =0 {# ore} one {# oră} other {# ore}}",
  countdown_minute_label:
    "{count, plural, =0 {# minute} one {# minut} other {# minute}}",
  countdown_second_label:
    "{count, plural, =0 {# secunde} one {# secundă} other {# secunde}}",
  and: "și",
  ap_testimonials_title: "Testimoniale",
  ap_testimonials_no_data: "Nu există testimoniale",
  ap_testimonials_load_more: "Încarcă mai multe testimoniale",
  review_modal_info:
    "Ratingul acordat va ajuta atat alți potențiali clienți, cât si terapeutul",
  review_modal_title: "Lasă un rating pentru ședința incheiată",
  review_modal_btn_label: "Adaugă rating",
  review_modal_add_comment: "Adaugă un comentariu (opțional)",
  review_modal_add_success_message: "Comentariu tau a fost salvat",
  calendar_event_title: "Ședință terapie - Psiholog.ro",
  calendar_event_description: "Ședință terapie - Psiholog.ro",
  payout_message:
    "Suma disponibilă va fi virată în contul bancar în fiecare dată de 30 a lunii",
  account_status_inactive: "Contul dumneavoastră este în curs de aprobare.",
  delete: "Șterge",
  are_you_sure: "Ești sigur?",
  cancel: "Anulează",
  balance_total: "Total disponibil",
  balance_pending: "Pending",
  calendar: "Calendar",
  mobile_phone_number: "Număr telefon",
  mobile_phone_number_placeholder: "740123456",
  charged: "Incasat",
  charged_package: "Incasat Pachet",
  bookings_package: "Pachet ședințe",
  bookings_individual: "Ședință individuală",
  booking_needs_appointment: "Necesită pogramare",
  patient_page: "Pagina pacientului",
  paid: "Plătit",
  write_message: "Scrie mesajul aici...",
  chat_title: "Mesaje",
  reminder_set:
    "Vei primi o informare cu 60 minute inainte de începerea ședintei",
  add_review: "Adaugă review",
  new_activation_code: "Vei primi un email cu un nou code de activare",
  session_price: "Prețul unei sesiuni",
  session_price_select_one_option: "Selectează un preț",
  session_price_select_mandatory: "Trebuie să selectezi o opțiune",
  activity_category_select_mandatory:
    "Trebuie să selectezi cel puțin o specializare terapeutică",
  terms_and_conditions: "Termeni și condiții",
  privacy_policy: "Politică de confidențialitate",
  auth_email_validation_message: "Adresa de email nu este validă",
  auth_phone_validation_message: "Adaugă un număr de telefon valid",
  auth_password_validation_message:
    "Te rugăm să introduci o parolă mai puternică",
  auth_repeat_password_validation_message:
    "Te rugăm să te asiguri că parolele sunt identice",
  auth_tnc_validation_message: "Te rugăm să accepți termenii și condițiile",
  mandatory_field_message: "Acest câmp este obligatoriu",
  birth_year_mandatory_message: "Te rugăm să introduci anul nașterii",
  birth_year_validation_message: "Te rugăm să introduci un număr valid",
  resume_booking_error: "Nu s-a putut finaliza plata sesiunii",
  mark_booking_free: "Programarea a fost marcata ca fiind gratuita",
  mark_booking_free_error: "Nu s-a putut marca programarea ca fiind gratuita",
  resume_booking_payment: "Finalizează rezervarea",
  resume_booking_payment_btn: "Continuă spre plată",
  resume_booking_payment_cancel_btn: "Anulează rezervarea",
  delete_booking_success: "Anularea rezervării s-a făcut cu succes",
  delete_booking_error: "Nu s-a putut realiza anularea rezervării",
  payment_pending_booking_modal_title: "Rezervare neplatită",
  payment_pending_booking_modal_description:
    "Această rezervare nu a fost finalizată de către client. În cazul în care nu va finaliza plata, această rezervare se va șterge",
  payment_pending_booking_modal_btn: "Închide",
  ap_vimeo_url: "Prezentare video",
  ap_vimeo_url_subtitle: "Adaugă adresa url a video-ului de pe vimeo",
  ap_vimeo_invalid_url: "Url-ul vimeo nu este valid",
};
