import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Flex, Avatar, Text, Button, Link } from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import Specializations from "./Specializations";
import Rating from "../../reviews/Rating";
import { IActivity } from "../types";
import { useGetLoggedInUserQuery } from "../../user/service";
import AvailabilitySlots from "./AvailabilitySlots";

type Props = {
  activity: IActivity;
  mainAction: 'book' | 'view';
};

const ActivityPreview = ({ activity, mainAction }: Props) => {
  const { data: user } = useGetLoggedInUserQuery();
  const bookingsDisabled = user?.status === 'disabled_bookings' || false;
  const navigate = useNavigate();
  const intl = useIntl();

  const { guid, name, price, rating, reviews_no, images, booking_package } =
    activity;

  return (
    <>
      <Flex
        layerStyle="card"
        h="100%"
        flexDir="column"
        justify="space-between"
        gap="16px"
      >
        <Link
          _hover={{ textDecoration: "none" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          as={RouteLink}
          to={`/activities/${guid}`}
        >
          <Avatar
            w="86px"
            h="86px"
            mb="4px"
            name={name}
            src={images.length ? images[0]["url"] : ""}
          />
          <h3 style={{ wordBreak: "break-word", textAlign: "center" }}>
            {name}
          </h3>
          {booking_package?.label_per_booking ? <Text my="6px">{booking_package.label_per_booking}</Text> : <Text my="6px">{price.label}</Text>}
          <Rating score={rating} count={reviews_no} />
          <SpecializationsOrSlots activity={activity} />
        </Link>
        {mainAction === "book" ? (<Button
          disabled={bookingsDisabled}
          w="full"
          size="md"
          onClick={() => navigate(`/activities/${guid}/book-activity`)}
        >
          {intl.formatMessage({ id: "activity_schedule_meeting" })}
        </Button>) : (
          <Button
            bgColor="gray.500"
            _hover={{ bgColor: "gray.600" }}
            _active={{ bgColor: "gray.700" }}
              w="full"
              onClick={() => navigate(`/activities/${guid}`)}
            >
          {intl.formatMessage({ id: "activity_view_profile" })}
          </Button>
        )}

      </Flex>
    </>
  );
};

type SpecOrSlotsProps = {
  activity: IActivity;
};

const SpecializationsOrSlots = ({ activity }: SpecOrSlotsProps) => {
  if (activity.availabilities_first_slots?.length && activity.availabilities_first_date) {
    return <AvailabilitySlots activity={activity} />;
  }

  return <Specializations specializations={activity.categories} />;
}

export default ActivityPreview;
