import { useState } from "react";
import { Flex, VStack } from "@chakra-ui/react";
import ScheduleItem from "./ScheduleItem";
import { ISchedule, IScheduleItem } from "./types";
import { getInitialSchedule, DAYS } from "./options";

type Props = {
  onScheduleChange: (schedule: ISchedule) => void;
  value?: ISchedule;
};

const Schedule = ({ value, onScheduleChange }: Props) => {
  const initialScheduleState = value || getInitialSchedule(DAYS);

  const [schedule, setSchedule] = useState<ISchedule>(initialScheduleState);

  const handleScheduleItemChange = ({
    day,
    start_time,
    end_time,
  }: IScheduleItem) => {
    let updatedSchedule: ISchedule = [];
    schedule.forEach((item) => {
      if (item.day === day) {
        const updatedScheduleItem = {
          day,
          start_time: ![start_time, end_time].includes("non_working_day")
            ? start_time
            : "",
          end_time: ![start_time, end_time].includes("non_working_day")
            ? end_time
            : "",
        };
        updatedSchedule.push(updatedScheduleItem);
      } else {
        updatedSchedule.push(item);
      }
    });

    setSchedule(updatedSchedule);
    onScheduleChange(updatedSchedule);
  };

  return (
    <Flex
      my="auto"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      w={{ base: "100%", md: "320px" }}
    >
      <VStack spacing="16px">
        {DAYS.map((day, idx) => {
          const scheduleItem = schedule.find((item) => item.day === day);
          return (
            <ScheduleItem
              key={idx}
              value={{
                day: day,
                start_time: scheduleItem?.start_time || "",
                end_time: scheduleItem?.end_time || "",
              }}
              onScheduleItemChange={handleScheduleItemChange}
            />
          );
        })}
      </VStack>
    </Flex>
  );
};

export default Schedule;
