import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { Chat } from "@carbon/icons-react";
// import Button from '@material-ui/core/Button';
// import ChatIcon from '../../../icons/ChatIcon';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useIntl } from 'react-intl';

export const ANIMATION_DURATION = 700;

const useStyles = makeStyles({
  iconContainer: {
    position: 'relative',
    display: 'flex',
  },
  circle: {
    width: '16px',
    height: '16px',
    backgroundColor: '#F26739',
    borderRadius: '50%',
    position: 'absolute',
    top: '-5px',
    right: '0px',
    border: "3px solid #fff",
    opacity: 0,
    transition: `opacity ${ANIMATION_DURATION * 0.5}ms ease-in`,
  },
  hasUnreadMessages: {
    opacity: 1,
  },
  ring: {
    border: '3px solid green',
    borderRadius: '30px',
    height: '13px',
    width: '13px',
    position: 'absolute',
    right: '0',
    top: '-4px',
    opacity: 0,
  },
  animateRing: {
    animation: `$expand ${ANIMATION_DURATION}ms ease-out`,
    animationIterationCount: 1,
  },
  '@keyframes expand': {
    '0%': {
      transform: 'scale(0.1, 0.1)',
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.4, 1.4)',
      opacity: 0,
    },
  },
});

export default function ToggleChatButton() {
  const intl = useIntl();
  const classes = useStyles();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { isChatWindowOpen, setIsChatWindowOpen, conversation, hasUnreadMessages } = useChatContext();
  const { setIsBackgroundSelectionOpen } = useVideoContext();

  const toggleChatWindow = () => {
    setIsChatWindowOpen(!isChatWindowOpen);
    setIsBackgroundSelectionOpen(false);
  };

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => setShouldAnimate(false), ANIMATION_DURATION);
    }
  }, [shouldAnimate]);

  useEffect(() => {
    if (conversation && !isChatWindowOpen) {
      const handleNewMessage = () => setShouldAnimate(true);
      conversation.on('messageAdded', handleNewMessage);
      return () => {
        conversation.off('messageAdded', handleNewMessage);
      };
    }
  }, [conversation, isChatWindowOpen]);

  return (
    <>
      <Tooltip placement='top' fontWeight="600" label={intl.formatMessage({ id: 'chat_title' })} >
        <IconButton
          w="54px"
          isRound
          bg="#fff"
          color="brandGray.500"
          borderColor="brandGray.500"
          _hover={{
            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.24)",
            bg: '#fff'
          }}
          _active={{
            bg: '#fff'
          }}
          style={{ height: "54px" }}
          icon={
            <div>
              <Chat size={24}/>
              <div className={clsx(classes.ring, { [classes.animateRing]: shouldAnimate })} />
              <div className={clsx(classes.circle, { [classes.hasUnreadMessages]: hasUnreadMessages })} />
            </div>
          }
          variant="outline"
          aria-label="Chat"
          data-cy-chat-button
          onClick={toggleChatWindow}
          disabled={!conversation}
        />
      </Tooltip>
      {/* <Button
        data-cy-chat-button
        onClick={toggleChatWindow}
        disabled={!conversation}
        startIcon={
          <div className={classes.iconContainer}>
            <ChatIcon />
            <div className={clsx(classes.ring, { [classes.animateRing]: shouldAnimate })} />
            <div className={clsx(classes.circle, { [classes.hasUnreadMessages]: hasUnreadMessages })} />
          </div>
        }
      >
        Chat
      </Button> */}
    </>
  );
}
