import * as React from "react";
import { Global } from "@emotion/react";

export const Fonts = () => (
  <Global
    styles={`
      @import url("https://use.typekit.net/vmx2jea.css");
      `}
  />
);
