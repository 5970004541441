import { Flex, InputGroup, InputRightElement, Input } from "@chakra-ui/react";
import { Search } from "@carbon/icons-react";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { getActivitiesFilters, changeFetchFilters } from "../slice";
import { useIntl } from "react-intl";

const Name = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const filters = useAppSelector(getActivitiesFilters);
  const { name } = filters;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeFetchFilters({ ...filters, name: e.target.value }));
  };

  return (
    <InputGroup>
      <Input
        placeholder={intl.formatMessage({ id: "activity_search_placeholder" })}
        type="text"
        name="search"
        maxW={{ base: "100%", lg: "180px" }}
        value={name}
        onChange={handleOnChange}
      />
      <InputRightElement width="40px" h="100%" pointerEvents="none">
        <Flex h="100%" w="40px" justifyContent="center" alignItems="center">
          <Search size={20} />
        </Flex>
      </InputRightElement>
    </InputGroup>
  );
};

export default Name;
