import React from 'react';
import { Flex } from "@chakra-ui/react";
import Div100vh from 'react-div-100vh'
import { styled, Theme } from '@material-ui/core/styles';
import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';
import Room from './components/Room/Room';
import useRoomState from './hooks/useRoomState/useRoomState';
import ActivityProviderNotes from './components/ActivityProviderNotes';
import { usePingMutation } from './service';
import { useParams } from 'react-router-dom';

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
    overflow: 'hidden',
    height: "100%",
    paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
    background: 'black',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
    },
}));

export default function VideoCallApp() {
    const roomState = useRoomState();
    const [ping] = usePingMutation();

    let { roomId } = useParams();

    React.useEffect(() => {
        const pinger = () => {
            if (roomId) {
                ping({ b_guid: roomId, status: roomState });
            }
        }
        const interval = setInterval(pinger, 60 * 1000);
        const timeout = setTimeout(pinger, 5 * 1000);
        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [roomState, ping, roomId]);

    console.log("roomState", roomState);
    return <Div100vh>
        {roomState === 'disconnected' ?
            <PreJoinScreens /> :
            <Main>
                <ReconnectingNotification />
                <RecordingNotifications />
                <MobileTopMenuBar />
                <Flex w="full" h="100%">
                    <ActivityProviderNotes />
                    <Room />
                </Flex>
                <MenuBar />
            </Main>
        }
    </Div100vh>
}