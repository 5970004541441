import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauthorization from "../store/baseQuery";
import {
    IFetchNotesPayload,
    IFetchNotesResponse,
    IPostNotesResponse,
    IPostNotesPayload,
    IDeleteNotePayload,
    IDeleteNoteResponse,
    IPatchNotePayload,
    IPatchNoteResponse,
    IFetchNotesNumberResponse
} from './types'

export const notesApi = createApi({
    reducerPath: "notesApi",
    baseQuery: baseQueryWithReauthorization,
    tagTypes: ["Notes"],
    endpoints: (builder) => ({
        fetchNotesNumber: builder.query<IFetchNotesNumberResponse, IFetchNotesPayload>(
            {
                query: (payload: IFetchNotesPayload) => {
                    const { u_guid, ...queryParams } = payload;
                    return {
                        url: `/note/user/${u_guid}/count`,
                        method: "GET",
                        params: queryParams,
                    };
                },
                providesTags: ["Notes"],
            }
        ),
        fetchNotes: builder.query<IFetchNotesResponse, IFetchNotesPayload>(
            {
                query: (payload: IFetchNotesPayload) => {
                    const { u_guid, ...queryParams } = payload;
                    return {
                        url: `/note/user/${u_guid}`,
                        method: "GET",
                        params: queryParams,
                    };
                },
                providesTags: ["Notes"],
            }
        ),
        postNotes: builder.mutation<IPostNotesResponse, IPostNotesPayload>({
            query: (payload: IPostNotesPayload) => {
                return {
                    url: `/note`,
                    method: "POST",
                    body: payload,
                };
            },
            invalidatesTags: ["Notes"],
        }),
        updateNote: builder.mutation<IPatchNoteResponse, IPatchNotePayload>({
            query: (payload: IPatchNotePayload) => {
                const { guid, data } = payload
                return {
                    url: `/note/${guid}`,
                    method: "PATCH",
                    body: data,
                };
            },
            invalidatesTags: ["Notes"],
        }),
        removeNote: builder.mutation<IDeleteNoteResponse, IDeleteNotePayload>({
            query: (guid: string) => {
                return {
                    url: `/note/${guid}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["Notes"],
        }),
    })
})

export const {
    useFetchNotesNumberQuery,
    useFetchNotesQuery,
    usePostNotesMutation,
    useRemoveNoteMutation,
    useUpdateNoteMutation,
} = notesApi;