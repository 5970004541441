import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCookie, setCookie } from "../utils/cookies";
import { useValidateVoucherQuery } from "../voucher/service";
import { useIsRegisteredUser } from "../user/hooks";

export default function VoucherHandler() {  //used because after leaving room page media streams are still active
    const location = useLocation();
    const isRegisteredUser = useIsRegisteredUser();
    const [globalVoucher, setGlobalVoucher] = useState<string | null>(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const urlVoucher = searchParams.get('global_voucher');
        if (urlVoucher) {
            setGlobalVoucher(urlVoucher);
            setCookie("_global_voucher", urlVoucher, {
                path: "/",
                expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
            });
            return;
        }
        // Or restore from cookies
        const cookieVoucher = getCookie('_global_voucher');
        if (cookieVoucher) {
            setGlobalVoucher(cookieVoucher);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (globalVoucher && isRegisteredUser) {
        return <VoucherHandlerValidator globalVoucher={globalVoucher} />
    }
    return null;

}

type VoucherHandlerValidatorProps = {
    globalVoucher: string;
}

const VoucherHandlerValidator: React.FC<VoucherHandlerValidatorProps> = ({ globalVoucher }) => {
    const { data: globalVoucherData, isFetching } = useValidateVoucherQuery({
        voucher_code: globalVoucher,
    });
    console.error('data', globalVoucherData)
    useEffect(() => {
        if (globalVoucherData) {
            if (globalVoucherData.is_valid) {
                setCookie("_global_voucher", globalVoucher, {
                    path: "/",
                    expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
                });
            }
            else {
                setCookie("_global_voucher", '', {
                    path: "/",
                    expires: new Date(Date.now() - 1000 * 60 * 60 * 24),
                });
            }
        }
    }, [globalVoucherData]);
    return null
}
