import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { defaultActivityFilters } from "./defaults";
import { IActivityFilters } from "./types";

const activitiesFiltersSlice = createSlice({
  name: "activities_filters",
  initialState: defaultActivityFilters,
  reducers: {
    changeFetchFilters: (state, action: PayloadAction<IActivityFilters>) => {
      state.rating = action.payload.rating;
      state.issues_to_fix = action.payload.issues_to_fix;
      state.name = action.payload.name;
      state.available_from = action.payload.available_from;
      state.available_until = action.payload.available_until;
    },
  },
});

const getActivitiesFilters = (state: RootState): IActivityFilters => {
  return state.activities_filters;
};

export { getActivitiesFilters };

export const { changeFetchFilters } = activitiesFiltersSlice.actions;

export default activitiesFiltersSlice.reducer;
