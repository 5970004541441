import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauthorization from "../store/baseQuery";
import {
  IArticlesResponse,
  IArticlesPayload,
  IArticle,
  IAddArticlePayload,
  IEditArticlePayload,
  IAuthorArticlesPayload,
} from "./types";

export const articlesApi = createApi({
  reducerPath: "articlesApi",
  baseQuery: baseQueryWithReauthorization,
  tagTypes: ["Articles"],
  endpoints: (builder) => ({
    getArticles: builder.query<IArticlesResponse, IArticlesPayload>({
      query: (payload: IArticlesPayload) => {
        const { status, limit, last_evaluated_key } = payload;
        const params: Partial<IArticlesPayload> = {
          limit,
        };
        if (last_evaluated_key) {
          params["last_evaluated_key"] = last_evaluated_key;
        }
        return {
          url: `/article/list/${status}`,
          method: "GET",
          params: params,
        };
      },
      providesTags: ["Articles"],
    }),
    getArticle: builder.query<IArticle, string>({
      query: (guid: string) => {
        return {
          url: `/article/${guid}`,
          method: "GET",
          headers: {},
        };
      },
      providesTags: ["Articles"],
    }),
    postArticle: builder.mutation<IArticle, IAddArticlePayload>({
      query: (payload: IAddArticlePayload) => {
        return {
          url: "/article",
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Articles"],
    }),
    editArticle: builder.mutation<IArticle, Partial<IEditArticlePayload>>({
      query: (payload: IEditArticlePayload) => {
        const { guid, ...body } = payload;
        return {
          url: `/article/${guid}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["Articles"],
    }),
    getArticlesPerAuthor: builder.query<
      IArticlesResponse,
      IAuthorArticlesPayload
    >({
      query: (payload: IAuthorArticlesPayload) => {
        const { u_guid, limit, last_evaluated_key } = payload;
        const params: Partial<IArticlesPayload> = {
          limit,
        };
        if (last_evaluated_key) {
          params["last_evaluated_key"] = last_evaluated_key;
        }

        return {
          url: `/article/public/author/${u_guid}`,
          method: "GET",
          params: params,
        };
      },
    }),
  }),
});

export const {
  useGetArticlesQuery,
  useGetArticleQuery,
  usePostArticleMutation,
  useEditArticleMutation,
  useGetArticlesPerAuthorQuery,
} = articlesApi;
