import { Flex } from "@chakra-ui/react";
import { useGetMyNextBookingQuery } from "../../booking/service";
import NextBooking from "./NextBooking";
import NoFutureBooking from "./NoFutureBooking";
import { IBooking } from "../../booking/types";
import { IRoles } from "../../user/types";
import moment from "moment";

type Props = {
  userType: IRoles;
};

const getNextBooking = (bookings: IBooking[] | null) => {
  if (!bookings) return null
  if (bookings.length === 1) return bookings[0]
  const startedMeeting = bookings.find(booking => {
    const isBetween = moment().isBetween(booking.start_time, booking.end_time, undefined, '[]')
    return isBetween;
  })
  return startedMeeting ? startedMeeting : bookings[0]
}

const NextBookingNotification = ({ userType }: Props) => {
  const { data, isLoading } = useGetMyNextBookingQuery();
  if (isLoading) return null;
  const booking = getNextBooking(data ? data.data : null)

  return (
    <Flex
      position="sticky"
      bottom={{ base: "78px", lg: "24px" }}
      zIndex="2"
      px={{ base: "16px", lg: "32px" }}
      margin="auto"
      alignItems="center"
      justifyContent="center"
      w='fit-content'
    >
      {booking && <NextBooking booking={booking} userType={userType} />}
      {!booking && <NoFutureBooking userType={userType} />}
    </Flex>
  );
};

export default NextBookingNotification;
