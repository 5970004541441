import * as React from "react";
import { Box, Image } from '@chakra-ui/react'
type Props = {
    src?: string
}
export const Logo = ({ src = '/logo.svg' }: Props) => (
    <Box>
        <Image
            src={src}
            alt="Psiholog.ro logo"
            height={{ base: '32px', lg: '40px' }}
        />
    </Box>
);
export default Logo
