import { useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@chakra-ui/react";

type Props = {
  specialization: string;
  onCheckSpecialization: (issues: string, isChecked: boolean) => void;
};

const SpecializationCheckbox = ({
  specialization,
  onCheckSpecialization,
}: Props) => {
  const intl = useIntl();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleOnClick = () => {
    setIsChecked(!isChecked);
    onCheckSpecialization(specialization, !isChecked);
  };

  return (
    <Box
      textTransform="capitalize"
      layerStyle={isChecked ? "checkboxPillSelected" : "checkboxPill"}
      onClick={handleOnClick}
    >
      {intl.formatMessage({ id: specialization })}
    </Box>
  );
};

export default SpecializationCheckbox;
