import { useEffect, useState } from "react";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import Layout from "../layout";
import { USER_HOME_PAGE } from "../../routes/defaults";
import Loader from "../../shared/Loader";
import useNotification from "../../shared/hooks/useNotification";
import { useOauthHandleMutation } from "../service";
import { setAuthorizationTokens } from "../slice";
import { Box, Button, AlertIcon, Alert, Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { Flex } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { getUTMParamsFromCookie } from "../../utils/requests";
import { getAndDeleteRedirect } from "../../utils/login";

const OauthHandle = () => {
  const intl = useIntl();
  const notify = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [handleOauth] = useOauthHandleMutation();
  const userType = "registered_user";

  // on mount check for code and error and call the server
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const error = urlParams.get("error");
    if (!code || error) {
      notify("error", "oauth_generic_error");

      // navigate("/auth/login");
      return;
    }
    const handlRequest = async () => {
      const utm = getUTMParamsFromCookie();
      const redirect_uri = `${window.location.protocol}//${window.location.hostname
        }${window.location.port ? `:${window.location.port}` : ""
        }/auth/oauth/handle`;
      try {
        const response = await handleOauth({ code, redirect_uri, utm }).unwrap();
        dispatch(
          setAuthorizationTokens({
            access_token: response.access_token,
            refresh_token: response.refresh_token,
          })
        );
        const lastRedirUrl = getAndDeleteRedirect();
        if (lastRedirUrl) {
          navigate(lastRedirUrl);
          return;
        }
        navigate(USER_HOME_PAGE);
      } catch (error: any) {
        setError(error?.data?.message);
        setLoading(false);
        notify("error", error?.data?.message || "oauth_generic_error");
        //navigate(USER_HOME_PAGE)
      }
    };
    handlRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout userType={userType}>
      <Box
        w="100%"
        borderRadius="8px"
        bg="#fff"
        border="1px"
        borderColor={{ base: "border.500", md: "transparent" }}
        p={{ base: "24px", md: "0" }}
      >
        {loading && <Loader />}
        {error && (
          <Alert
            mt="16px"
            w="full"
            status="info"
            fontSize="14px"
            lineHeight="normal"
            padding="24px"
            borderRadius="8px"
            color="infoBlue.500"
            bg="infoBlue.50"
          >
            <Flex flexDirection="column">
              <Flex alignItems="center">
                <AlertIcon color="infoBlue.500" mt="1px" />
                <Text textStyle="largeP" m="6px">
                  {intl.formatMessage({ id: "oauth_login_long_error" })}
                </Text>
              </Flex>
              <Button
                as={RouteLink}
                py="8px"
                to="/auth/login"
                size="md"
                mt="8px"
                variant="link"
              >
                {intl.formatMessage({ id: "auth_back_to_login" })}
              </Button>
            </Flex>
          </Alert>
        )}
      </Box>
    </Layout>
  );
};

export default OauthHandle;
