import { extendTheme } from "@chakra-ui/react";
import type { ComponentStyleConfig } from '@chakra-ui/theme'

const colors = {
  brand: {
    50: "#FFF3F0",
    100: "#FFE8E0",
    500: "#F26739",
    600: "#dd5529",
    700: "#c6481f",
  },
  brandGray: {
    500: "#272F37",
  },
  brandRed: {
    500: "#E65050",
  },
  defaultGray: {
    400: "#696871",
    500: "#5B5B62",
  },
  infoBlue: {
    50: "#E2F3FD",
    500: "#2D3748",
  },
  border: {
    400: "#E7E8E9",
    500: "#CFD1D4",
  },
};

const fonts = {
  body: "sofia-pro, sans-serif", //400, 600, 700
  heading: "quincy-cf, sans-serif", //600
  mono: "Courier, Courier New, monospace",
};

const styles = {
  global: {
    body: {
      background: "#F7F7F8",
      fontSize: "16px",
      lineHeight: "125%",
      letterSpacing: "-.15px",
      color: "#5B5B62",
    },
    p: {
      lineHeight: "1.5"
    },
    h1: {
      fontFamily: "quincy-cf, sans-serif",
      fontSize: ['40px', '40px', '48px'],
      lineHeight: ['38px','38px', '46px'],
      letterSpacing: "-1.5px",
      color: "var(--chakra-colors-brandGray-500)",
    },
    h2: {
      fontSize: "24px",
      lineHeight: "30px",
      fontWeight: "600",
      letterSpacing: "-0.5px",
      color: "var(--chakra-colors-brandGray-500)",
    },
    h3: {
      fontSize: "18px",
      lineHeight: "26px",
      fontWeight: "600",
      letterSpacing: "-0.5px",
      color: "var(--chakra-colors-brandGray-500)",
    },
    h4: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "600",
      letterSpacing: "-0.5px",
      color: "var(--chakra-colors-brandGray-500)",
    },
    h5: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: "700",
      color: "var(--chakra-colors-brandGray-500)",
    },
    h6: {
      fontSize: "11px",
      lineHeight: "16px",
      fontWeight: "700",
      letterSpacing: "1px",
      textTransform: "uppercase", 
      color: "var(--chakra-colors-brandGray-500)",
    },
    blockquote: {
      fontSize: "16px",
      lineHeight: "22px",
      margin: "10px 0",
      padding: "10px 20px",
      borderLeft: "5px solid var(--chakra-colors-brand-500)",
      fontStyle: "italic",
    },
    ul: {
      margin: "10px 0",
      padding: "0 0 0 16px"
    },
    ol: {
      margin: "10px 0",
      padding: "0 0 0 16px"
    },
    th: {
      fontFamily: "sofia-pro, sans-serif !important",
    },
  },
};

//https://github.com/chakra-ui/chakra-ui/blob/main/packages/anatomy/src/index.ts
// export default {
//   // The parts of the component
//   parts: [],
//   // The base styles for each part
//   baseStyle: {},
//   // The size styles for each part
//   sizes: {},
//   // The variant styles for each part
//   variants: {},
//   // The default `size` or `variant` values
//   defaultProps: {},
// }
const Menu: ComponentStyleConfig = { 
  parts: ['menu', 'item'],
  baseStyle: {
    menu: {
    },
    item: {
      px: "16px",
      h: "48px",
      _hover: {
        bg: "#F8FAFC", 
        color: "#000"
      }
    },
  },
};
const Tabs: ComponentStyleConfig = { 
  parts: ['tab', 'tablist', 'tabpanel', 'tabpanels', 'indicator'],
  baseStyle: {
    tab: {
      color: "#13181D",
      textTransform: "uppercase",
      letterSpacing: "1px",
      fontWeight: "700",
    },
  },
};
const Select: ComponentStyleConfig = { 
  parts: ['field', 'control', 'placeholder', 'icon'],
  baseStyle: {
    field: {
      borderTopLeftRadius: "8px !important",
      borderTopRightRadius: "8px !important",
      borderBottomRightRadius: "8px !important",
      borderBottomLeftRadius: "8px !important",
      borderTopColor: "var(--chakra-colors-border-500) !important",
      borderRightColor: "var(--chakra-colors-border-500) !important",
      borderBottomColor: "var(--chakra-colors-border-500) !important",
      borderLeftColor: "var(--chakra-colors-border-500) !important",
      backgroundColor: "#fff",
    },
  },
  sizes: {
    md: {
      field: {
        h: "44px",
      },
    },
    lg: {
      field: {
        h: "54px",
        fontSize: "16px",
      },
    },
  },
};
const Input: ComponentStyleConfig = { 
  parts: ['addon', 'field', 'element'],
  baseStyle: {
    field: {
      borderTopLeftRadius: "8px !important",
      borderTopRightRadius: "8px !important",
      borderBottomRightRadius: "8px !important",
      borderBottomLeftRadius: "8px !important",
      borderTopColor: "var(--chakra-colors-border-500) !important",
      borderRightColor: "var(--chakra-colors-border-500) !important",
      borderBottomColor: "var(--chakra-colors-border-500) !important",
      borderLeftColor: "var(--chakra-colors-border-500) !important",
      backgroundColor: "#fff !important",
    },
  },
  sizes: {
    md: {
      field: {
        h: "44px",
      },
    },
    lg: {
      field: {
        h: "54px",
        fontSize: "16px",
      },
    },
  },
};

const components = {
  Tabs,
  Menu,
  Select,
  Input,
  Button: {
    baseStyle: {
      borderRadius: "8px",
    },
    variants: {
      'solid': {
        bg: "var(--chakra-colors-brand-500)",
        color: "#fff",
        _hover: {
          bg: "var(--chakra-colors-brand-600)",
          _disabled: {
            bg: "var(--chakra-colors-brand-500)",
          },
        },
        _active: {
          bg: "var(--chakra-colors-brand-700)",
        },
      },
      'outline': {
        borderWidth: "2px",
        borderColor: "var(--chakra-colors-brand-500)",
        color: "var(--chakra-colors-brand-500)",
        _hover: {
          bg: "var(--chakra-colors-brand-50)",
          _disabled: {
            bg: "transparent",
          },
        },
        _active: {
          bg: "var(--chakra-colors-brand-100)",
        },
      },
      'ghost': {
        color: "var(--chakra-colors-brand-500)",
        _hover: {
          bg: "var(--chakra-colors-brand-50)",
          _disabled: {
            bg: "transparent",
          },
        },
        _active: {
          bg: "var(--chakra-colors-brand-100)",
        },
        _disabled: {
          pointerEvents: "none",
        },
      },
      'link': {
        color: "var(--chakra-colors-brand-500)",
        textDecoration: "underline",
        _hover: {
          textDecoration: "none",
          _disabled: {
            bg: "transparent",
          },
        },
        _active: {
          textDecoration: "none",
          color: "var(--chakra-colors-brand-700)",
        },
        _disabled: {
          pointerEvents: "none",
        },
      },
    },
    sizes: {
      'md': {
        height: "44px",
      },
      'lg': {
        height: "54px",
      },
    },
  }
};

const layerStyles = {
  formInput: {
    borderRadius: "8px",
    bg: "#fff",
    borderColor: "var(--chakra-colors-border-500)",
  },
  bottomNotification: {
    transition: "all 120ms ease-in-out",
    borderRadius: "8px",
    bg: "var(--chakra-colors-brandGray-500)",
    boxShadow: "0 12px 24px 0 rgba(101,100,109,0.4)",
    display: "flex",
    alignItems: "center",
    padding: "8px 20px 8px 16px",
    color: "#fff",
    _hover: {
      bg: "#111418",
      boxShadow: "0 6px 12px 0 rgba(101,100,109,0.64)",
    },
  },
  videoThumbnail: {
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 16px 32px 0 rgba(0,0,0,0.12)",
    color: "#fff",
    background: "url(/icon-video.svg) no-repeat center var(--chakra-colors-brandGray-500)",
    backgroundSize: "128px",
  },
  card: {
    bg: "#fff",
    borderRadius: "8px",
    padding: "24px",
    boxShadow: "0 8px 16px 0 rgba(39,47,55,0.12)",
    transition: "all 120ms ease-in-out",
    _hover: {
      boxShadow: "0 4px 8px 0 rgba(39,47,55,0.12)",
    }
  },
  dataTypePill: {
    borderRadius: "40px",
    padding: "10px 8px",
    fontSize: "12px",
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontWeight: "700",
    color: "var(--chakra-colors-brandGray-500)",
    width: "auto",
    lineHeight: "1",
    cursor: "pointer",
    transition: ["none", "none", "all 120ms ease-in-out"],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "30px",
    margin: "8px 4px",
    background: "#E7E8E9",
    whiteSpace: "nowrap",
  },
  smallCheckboxPill: {
    borderRadius: "40px",
    padding: "10px 8px",
    fontSize: "12px",
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontWeight: "700",
    color: "var(--chakra-colors-brandGray-500)",
    width: "auto",
    lineHeight: "1",
    cursor: "pointer",
    transition: ["none", "none", "all 120ms ease-in-out"],
    display: "flex",
    alignItems: "center",
    flex: "1",
    height: "30px",
    margin: "0 8px",
    background: "#E7E8E9",
    whiteSpace: "nowrap",
    _hover: {
      color: "#000",
      background: "#D4D6D8",
    },
  },
  smallCheckboxPillSelected: {
    borderRadius: "40px",
    padding: "10px 8px",
    fontSize: "12px",
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontWeight: "700",
    color: "#fff",
    width: "auto",
    lineHeight: "1",
    cursor: "pointer",
    transition: ["none", "none", "all 120ms ease-in-out"],
    display: "flex",
    alignItems: "center",
    flex: "1",
    height: "30px",
    margin: "0 8px",
    background: "#272F37",
    whiteSpace: "nowrap",
    _hover: {
      background: "#62686D",
    },
  },
  checkboxPill: {
    borderRadius: "40px",
    padding: "10px 16px 10px 50px",
    border: "1px solid var(--chakra-colors-border-500)",
    color: "var(--chakra-colors-brandGray-500)",
    width: "auto",
    lineHeight: "1",
    cursor: "pointer",
    transition: ["none", "none", "all 120ms ease-in-out"],
    display: "flex",
    alignItems: "center",
    height: "54px",
    margin: ['8px', '8px', '12px 8px'],
    background: "url(/checkbox.svg) no-repeat 10px center #fff",
    backgroundSize: "32px 32px",
    _hover: {
      color: "#000",
      background: "url(/checkbox-hover.svg) no-repeat 10px center #fff",
      backgroundSize: "32px 32px",
      boxShadow: "0 4px 8px 0 rgba(39,47,55,0.10)",
    },
    _active: {
      color: "#000",
      background: "url(/checkbox-hover.svg) no-repeat 10px center rgba(255,255,255,0.2)",
      backgroundSize: "32px 32px",
      boxShadow: "0 2px 4px 0 rgba(39,47,55,0.10)",
    },
  },
  checkboxPillSelected: {
    borderRadius: "40px",
    padding: "10px 16px 10px 50px",
    border: "1px solid var(--chakra-colors-brand-500)",
    color: "var(--chakra-colors-brandGray-500)",
    boxShadow: "inset 0px 0px 0px 1px var(--chakra-colors-brand-500)",
    lineHeight: "1",
    cursor: "pointer",
    transition: ["none", "none", "all 120ms ease-in-out"],
    display: "flex",
    alignItems: "center",
    height: "54px",
    margin: ['8px', '8px', '12px 8px'],
    background: "url(/checkbox-selected.svg) no-repeat 10px center #fff",
    backgroundSize: "32px 32px",
    _hover: {
      color: "#000",
      background: "url(/checkbox-selected.svg) no-repeat 10px center #fff",
      backgroundSize: "32px 32px",
      boxShadow: "inset 0px 0px 0px 1px var(--chakra-colors-brand-500), 0 4px 8px 0 rgba(39,47,55,0.10)",
    },
    _active: {
      color: "#000",
      background: "url(/checkbox-selected.svg) no-repeat 10px center rgba(255,255,255,0.2)",
      backgroundSize: "32px 32px",
      boxShadow: "inset 0px 0px 0px 1px var(--chakra-colors-brand-500), 0 2px 4px 0 rgba(39,47,55,0.10)",
    },
  },
  stepCounter: {
    height: "8px",
    borderRadius: "20px",
    bg: "var(--chakra-colors-border-500)",
    margin: "0 6px 24px 6px",
    flex: "1",
    maxWidth: "54px",
  },
  stepCounterActive: {
    height: "8px",
    borderRadius: "20px",
    bg: "var(--chakra-colors-brand-500)",
    margin: "0 6px 24px 6px",
    flex: "1",
    maxWidth: "54px",
  },
  actionBadge: {
    borderRadius: "100px",
    fontSize: "12px",
    lineHeight: "1",
    textTransform: "uppercase",
    letterSpacing: "2px",
    color: "var(--chakra-colors-brandGray-500)",
    bg: "var(--chakra-colors-border-400)",
    padding: "8px 10px",
    transition: "all 120ms ease-in-out",
    cursor: "pointer",
    fontWeight: "700",
    display: "inline-flex",
    base: {
      borderRadius: "100px",
      fontSize: "12px",
      lineHeight: "1",
      textTransform: "uppercase",
      letterSpacing: "2px",
      color: "var(--chakra-colors-brandGray-500)",
      bg: "var(--chakra-colors-border-400)",
      padding: "8px 10px",
      transition: "all 120ms ease-in-out",
      cursor: "pointer",
      fontWeight: "700",
      display: "inline-flex",
    },
    selected: {
      borderRadius: "100px",
      fontSize: "12px",
      lineHeight: "1",
      textTransform: "uppercase",
      letterSpacing: "2px",
      padding: "8px 10px",
      transition: "all 120ms ease-in-out",
      color: "#fff",
      bg: "var(--chakra-colors-brandGray-500)",
      fontWeight: "700",
      display: "inline-flex",
    }
  }
};

const textStyles = {
  largeP: {
    fontSize: "18px",
    lineHeight: "26px",
  },
  smallLabel: {
    fontSize: "12px",
    lineHeight: "normal",
    color: "var(--chakra-colors-brandGray-500)",
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontWeight: "700",
  },
};

const theme = extendTheme({ colors, fonts, styles, textStyles, layerStyles, components });

export default theme;
