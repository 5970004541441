import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useToast } from "@chakra-ui/toast";
import {
  Input,
  Button,
  Box,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { Email } from "@carbon/icons-react";
import { Form, FormControl } from "../../shared/form";
import { validateForm } from "../../utils/forms";
import { forgotPasswordValidationRules } from "../../utils/forms/validators";
import { useResetPasswordRequestMutation } from "../../user/service";

const ForgotPasswordForm = () => {
  const intl = useIntl();
  const toast = useToast();

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<null | string>(null);

  const [resetPasswordRequest, { isLoading }] =
    useResetPasswordRequestMutation();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = validateForm(
      { email },
      forgotPasswordValidationRules
    );

    if (!validationErrors) {
      setEmailError(null);
      try {
        await resetPasswordRequest(email).unwrap();
        toast({
          title: intl.formatMessage({
            id: "auth_forgot_password_success_message",
          }),
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.log("Error", error);
        toast({
          title: intl.formatMessage({
            id: "auth_forgot_password_error_message",
          }),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      setEmailError(validationErrors["email"]);
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError(null);
    setEmail(e.target.value);
  };

  return (
    <Box w="100%">
      <Form onSubmit={handleLogin} mt="16px">
        <FormControl errorMessage={emailError}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              opacity=".54"
              pt="3px"
              px="8px"
              h="100%"
              children={<Email size={20}color="#696871" />}
            />
            <Input
              placeholder={intl.formatMessage({ id: "auth_email" })}
              type="email"
              name="email"
              value={email}
              size="lg"
              onChange={(e) => onInputChange(e)}
              required
            />
          </InputGroup>
        </FormControl>
        <Button
          type="submit"
          w="full"
          size="lg"
          mt="16px"
          isLoading={isLoading}
          isDisabled={email.length === 0}
        >
          {intl.formatMessage({ id: "auth_forgot_password_btn" })}
        </Button>
      </Form>
    </Box>
  );
};

export default ForgotPasswordForm;
