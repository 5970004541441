import { useCountdown } from "./useCountdown";
import ShowCounter from "./ShowCounter";
import { Box } from "@chakra-ui/react";
import { useIntl } from "react-intl";

type Props = {
  targetDate: string;
};

const CountdownTimer = ({ targetDate }: Props) => {
  const intl = useIntl();

  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return (
      <>
        <Box opacity=".64">{intl.formatMessage({ id: "meeting_started" })}</Box>
      </>
    );
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
