import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Text, Flex, Button } from "@chakra-ui/react";
import Steps from "../../shared/Steps";
import IssueToFixCheckbox from "./IssueToFixCheckbox";
import conditions from "../../conditions";

const Step2 = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [selectedIssuesToFix, setSelectedIssuesToFix] = useState<string[]>([]);

  const onCheckIssueToFix = (issue: string, isChecked: boolean) => {
    let updatedSelectedIssuesToFix = [...selectedIssuesToFix];
    if (isChecked) {
      updatedSelectedIssuesToFix = [...updatedSelectedIssuesToFix, issue];
    } else {
      updatedSelectedIssuesToFix = updatedSelectedIssuesToFix.filter(
        (selectedIssue) => selectedIssue !== issue
      );
    }
    setSelectedIssuesToFix(updatedSelectedIssuesToFix);
  };

  const handleMoveToNextStep = () => {
    navigate("/onboarding/step/3", {
      state: { issues_to_fix: selectedIssuesToFix },
    });
  };

  const handleSkipToNextStep = () => {
    navigate("/onboarding/step/3", {
      state: { issues_to_fix: selectedIssuesToFix },
    });
  };

  return (
    <Steps activeStep={2} stepCount={4}>
      <h1>{intl.formatMessage({ id: "user_onboarding_step2_title" })}</h1>
      <Text textStyle="largeP" mt="12px">
        {intl.formatMessage({ id: "user_onboarding_step2_subtitle" })}
      </Text>
      <Flex
        m="12px auto"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        maxW="800px"
      >
        {conditions.map((condition, idx) => (
          <IssueToFixCheckbox
            key={idx}
            issue={condition}
            onCheckIssueToFix={onCheckIssueToFix}
          />
        ))}
      </Flex>
      <Button
        w="full"
        maxW={{ base: "100%", md: "310px" }}
        m="auto"
        size="lg"
        disabled={selectedIssuesToFix.length === 0}
        onClick={handleMoveToNextStep}
      >
        {intl.formatMessage({ id: "user_onboarding_continue_btn_label" })}
      </Button>
      <Text m="16px 0 24px 0" color="#696871" fontWeight="600">
        <Button
          variant="link"
          onClick={handleSkipToNextStep}
        >
          {intl.formatMessage({ id: "user_onboarding_step2_link_label" })}
        </Button>
      </Text>
    </Steps>
  );
};

export default Step2;
