import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Button, Grid, Flex } from "@chakra-ui/react";
import { Document } from "@carbon/icons-react";
import { useGetArticlesPerAuthorQuery } from "../service";
import ArticlePreview from "./ArticlePreview";
import Article from "./Article";
import Loader from "../../shared/Loader";
import serialize from "../../utils/serialize";
import { IArticle } from "../types";

const ARTICLES_LIMIT = 10;

type Props = { authorGuid: string };

const Articles = ({ authorGuid }: Props) => {
  const intl = useIntl();

  const [articles, setArticles] = useState<IArticle[]>([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | null>(null);

  const [selectedArticle, setSelectedArticle] = useState<IArticle | null>(null);

  const { data, isLoading, isFetching } = useGetArticlesPerAuthorQuery({
    u_guid: authorGuid,
    limit: ARTICLES_LIMIT,
    last_evaluated_key: lastEvaluatedKey,
  });

  useEffect(() => {
    const newArticles = data?.data || [];
    if (newArticles.length > 0) {
      setArticles((articles) => [...articles, ...newArticles]);
    }
  }, [data]);

  const meta = data?.meta || null;
  const hasMore = meta?.has_more || false;
  const serializedLastEvaluatedKey = meta?.last_evaluated_key
    ? serialize(meta.last_evaluated_key)
    : "";

  const handleLoadMore = () => {
    setLastEvaluatedKey(serializedLastEvaluatedKey);
  };

  const handleShowArticle = (article: IArticle) => {
    setSelectedArticle(article);
  };

  return (
    <>
      {isLoading && (
        <>
          <h2>{intl.formatMessage({ id: "activity_details_articles" })}</h2>
          <Loader />
        </>
      )}

      {articles.length > 0 && !isLoading && !selectedArticle && (
        <>
          <h2>{intl.formatMessage({ id: "activity_details_articles" })}</h2>
          <Grid templateColumns="repeat(1, 1fr)" gap={4} mt="16px">
            {articles.map((article, idx) => (
              <Box key={idx} onClick={() => handleShowArticle(article)}>
                <ArticlePreview key={idx} article={article} />
              </Box>
            ))}
          </Grid>

          {hasMore && (
            <Button
              mt="16px"
              size="lg"
              isLoading={isFetching}
              onClick={handleLoadMore}
            >
              {intl.formatMessage({ id: "articles_load_more" })}
            </Button>
          )}
        </>
      )}
      {articles.length === 0 && !isLoading && !selectedArticle && (
        <>
          <h2>{intl.formatMessage({ id: "activity_details_articles" })}</h2>
          <Box layerStyle="card" mt="16px">
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              textAlign="center"
            >
              <Flex
                borderRadius="100%"
                alignItems="center"
                justifyContent="center"
                color="#fff"
                w="80px"
                h="80px"
                bg="gray.500"
                mb="16px"
              >
                <Document size={32}/>
              </Flex>
              <>
                <h2>{intl.formatMessage({ id: "articles_no_data" })}</h2>
              </>
            </Flex>
          </Box>
        </>
      )}

      {selectedArticle && (
        <>
          <Article
            handleGoBack={() => setSelectedArticle(null)}
            article={selectedArticle}
          />
        </>
      )}
    </>
  );
};

export default Articles;
