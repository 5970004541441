import { useIntl } from "react-intl";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Articles from "../../../articles/RegisteredUserArticles/Articles";
import Description from "../Description";
import Testimonials from "../Testimonials";
import { IActivity } from "../../types";
import { IActivityProvider } from "../../../activityProvider/types";

type Props = {
  activity: IActivity;
  activityProvider: IActivityProvider;
};

const ActivityDetailsTabs = ({ activity, activityProvider }: Props) => {
  const intl = useIntl();

  return (
    <Tabs colorScheme="brand">
      <TabList
        position="sticky"
        top={{ base: "64px", md: "80px" }}
        bg="#F7F7F8"
        zIndex="2"
      >
        <Tab fontSize="13px" px="0" mr="24px">
          {intl.formatMessage({ id: "activity_details_description" })}
        </Tab>
        <Tab fontSize="13px" px="0" mr="24px">
          {intl.formatMessage({ id: "activity_details_testimonials" })}{" "}
          {`(${activity.reviews_no})`}
        </Tab>
        <Tab fontSize="13px" px="0">
          {intl.formatMessage({ id: "activity_details_articles" })}
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel px="0" py="24px">
          <Description
            activity={activity}
            activityProvider={activityProvider}
          />
        </TabPanel>
        <TabPanel px="0" py="24px">
          <Testimonials activityGuid={activity.guid} />
        </TabPanel>
        <TabPanel px="0" py="24px">
          <Articles authorGuid={activity.executed_by_guid} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ActivityDetailsTabs;
