import Layout from "../../shared/Layout";
import Navbar from "../../shared/Navbar";
import MainContainer from "../../shared/MainContainer";
import { FormattedMessage } from "react-intl";
import { Flex, Text } from "@chakra-ui/react";

const EnterWait = () => {
  return (
    <Layout>
      <>
        <Navbar userType="registered_user" hideNavigation allowLogoutOnly />
        <MainContainer>
          <Flex mt="10" justifyContent="center" width="100%">
            <Flex direction="column" mt="10" maxWidth="xl">
              <h1>
                <FormattedMessage
                  id="user_onboarding_enter_title"
                />
              </h1>
              <Text textStyle="largeP" mt="12px">
                <FormattedMessage
                  id="user_onboarding_enter_subtitle"
                />
              </Text>
            </Flex>
          </Flex>
        </MainContainer>
      </>
    </Layout >
  );
};

export default EnterWait;
