import { SimpleGrid, Show, Link, Flex, Box } from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import Logo from "../../shared/Logo";
import Layout from "../../shared/Layout";
import AuthSideLayout from "./AuthSideLayout";
import GDPR from "./GDPR";

type Props = {
  children: React.ReactNode;
  userType: string;
};

const AuthLayout = ({ children, userType }: Props) => {
  const imageUrl =
    userType === "activity_provider"
      ? "/auth-image.jpeg"
      : "/auth-image-client.jpeg";
  const logoImage =
    userType === "activity_provider" ? "/logo.svg" : "/logo.svg";
  const logoRouteUrl =
    userType === "activity_provider" ? "/ap/auth/login" : "/";
  return (
    <Layout>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        h="100%"
        bg={{ base: "inherit", md: "#fff" }}
      >
        <AuthSideLayout
          imageUrl={imageUrl}
          logoImage={logoImage}
          logoRouteUrl={logoRouteUrl}
        />
        <Flex
          p="16px"
          width="100%"
          maxW={{ base: "100%", md: "352px" }}
          m="auto"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Show below="md">
            <Box mb="24px" w="full">
              <Link
                as={RouteLink}
                to={logoRouteUrl}
                display="inline-flex"
                mr="auto"
              >
                <Logo src={logoImage} />
              </Link>
            </Box>
          </Show>
          {children}
        </Flex>
        <GDPR />
      </SimpleGrid>
    </Layout>
  );
};

export default AuthLayout;
