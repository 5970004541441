import { useIntl } from "react-intl";
import { HrefLink, NavLink } from "./NavLink";
import { useLocation } from "react-router-dom";
import {
  Stethoscope,
  EventSchedule,
  SettingsAdjust,
  Email,
  DocumentDownload,
} from "@carbon/icons-react";
import { EMAIL_ADDRESS } from "../config";

const ClientNav = () => {
  const intl = useIntl();
  const location = useLocation();
  const current_page = location.pathname;

  return (
    <>
      <NavLink
        to="/activities"
        label={intl.formatMessage({ id: "navbar_activities_label" })}
        icon={Stethoscope}
        iconSize={20}
        isActive={current_page === "/activities"}
      />
      <NavLink
        to="/bookings"
        label={intl.formatMessage({ id: "navbar_bookings_label" })}
        icon={EventSchedule}
        iconSize={20}
        isActive={current_page === "/bookings"}
      />
      <HrefLink
        to="https://app.psiholog.ro/ghid-de-utilizare-psiholog.ro.pdf"
        target="_blank"
        label={intl.formatMessage({ id: "navbar_guide_label" })}
        icon={DocumentDownload}
        iconSize={20}
      />
      <HrefLink
        to={`mailto:${EMAIL_ADDRESS}`}
        target="_blank"
        label={intl.formatMessage({ id: "navbar_contact_label" })}
        icon={Email}
        iconSize={20}
      />
    </>
  );
};
export default ClientNav;
