import React from 'react'
import { Box, Divider, Flex, Editable, EditablePreview, EditableTextarea, Text, useDisclosure } from '@chakra-ui/react'
import { INote } from './types'
import NoteMenu from './NoteMenu'
import { useState } from 'react';
import moment from 'moment';
import { useRemoveNoteMutation, useUpdateNoteMutation } from './service';
import ConfirmDialog from '../shared/ConfirmDialog';
import useNotification from '../shared/hooks/useNotification';

type Props = {
    note: INote,
    featured?: boolean
}

export default function Note({ note, featured }: Props) {
    const previewRef: any = React.useRef()
    const textRef: any = React.useRef()
    const date = moment(note.c_at).format("DD MMMM yyyy")
    const notify = useNotification()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [text, setNoteText] = useState(note.note)
    const [edit, setEditableState] = useState(false)
    const [removeNote, { isLoading }] = useRemoveNoteMutation()
    const [updateNote] = useUpdateNoteMutation()
    const deleteNote = () => {
        removeNote(note.guid).then(() => {
            notify('success', 'remove_note_success')
        }, () => {
            notify('error', 'ap_onboarding_step1_error')
        }).finally(() => {
            onClose()
        }).catch(() => {
            notify('error', 'ap_onboarding_step1_error')
        })
    }
    const makeImportant = (isImportant: boolean) => {
        updateNote({ guid: note.guid, data: { featured: isImportant } }).then(() => {
            notify('success', 'note_success_update')
        }, () => {
            notify('error', 'ap_onboarding_step1_error')
        }).catch(() => {
            notify('error', 'ap_onboarding_step1_error')
        })
    }
    const toggleEdit = (enable: boolean) => {
        if (enable) {
            setEditableState(true)
            previewRef.current.setAttribute('hidden', true)
            textRef.current.removeAttribute('hidden')
            textRef.current.focus()
        } else {
            setEditableState(false)
            textRef.current.setAttribute('hidden', true)
            previewRef.current.removeAttribute('hidden')
        }
    }
    const submit = () => {
        if (text === note.note) {
            toggleEdit(false)
        } else {
            updateNote({ guid: note.guid, data: { note: text } }).then(() => {
                notify('success', 'note_success_update')
            }, () => {
                notify('error', 'ap_onboarding_step1_error')
            }).catch(() => {
                notify('error', 'ap_onboarding_step1_error')
            }).finally(() => {
                toggleEdit(false)
            })
        }
    }
    return (
        <Box>
            <ConfirmDialog isOpen={isOpen} makeAction={deleteNote} onClose={onClose} isLoading={isLoading} />
            <Divider borderColor="border.400" opacity={featured?"0.1":"1"} mt="16px" mb="0" />
            <Flex alignItems="center" justifyContent="space-between">
                <Flex alignItems="center">
                    <Box
                        w="12px"
                        h="12px"
                        borderRadius="100%"
                        borderWidth="2px"
                        borderColor="brand.500"
                        mr="8px"
                        bg={featured ? "brand.500" : ""}
                    />
                    <Text
                        fontSize="10px"
                        letterSpacing="1px"
                        textTransform="uppercase"
                        lineHeight="normal"
                        fontWeight="700"
                        opacity=".64"
                    >
                        {date}
                    </Text>
                </Flex>
                <NoteMenu editNote={() => toggleEdit(true)} removeNote={onOpen} makeImportant={makeImportant} featured={featured} />
            </Flex>
            <Editable
                defaultValue={text}
                mt="-12px"
                pl="19px"
                isPreviewFocusable={edit}
                onSubmit={submit}
                onChange={(newText) => setNoteText(newText)}
            >
                <EditablePreview ref={previewRef} />
                <EditableTextarea ref={textRef} />
            </Editable>
        </Box>
    )
}
