import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { IRoles } from "../types";
import getUserRoles from "../userRoles";

const useGetUserRoles = (): IRoles[] => {
  const accessToken = useSelector((state: RootState) => state.iam.access_token);
  return getUserRoles(accessToken);
};

export default useGetUserRoles;
