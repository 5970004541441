import { useIntl } from "react-intl";
import {
  Box,
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
} from "@chakra-ui/react";
import { useDeleteBookingMutation, useResumeBookingMutation } from "../service";
import { activityApi } from "../../activity/service";
import { useAppDispatch } from "../../hooks";
import useNotification from "../../shared/hooks/useNotification";
import ActivityMeta from "../../shared/ActivityMeta";
import { toLocalddddDMY, toLocalHM } from "../utils";

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  start: string;
  end: string;
  a_guid: string;
  b_guid: string;
};

const ResumeBookingPaymentModal = ({
  isOpen,
  onCloseModal,
  start,
  end,
  a_guid,
  b_guid,
}: Props) => {
  const intl = useIntl();
  const notify = useNotification();
  const dispatch = useAppDispatch();

  const [resumeBooking, { isLoading }] = useResumeBookingMutation();
  const [deleteBooking, { isLoading: isDeletingBooking }] =
    useDeleteBookingMutation();

  const handleResumeBooking = () => {
    resumeBooking({ a_guid, b_guid })
      .unwrap()
      .then((response) => {
        const paymentLink = response.url || "";
        window.location.href = paymentLink;
        onCloseModal();
      })
      .catch(() => {
        onCloseModal();
        notify("error", "resume_booking_error");
      });
  };

  const handleCancelBooking = () => {
    deleteBooking({ a_guid, b_guid })
      .unwrap()
      .then((response) => {
        dispatch(activityApi.util.invalidateTags(["Activity"]));
        const isDeleted = response.deleted;
        if (isDeleted) {
          notify("success", "delete_booking_success");
          onCloseModal();
        } else {
          notify("error", "delete_booking_error");
          onCloseModal();
        }
      })
      .catch(() => {
        onCloseModal();
        notify("error", "delete_booking_error");
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered={true}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          borderBottom="1px solid var(--chakra-colors-border-400)"
          textAlign="center"
        >
          <h2>{intl.formatMessage({ id: "resume_booking_payment" })}</h2>
        </ModalHeader>
        <ModalCloseButton w="44px" h="44px" />
        <ModalBody py="16px">
          <Flex alignItems="center" justifyContent="space-between">
            <ActivityMeta guid={a_guid} />
            <Divider orientation="vertical" h="44px" opacity="1" />
            <Box textAlign="right">
              <Text
                fontSize="18px"
                lineHeight="normal"
                color="brandGray.500"
                fontWeight="600"
              >
                {toLocalddddDMY(start)}
              </Text>
              <Text>Ora {toLocalHM(start)} - {toLocalHM(end)}</Text>
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter borderTop="1px solid var(--chakra-colors-border-400)">
          <Box>
            <Button
              variant="outline"
              w="full"
              size="lg"
              mb="16px"
              isLoading={isDeletingBooking}
              onClick={handleCancelBooking}
            >
              {intl.formatMessage({
                id: "resume_booking_payment_cancel_btn",
              })}
            </Button>

            <Button
              w="full"
              size="lg"
              isLoading={isLoading}
              onClick={handleResumeBooking}
            >
              {intl.formatMessage({ id: "resume_booking_payment_btn" })}
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ResumeBookingPaymentModal;
