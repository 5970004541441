import {
    Box,
} from '@chakra-ui/react'
type Props = {
    children: JSX.Element | string,
    containerHeight?: string,
};

const MainContainer = ({ children, containerHeight }: Props) => {
    return (
        <Box flex="1" pt={{ base: "64px", lg: "80px" }} zIndex="1" position="relative" height={containerHeight}>
            <Box
                py={{ base: "24px", lg: "48px" }}
                px={{ base: "16px", lg: "32px" }}
                maxW="1200px"
                margin="auto"
                height={containerHeight}
            >
                {children}
            </Box>
        </Box>
    )
};

export default MainContainer;