import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import { Box, Text, Button } from "@chakra-ui/react";
import Layout from "../layout";
import ActivityProviderRegisterForm from "../forms/ActivityProviderRegisterForm";
import { AP_HOME_PAGE } from "../../routes/defaults";
import { RootState } from "../../store";

const ActivityProviderSignUp = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const accessToken = useSelector((state: RootState) => state.iam.access_token);

  useEffect(() => {
    if (accessToken) {
      navigate(AP_HOME_PAGE);
    }
  }, [accessToken, navigate]);

  return (
    <Layout userType="activity_provider">
      <Box
        borderRadius="8px"
        w="100%"
        bg="#fff"
        border="1px"
        borderColor={{ base: "border.500", md: "transparent" }}
        p={{ base: "24px", md: "0" }}
      >
        <h1>{intl.formatMessage({ id: "auth_register_ap_title" })}</h1>
        <Text textStyle="largeP" mt="12px">
          {intl.formatMessage({ id: "auth_register_ap_subtitle" })}
        </Text>

        <ActivityProviderRegisterForm />

        <Text
          fontWeight="600"
          display="flex"
          alignItems="center"
          w="full"
          justifyContent="center"
        >
          <Text>
            {intl.formatMessage({ id: "auth_registered_user_message" })} &nbsp;
          </Text>
          <Button
            as={RouteLink}
            display="inline-flex"
            to="/ap/auth/login"
            size="md"
            variant="link"
          >
            {intl.formatMessage({ id: "auth_login_incentive" })}
          </Button>
        </Text>
      </Box>
    </Layout>
  );
};

export default ActivityProviderSignUp;
