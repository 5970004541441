import * as React from "react";
import {
  Link,
  LinkProps,
  Text,
  Icon,
  Show,
  Flex,
} from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";

interface NavLinkProps extends LinkProps {
  isActive?: boolean;
  label: string;
  icon?: React.ElementType;
  iconSize?: number;
  to: string;
  pageId?: string;
}

export const NavLink = (props: NavLinkProps) => {
  const { icon, isActive, label, to, pageId, iconSize, ...rest } = props;

  return (
    <Link
      as={RouteLink}
      to={to}
      display="block"
      py="2"
      mr={{ base: "0", lg: "32px" }}
      flex={{ base: "1", lg: "auto" }}
      transition="all 120ms ease-in-out"
      userSelect="none"
      lineHeight="100%"
      borderBottom={{ base: "0", lg: "2px" }}
      borderTop={{ base: "2px", lg: "0" }}
      borderColor={{ base: "transparent", lg: "transparent" }}
      aria-current={isActive ? "page" : undefined}
      color="brandGray.500"
      _hover={{
        color: "brandGray.500",
        borderColor: "brandGray.500",
      }}
      _activeLink={{
        color: "brand.500",
        borderColor: "brand.500"
      }}
      {...rest}
    >
      <Flex justifyContent="center" alignItems="center" flexDirection="column" textAlign="center">
        <Show below="lg">
          <Icon as={icon} fontSize="20px" mb="4px" iconSize={iconSize}/>
        </Show>
        <Text as="span">{label}</Text>
      </Flex>
    </Link>
  );
};

export const HrefLink = (props: NavLinkProps) => {
  const { icon, isActive, label, to, pageId, iconSize,...rest } = props;

  return (
    <Link
      href={to}
      display="block"
      py="2"
      mr={{ base: "0", lg: "32px" }}
      flex={{ base: "1", lg: "auto" }}
      transition="all 120ms ease-in-out"
      userSelect="none"
      lineHeight="100%"
      borderBottom={{ base: "0", lg: "2px" }}
      borderTop={{ base: "2px", lg: "0" }}
      borderColor={{ base: "transparent", lg: "transparent" }}
      aria-current={isActive ? "page" : undefined}
      color="brandGray.500"
      _hover={{
        color: "brandGray.500",
        borderColor: "brandGray.500",
      }}
      _activeLink={{
        color: "brand.500",
        borderColor: "brand.500"
      }}
      {...rest}
    >
      <Flex justifyContent="center" alignItems="center" flexDirection="column" textAlign="center">
        <Show below="lg">
          <Icon as={icon} fontSize="20px" mb="4px" size={iconSize}/>
        </Show>
        <Text as="span">{label}</Text>
      </Flex>
    </Link>
  );
};