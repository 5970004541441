import { useState } from "react";
import { useIntl } from "react-intl";
import {
    Box, Stack, Button, Divider, Flex, Text, FormControl,
    FormLabel,
    FormHelperText, Input
} from "@chakra-ui/react";
import { IActivityProvider } from "./types";
import useNotification from "../shared/hooks/useNotification";

type Props = {
    activityProvider: IActivityProvider;
    updating: boolean;
    updateActivityProvider: (activityProvider: IActivityProvider) => void;
};

const InvoiceInformationForm = ({
    activityProvider,
    updating,
    updateActivityProvider,
}: Props) => {
    const intl = useIntl();
    const notify = useNotification();
    const [formValues, setFormValues] = useState(activityProvider)



    const submit = () => {
        if (
            (formValues.business_name || "").length > 30 ||
            (formValues.business_tax_id || "").length > 30 ||
            (formValues.business_reg_no || "").length > 30 ||
            (formValues.business_address_1 || "").length > 30 ||
            (formValues.business_address_2 || "").length > 30
        ) {
            notify("error", "invoice_details_greater_than_30");
            return;
        }

        const updatedActivityProvider: IActivityProvider = {
            ...activityProvider,
            business_name: formValues.business_name,
            business_tax_id: formValues.business_tax_id,
            business_reg_no: formValues.business_reg_no,
            business_address_1: formValues.business_address_1,
            business_address_2: formValues.business_address_2
        };
        updateActivityProvider(updatedActivityProvider);
    };

    return (
        <Box layerStyle="card" mt="16px">
            <Stack spacing="5">
                <Stack
                    spacing="4"
                    direction={{ base: "column", sm: "row" }}
                    justify="space-between"
                >
                    <Box>
                        <Text fontSize="lg" fontWeight="bold" color="brandGray.500">
                            {intl.formatMessage({ id: "my_account_invoice_title" })}
                        </Text>
                        <Text color="muted" fontSize="sm">
                            {intl.formatMessage({ id: "my_account_invoice_subtitle" })}
                        </Text>
                    </Box>
                    <Button alignSelf="start" isLoading={updating} onClick={submit}>
                        {intl.formatMessage({ id: "save_btn" })}
                    </Button>
                </Stack>
                <Divider />
                <FormControl>
                    <Stack
                        direction={{ base: "column", lg: "row" }}
                        spacing={{ base: "1.5", lg: "8" }}
                        justify="space-between"
                    >
                        <Box>
                            <FormLabel
                                variant="inline"
                                fontWeight="600"
                                color="brandGray.500"
                            >
                                {intl.formatMessage({ id: "business_name" })}
                            </FormLabel>
                            <FormHelperText mt="0" color="muted">
                                {intl.formatMessage({ id: "business_name_subtitle" })}
                            </FormHelperText>
                        </Box>
                        <Box w="full" maxW={{ lg: "3xl" }}>
                            <Input
                                placeholder=""
                                value={formValues.business_name}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, business_name: e.target.value })
                                }
                            />
                        </Box>
                    </Stack>
                </FormControl>
                <FormControl>
                    <Stack
                        direction={{ base: "column", lg: "row" }}
                        spacing={{ base: "1.5", lg: "8" }}
                        justify="space-between"
                    >
                        <Box>
                            <FormLabel
                                variant="inline"
                                fontWeight="600"
                                color="brandGray.500"
                            >
                                {intl.formatMessage({ id: "business_tax_id" })}
                            </FormLabel>
                            <FormHelperText mt="0" color="muted">
                                {intl.formatMessage({ id: "business_tax_id_subtitle" })}
                            </FormHelperText>
                        </Box>
                        <Box w="full" maxW={{ lg: "3xl" }}>
                            <Input
                                placeholder=""
                                value={formValues.business_tax_id}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, business_tax_id: e.target.value })
                                }
                            />
                        </Box>
                    </Stack>
                </FormControl>
                <FormControl>
                    <Stack
                        direction={{ base: "column", lg: "row" }}
                        spacing={{ base: "1.5", lg: "8" }}
                        justify="space-between"
                    >
                        <Box>
                            <FormLabel
                                variant="inline"
                                fontWeight="600"
                                color="brandGray.500"
                            >
                                {intl.formatMessage({ id: "business_reg_no" })}
                            </FormLabel>
                            <FormHelperText mt="0" color="muted">
                                {intl.formatMessage({ id: "business_reg_no_subtitle" })}
                            </FormHelperText>
                        </Box>
                        <Box w="full" maxW={{ lg: "3xl" }}>
                            <Input
                                placeholder=""
                                value={formValues.business_reg_no}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, business_reg_no: e.target.value })
                                }
                            />
                        </Box>
                    </Stack>
                </FormControl>
                <FormControl>
                    <Stack
                        direction={{ base: "column", lg: "row" }}
                        spacing={{ base: "1.5", lg: "8" }}
                        justify="space-between"
                    >
                        <Box>
                            <FormLabel
                                variant="inline"
                                fontWeight="600"
                                color="brandGray.500"
                            >
                                {intl.formatMessage({ id: "business_address_1" })}
                            </FormLabel>
                            <FormHelperText mt="0" color="muted">
                                {intl.formatMessage({ id: "business_address_1_subtitle" })}
                            </FormHelperText>
                        </Box>
                        <Box w="full" maxW={{ lg: "3xl" }}>
                            <Input
                                placeholder=""
                                value={formValues.business_address_1}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, business_address_1: e.target.value })
                                }
                            />
                        </Box>
                    </Stack>
                </FormControl>
                <FormControl>
                    <Stack
                        direction={{ base: "column", lg: "row" }}
                        spacing={{ base: "1.5", lg: "8" }}
                        justify="space-between"
                    >
                        <Box>
                            <FormLabel
                                variant="inline"
                                fontWeight="600"
                                color="brandGray.500"
                            >
                                {intl.formatMessage({ id: "business_address_2" })}
                            </FormLabel>
                            <FormHelperText mt="0" color="muted">
                                {intl.formatMessage({ id: "business_address_2_subtitle" })}
                            </FormHelperText>
                        </Box>
                        <Box w="full" maxW={{ lg: "3xl" }}>
                            <Input
                                placeholder=""
                                value={formValues.business_address_2}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, business_address_2: e.target.value })
                                }
                            />
                        </Box>
                    </Stack>
                </FormControl>
                <Divider />
                <Stack spacing="5">
                    <Flex direction="row-reverse">
                        <Button onClick={submit} isLoading={updating}>
                            {intl.formatMessage({ id: "save_btn" })}
                        </Button>
                    </Flex>
                </Stack>
            </Stack>
        </Box>
    );
};

export default InvoiceInformationForm;
