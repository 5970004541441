import Layout from "../../shared/Layout";
import Navbar from "../../shared/Navbar";
import MainContainer from "../../shared/MainContainer";
import BottomNavigation from "../../shared/BottomNavigation";
import EditArticle from "../ActivityProviderArticles/EditArticle";

const ActivityProviderEditArticlePage = () => {
  return (
    <Layout>
      <>
        <Navbar userType="activity_provider" />
        <MainContainer>
          <EditArticle />
        </MainContainer>
        <BottomNavigation userType="activity_provider" />
      </>
    </Layout>
  );
};

export default ActivityProviderEditArticlePage;
