export const translations = {
  anxiety: "anxietate",
  burnout: "burnout",
  depression: "depresie",
  insomnia: "insomnie",
  stress: "stress",
  low_confidence: "încredere scăzută",
  sadness: "tristețe",
  loneliness: "singurătate",
  sexuality: "sexualitate",
  anger: "furie",
  motivation: "motivație",
  trauma: "traumă",
  ADHD: "ADHD",
  addiction: "dependență",
  abuse: "abuz",
  cognitive_behavioral_psychotherapy: "Psihoterapie cognitiv-comportamentală",
  systemic_family_and_couple_psychotherapy: "Psihoterapie sistemică de familie și cuplu",
  adlerian_psychotherapy: "Psihoterapia adleriană",
  integrative_psychotherapy: "Psihoterapie integrativă",
  clinical_hypnosis_and_ericksonian_therapy:
    "Hipnoză clinică și terapie ericksoniană",
  experiential_psychotherapy: "Psihoterapie experențială",
  psychoanalytic_psychotherapy: "Psihoterapie psihanalitică",
  clinical_psychotherapy: "Psihoterapie clinică",
  psychological_counseling: "Consiliere psihologică",
  emdr: "EMDR",
  ericksonian_hypnosis: "Hipnoză Ericksoniană",
  relaxation_techniques: "Tehnici de relaxare",
  cognitive_restructuring: "Restructurare cognitivă",
  breathing_techniques: "Tehnici de Respirație",
  short_solution_focused_therapies: "Terapii scurte centrate pe soluții",
  mindfullness: "Mindfullness",
  narrative_therapy: "Terapia narativă",
  grief_therapy: "Terapia doliului",
};
