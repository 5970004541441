import { Flex } from "@chakra-ui/react";
import Div100vh from "react-div-100vh";
import ScrollToTop from "./ScrollToTop";
type Props = {
  children: JSX.Element | string;
};

const Layout = ({ children }: Props) => {
  return (
    <Div100vh>
      <ScrollToTop />
      <Flex h="100%" flexDirection="column" w="full" justifyContent="stretch">
        {children}
      </Flex>
    </Div100vh>
  );
};

export default Layout;
