import { validate } from "validate.js";
import {
  registerValidationRules,
  loginValidationRules,
  forgotPasswordValidationRules,
  resetPasswordValidationRules,
  articleValidationRules,
  onboardingValidationRules,
  registerActivityProviderValidationRules,
  changeEmailValidationRules
} from "./validators";

type ValidationRules =
  | typeof registerValidationRules
  | typeof loginValidationRules
  | typeof forgotPasswordValidationRules
  | typeof articleValidationRules
  | typeof resetPasswordValidationRules
  | typeof onboardingValidationRules
  | typeof registerActivityProviderValidationRules
  | typeof changeEmailValidationRules

export const validateForm = (
  formValues: Record<string, string | boolean>,
  validationRules: ValidationRules
): Record<string, string> | null => {
  const errors = validate(formValues, validationRules);

  if (errors) {
    const stringifiedErrors: Record<string, string> = {};
    for (const error in errors) {
      stringifiedErrors[error] = errors[error][0];
    }

    return stringifiedErrors;
  } else {
    return null;
  }
};