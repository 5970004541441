import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Text, Box, Button } from "@chakra-ui/react";
import { useGetLoggedInUserQuery } from "../../user/service";
import {
  useGetActivityProviderQuery,
  useModifyActivityProviderMutation,
} from "../../activityProvider/service";
import Steps from "../../shared/Steps";
import EducationForm from "./EducationForm";
import useNotification from "../../shared/hooks/useNotification";

const Step2 = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const notify = useNotification();

  const { data: loggedInUser } = useGetLoggedInUserQuery();
  const apGuid = loggedInUser?.guid || "";
  const { data: activityProvider } = useGetActivityProviderQuery(apGuid, {
    skip: !apGuid,
  });

  const [modifyAP, { isLoading }] = useModifyActivityProviderMutation();

  const [studies, setStudies] = useState<{
    graduationYear: string;
    bachelorDegree: string;
    masterDegree: string;
    drDegree: string;
  }>({ graduationYear: "", bachelorDegree: "", masterDegree: "", drDegree: "" });

  const [training, setTraining] = useState<string[]>([]);
  const [methods, setMethods] = useState<string[]>([]);

  const handleStudiesChange = (fieldToChange: string, value: string) => {
    setStudies({ ...studies, [fieldToChange]: value });
  };

  const handleSubmit = () => {
    const { graduationYear, bachelorDegree, masterDegree, drDegree } = studies;
    if (
      !graduationYear ||
      !bachelorDegree ||
      !masterDegree ||
      !training.length ||
      !methods.length
    ) {
      notify("error", "therapy_service_mandatory_message");
      return;
    }

    if (loggedInUser) {
      const updatedProvider = {
        ...activityProvider,
        graduation_year: graduationYear,
        bachelor_degree: bachelorDegree,
        master_degree: masterDegree,
        dr_degree: drDegree,
        psychological_training: training.join(","),
        psychological_methods: methods.join(","),
      };
      modifyAP({ guid: apGuid, activityProvider: updatedProvider })
        .then(() => navigate("/ap/onboarding/step/3"))
        .catch((error) => console.log("Error"));
    }
  };

  return (
    <Steps activeStep={2} stepCount={6}>
      <h1>{intl.formatMessage({ id: "ap_onboarding_step2_title" })}</h1>
      <Text textStyle="largeP" mt="8px" mb="32px">
        {intl.formatMessage({ id: "ap_onboarding_step2_subtitle" })}
      </Text>
      <Box mx="auto" w={{ base: "100%", md: "576px" }}>
        <EducationForm
          onStudiesChange={handleStudiesChange}
          onTrainingChange={(updatedTraining) => setTraining(updatedTraining)}
          onMethodsChange={(updatedMethods) => setMethods(updatedMethods)}
        />
        <Button
          w="full"
          m="auto"
          size="lg"
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          {intl.formatMessage({ id: "user_onboarding_continue_btn_label" })}
        </Button>
      </Box>
    </Steps>
  );
};

export default Step2;
