import { createApi } from "@reduxjs/toolkit/query/react";
import { IActivity, IActivitiesResponse, IAddActivityPayload } from "./types";
import baseQueryWithReauthorization from "../store/baseQuery";
import { IActivityFilters } from "./activitiesFilters/types";

export const activityApi = createApi({
  reducerPath: "activityApi",
  baseQuery: baseQueryWithReauthorization,
  tagTypes: ["Activity"],
  endpoints: (builder) => ({
    addActivity: builder.mutation<IActivity, IAddActivityPayload>({
      query: (payload: IAddActivityPayload) => {
        return {
          url: "/activity",
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Activity"],
    }),
    modifyActivity: builder.mutation<
      IActivity,
      { guid: string; activity: Partial<IActivity> }
    >({
      query: ({ guid, activity }) => {
        return {
          url: `/activity/${guid}`,
          method: "PATCH",
          body: activity,
        };
      },
      invalidatesTags: ["Activity"],
    }),
    getActivities: builder.query<IActivitiesResponse, IActivityFilters>({
      query: (filters: IActivityFilters) => {
        return {
          url: "/activity",
          method: "GET",
          params: filters,
        };
      },
      providesTags: ["Activity"],
    }),
    getActivity: builder.query<IActivity, string>({
      query: (guid) => {
        return {
          url: `/activity/${guid}`,
          method: "GET",
        };
      },
      providesTags: (result, error, guid) => [{ type: "Activity", id: guid }],
    }),
    getMyActivities: builder.query<IActivitiesResponse, void>({
      query: () => {
        return {
          url: "activity/my-activities",
          method: "GET",
        };
      },
      providesTags: ["Activity"],
    }),
    getFirstAvailableDay: builder.query<{ day: string }, string>({
      query: (guid) => {
        return {
          url: `new/activity/${guid}/first-available-day`,
          method: "GET",
        };
      },
      providesTags: ["Activity"],
    }),
    getActivitiesForToday: builder.query<IActivitiesResponse, IActivityFilters>({
      query: (filters: IActivityFilters) => {
        return {
          url: "/new/activity/today",
          method: "GET",
          params: filters,
        };
      },
      providesTags: ["Activity"],
    }),
    getActivitiesOneWeekFromTomorrow: builder.query<IActivitiesResponse, IActivityFilters>({
      query: (filters: IActivityFilters) => {
        return {
          url: "/new/activity/one-week-from-tomorrow",
          method: "GET",
          params: filters,
        };
      },
      providesTags: ["Activity"],
    }),
    getActivitiesUnavailableForOneWeek: builder.query<IActivitiesResponse, IActivityFilters>({
      query: (filters: IActivityFilters) => {
        return {
          url: "/new/activity/unavailable-for-one-week",
          method: "GET",
          params: filters,
        };
      },
      providesTags: ["Activity"],
    }),
  }),
});

export const {
  useAddActivityMutation,
  useModifyActivityMutation,
  useGetActivitiesQuery,
  useGetActivityQuery,
  useGetMyActivitiesQuery,
  useGetFirstAvailableDayQuery,
  useGetActivitiesForTodayQuery,
  useGetActivitiesOneWeekFromTomorrowQuery,
  useGetActivitiesUnavailableForOneWeekQuery,
} = activityApi;
