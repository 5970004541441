import { useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import { Box, Text, Button, Flex, Divider, Link, Show } from "@chakra-ui/react";
import ActivityMeta from "../../shared/ActivityMeta";
import CountdownTimer from "../../shared/countdown/CountdownTimer";
import ReviewModal from "../../reviews/ReviewModal";
import { IBooking } from "../types";
import { fromServerToLocalMoment, fromServertoLocalYMDTHms, toLocalddddDMY, toLocalHM } from "../utils";

type Props = {
  booking: IBooking;
  isInThePast?: boolean;
  isInTheFuture?: boolean;
};

const BookingPreview = ({ booking, isInThePast, isInTheFuture }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [showReviewModal, toggleModal] = useState(false);

  const { start_time, end_time, b_guid, a_guid, price, is_free_slot_booking } = booking;
  const localStartTime = fromServerToLocalMoment(start_time);
  const localEndTime = fromServerToLocalMoment(end_time);
  const startsInTheNext6Hours =
    localStartTime.valueOf() - new Date().getTime() <=
    6 * 60 * 60 * 1000;
  const startsInTheNext24Hours =
    localStartTime.valueOf() - new Date().getTime() <=
    24 * 60 * 60 * 1000;

  const isOngoingBooking =
    moment().isAfter(localStartTime) && moment().isBefore(localEndTime);


  const isInTheNext6h = isInTheFuture && startsInTheNext6Hours && !is_free_slot_booking
  const isInTheNext6to24h = isInTheFuture && !startsInTheNext24Hours && !is_free_slot_booking
  const isFreeSlotBooking = is_free_slot_booking

  return (
    <>
      <Box layerStyle="card">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          w="100%"
        >
          <Box
            position="relative"
            w={{ base: "100%", lg: "auto" }}
            flex={{ base: "auto", lg: "1" }}
          >
            <Link
              _hover={{ textDecoration: "none" }}
              as={RouteLink}
              to={`/activities/${booking.a_guid}`}
            >
              <ActivityMeta guid={booking.a_guid} />
            </Link>
            <Show above="lg">
              <Divider
                orientation="vertical"
                borderColor="border.500"
                position="absolute"
                top="0"
                bottom="0"
                right="0"
                h="100%"
              />
            </Show>
            <Show below="lg">
              <Divider borderColor="border.500" my="16px" />
            </Show>
          </Box>

          <Box
            pl={{ base: "0", lg: "16px" }}
            position="relative"
            w={{ base: "100%", lg: "auto" }}
            flex={{ base: "auto", lg: "1" }}
          >
            {!isFreeSlotBooking && (
              <>
                <Text
                  fontSize="18px"
                  lineHeight="normal"
                  color="brandGray.500"
                  fontWeight="600"
                  textTransform="capitalize"
                >
                  {toLocalddddDMY(localStartTime)}
                </Text>
                <Text>{`Ora ${toLocalHM(localStartTime)} - ${toLocalHM(localEndTime)}`}</Text>
                <Show above="lg">
                  <Divider
                    orientation="vertical"
                    borderColor="border.500"
                    position="absolute"
                    top="0"
                    bottom="0"
                    right="0"
                    h="100%"
                  />
                </Show>
                <Show below="lg">
                  <Divider borderColor="border.500" my="16px" />
                </Show>
              </>
            )}
            {isFreeSlotBooking && (
              <>
                <Text
                  fontSize="16px"
                  lineHeight="normal"
                  color="brandGray.500"
                  textTransform="capitalize"
                >
                  Sedinta preplatita
                </Text>
                <Show below="lg">
                  <Divider borderColor="border.500" my="16px" />
                </Show>
              </>
            )}
          </Box>

          <Box
            pl={{ base: "0", lg: "16px" }}
            w={{ base: "100%", lg: "auto" }}
            position="relative"
            flex={{ base: "auto", lg: "1" }}
          >
            {isInThePast && (
              <>
                <Text
                  fontSize="18px"
                  lineHeight="normal"
                  color="brandGray.500"
                  fontWeight="600"
                  textTransform="capitalize"
                >
                  {intl.formatMessage({ id: "paid" })}
                </Text>
                <Text>{price ? price.label : "N/A"}</Text>
              </>
            )}
            {isInTheFuture && isOngoingBooking && (
              <Box textAlign={{ base: "left", lg: "center" }}>
                {intl.formatMessage({ id: "booking_ongoing_booking" })}
              </Box>
            )}
            {isInTheFuture && !isOngoingBooking && !isFreeSlotBooking && (
              <Box textAlign="center">
                <CountdownTimer targetDate={fromServertoLocalYMDTHms(start_time)} />
              </Box>
            )}
            <Show above="lg">
              <Divider
                orientation="vertical"
                borderColor="border.500"
                position="absolute"
                top="0"
                bottom="0"
                right="0"
                h="100%"
              />
            </Show>
            <Show below="lg">
              <Divider borderColor="border.500" my="16px" />
            </Show>
          </Box>

          <Flex
            w={{ base: "100%", lg: "auto" }}
            pl={{ base: "0", lg: "16px" }}
            flex={{ base: "auto", lg: "1" }}
          >
            {isInThePast && (
              <Button w="full" onClick={() => toggleModal(true)}>
                {intl.formatMessage({ id: "add_review" })}
              </Button>
            )}
            {isInTheNext6h && (
              <Button
                w="full"
                onClick={() => {
                  navigate(`/room/${a_guid}/${b_guid}`);
                }}
              >
                {intl.formatMessage({ id: "booking_session_page_btn_label" })}
              </Button>
            )}
            {isInTheNext6to24h && (
              <Button
                w="full"
                variant="outline"
                onClick={() => {
                  navigate(`/activities/${a_guid}/change-booking/${b_guid}`);
                }}
              >
                {intl.formatMessage({
                  id: "booking_change_session_btn_label",
                })}
              </Button>
            )}
            {isFreeSlotBooking && (
              <Button
                w="full"
                variant="outline"
                onClick={() => {
                  navigate(`/activities/${a_guid}/change-booking/${b_guid}`);
                }}
              >
                {intl.formatMessage({
                  id: "booking_schedule_session_btn_label",
                })}
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>
      {showReviewModal && (
        <ReviewModal
          booking={booking}
          isOpen={showReviewModal}
          closeModal={() => toggleModal(false)}
        />
      )}
    </>
  );
};

export default BookingPreview;
