import { useIntl } from "react-intl";
import { useState } from "react";
import {
  Box,
  Stack,
  Button,
  Divider,
  StackDivider,
  FormControl,
  FormLabel,
  FormHelperText,
  Flex,
  Text,
} from "@chakra-ui/react";
import useNotification from "../shared/hooks/useNotification";
import ActivityCategoriesList from "./ActivityCategoriesList";
import { IRegisteredUser } from "./types";
import conditions from "../conditions";

type Props = {
  user: IRegisteredUser;
  updating: boolean;
  updateUser: (params: {
    user: Partial<IRegisteredUser>;
    guid: string;
  }) => void;
};
export default function TherapyPreferences({
  user,
  updateUser,
  updating,
}: Props) {
  const intl = useIntl();
  const notify = useNotification();

  const issuesToFix = user.profile ? user.profile.issues_to_fix : [];
  const [issues, updateIssues] = useState<string[]>(issuesToFix);

  const submit = () => {
    if (issues.length === 0) {
      notify("error", "therapy_category_mandatory");
      return;
    }
    const profile: any = { ...user.profile, issues_to_fix: issues };
    updateUser({ user: { profile }, guid: user.guid });
  };

  return (
    <Box layerStyle="card" mt="16px">
      <Stack spacing="5">
        <Stack
          spacing="4"
          direction={{ base: "column", sm: "row" }}
          justify="space-between"
        >
          <Box>
            <Text fontSize="lg" fontWeight="bold" color="brandGray.500">
              {intl.formatMessage({ id: "therapy_category" })}
            </Text>
            <Text color="muted" fontSize="sm">
              {intl.formatMessage({ id: "select_your_problems" })}
            </Text>
          </Box>
          <Button alignSelf="start" onClick={submit} isLoading={updating}>
            {intl.formatMessage({ id: "save_btn" })}
          </Button>
        </Stack>
        <Divider />
        <Stack spacing="5" divider={<StackDivider />}>
          <FormControl id="serviceselect">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <Box>
                <FormLabel
                  variant="inline"
                  fontWeight="600"
                  color="brandGray.500"
                >
                  {intl.formatMessage(
                    { id: "available_options" },
                    { count: conditions.length }
                  )}
                </FormLabel>
                <FormHelperText mt="0" color="muted">
                  {intl.formatMessage({ id: "select_multiple_options" })}
                </FormHelperText>
              </Box>
              <ActivityCategoriesList
                onChange={updateIssues}
                selected={issues}
              />
            </Stack>
          </FormControl>

          <Flex direction="row-reverse">
            <Button onClick={submit} isLoading={updating}>
              {intl.formatMessage({ id: "save_btn" })}
            </Button>
          </Flex>
        </Stack>
      </Stack>
    </Box>
  );
}
