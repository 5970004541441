import { useIntl } from "react-intl";
import {
  Box,
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Divider,
  Alert,
  AlertIcon,
  useToast,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { usePostBookingMutation, usePostCheckBookingPriceMutation } from "../service";
import ActivityMeta from "../../shared/ActivityMeta";
import { IActivity } from "../../activity/types";
import { fromLocalToServerYMDTHms, toLocalddddDMY, toLocalHM } from "../utils";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { TrackGoogleAnalyticsEventOnce } from "../../utils/GoogleAnalyticsHelper";
import { useGetLoggedInUserQuery } from "../../user/service";
import { Gift } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/cookies";

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  onSuccessBooking: () => void;
  start: string;
  end: string;
  activity: IActivity;
};

const BookingModal = ({
  isOpen,
  onCloseModal,
  onSuccessBooking,
  start,
  end,
  activity,
}: Props) => {
  const intl = useIntl();
  const toast = useToast();
  const navigate = useNavigate();
  const [bookActivity, { isLoading }] = usePostBookingMutation();
  const [bookCheckPrice, { isLoading: isLoadingCheckPrice }] = usePostCheckBookingPriceMutation();
  const { data: user } = useGetLoggedInUserQuery();
  const [couponCode, setCouponCode] = useState("");
  const [showCoupon, setShowCoupon] = useState(false);
  const [globalVoucher, setGlobalVoucher] = useState<string | null>(null);
  const [finalAmountToPay, setFinalAmountToPay] = useState<number | null>(null);
  const [discountApplied, setDiscountApplied] = useState(false);

  let discountedPrice = null;
  if (finalAmountToPay !== null) {
    discountedPrice = `${finalAmountToPay / 100} RON`;
  }

  useEffect(() => {
    if (activity.guid) {
      TrackGoogleAnalyticsEventOnce(activity.guid, "user_payment", "show_confirm_to_pay")
    }

  }, [activity.guid])

  useEffect(() => {
    if (globalVoucher) {
      setCouponCode(globalVoucher);
      setShowCoupon(true);
    }
  }, [globalVoucher]);

  useEffect(() => {
    const cookieVoucher = getCookie('_global_voucher');
    if (cookieVoucher) {
      setGlobalVoucher(cookieVoucher);
    }
  }, []);

  const showEnterCoupon = useCallback(() => {
    setShowCoupon(true)
    if (!globalVoucher) {
      if (user?.user_voucher?.code && user?.has_eligible_voucher) {
        setCouponCode(user?.user_voucher?.code)
      }
      else {
        setCouponCode("")
      }
    }
  }, [user?.user_voucher?.code]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBookActivity = (with_package = false) => {
    bookActivity({
      a_guid: activity.guid,
      start_time: fromLocalToServerYMDTHms(start),
      end_time: fromLocalToServerYMDTHms(end),
      ap_guid: activity.executed_by_guid,
      local_tz: moment().format("Z"),
      use_booking_package: with_package,
      coupon_code: couponCode || undefined,
    })
      .unwrap()
      .then((response) => {
        if (response.is_free_from_voucher) {
          toast({
            title: "Voucherul a fost aplicat cu succes",
            status: "info",
            duration: 5000,
            isClosable: true,
          });
          navigate('/bookings')
        }
        else {
          const paymentLink = response.url || "";
          window.location.href = paymentLink;
          TrackGoogleAnalyticsEventOnce(activity.guid, "user_payment", "pay_attempt_start")
          onSuccessBooking();
        }
      })
      .catch((e) => {
        onCloseModal();
        console.error(e)
        if (e?.data?.detail?.code === 'invalid_coupon_code') {
          toast({
            title: e?.data?.detail?.message || "Voucherul introdus nu este valid",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return
        }
        else if (e?.data?.detail === 'invalid_coupon_code') {
          toast({
            title: "Voucherul introdus nu este valid",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return
        }
        else {
          toast({
            title: intl.formatMessage({ id: "book_activity_error" }),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }

      });
  };

  const handleComputeBookActivity = () => {
    bookCheckPrice({
      a_guid: activity.guid,
      start_time: fromLocalToServerYMDTHms(start),
      end_time: fromLocalToServerYMDTHms(end),
      ap_guid: activity.executed_by_guid,
      local_tz: moment().format("Z"),
      use_booking_package: false,
      coupon_code: couponCode || undefined,
      compute_price_only: true,
    })
      .unwrap()
      .then((response) => {
        if (typeof response.new_price !== 'undefined') {
          setFinalAmountToPay(response.new_price)
          setDiscountApplied(true)
          toast({
            title: `Pretul a fost actualizat la ${response.new_price / 100} RON`,
            status: "info",
            duration: 5000,
            isClosable: true,
          });
          return
        }
      })
      .catch((e) => {


        if (e?.data?.detail?.code === 'invalid_coupon_code') {
          toast({
            title: e?.data?.detail?.message || "Voucherul introdus nu este valid",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return
        }
        else if (e?.data?.detail === 'invalid_coupon_code') {
          toast({
            title: "Voucherul introdus nu este valid",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return
        }
        else {
          toast({
            title: intl.formatMessage({ id: "book_activity_error" }),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }

      });
  };

  const handleBookActivityNoPackage = (e: React.FormEvent) => {
    e.preventDefault();
    handleBookActivity()
  };

  const handleBookComputePrice = (e: React.FormEvent) => {
    e.preventDefault();
    handleComputeBookActivity()
  };

  const handleBookActivityWithPackage = (e: React.FormEvent) => {
    e.preventDefault();
    handleBookActivity(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered={true}
      scrollBehavior="inside"
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottom="1px solid var(--chakra-colors-border-400)">
          <h2>{intl.formatMessage({ id: "booking_confirmation" })}</h2>
        </ModalHeader>
        <ModalCloseButton w="44px" h="44px" />
        <ModalBody py="16px">
          <Flex alignItems="center" justifyContent="space-between">
            <ActivityMeta guid={activity.guid} />
            <Divider orientation="vertical" h="44px" opacity="1" />
            <Box textAlign="right">
              <Text
                fontSize="18px"
                lineHeight="normal"
                color="brandGray.500"
                fontWeight="600"
              >
                {toLocalddddDMY(start)}
              </Text>
              <Text>
                Ora {toLocalHM(start)} - {toLocalHM(end)}
              </Text>
            </Box>
          </Flex>
          <Alert
            mt="16px"
            status="info"
            fontSize="14px"
            lineHeight="normal"
            padding="8px 12px"
            borderRadius="8px"
            color="infoBlue.500"
            bg="infoBlue.50"
          >
            <AlertIcon color="infoBlue.500" mt="1px" />
            {!activity.booking_package?.active && intl.formatMessage({ id: "booking_reschedule" })}
            {activity.booking_package?.active && intl.formatMessage({ id: "booking_reschedule_with_package_info" })}
          </Alert>
          <Divider my="16px" opacity="1" />
          <Box w="full" textAlign="center">
            <Text textStyle="smallLabel">
              {intl.formatMessage({ id: "booking_price_after_discount" })}
            </Text>
          </Box>
          {!activity.booking_package?.active && <>
            <Box w="full" textAlign="center">
              <Text color="brand.500" fontWeight="700" fontSize="20px">
                {discountedPrice || activity.price.label}
              </Text>
            </Box>
            <Button
              w="full"
              size="lg"
              isLoading={isLoading}
              onClick={!couponCode || discountApplied ? handleBookActivityNoPackage : handleBookComputePrice}
            >
              {intl.formatMessage({ id: !couponCode || discountApplied ? "booking_btn_label_continue" : "booking_btn_label_use_coupon" })}
            </Button></>
          }
          {activity.booking_package?.active && <>
            <Box w="full" textAlign="center" paddingTop="20px" paddingBottom="10px" paddingLeft="30px" paddingRight="30px">
              <Text color="brand.500" fontWeight="700" fontSize="20px">
                {activity.booking_package.label} / {activity.booking_package.booking_num} {intl.formatMessage({ id: "activity_sessions" })}
              </Text>
              <Button
                w="full"
                size="lg"
                isLoading={isLoading}
                onClick={handleBookActivityWithPackage}
              >
                {intl.formatMessage({ id: "booking_price_buy_package" })}
              </Button>
            </Box>
            <Divider my="20px" opacity="1" />
            <Box w="full" textAlign="center" paddingBottom="10px" paddingLeft="30px" paddingRight="30px">
              <Text color="brand.500" fontWeight="700" fontSize="20px">
                {discountedPrice || activity.price.label} /  {intl.formatMessage({ id: "activity_session" })}
              </Text>
              <Button
                w="full"
                size="lg"
                isLoading={isLoading}
                onClick={!couponCode || discountApplied ? handleBookActivityNoPackage : handleBookComputePrice}
              >
                {intl.formatMessage({ id: !couponCode || discountApplied ? "booking_btn_label_continue" : "booking_btn_label_use_coupon" })}
              </Button>
            </Box>
          </>
          }
          <Divider my="16px" opacity="1" />
            <>
              <Button
                display={showCoupon ? "none" : "block"}
                w="full"
                size="md"
                mt="16px"
                variant="link"
                onClick={showEnterCoupon}
              >
                Ai cod de discount?
              </Button>
              {showCoupon && (
                <Alert
                  mt="16px"
                  status="info"
                  fontSize="14px"
                  lineHeight="normal"
                  padding="8px 12px"
                  borderRadius="8px"
                  color="infoBlue"
                  bg="infoBlue"
                >
                  <FormControl>
                    <Text
                      fontSize="12px"
                      mx="15px"
                      mb="2px"
                    >
                      Introdu codul de discount in casuta de mai jos.<br />
                    Daca acesta este valid, pretul va fi actualizat automat la pasul urmator.
                    </Text>
                    <InputGroup
                      mx="5px"
                      mb="2">
                      <InputLeftElement
                        pointerEvents="none"
                        opacity=".54"
                        pt="3px"
                        px="8px"
                        h="100%"
                        children={<Gift size={20} />}
                      />
                      <Input
                        placeholder="Cod promoțional"
                        name="coupon"
                        value={couponCode}
                      readOnly={discountApplied}
                        onChange={(e) => setCouponCode(e.target.value)}
                      />
                    </InputGroup>
                  </FormControl>
                </Alert>
              )}
          </>
        </ModalBody>

      </ModalContent>
    </Modal>
  );
};

export default BookingModal;
