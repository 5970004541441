import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

type Props = BoxProps & {
  onSubmit: (e: React.FormEvent) => void;
  children: React.ReactNode;
};

const Form = (props: Props) => {
  return (
    <Box as="form" {...props} onSubmit={props.onSubmit}>
      {props.children}
    </Box>
  );
};

export default Form;
