import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauthorization from "../store/baseQuery";
import {
  IBooking,
  IFetchBookingsPayload,
  IFetchBookingsResponse,
  IGetBookingsForUserAndActivity,
  IInterval,
  IMyNextBookingResponse,
  IPostBookingPayload,
  IPostBookingResponse,
  IBookingsCountPayload,
  IBookingsCountResponse,
  IFetchBookingPayload,
  IPostBookingReminderResponse,
  IPostBookingReminderPayload,
  IResumeBookingPayload,
  IDeleteBookingPayload,
  IDeleteBookingResponse,
  IMarkAsFreeBookingResponse,
  IarkAsFreeBookingPayload,
  IPostBookingPriceResponse,
} from "./types";

export const bookingApi = createApi({
  reducerPath: "bookingApi",
  baseQuery: baseQueryWithReauthorization,
  tagTypes: ["Booking"],
  endpoints: (builder) => ({
    fetchBookings: builder.query<IFetchBookingsResponse, IFetchBookingsPayload>(
      {
        query: (payload: IFetchBookingsPayload) => {
          const { a_guid, ...queryParams } = payload;
          return {
            url: `/booking/${a_guid}`,
            method: "GET",
            params: queryParams,
          };
        },
        providesTags: ["Booking"],
      }
    ),
    fetchMyBookings: builder.query<IFetchBookingsResponse, IInterval>({
      query: (payload: IInterval) => {
        return {
          url: "booking/my-bookings",
          params: payload,
        };
      },
      providesTags: ["Booking"],
    }),
    postBooking: builder.mutation<IPostBookingResponse, IPostBookingPayload>({
      query: (payload: IPostBookingPayload) => {
        return {
          url: `/booking`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Booking"],
    }),
    postCheckBookingPrice: builder.mutation<IPostBookingPriceResponse, IPostBookingPayload>({
      query: (payload: IPostBookingPayload) => {
        return {
          url: `/booking/check-price`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Booking"],
    }),
    addBookingReminder: builder.mutation<
      IPostBookingReminderResponse,
      IPostBookingReminderPayload
    >({
      query: (payload: IPostBookingReminderPayload) => {
        return {
          url: `/booking/reminder`,
          method: "POST",
          body: payload,
        };
      },
    }),
    getMyNextBooking: builder.query<IMyNextBookingResponse, void>({
      query: () => {
        return {
          url: "/booking/my-next-booking",
          method: "GET",
        };
      },
      providesTags: ["Booking"],
    }),
    getNextBookingsOfUserAndActivity: builder.query<
      IBooking[],
      IGetBookingsForUserAndActivity
    >({
      query: ({ u_guid, a_guid }) => {
        return {
          url: `/booking/my-next-booking/${u_guid}/${a_guid}`,
          method: "GET",
        };
      },
      providesTags: ["Booking"],
    }),
    getBookingsCount: builder.query<
      IBookingsCountResponse,
      IBookingsCountPayload
    >({
      query: (payload) => {
        const { u_guid, a_guid, ...queryParams } = payload;
        return {
          url: `/booking/count-user-bookings/${u_guid}/${a_guid}`,
          method: "GET",
          params: queryParams,
        };
      },
      providesTags: ["Booking"],
    }),
    getBooking: builder.query<IBooking, IFetchBookingPayload>({
      query: ({ a_guid, b_guid }) => {
        return {
          url: `/booking/${a_guid}/${b_guid}`,
          method: "GET",
        };
      },
      providesTags: ["Booking"],
    }),
    resumeBooking: builder.mutation<
      IPostBookingResponse,
      IResumeBookingPayload
    >({
      query: (payload: IResumeBookingPayload) => {
        return {
          url: `/booking/resume`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Booking"],
    }),
    deleteBooking: builder.mutation<
      IDeleteBookingResponse,
      IDeleteBookingPayload
    >({
      query: (payload) => {
        const { a_guid, b_guid } = payload;
        return {
          url: `/booking/${a_guid}/${b_guid}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Booking"],
    }),
    markAsFree: builder.mutation<
      IMarkAsFreeBookingResponse,
      IarkAsFreeBookingPayload
    >({
      query: (payload) => {
        const { a_guid, b_guid } = payload;
        return {
          url: `/booking/${a_guid}/${b_guid}/mark-as-free`,
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
});

export const {
  useFetchBookingsQuery,
  useFetchMyBookingsQuery,
  usePostBookingMutation,
  useGetMyNextBookingQuery,
  useGetNextBookingsOfUserAndActivityQuery,
  useGetBookingsCountQuery,
  useGetBookingQuery,
  useAddBookingReminderMutation,
  useResumeBookingMutation,
  useDeleteBookingMutation,
  useMarkAsFreeMutation,
  usePostCheckBookingPriceMutation,
} = bookingApi;
