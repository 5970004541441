import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Stack,
  Button,
  Divider,
  StackDivider,
  FormControl,
  FormLabel,
  FormHelperText,
  Flex,
  Text,
  Input,
} from "@chakra-ui/react";
import PresetTextEditor from "../shared/textEditor/presetTextEditor";
import ActivityCategoriesList from "../user/ActivityCategoriesList";
import ActivityPrice from "../activity/activityPrice";
import useNotification from "../shared/hooks/useNotification";
import { getVimeoId, validateVimeoURL } from "../utils/vimeoUrl";
import { IActivity, IPrice } from "../activity/types";

type Props = {
  activity: IActivity;
  updating: boolean;
  updateActivity: (payload: Partial<IActivity>) => void;
};

const ActivityProviderServices = ({
  activity,
  updating,
  updateActivity,
}: Props) => {
  const intl = useIntl();
  const notify = useNotification();

  const [formValues, setFormValues] = useState<{
    categories: string[];
    description: string;
    price: IPrice | undefined;
    vimeo_url: string;
  }>({
    categories: activity.categories,
    description: activity.description,
    price: activity.price,
    vimeo_url: activity.vimeo_url || "",
  });

  useEffect(() => {
    const { categories, description, price, vimeo_url } = activity;
    setFormValues({
      categories,
      description,
      price,
      vimeo_url: vimeo_url || "",
    });
  }, [activity]);

  const submit = () => {
    if (formValues.categories.length === 0) {
      notify("error", "activity_category_select_mandatory");
      return;
    }
    if (formValues.description.length < 10) {
      notify("error", "ap_onboarding_step1_description_required");
      return;
    }
    if (formValues.vimeo_url) {
      const isValid = validateVimeoURL(formValues.vimeo_url);
      if (!isValid) {
        notify("error", "ap_vimeo_invalid_url");
        return;
      }
      const url = isValid
        ? `https://vimeo.com/${getVimeoId(formValues.vimeo_url)}`
        : "";
      updateActivity({ ...formValues, vimeo_url: url });
    } else {
      updateActivity(formValues);
    }
  };

  return (
    <Box layerStyle="card" mt="16px">
      <Stack spacing="5">
        <Stack
          spacing="4"
          direction={{ base: "column", sm: "row" }}
          justify="space-between"
        >
          <Box>
            <Text fontSize="lg" fontWeight="bold" color="brandGray.500">
              {intl.formatMessage({ id: "therapy_service" })}
            </Text>
            <Text color="muted" fontSize="sm">
              {intl.formatMessage({ id: "describe_your_services" })}
            </Text>
          </Box>
          <Button alignSelf="start" isLoading={updating} onClick={submit}>
            {intl.formatMessage({ id: "save_btn" })}
          </Button>
        </Stack>
        <Divider />
        <Stack spacing="5" divider={<StackDivider />}>
          <FormControl>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "1.5", lg: "8" }}
              justify="space-between"
            >
              <Box>
                <FormLabel
                  variant="inline"
                  fontWeight="600"
                  color="brandGray.500"
                >
                  {intl.formatMessage({ id: "session_price" })}
                </FormLabel>
                <FormHelperText mt="0" color="muted">
                  {intl.formatMessage({
                    id: "session_price_select_one_option",
                  })}
                </FormHelperText>
              </Box>
              <Box w="full" maxW={{ lg: "3xl" }}>
                <ActivityPrice
                  initialPrice={formValues.price}
                  isDisabled={true}
                  onSelectPrice={(selectedPrice) =>
                    setFormValues({
                      ...formValues,
                      price: selectedPrice,
                    })
                  }
                />
              </Box>
            </Stack>
          </FormControl>
          <FormControl id="serviceselect">
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "1.5", mlgd: "8" }}
              justify="space-between"
            >
              <Box>
                <FormLabel
                  variant="inline"
                  fontWeight="600"
                  color="brandGray.500"
                >
                  {intl.formatMessage({ id: "therapy_specializations" })}
                </FormLabel>
                <FormHelperText mt="0" color="muted">
                  {intl.formatMessage({ id: "select_from_options" })}
                </FormHelperText>
              </Box>
              <ActivityCategoriesList
                onChange={(updates: any) =>
                  setFormValues({ ...formValues, categories: updates })
                }
                selected={formValues.categories}
              />
            </Stack>
          </FormControl>

          <FormControl>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "1.5", lg: "8" }}
              justify="space-between"
            >
              <Box>
                <FormLabel
                  variant="inline"
                  fontWeight="600"
                  color="brandGray.500"
                >
                  {intl.formatMessage({ id: "ap_vimeo_url" })}
                </FormLabel>
                <FormHelperText mt="0" color="muted">
                  {intl.formatMessage({ id: "ap_vimeo_url_subtitle" })}
                </FormHelperText>
              </Box>
              <Box w="full" maxW={{ lg: "3xl" }}>
                <Input
                  placeholder="https://vimeo.com/123456789"
                  value={formValues.vimeo_url}
                  onChange={(e) =>
                    setFormValues({ ...formValues, vimeo_url: e.target.value })
                  }
                />
              </Box>
            </Stack>
          </FormControl>

          <FormControl id="bio">
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "1.5", lg: "8" }}
              justify="space-between"
            >
              <Box>
                <FormLabel
                  variant="inline"
                  fontWeight="600"
                  color="brandGray.500"
                >
                  {intl.formatMessage({ id: "bio" })}
                </FormLabel>
                <FormHelperText mt="0" color="muted">
                  {intl.formatMessage({ id: "add_short_description" })}
                </FormHelperText>
              </Box>
              <Box w="full" maxW={{ lg: "3xl" }}>
                <PresetTextEditor
                  onContentChange={(htmlContent) =>
                    setFormValues({ ...formValues, description: htmlContent })
                  }
                  content={formValues.description}
                  formatStyle="paragraph"
                />
              </Box>
            </Stack>
          </FormControl>

          <Flex direction="row-reverse">
            <Button onClick={submit} isLoading={updating}>
              {intl.formatMessage({ id: "save_btn" })}
            </Button>
          </Flex>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ActivityProviderServices;
