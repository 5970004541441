import { useEffect } from "react";
import Layout from "../../shared/Layout";
import Navbar from "../../shared/Navbar";
import MainContainer from "../../shared/MainContainer";
import BottomNavigation from "../../shared/BottomNavigation";
import NextBookingNotification from "../../shared/nextBookingNotification";
import Bookings from "../APBookingsCalendar/Bookings";
import { useNavigate } from "react-router-dom";

interface IProps {
  redirectUrl?: string | undefined;
  redirectTimeout?: number;
};

const ActivityProviderBookingsPage = ({ redirectUrl, redirectTimeout = 3000 }: IProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      if (redirectUrl) {
        navigate(redirectUrl);
      }
    }, redirectTimeout);
    return () => clearTimeout(timer);
  });
  return (
    <Layout>
      <>
        <Navbar userType="activity_provider" />
        <MainContainer>
          <Bookings />
        </MainContainer>
        <NextBookingNotification userType="activity_provider" />
        <BottomNavigation userType="activity_provider" />
      </>
    </Layout>
  );
};

export default ActivityProviderBookingsPage;
