import moment from "moment";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { useAppDispatch } from "../../hooks";
import { usePostBookingMutation } from "../service";
import { activityApi } from "../../activity/service";
import { getRoLocaleDate } from "../../utils/dates";
import ActivityMeta from "../../shared/ActivityMeta";
import { IActivity } from "../../activity/types";
import { fromLocalToServerYMDTHms } from "../utils";

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  start: string;
  end: string;
  activity: IActivity;
  b_guid: string;
};

const ChangeBookingModal = ({
  isOpen,
  onCloseModal,
  start,
  end,
  activity,
  b_guid,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useAppDispatch();

  const [bookActivity, { isLoading }] = usePostBookingMutation();

  const handleChangeBooking = (e: React.FormEvent) => {
    e.preventDefault();
    bookActivity({
      a_guid: activity.guid,
      start_time: fromLocalToServerYMDTHms(start),
      end_time: fromLocalToServerYMDTHms(end),
      ap_guid: activity.executed_by_guid,
      replaces_b_guid: b_guid,
      local_tz: moment().format("Z"),
    })
      .unwrap()
      .then((response) => {
        dispatch(activityApi.util.invalidateTags(["Activity"]));
        onCloseModal();
        navigate(`/bookings`);
      })
      .catch(() => {
        onCloseModal();
        toast({
          title: intl.formatMessage({ id: "booking_change_booking_error" }),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered={true}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          borderBottom="1px solid var(--chakra-colors-border-400)"
          textAlign="center"
        >
          <h2>{intl.formatMessage({ id: "booking_change_booking" })}</h2>
        </ModalHeader>
        <ModalCloseButton w="44px" h="44px" />
        <ModalBody py="16px">
          <Flex alignItems="center" justifyContent="space-between">
            <ActivityMeta guid={activity.guid} />
            <Divider orientation="vertical" h="44px" opacity="1" />
            <Box textAlign="right">
              <Text
                fontSize="18px"
                lineHeight="normal"
                color="brandGray.500"
                fontWeight="600"
              >
                {getRoLocaleDate(start, "dddd, DD MMMM YYYY")}
              </Text>
              <Text>{`Ora ${moment(start).format("HH:mm")} - ${moment(
                end
              ).format("HH:mm")}`}</Text>
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter borderTop="1px solid var(--chakra-colors-border-400)">
          <Button
            w="full"
            size="lg"
            isLoading={isLoading}
            onClick={handleChangeBooking}
          >
            {intl.formatMessage({ id: "booking_change_session_btn_label" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangeBookingModal;
