const psychologicalMethods = [
  "emdr",
  "ericksonian_hypnosis",
  "relaxation_techniques",
  "cognitive_restructuring",
  "breathing_techniques",
  "short_solution_focused_therapies",
  "mindfullness",
  "narrative_therapy",
  "grief_therapy"
];

export default psychologicalMethods;
