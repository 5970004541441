import { useEffect } from "react";
import momentTz from "moment-timezone";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Box, useToast } from "@chakra-ui/react";
import {
  useGetActivityQuery,
  useGetFirstAvailableDayQuery,
} from "../../activity/service";
import Layout from "../../shared/Layout";
import BookingHeader from "../bookActivity/BookingHeader";
import BookingCalendar from "../bookActivity/BookingCalendar";
import Loader from "../../shared/Loader";

const BookActivityPage = () => {
  const intl = useIntl();
  const toast = useToast();

  let { guid = "" } = useParams();

  const { data: activity, isLoading: isLoadingActivity } =
    useGetActivityQuery(guid);
  const {
    data: firstAvailableDay,
    isLoading: isLoadingFirstAvailableDay,
    isSuccess,
  } = useGetFirstAvailableDayQuery(guid);

  useEffect(() => {
    const toastId = "no_available_dates";
    if (isSuccess && !firstAvailableDay.day) {
      toast({
        id: toastId,
        title: intl.formatMessage({
          id: "book_activity_no_available_dates",
        }),
        status: "info",
        duration: null,
        isClosable: true,
      });
    }
    return () => toast.close(toastId);
  }, [isSuccess, firstAvailableDay, intl, toast]);

  const isLoading = isLoadingActivity || isLoadingFirstAvailableDay;

  return (
    <Layout>
      <>
        {isLoading && <Loader />}
        {!isLoading && activity && firstAvailableDay && (
          <>
            <Box
              flex="1"
              pt={{ base: "64px", lg: "80px" }}
              zIndex="1"
              position="relative"
            >
              <Box maxW="100%" margin="auto">
                <BookingHeader activity={activity} />
                <BookingCalendar
                  activity={activity}
                  firstAvailableDay={
                    firstAvailableDay.day || momentTz.tz("Europe/Bucharest").format("YYYY-MM-DD")
                  }
                />
              </Box>
            </Box>
          </>
        )}
      </>
    </Layout>
  );
};

export default BookActivityPage;
