import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  CloseButton,
} from "@chakra-ui/react";

import useChatContext from '../../../hooks/useChatContext/useChatContext';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      background: '#fff',
      borderBottom: '1px solid #E4E7E9',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px 8px 16px 16px',
    },
    text: {
      fontWeight: 'bold',
      color: "#272F37",
    },
    closeChatWindow: {
      cursor: 'pointer',
      display: 'flex',
      background: 'transparent',
      border: '0',
    },
  })
);

export default function ChatWindowHeader() {
  const intl = useIntl();
  const classes = useStyles();
  const { setIsChatWindowOpen } = useChatContext();

  return (
    <div className={classes.container}>
      <h2>{intl.formatMessage({ id: 'chat_title' })}</h2>
      <button className={classes.closeChatWindow} onClick={() => setIsChatWindowOpen(false)}>
        <CloseButton />
      </button>
    </div>
  );
}
