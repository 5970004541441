import { Box, Flex } from "@chakra-ui/react";
import DateTimeDisplay from "./DatetimeDisplay";

type Props = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const ShowCounter = ({ days, hours, minutes, seconds }: Props) => {
  return (
    <Flex dir="row" minW="130px">
      <DateTimeDisplay value={days} label={"zile"} isDanger={days === 0} />
      <Box>:</Box>
      <DateTimeDisplay
        value={hours}
        label={"ore"}
        isDanger={days === 0 && hours === 0}
      />
      <Box>:</Box>
      <DateTimeDisplay
        value={minutes}
        label={"min"}
        isDanger={days === 0 && hours === 0 && minutes === 0}
      />
      <Box>:</Box>
      <DateTimeDisplay
        value={seconds}
        label={"sec"}
        isDanger={days === 0 && hours === 0 && minutes === 0 && seconds === 0}
      />
    </Flex>
  );
};

export default ShowCounter;
