import { IconButton, IconButtonProps, Tooltip } from "@chakra-ui/react";

type Props = {
  isActive: boolean;
  tooltipLabel?: string;
} & IconButtonProps;

const MenuItem = (props: Props) => {
  const { isActive, tooltipLabel, ...rest } = props;
  return (
    <Tooltip label={tooltipLabel || ""}>
      <IconButton
        {...rest}
        m="6px"
        backgroundColor={isActive ? "brand.500" : "defaultGray.400"}
      />
    </Tooltip>
  );
};

export default MenuItem;
