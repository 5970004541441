import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauthorization from "../store/baseQuery";
import { FetchPaymentResponse } from "./types";

export const paymentApi = createApi({
    reducerPath: "paymentApi",
    baseQuery: baseQueryWithReauthorization,
    tagTypes: ["Payment"],
    endpoints: (builder) => ({
        fetchPaymentBalance: builder.query<FetchPaymentResponse, void>({
            query: () => {
                return {
                    url: "payment/balance",
                };
            },
            providesTags: ["Payment"],
        }),
    })
});

export const {
    useFetchPaymentBalanceQuery,
} = paymentApi;