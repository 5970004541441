import React from 'react'
import { Button, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, AlertDialog } from '@chakra-ui/react'
import { useIntl } from 'react-intl'

type Props = {
    isOpen: boolean,
    makeAction: () => void,
    onClose: () => void,
    isLoading?: boolean
}

const ConfirmDialog = ({ isOpen, onClose, makeAction, isLoading }: Props) => {
    const cancelRef: any = React.useRef()
    const intl = useIntl();
    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {intl.formatMessage({ id: "delete" })}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {intl.formatMessage({ id: "are_you_sure" })}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={onClose}>
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button onClick={makeAction} ml={3} isLoading={isLoading} >
                            {intl.formatMessage({ id: "delete" })}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default ConfirmDialog