import Layout from "../../shared/Layout";
import Navbar from "../../shared/Navbar";
import MainContainer from "../../shared/MainContainer";
import BottomNavigation from "../../shared/BottomNavigation";
import AddArticle from "../ActivityProviderArticles/AddArticle";

const ActivityProviderAddArticlePage = () => {
  return (
    <Layout>
      <>
        <Navbar userType="activity_provider" />
        <MainContainer>
          <AddArticle />
        </MainContainer>
        <BottomNavigation userType="activity_provider" />
      </>
    </Layout>
  );
};

export default ActivityProviderAddArticlePage;
