import { Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useCountdown } from "../countdown/useCountdown";

type Props = {
  targetDate: string;
};

const CountdownTimer = ({ targetDate }: Props) => {
  const intl = useIntl();

  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return null;
  }
  const daysValue = intl.formatMessage(
    { id: "countdown_day_label" },
    { count: days }
  );
  const hoursValue = intl.formatMessage(
    { id: "countdown_hour_label" },
    { count: hours }
  );
  const minutesValue = intl.formatMessage(
    { id: "countdown_minute_label" },
    { count: minutes }
  );
  const secondsValue = intl.formatMessage(
    { id: "countdown_second_label" },
    { count: seconds }
  );
  const and = intl.formatMessage({ id: "and" });

  let remainingTime;

  if (days > 0) {
    intl.formatMessage({ id: "countdown_day_label" });
    remainingTime = hours
      ? `${daysValue} ${and} ${hoursValue}`
      : `${daysValue}`;
  } else if (hours > 0) {
    remainingTime = minutes
      ? `${hoursValue} ${and} ${minutesValue}`
      : `${hoursValue}`;
  } else if (minutes > 0) {
    remainingTime = seconds
      ? `${minutesValue} ${and} ${secondsValue}`
      : `${minutesValue}`;
  } else {
    remainingTime = `${secondsValue}`;
  }

  return (
    <Text fontSize="16px" lineHeight="24px" fontWeight="600">
      {remainingTime}
    </Text>
  );
};

export default CountdownTimer;
