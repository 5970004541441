import React from "react";
import { useIntl } from "react-intl";
import {
  Typography,
  Grid,
  // Theme,
  // makeStyles,
  // useMediaQuery,
  //useTheme
} from "@material-ui/core";
import {
  Box,
  Flex,
  Button,
  // IconButton,
  Text,
  Divider,
  List,
  ListItem,
  ListIcon,
  Spacer,
  Show,
  // CloseButton,
} from "@chakra-ui/react";
import { CheckmarkFilled } from "@carbon/icons-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import LocalVideoPreview from "./LocalVideoPreview/LocalVideoPreview";
import SettingsMenu from "./SettingsMenu/SettingsMenu";
import ToggleAudioButton from "../../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "../../Buttons/ToggleVideoButton/ToggleVideoButton";
import useChatContext from "../../../hooks/useChatContext/useChatContext";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
// import { useGetLoggedInUserQuery } from '../../../../user/service';
import { useCreateTokenMutation } from "../../../service";
import ActivityProviderNotes from "../../ActivityProviderNotes";
// const useStyles = makeStyles((theme: Theme) => ({
//   gutterBottom: {
//     marginBottom: '1em',
//   },
//   marginTop: {
//     marginTop: '1em',
//   },
//   deviceButton: {
//     width: '100%',
//     border: '2px solid #aaa',
//     margin: '1em 0',
//   },
//   localPreviewContainer: {
//     paddingRight: '2em',
//     [theme.breakpoints.down('sm')]: {
//       padding: '0 2.5em',
//     },
//   },
//   joinButtons: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     [theme.breakpoints.down('sm')]: {
//       flexDirection: 'column-reverse',
//       width: '100%',
//       '& button': {
//         margin: '0.5em 0',
//       },
//     },
//   },
//   mobileButtonBar: {
//     [theme.breakpoints.down('sm')]: {
//       display: 'flex',
//       justifyContent: 'space-between',
//       margin: '1.5em 0 1em',
//     },
//   },
//   mobileButton: {
//     padding: '0.8em 0',
//     margin: 0,
//   },
// }));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
}

export default function DeviceSelectionScreen({
  name,
  roomName,
}: DeviceSelectionScreenProps) {
  const intl = useIntl();
  // const { isOpen, onOpen, onClose } = useDisclosure()
  // const classes = useStyles();
  const { connect: chatConnect } = useChatContext();
  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting,
  } = useVideoContext();
  const [createToken, { isLoading }] = useCreateTokenMutation();

  const disableButtons = isLoading || isAcquiringLocalTracks || isConnecting;
  // const theme = useTheme();
  // const mdUpHidden = useMediaQuery(theme.breakpoints.up('md'));
  // const smDownHidden = useMediaQuery(theme.breakpoints.down('sm'));
  // const { data: loggedInUser } = useGetLoggedInUserQuery();
  const handleJoin = () => {
    createToken({ b_guid: roomName }).then((token: any) => {
      videoConnect(token.data.token);
      chatConnect(token.data.token);
    });
  };

  if (isLoading || isConnecting) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "100%" }}
      >
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography
            variant="body2"
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            Conectare la ședință...
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Flex h="100%" flexWrap="wrap">
        <Show above="md">
          <Box
            w="350px"
            h="100%"
            bg="#fff"
            overflow="auto"
            p="24px"
            display="none"
          >
            <h2>
              {intl.formatMessage({
                id: "activity_details_general_description",
              })}
            </h2>
            <Text mt="16px">
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet auctor urna. Fusce pellentesque lorem eget lorem elementum sagittis. Morbi non lacus dictum, lobortis nisl vel, luctus urna. Donec enim tortor, sagittis nec sem sed, varius ornare enim. Curabitur non lectus sit amet purus dapibus mollis. Maecenas tristique rutrum orci eget tempus. */}
            </Text>
            <Divider my="24px" opacity="1" />

            <h2>
              {intl.formatMessage({ id: "activity_details_specializations" })}
            </h2>
            <List
              spacing={4}
              mt="16px"
              color="brandGray.500"
              fontSize="16px"
              p="0"
            >
              <ListItem display="flex" alignItems="center">
                <ListIcon
                  w="24px"
                  h="24px"
                  as={CheckmarkFilled}
                  size={32}
                  color="brand.500"
                  mt="2px"
                />
                <Text>{/* Lorem ipsum */}</Text>
              </ListItem>
            </List>
          </Box>
        </Show>
        <ActivityProviderNotes />
        <Flex
          flex="1"
          bg="#000"
          h="100%"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <LocalVideoPreview identity={name} />
          <Flex
            position="fixed"
            bottom="0"
            borderRadius={{ base: "0", sm: "16px" }}
            bg="rgba(255,255,255,1)"
            backdropFilter="blur(4px)"
            alignItems="center"
            p="16px"
            mb={{ base: "0", sm: "24px" }}
            zIndex="3"
            w={{ base: "100%", sm: "auto" }}
          >
            <ToggleAudioButton disabled={disableButtons} />
            <Spacer w="12px" />
            <ToggleVideoButton disabled={disableButtons} />
            <Spacer w="12px" />
            <SettingsMenu />
            <Spacer w="12px" />
            <Button
              style={{ height: "54px" }}
              data-cy-join-now
              onClick={handleJoin}
              disabled={disableButtons}
            >
              Intră în ședință
            </Button>
          </Flex>
        </Flex>
      </Flex>

      {/* <Typography variant="h5" className={classes.gutterBottom}>
        Join {roomName} as {loggedInUser?.f_name}
      </Typography>

      <Grid container justifyContent="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            {mdUpHidden && <>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
            </>}
            <SettingsMenu mobileButtonClass={classes.mobileButton} />
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
            <div>
              {smDownHidden && <>
                <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
              </>}
            </div>
            <div className={classes.joinButtons}>
              <Button variant="outlined" color="primary" onClick={() => { window.location.href = '/dashboard' }}>
                Cancel
              </Button>
              <Button
                size="lg"
                data-cy-join-now
                onClick={handleJoin}
                disabled={disableButtons}
              >
                Intră în ședință
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid> */}
    </>
  );
}
