import { Flex, Show } from "@chakra-ui/react";
import ClientNav from "./ClientNav";
import ProviderNav from './ProviderNav';
import { IRoles } from "../user/types";

type Props = {
  display?: string;
  userType: IRoles;
};

const BottomNavigation = ({ display, userType }: Props) => {
  return (
    <>
      <Show below="lg">
        <Flex
          display={display}
          w="full"
          bg="white"
          borderTopWidth="1px"
          borderColor="border.400"
          alignItems="center"
          position="sticky"
          bottom="0"
          fontSize="11px"
          fontWeight="700"
          style={{ paddingBottom: "env(safe-area-inset-bottom)" }}
          zIndex="2"
        >
          {userType === "registered_user" && <ClientNav />}
          {userType === "activity_provider" && <ProviderNav />}
        </Flex>
      </Show>
    </>
  );
};

export default BottomNavigation;
