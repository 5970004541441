import { Navigate } from "react-router-dom";
import { useGetMyActivitiesQuery } from "../../activity/service";
import { useGetActivityProviderQuery } from "../../activityProvider/service";
import { useGetUserGuidFromAccessToken } from "../../user/hooks";
import Loader from "../../shared/Loader";

type Props = {
  children: JSX.Element;
};

const OnboardedWithOneActivity = ({ children }: Props) => {
  const { data: myActivities, isLoading } = useGetMyActivitiesQuery();

  const hasAtLeastOneActivity =
    myActivities && myActivities.data.length ? true : false;

  if (isLoading) {
    return <Loader />;
  } else if (!hasAtLeastOneActivity) {
    return <Navigate to="/ap/onboarding/step/1" />;
  } else {
    return children;
  }
};

const ActivityProviderOnboarded = ({ children }: Props) => {
  const activityProviderGuid = useGetUserGuidFromAccessToken();

  const { data: activityProvider, isLoading } =
    useGetActivityProviderQuery(activityProviderGuid);

  const hasOnboardedOnStripe = activityProvider && activityProvider.stripe_account_id;

  if (isLoading) {
    return <Loader />;
  } else if (!hasOnboardedOnStripe) {
    // return <Navigate to="/ap/onboarding/stripe" />;
    return <Navigate to="/ap/onboarding-info" />;
  } else {
    return <OnboardedWithOneActivity>{children}</OnboardedWithOneActivity>;
  }
};

export default ActivityProviderOnboarded;
