import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Fonts } from "./shared/Fonts";
import theme from "./theme/chakra";
import { DEFAULT_LOCALE } from "./config";
import { IntlProvider } from "react-intl";
import getTranslations from "./locale";
import HomePage from "./home/routes/HomePage";
import SignIn from "./auth/routes/SignIn";
import SignUp from "./auth/routes/SignUp";
import ActivityProviderSignIn from "./auth/routes/ActivityProviderSignIn";
import ActivityProviderSignUp from "./auth/routes/ActivityProviderSignUp";
import ForgotPassword from "./auth/routes/ForgotPassword";
import ResetPassword from "./auth/routes/ResetPassword";
import OnboardingStep1 from "./onboarding/routes/OnboardingStep1";
import OnboardingStep2 from "./onboarding/routes/OnboardingStep2";
import OnboardingStep3 from "./onboarding/routes/OnboardingStep3";
import OnboardingStep4 from "./onboarding/routes/OnboardingStep4";
import ActivitiesPage from "./activity/routes/Activities";
import ActivityDetails from "./activity/routes/ActivityDetails";
import BookActivityPage from "./booking/routes/BookActivityPage";
import ChangeBookingPage from "./booking/routes/ChangeBookingPage";
import UserBookingsPage from "./booking/routes/UserBookingsPage";
import APBookingsPage from "./booking/routes/APBookingsPage";
import StripeOnboarding from "./onboardingActivityProvider/routes/StripeOnboarding";
import StripeOnboardingFinalized from "./onboardingActivityProvider/routes/StripeOnboardingFinalized";
import APOnboardingStep1 from "./onboardingActivityProvider/routes/OnboardingStep1";
import APOnboardingStep2 from "./onboardingActivityProvider/routes/OnboardingStep2";
import APOnboardingStep3 from "./onboardingActivityProvider/routes/OnboardingStep3";
import APOnboardingStep4 from "./onboardingActivityProvider/routes/OnboardingStep4";
import APOnboardingStep5 from "./onboardingActivityProvider/routes/OnboardingStep5";
import APOnboardingStep6 from "./onboardingActivityProvider/routes/OnboardingStep6";
import ActivityProviderBookingsPage from "./booking/routes/ActivityProviderBookingsPage";
import ActivityProviderArticlesPage from "./articles/routes/ActivityProviderArticlesPage";
import ActivityProviderArticlePage from "./articles/routes/ActivityProviderArticlePage";
import ActivityProviderEditArticlePage from "./articles/routes/ActivityProviderEditArticlePage";
import ActivityProviderAddArticlePage from "./articles/routes/ActivityProviderAddArticlePage";
import VideoCallApp from "./room/VideoCallApp";
import MyAccountPage from "./user/routes/MyAccountPage";
import PatientPage from "./patient/routes/PatientPage";
import PreferencesPage from "./user/routes/PreferencesPage";
import APMyAccountPage from "./activityProvider/routes/MyAccountPage";
import APTestimonialsPage from "./reviews/routes/APTestimonialsPage";
import PaymentsHistoryPage from "./payments/routes/PaymentsHistoryPage";
import PublicProfilePage from "./activityProvider/routes/PublicProfilePage";
import ContactPage from "./contact/routes/ContactPage";

import ProtectedComponent from "./routes/protected/ProtectedComponent";
import Reload from "./shared/Reload";
import VoucherHandler from "./shared/VoucherHandler";
import OnboardingInfo from "./onboardingActivityProvider/OnboardingInfo";
// import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';
import { createHashHistory } from 'history';
import { InitializeGoogleAnalytics } from "./utils/GoogleAnalyticsHelper";
import OauthHandle from "./auth/routes/OauthHandle";
import QLogin from "./auth/routes/QLogin";
import { redirectHTTPToHTTPS } from "./utils/requests";
import Enter from "./auth/routes/Enter";
import EnterWait from "./auth/routes/EnterWait";
import Unsub from "./auth/routes/Unsub";
import Error404 from "./auth/routes/Error404";

// TagManager.dataLayer({})
//Your initialization

// const tagManagerArgs = {
//   gtmId: 'GTM-MLJ5CN7'
// }
// TagManager.initialize(tagManagerArgs)

InitializeGoogleAnalytics()

const hist = createHashHistory();

ReactPixel.init('547021850714772');
ReactPixel.pageView();
ReactPixel.fbq('track', 'PageView');

hist.listen(location => {
  ReactPixel.pageView();
  ReactPixel.fbq('track', 'PageView');
});


function App(): JSX.Element {
  const location = useLocation();

  useEffect(() => {
    // HTTPS upgrade redirect
    redirectHTTPToHTTPS();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps



  const currentLanguage = "ro"; //This should be derived from app state when implementing multi language
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Reload />
      <VoucherHandler />
      <IntlProvider
        messages={getTranslations(currentLanguage)}
        locale={currentLanguage}
        defaultLocale={DEFAULT_LOCALE}
      >
        <Routes location={location}>
          <Route path="/" element={<HomePage />} />
          <Route path="/auth/login" element={<SignIn />} />
          <Route path="/auth/register" element={<SignUp />} />
          <Route path="/auth/enter" element={<Enter />} />
          <Route path="/auth/enter/wait" element={<EnterWait />} />
          <Route path="/auth/oauth/handle" element={<OauthHandle />} />
          <Route path="/auth/unsub" element={<Unsub />} />
          <Route path="/ap/auth/login" element={<ActivityProviderSignIn />} />
          <Route
            path="/ap/auth/register"
            element={<ActivityProviderSignUp />}
          />
          <Route
            path="/auth/forgot-password"
            element={<ForgotPassword userType="registered_user" />}
          />
          <Route
            path="/ap/auth/forgot-password"
            element={<ForgotPassword userType="activity_provider" />}
          />
          <Route
            path="/auth/qlogin"
            element={<QLogin />}
          />
          <Route
            path="/reset-password/:token"
            element={<ResetPassword userType="registered_user" />}
          />
          <Route
            path="/ap/reset-password/:token"
            element={<ResetPassword userType="activity_provider" />}
          />

          <Route
            path="/onboarding/step/1"
            element={
              <ProtectedComponent userType="registered_user" omitOnboarding>
                <OnboardingStep1 />
              </ProtectedComponent>
            }
          />
          <Route
            path="/onboarding/step/2"
            element={
              <ProtectedComponent userType="registered_user" omitOnboarding>
                <OnboardingStep2 />
              </ProtectedComponent>
            }
          />
          <Route
            path="/onboarding/step/3"
            element={
              <ProtectedComponent userType="registered_user" omitOnboarding>
                <OnboardingStep3 />
              </ProtectedComponent>
            }
          />
          <Route
            path="/onboarding/step/4"
            element={
              <ProtectedComponent userType="registered_user" omitOnboarding>
                <OnboardingStep4 />
              </ProtectedComponent>
            }
          />
          <Route
            path="/dashboard"
            element={<Navigate replace to="/activities" />}
          />
          <Route
            path="/my-account"
            element={
              <ProtectedComponent userType="registered_user">
                <MyAccountPage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/preferences"
            element={
              <ProtectedComponent userType="registered_user">
                <PreferencesPage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/onboarded"
            element={
              <ProtectedComponent userType="registered_user">
                <ActivitiesPage redirectUrl={"/activities"} />
              </ProtectedComponent>
            }
          />
          <Route
            path="/activities"
            element={
              <ProtectedComponent userType="registered_user">
                <ActivitiesPage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/activities/:guid"
            element={
              <ProtectedComponent userType="registered_user">
                <ActivityDetails />
              </ProtectedComponent>
            }
          />
          <Route
            path="/activities/:guid/book-activity"
            element={
              <ProtectedComponent userType="registered_user">
                <BookActivityPage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/activities/:a_guid/change-booking/:b_guid"
            element={
              <ProtectedComponent userType="registered_user">
                <ChangeBookingPage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/bookings"
            element={
              <ProtectedComponent userType="registered_user">
                <UserBookingsPage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/room/:activityId/:roomId"
            element={
              <ProtectedComponent userType="registered_user">
                <VideoCallApp />
              </ProtectedComponent>
            }
          />
          <Route
            path="/contact"
            element={
              <ProtectedComponent userType="registered_user">
                <ContactPage />
              </ProtectedComponent>
            }
          />

          <Route
            path="/ap/onboarding/stripe"
            element={
              <ProtectedComponent userType="activity_provider" omitOnboarding>
                <StripeOnboarding />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/onboarding/stripe-finalized"
            element={
              <ProtectedComponent userType="activity_provider" omitOnboarding>
                <StripeOnboardingFinalized />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/onboarding/step/1"
            element={
              <ProtectedComponent userType="activity_provider" omitOnboarding>
                <APOnboardingStep1 />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/onboarding/step/2"
            element={
              <ProtectedComponent userType="activity_provider" omitOnboarding>
                <APOnboardingStep2 />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/onboarding/step/3"
            element={
              <ProtectedComponent userType="activity_provider" omitOnboarding>
                <APOnboardingStep3 />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/onboarding/step/4"
            element={
              <ProtectedComponent userType="activity_provider" omitOnboarding>
                <APOnboardingStep4 />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/onboarding/step/5"
            element={
              <ProtectedComponent userType="activity_provider" omitOnboarding>
                <APOnboardingStep5 />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/onboarding/step/6"
            element={
              <ProtectedComponent userType="activity_provider" omitOnboarding>
                <APOnboardingStep6 />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/my-account"
            element={
              <ProtectedComponent userType="activity_provider">
                <APMyAccountPage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/onboarded"
            element={
              <ProtectedComponent userType="activity_provider">
                <ActivityProviderBookingsPage redirectUrl="/ap/dashboard" />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/dashboard"
            element={
              <ProtectedComponent userType="activity_provider">
                <ActivityProviderBookingsPage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/articles"
            element={
              <ProtectedComponent userType="activity_provider">
                <ActivityProviderArticlesPage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/articles/:guid"
            element={
              <ProtectedComponent userType="activity_provider">
                <ActivityProviderArticlePage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/articles/:guid/edit"
            element={
              <ProtectedComponent userType="activity_provider">
                <ActivityProviderEditArticlePage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/articles/add"
            element={
              <ProtectedComponent userType="activity_provider">
                <ActivityProviderAddArticlePage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/sessions"
            element={
              <ProtectedComponent userType="activity_provider">
                <APBookingsPage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/patient/:userId/:activityId"
            element={
              <ProtectedComponent userType="activity_provider">
                <PatientPage />
              </ProtectedComponent>
            }
          />
          <Route
            path="ap/room/:activityId/:roomId"
            element={
              <ProtectedComponent userType="activity_provider">
                <VideoCallApp />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/testimonials"
            element={
              <ProtectedComponent userType="activity_provider">
                <APTestimonialsPage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/payments"
            element={
              <ProtectedComponent userType="activity_provider">
                <PaymentsHistoryPage />
              </ProtectedComponent>
            }
          />
          <Route
            path="/ap/public-profile"
            element={
              <ProtectedComponent userType="activity_provider">
                <PublicProfilePage />
              </ProtectedComponent>
            }
          />
          <Route path="/ap/onboarding-info" element={<OnboardingInfo />} />
          <Route path="/error404" element={<Error404 />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </IntlProvider>
    </ChakraProvider>
  );
}

export default App;
