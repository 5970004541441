import moment from "moment";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Flex,
  Avatar,
  Text,
  IconButton,
  Divider,
  Show,
  Button,
} from "@chakra-ui/react";
import { ArrowRight } from "@carbon/icons-react";
import { useGetBookingsCountQuery } from "../service";
import commissionAndNetFee from "../../activity/activityPrice/price";
import { IBooking } from "../types";

type Props = {
  booking: IBooking;
  start_datetime: string;
  end_datetime: string;
};

const SessionPreview = ({ booking, start_datetime, end_datetime }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { display_name, start_time, u_guid, a_guid, price, is_free, is_free_slot_booking, is_from_package } = booking;

  const { netFee } = commissionAndNetFee(price ? Number(price.value) : 0);

  const { data: myNextBookings } = useGetBookingsCountQuery({
    a_guid,
    u_guid,
    start_datetime,
    end_datetime,
  });

  const myNextBookingsCount = myNextBookings ? myNextBookings.booking_count : 0;

  return (
    <Flex
      flexWrap="wrap"
      layerStyle="card"
      mt="16px"
      onClick={() => navigate(`/ap/patient/${u_guid}/${a_guid}`)}
      cursor="pointer"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex
        alignItems="center"
        position="relative"
        w={{ base: "100%", lg: "33.33%" }}
        flexWrap="wrap"
      >
        <Avatar w="48px" h="48px" mr="12px" name="Maria" fontWeight="600" />
        <Box>
          <h3>{display_name}</h3>
          <Text>
            <FormattedMessage
              id="booking_session"
              values={{ count: myNextBookingsCount }}
            />
          </Text>
        </Box>
        <Show above="lg">
          <Divider
            orientation="vertical"
            borderColor="border.500"
            position="absolute"
            top="0"
            bottom="0"
            right="0"
            h="100%"
          />
        </Show>
        <Show below="lg">
          <Divider borderColor="border.500" my="16px" />
        </Show>
      </Flex>
      <Flex
        alignItems="center"
        position="relative"
        pl={{ base: "0", lg: "16px" }}
        w={{ base: "100%", lg: "33.33%" }}
        flexWrap="wrap"
      >
        {is_free ? (<Box textAlign="left" mr="12px">
          <h3>Ședință Gratuită</h3>
          <Text>0 RON</Text>
        </Box>) : (
            <Box textAlign="left" mr="12px">
              <h3>{intl.formatMessage({ id: 'charged' })}</h3>
              <Text>
                {price ? `${netFee} RON ` : ""}
                {is_from_package ? (intl.formatMessage({ id: 'bookings_package' })) : ""}
              </Text>

          </Box>)}
        <Show above="lg">
          <Divider
            orientation="vertical"
            borderColor="border.500"
            position="absolute"
            top="0"
            bottom="0"
            right="0"
            h="100%"
          />
        </Show>
        <Show below="lg">
          <Divider borderColor="border.500" my="16px" />
        </Show>
      </Flex>
      <Flex
        alignItems="center"
        position="relative"
        pl={{ base: "0", lg: "16px" }}
        w={{ base: "100%", lg: "33.33%" }}
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <Box
          textAlign="left"
          mr="12px"
          style={{ maxWidth: "calc(100% - 60px)" }}
        >
          <h3>
            <Text noOfLines={1}>
              {is_free_slot_booking ? intl.formatMessage({ id: 'booking_needs_appointment' }) : moment(start_time).format("dddd, DD MMMM YYYY, HH:mm")}
            </Text>
          </h3>
        </Box>
        {!is_free_slot_booking && <>
          <Show above="lg">
            <IconButton
              w="44px"
              h="44px"
              aria-label="Mergi la pagina pacientului"
              icon={<ArrowRight size={24} />}
            />
          </Show>
          <Show below="lg">
            <Button w="100%" mt="16px">
              {intl.formatMessage({ id: "patient_page" })}
            </Button>
          </Show>
        </>}
      </Flex>
    </Flex>
  );
};

export default SessionPreview;
