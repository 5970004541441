import React, { useState } from 'react'
import { Add } from '@carbon/icons-react';
import { Box, Textarea, IconButton } from '@chakra-ui/react';
import ResizeTextarea from "react-textarea-autosize";
import { useIntl } from 'react-intl';
import { usePostNotesMutation } from './service';
type Props = {
    userId: string
}
const CreateNote = ({ userId }: Props) => {
    const intl = useIntl();
    const [text, setText] = useState('')
    const [postNote, { isLoading }] = usePostNotesMutation()
    const saveNote = () => {
        if (text.length > 3) {
            postNote({
                note: text,
                featured: false,
                c_for: userId
            })
            setText('')
        }
    }
    const updateText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.currentTarget.value)
    }
    return (
        <Box position="relative">
            <Textarea
                mt="16px"
                placeholder={intl.formatMessage({ id: "add_note" })}
                minH="44px"
                overflow="hidden"
                w="100%"
                resize="none"
                minRows={1}
                as={ResizeTextarea}
                position="relative"
                zIndex="1"
                pr="52px"
                value={text}
                onChange={updateText}
            />
            <IconButton
                onClick={saveNote}
                isLoading={isLoading}
                width="44px"
                height="44px"
                minW="44px"
                minH="44px"
                position="absolute"
                bottom="0px"
                right="0px"
                zIndex="1"
                icon={<Add size={24} />}
                aria-label={intl.formatMessage({ id: "add_note" })}
            />
        </Box>
    )
}

export default CreateNote