import { useState } from "react";
import { Input, Box, Button, Grid, Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Form, FormControl } from "../../shared/form";
import {
  useGetLoggedInUserQuery,
  useModifyUserMutation,
} from "../../user/service";
import YearSelector from "../../shared/YearSelector";
import GenderSelector from "../../shared/GenderSelector";
import { initialFormValues, initialFormErrors } from "./defaults";
import { IMediaAsset } from "../../mediaAssets/types";
import { IRegisteredUser } from "../../user/types";
import AvatarUploader from "../../mediaAssets/AvatarUploader";
import PresetTextEditor from "../../shared/textEditor/presetTextEditor";
import useNotification from "../../shared/hooks/useNotification";

type IFormValues = {
  image: IMediaAsset | null;
  firstName: string;
  lastName: string;
  gender: string;
  birthYear: string;
};

type IFormErrors = {
  firstName: string | null;
  lastName: string | null;
  gender: string | null;
  birthYear: string | null;
};

const APInfoForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const notify = useNotification();

  const { data: loggedInUser } = useGetLoggedInUserQuery();
  const [modifyUser, { isLoading }] = useModifyUserMutation();

  const [formValues, setFormValues] = useState<IFormValues>({
    ...initialFormValues,
    firstName: loggedInUser?.f_name || "",
    lastName: loggedInUser?.l_name || "",
  });
  const [formErrors] = useState<IFormErrors>(initialFormErrors);

  const [description, setDescription] = useState<string>("");

  const { firstName, lastName, birthYear, gender, image } = formValues;

  const handleDescriptionChange = (htmlContent: string) => {
    setDescription(htmlContent);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleYearChange = (year: string) => {
    setFormValues({ ...formValues, birthYear: year });
  };

  const handleGenderChange = (e: { target: { value: any } }) => {
    setFormValues({ ...formValues, gender: e.target.value });
  };

  const handleImageUpload = (mediaAssets: IMediaAsset[]) => {
    setFormValues({ ...formValues, image: mediaAssets[0] || null });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (description === "<p></p>") {
      notify("error", "ap_onboarding_step1_description_required");
      return;
    }
    const fieldsToChange: Partial<IRegisteredUser> = {
      f_name: firstName,
      l_name: lastName,
      birthdate: birthYear,
      gender: gender,
      profile_img: image,
      description: description,
    };

    if (loggedInUser) {
      modifyUser({ guid: loggedInUser.guid, user: fieldsToChange })
        .then(() => navigate("/ap/onboarding/step/2"))
        .catch((error) => console.log("Error"));
    }
  };

  return (
    <Box w="100%">
      <Form onSubmit={handleSubmit}>
        <Grid
          gridTemplateColumns="1fr auto 1fr"
          gap="16px"
          alignItems="center"
          mb="24px"
        >
          <Box h="1px" bgColor="border.500"></Box>
          <Box d="flex" w="176px" h="176px">
            <FormControl errorMessage={null}>
              <AvatarUploader
                onImageUpload={handleImageUpload}
                label={intl.formatMessage({
                  id: "ap_onboarding_step1_avatar_uploader_label",
                })}
              />
            </FormControl>
          </Box>
          <Box h="1px" bgColor="border.500"></Box>
        </Grid>

        <Grid
          gridGap="24px"
          gridTemplateColumns={{ base: "1fr", md: "repeat(2, minmax(0, 1fr))" }}
          mb="24px"
        >
          <FormControl errorMessage={formErrors.firstName}>
            <Input
              required
              size="lg"
              placeholder={intl.formatMessage({
                id: "ap_onboarding_step1_first_name",
              })}
              type="text"
              name="firstName"
              value={firstName}
              onChange={(e) => onInputChange(e)}
            />
          </FormControl>

          <FormControl errorMessage={formErrors.lastName}>
            <Input
              required
              size="lg"
              placeholder={intl.formatMessage({
                id: "ap_onboarding_step1_name",
              })}
              type="text"
              name="lastName"
              value={lastName}
              onChange={(e) => onInputChange(e)}
            />
          </FormControl>

          <FormControl errorMessage={formErrors.birthYear}>
            <YearSelector
              placeholder={intl.formatMessage({
                id: "ap_onboarding_step1_birth_year",
              })}
              startYear={1933}
              onYearSelect={handleYearChange}
            />
          </FormControl>

          <FormControl errorMessage={formErrors.gender}>
            <GenderSelector onChange={handleGenderChange} value={gender} />
          </FormControl>
        </Grid>
        <Box textAlign="left" mb="24px">
          <Text
            textStyle="largeP"
            fontWeight={"700"}
            mb="12px"
            textAlign="left"
          >
            {intl.formatMessage({
              id: "ap_onboarding_step1_description_placeholder",
            })}
          </Text>
          <PresetTextEditor
            onContentChange={handleDescriptionChange}
            content={description}
            formatStyle="paragraph"
          />
        </Box>

        <Button w="full" size="lg" type="submit" isLoading={isLoading}>
          {intl.formatMessage({ id: "user_onboarding_continue_btn_label" })}
        </Button>
      </Form>
    </Box>
  );
};

export default APInfoForm;
