import { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, CloseButton, IconButton } from "@chakra-ui/react";
import { Catalog } from "@carbon/icons-react";
import { useGetBookingQuery } from "../../booking/service";
import { useIsActivityProvider } from "../../user/hooks";
import Notes from "../../notes/Notes";
import ImportantNotes from "../../notes/ImportantNotes";

export default function ActivityProviderNotes() {
  const [showNotes, toggleNotes] = useState(false);

  const { activityId = "", roomId = "" } = useParams();
  const { data } = useGetBookingQuery(
    { a_guid: activityId, b_guid: roomId },
    { skip: !activityId || !roomId }
  );
  const isActivityProvider = useIsActivityProvider();
  const userGuid = data?.u_guid || "";

  if (!isActivityProvider || !userGuid) return null;

  return (
    <Box position="relative">
      {showNotes && (
        <Box
          w={{ base: "100%", lg: "350px" }}
          h="100%"
          bg="#fff"
          position={{ base: "fixed", lg: "relative" }}
          top="0"
          right="0"
          left="0"
          bottom="0"
          zIndex={{ base: "100", lg: "4" }}
        >
          <CloseButton
            position="absolute"
            zIndex="4"
            top="16px"
            right="8px"
            bg="#fff"
            onClick={() => toggleNotes(false)}
          />
          <Box h="50%" overflow="auto" bg="#fff" position="relative" zIndex="3">
            <Notes userId={userGuid} radius="0" shadow="none" padding="16px" />
            <Box
              bg="linear-gradient(180deg, rgba(255,255,255,0.00) 0%, #FFFFFF 90%)"
              position="sticky"
              bottom="0"
              h="40px"
            />
          </Box>
          <Box
            h="50%"
            overflow="auto"
            bg="#272F37"
            position="relative"
            zIndex="3"
          >
            <ImportantNotes
              userId={userGuid}
              radius="0"
              shadow="none"
              padding="16px"
            />
            <Box
              bg="linear-gradient(180deg, rgba(255,255,255,0.00) 0%, #272F37 90%)"
              position="sticky"
              bottom="0"
              h="40px"
            />
          </Box>
        </Box>
      )}
      <IconButton
        isRound
        w="128px"
        height="128px"
        mt="-64px"
        pr="24px"
        left="-64px"
        top="50%"
        position="absolute"
        zIndex="3"
        aria-label="Arată notițele"
        icon={<Catalog size={32}style={{ width: "36px", height: "36px" }} />}
        justifyContent="flex-end"
        onClick={() => toggleNotes(true)}
      />
    </Box>
  );
}
