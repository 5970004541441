import { useIntl } from "react-intl";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  // InputGroup,
  // Input,
  // InputRightElement,
} from "@chakra-ui/react";
// import { Search } from "@carbon/icons-react";
import ActiveSessions from "./ActiveSessions";
import PastSessions from "./PastSessions";

const Sessions = () => {
  const intl = useIntl();

  return (
    <Box>
      <>
        <h1>{intl.formatMessage({ id: "ap_sessions" })}</h1>
        <Tabs colorScheme="brand" mt="16px">
          <Flex flexWrap="wrap" justifyContent="space-between">
            <TabList border="0">
              <Tab fontSize="13px" px="0" mr="24px">
                {intl.formatMessage({ id: "booking_next_sessions_tab_label" })}
              </Tab>
              <Tab fontSize="13px" px="0" mr="24px">
                {intl.formatMessage({ id: "booking_last_session_tab_label" })}
              </Tab>
            </TabList>
            {/* <InputGroup
              width={{ base: "100%", md: "320px" }}
              mt={{ base: "16px", md: "0" }}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: "activity_search_placeholder",
                })}
                type="text"
                name="search"
              />
              <InputRightElement width="40px" h="100%" pointerEvents="none">
                <Flex
                  h="100%"
                  w="40px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Search size={20} />
                </Flex>
              </InputRightElement>
            </InputGroup> */}
          </Flex>

          <TabPanels>
            <TabPanel px="0" py="0">
              <ActiveSessions />
            </TabPanel>
            <TabPanel px="0" py="0">
              <PastSessions />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
    </Box>
  );
};

export default Sessions;
