import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import CountdownTimer from "../../../shared/countdown/CountdownTimer";
import {
  Box,
  IconButton,
  Flex,
  Show,
  Text,
  Avatar,
} from "@chakra-ui/react";
import { ArrowLeft } from "@carbon/icons-react";
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useGetBookingQuery } from '../../../booking/service';
import { useGetActivityQuery } from '../../../activity/service';
import { useIsActivityProvider } from '../../../user/hooks';
import { fromServertoLocalYMDTHms } from '../../../booking/utils';

export default function PreJoinScreens() {
  const { user } = useAppState();
  const navigate = useNavigate();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { activityId, roomId } = useParams();
  const isActivityProvider = useIsActivityProvider();
  const { data: activity } = useGetActivityQuery(activityId ? activityId : '', { skip: isActivityProvider });
  const { data } = useGetBookingQuery({ a_guid: activityId ? activityId : '', b_guid: roomId ? roomId : '' }, { skip: !activityId || !roomId })
  const { display_name, start_time, status } = data ? data : { display_name: null, start_time: null, status: null }
  useEffect(() => {
    if (status && status !== 'active') {
      navigate(-1)
    }
  }, [status, navigate])
  const [name] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (roomId) {
      setRoomName(roomId);
    }
  }, [user, roomId]);

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, mediaError]);
  return (
    <Flex
      h="100%"
      flexDirection="column"
      w="full"
      justifyContent="stretch"
    >
      <Flex
        w="full"
        bg="white"
        py={{ base: "16px", lg: "24px" }}
        px={{ base: "16px", lg: "24px" }}
        borderBottomWidth="1px"
        borderColor="border.400"
        justifyContent="space-between"
        alignItems="center"
        h={{ base: "64px", lg: "80px" }}
        zIndex="3"
      >
        <Flex alignItems="center" zIndex="1">
          <IconButton
            bg="#fff"
            color="brandGray.500"
            border="1px solid var(--chakra-colors-border-500)"
            borderRadius="100%"
            mr="8px"
            w="44px"
            h="44px"
            minW="44px"
            minH="44px"
            position="relative"
            _hover={{
              color: "#fff",
              background: "brand.500",
              borderColor: "brand.500",
            }}
            icon={<ArrowLeft size={24} />}
            aria-label="înapoi"
            onClick={() => navigate(`/ap/dashboard`)}
          />
          {display_name &&
            <Flex alignItems="center">
              <Avatar
                name={display_name}
                w="44px"
                h="44px"
                mr="8px"
                display={{ base: "none", md: "flex" }}
                src={(activity && activity.images[0]) ? activity.images[0].url : ""}
              />
              <Box>
                <Text
                  fontSize="18px"
                  lineHeight="normal"
                  color="brandGray.500"
                  fontWeight="600"
                >
                  {display_name}
                </Text>
                {/* <Text>34 de ani</Text> */}
              </Box>
            </Flex>
          }
        </Flex>
        <Show above="md">
          <Text
            color="brandGray.500"
            fontWeight="600"
            position="absolute"
            left="0"
            right="0"
            textAlign="center"
            zIndex="0"
            fontSize="20px"
          >
            Lobby ședință
          </Text>
        </Show>
        {start_time &&
          <Flex alignItems="center" zIndex="1" justifyContent="center" textAlign="center">
            <CountdownTimer targetDate={fromServertoLocalYMDTHms(start_time)} />
          </Flex>
        }
      </Flex>
      <MediaErrorSnackbar error={mediaError} />
      <DeviceSelectionScreen name={name} roomName={roomName} />
    </Flex>
  );
}
