import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauthorization from "../store/baseQuery";
import {
  IActivitySchedulePayload,
  IActivitySchedule,
  IAddExceptionPayload,
} from "./types";

export const activityScheduleApi = createApi({
  reducerPath: "activityScheduleApi",
  baseQuery: baseQueryWithReauthorization,
  tagTypes: ["ActivitySchedule"],
  endpoints: (builder) => ({
    getActivitySchedule: builder.query<
      IActivitySchedule,
      IActivitySchedulePayload
    >({
      query: (payload: IActivitySchedulePayload) => {
        const { a_guid, ...queryParams } = payload;
        return {
          url: `/activity-schedule/${a_guid}`,
          method: "GET",
          params: queryParams,
        };
      },
      providesTags: ["ActivitySchedule"],
    }),
    addException: builder.mutation<void, IAddExceptionPayload>({
      query: (payload: IAddExceptionPayload) => {
        return {
          url: "/activity-schedule/exception",
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["ActivitySchedule"],
    }),
  }),
});

export const { useGetActivityScheduleQuery, useAddExceptionMutation } =
  activityScheduleApi;
