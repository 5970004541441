import moment from "moment";
import { useIntl } from "react-intl";
import { VStack, Box, Flex } from "@chakra-ui/react";
import { EventSchedule } from "@carbon/icons-react";
import BookingPreview from "./BookingPreview";
import Loader from "../../shared/Loader";
import useGetMyActiveBookings from "../hooks/useGetMyActiveBookings";
import { IBooking } from "../types";

const PastBookings = () => {
  const intl = useIntl();

  const { data, isLoading } = useGetMyActiveBookings({
    start_datetime: "2022-09-01T00:00:00",
    end_datetime: moment()
      .subtract(1, "hour")
      .endOf("hour")
      .format("YYYY-MM-DDTHH:mm:ss"),
  });

  const activeBookings =
    data && data.length
      ? data.filter((booking) => booking.status === "active")
      : [];

  const sortedBookings = activeBookings.sort(
    (a: IBooking, b: IBooking) =>
      moment(b.start_time).valueOf() - moment(a.start_time).valueOf()
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {sortedBookings.length === 0 && (
        <>
          <Box layerStyle="card" mt="24px">
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              textAlign="center"
            >
              <Flex
                borderRadius="100%"
                alignItems="center"
                justifyContent="center"
                color="#fff"
                w="80px"
                h="80px"
                bg="gray.500"
              >
                <EventSchedule size={32}/>
              </Flex>
              <h2>
                {intl.formatMessage({ id: "booking_no_past_bookings_title" })}
              </h2>
              <p>
                {intl.formatMessage({
                  id: "booking_no_active_bookings_subtitle",
                })}
              </p>
            </Flex>
          </Box>
        </>
      )}

      {sortedBookings.length > 0 && (
        <VStack spacing="16px" mt="16px" alignItems="stretch">
          {sortedBookings.map((booking, idx) => (
            <BookingPreview key={idx} booking={booking} isInThePast={true} />
          ))}
        </VStack>
      )}
    </>
  );
};

export default PastBookings;
