import moment from "moment";
import "moment/locale/ro";

const DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";

const getRoLocaleDate = (dateString: string, format: string) => {
  return moment(dateString).locale("ro").format(format);
};

export { getRoLocaleDate, DATETIME_FORMAT };
