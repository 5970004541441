const serialize = (obj: Record<string, string>): string => {
  let serialized = "";
  for (const key in obj) {
    serialized += `${key}=${obj[key]},`;
  }

  return serialized.slice(0, -1);
};

export default serialize;
