import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { Button, Box, Text } from "@chakra-ui/react";
import {
  useGetLoggedInUserQuery,
  useModifyUserMutation,
} from "../../user/service";
import { IRegisteredUser } from "../../user/types";
import { Form, FormControl } from "../../shared/form";
import YearSelector from "../../shared/YearSelector";
import GenderSelector from "../../shared/GenderSelector";
import GenderTherapistSelector from "../../shared/GenderTherapistSelector";

type IFormTypes = {
  birthYear: string;
  gender: string;
  preferredAP: string;
};

const initialFormValues: IFormTypes = {
  birthYear: "",
  gender: "",
  preferredAP: "",
};

const UserInfoForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { state } = useLocation();

  const issuesToFix =
    (state as { issues_to_fix: string[] })?.issues_to_fix || [];
  const { data: loggedInUser } = useGetLoggedInUserQuery();
  const [modifyUser, { isLoading }] = useModifyUserMutation();

  const [formValues, setFormValues] = useState<IFormTypes>(initialFormValues);
  const { birthYear, gender, preferredAP } = formValues;

  const handlePreferencesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormValues({
      ...formValues,
      preferredAP: e.target.value,
    });
  };

  const handleYearChange = (year: string) => {
    setFormValues({ ...formValues, birthYear: year });
  };

  const handleGenderChange = (e: { target: { value: any } }) => {
    setFormValues({ ...formValues, gender: e.target.value });
  };


  const handleSkipToNextStep = () => {
    navigate("/onboarding/step/4");
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (loggedInUser) {
      const fieldsToChange: Partial<IRegisteredUser> = {
        birthdate: birthYear,
        gender: gender || null,
        profile: {
          ...loggedInUser.profile,
          issues_to_fix: issuesToFix,
          preferred_activity_providers:
            preferredAP && preferredAP !== "no_preference" ? [preferredAP] : [],
        },
        labels: [...loggedInUser.labels, "onboarded"],
      };

      const patchPayload = {
        guid: loggedInUser.guid,
        user: fieldsToChange,
      };
      modifyUser(patchPayload)
        .then(() => {
          navigate("/onboarding/step/4");
        })
        .catch((error) => console.log("Patch Error", error));
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Box
        maxW={{ base: "100%", md: "310px" }}
        my="24px"
        mx="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="16px"
      >
        <FormControl errorMessage={null}>
          <YearSelector
            startYear={1933}
            placeholder={intl.formatMessage({
              id: "user_onboarding_step3_birth_year",
            })}
            onYearSelect={handleYearChange}
          />
        </FormControl>

        <FormControl errorMessage={null}>
          <GenderSelector onChange={handleGenderChange} value={gender} />
        </FormControl>

        <FormControl errorMessage={null}>
          <GenderTherapistSelector
            onChange={handlePreferencesChange}
            value={preferredAP}
          />
        </FormControl>
      </Box>

      <Button
        w="full"
        maxW={{ base: "100%", md: "310px" }}
        m="auto"
        size="lg"
        type="submit"
        isLoading={isLoading}
      >
        {intl.formatMessage({ id: "user_onboarding_continue_btn_label" })}
      </Button>
      <Text m="16px 0 24px 0" color="#696871" fontWeight="600">
        <Button
          variant="link"
          onClick={handleSkipToNextStep}
        >
          {intl.formatMessage({ id: "user_onboarding_step2_link_label" })}
        </Button>
      </Text>
    </Form>
  );
};

export default UserInfoForm;
