import { Text, Box, Heading } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import Steps from "../../shared/Steps";
import Loader from "../../shared/Loader";
import APInfoForm from "./APInfoForm";
import { useGetLoggedInUserQuery } from "../../user/service";

const Step1 = () => {
  const intl = useIntl();
  const {
    data: activityProvider,
    isLoading,
    error,
  } = useGetLoggedInUserQuery();

  return (
    <Steps activeStep={1} stepCount={6}>
      {isLoading && <Loader />}
      {!isLoading && activityProvider && (
        <>
          <Heading as="h1" my="8px">
            <FormattedMessage
              id="ap_onboarding_step1_title"
              values={{ name: activityProvider.f_name }}
            />
          </Heading>
          <Text textStyle="largeP" mb="32px">
            {intl.formatMessage({ id: "ap_onboarding_step1_subtitle" })}
          </Text>
          <Box w={{ base: "full", md: "xl" }}>
            <APInfoForm />
          </Box>
        </>
      )}
      {!isLoading && !activityProvider && error && (
        <Text>{intl.formatMessage({ id: "ap_onboarding_step1_error" })}</Text>
      )}
    </Steps>
  );
};

export default Step1;
